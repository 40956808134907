import React from 'react';
import { JSX } from 'react/jsx-runtime';

const Icon = (rest: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    preserveAspectRatio="xMidYMid slice"
    style={{ marginBottom: '-10px' }}
    viewBox="-174.438 90.73 1432.767 119.745"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M 1258.325 213.333 C 1258.325 213.333 129.136 215.661 -179.319 212.681 L -181.9 189.956 L 854.684 189.956 C 879.022 189.956 902.289 179.948 919.027 162.279 L 932.119 148.461 C 948.857 130.792 972.124 120.784 996.462 120.784 L 1219.639 120.784 C 1235.382 120.784 1249.612 111.411 1255.828 96.947 L 1258.101 91.659"
      fill="#536121"
      id="Provani-shape"
      style={{ strokeWidth: '0px' }}
      transform="matrix(1, 0, 0, 1, 0.000006, -0.000015)"
      width="100%"
    />
  </svg>
);

export default Icon;
