/* eslint-disable sort-keys-fix/sort-keys-fix */

import Logo from './Logo';

export const baseColors = {
  background: '#fff',
  black: '#050404',
  darkGreen: '#858C4A',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
    '#414042',
  ],
  lightGreen: '#A1D798',
  orange: '#D3651F',
  red: '#f00101',
  white: '#FFFDF2',
};

export const colors = {
  ...baseColors,
  danger: baseColors.red,
  dark: baseColors.gray[5],
  darkGray: baseColors.gray[5],
  info: baseColors.lightGreen,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.black,
  primaryBlack: baseColors.black,
  primaryWhite: baseColors.white,
  secondaryDarkGreen: baseColors.darkGreen,
  secondaryGreen: baseColors.darkGreen,
  success: baseColors.lightGreen,
  text: baseColors.gray[5],
  textMuted: baseColors.gray[3],
  warning: baseColors.orange,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: "'Source Serif 4', serif",
  heading: 'DM Serif Display',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 700,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = [
  '0.75rem', // 12px (80%),
  '0.875rem',
  '1rem', // 16px
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem', // 32px
  '2.25rem',
  '2.5rem',
  '3.5rem', // 56px
  '4.5rem',
  '5.5rem',
  '6rem',
];

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '1150px',
  md: '720px',
  sm: '540px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  lg: '0.3rem',
  pill: '50rem',
  sm: '0.2rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
  marginBottom: '1rem',
  marginTop: '1rem',
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  a: {
    ':hover': {
      color: colors.primary,
    },
    color: colors.darkGray,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.secondaryGreen,
    color: colors.white,
    fontStyle: 'normal',
    fontWeight: 'bold',
    textTransform: 'normal',
  },
  h1: {
    ...heading,
    fontSize: fontSizes[8],
  },
  h2: {
    ...heading,
    fontSize: fontSizes[7],
  },
  h3: {
    ...heading,
    fontSize: fontSizes[6],
  },
  h4: {
    fontSize: fontSizes[2],
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.black,
  },
  li: {
    bulletBorderRadius: '5px',
    bulletColor: colors.black,
  },
  p: {
    fontSize: fontSizes[2],
    lineHeight: lineHeights.paragraph,
    margin: '0',
    marginBottom: '1.7rem',
  },
  root: {
    backgroundColor: colors.background,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  rteHighlight: {
    ':hover': {
      color: colors.black,
    },
    color: colors.secondaryGreen,
    textDecoration: 'underline',
  },
  table: {
    borderCollapse: 'collapse',

    color: colors.gray[3],

    marginBottom: '3rem',
    // todo
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '12px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: fontSizes[1],
  fontWeight: fontWeights.bold,
  height: '48px',
  minWidth: '194px',
  textTransform: 'none',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.orange,
      color: colors.orange,
    },
    backgroundColor: colors.orange,
    color: colors.white,
  },
  'primary-with-background': {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: 'white',
      color: colors.orange,
    },
    backgroundColor: colors.orange,
    color: colors.background,
  },
  secondary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
      borderStyle: 'solid',
      borderWidth: '1px',
      color: colors.white,
    },
    backgroundColor: colors.background,
    color: colors.orange,
  },
};

export const header = {
  activeColor: colors.black,
  backgroundColor: colors.primaryWhite,
  color: colors.black,
};

export const headerBottom = {
  activeColor: colors.black,
  activeFontWeight: '700',
  backgroundColor: colors.primaryWhite,
  color: colors.black,
  fontSize: '14px',
  fontWeight: '400',
};

export const mobileNavigation = {
  arrowColor: colors.lightGray,
  chevronColor: colors.darkGray,
};

export const footer = {
  activeColor: colors.secondaryGreen,
  backgroundColor: colors.white,
  color: colors.black,
  minHeight: '160px',
};

export const cardCtas = {
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.secondaryGreen,
  color: 'rgba(138, 188, 36, 0.8)',
  fontSize: '14px',
  hovercolor: colors.secondaryGreen,
  letterSpacing: '0px',
  lineHeight: '14px',
  textTransform: 'normal',
};

export const documentDownload = {
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.secondaryGreen,
  color: 'rgba(138, 188, 36, 0.8)',
  fontWeight: 'bold',
  hovercolor: colors.secondaryGreen,
};

export const formInputs = {
  activeColor: colors.secondaryGreen,
  borderRadius: '4px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
};

export const categoryCards = {
  backgroundColor: colors.secondaryGreen,
  titleFontWeight: 'bold',
};

export const search = {
  activeColor: colors.secondaryGreen,
  borderRadius: '0px',
  fontWeight: 'normal',
  textTransform: 'normal',
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.secondaryGreen,
    color: colors.white,
    titleFontWeight: 'bold',
  },
  focusText: {
    backgroundColor: colors.secondaryGreen,
    color: colors.white,
  },
};

export const shortBanner = {
  backgroundColor: colors.secondaryGreen,
  color: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};

export const upComingEvents = {
  backgroundColor: colors.secondaryGreen,
  color: colors.white,
  titleFontWeight: 'bold',
};

export const infoSection = {
  backgroundColor: colors.secondaryGreen,
  color: colors.white,
};

export const ingredients = {
  backgroundColor: colors.secondaryGreen,
  color: colors.white,
  hoverColor: colors.primaryWhite,
};

export const salesPerson = {
  borderColor: colors.primaryWhite,
};

export const dealer = {
  textColor: colors.secondaryGreen,
  titleFontWeight: 'bold',
};

export const navItems = {
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0px',
  selectedFontWeight: 400,
  textTransform: 'normal',
};

export const imageAndText = {
  color: colors.black,
};

export const media = {
  backgroundColor: 'transparent',
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const table = {
  headerBackgroundColor: colors.secondaryGreen,
  headerColor: colors.white,
  headerFontSize: '12px',
  headerLetterSpacing: '0px',
  scrollbarColor: colors.primaryWhite,
};

export const customBlockCards = {
  backgroundColor: colors.secondaryGreen,
  titleFontWeight: 'bold',
};

export const adviceCardModule = {
  backgroundColor: colors.secondaryGreen,
  titleFontWeight: 'bold',
};

export const contacts = {
  office: {
    mapTextColor: colors.secondaryGreen,
    mapTextFontSize: '14px',
    mapTextFontWeight: 'bold',
    mapTextLetterSpacing: '0px',
    mapTextLineHeight: '2',
    mapTextTransform: 'normal',
    openingHoursFontSize: '16px',
  },
  titleFontWeight: 'bold',
};

export const heroSection = {
  titleFontWeight: 'bold',
};

export const newsListing = {
  titleFontWeight: 'bold',
};

export const productListing = {
  titleFontWeight: 'bold',
};

export const cookieBanner = {
  backgroundColor: colors.secondaryGreen,
};

export default {
  adviceCardModule,
  breakpoints,
  buttons,
  cardCtas,
  categoryCards,
  colors,
  contacts,
  cookieBanner,
  ctaBanner,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fontWeights,
  fonts,
  footer,
  formInputs,
  header,
  headerBottom,
  heroSection,
  imageAndText,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'WimLybaert',
  navItems,
  newsListing,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  space,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
