import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

const Cart = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg height="16.001" viewBox="0 0 17.226 16.001" width="17.226" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="shopping-cart" transform="translate(-1.5 -3)">
      <g data-name="Group 1992" id="Group_1992" transform="translate(1.5 3)">
        <path
          d="M5.334,3H1.5V4.6H4.075L6.291,14.743H6.3V15H16.7v-.2l1.755-7.729.27-.873H6.024L5.568,4.111ZM16.691,7.8H6.374L7.6,13.4h7.822L16.69,7.8Z"
          data-name="Path 2195"
          fill="currentColor"
          fillRule="evenodd"
          id="Path_2195"
          transform="translate(-1.5 -3)"
        />
        <path
          d="M13.6,30.2A1.6,1.6,0,1,0,12,28.6,1.6,1.6,0,0,0,13.6,30.2Z"
          data-name="Path 2196"
          fill="currentColor"
          id="Path_2196"
          transform="translate(-6.4 -14.199)"
        />
        <path
          d="M25.7,28.6A1.6,1.6,0,1,1,24.1,27,1.6,1.6,0,0,1,25.7,28.6Z"
          data-name="Path 2197"
          fill="currentColor"
          id="Path_2197"
          transform="translate(-11.299 -14.199)"
        />
      </g>
    </g>
  </svg>
);

export default Cart;
