import { Label } from '@rebass/forms';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

interface IExpandedStyled {
  expanded?: boolean;
  checked?: boolean;
  contactform?: boolean;
  hasError?: boolean;
}

export const StyledDropDown = styled.div<IExpandedStyled>`
  position: absolute;
  top: 48px;
  z-index: 1;
  padding: 8px 0;
  border-bottom-left-radius: ${props => (props.expanded ? props.theme.formInputs.borderRadius : 0)};
  border-bottom-right-radius: ${props => (props.expanded ? props.theme.formInputs.borderRadius : 0)};
  background: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);

  @media (max-width: 393px) {
    top: 48px;
  }

  @media (max-width: 1084px) {
    min-width: 300px;
  }

  @media (min-width: 1024px) {
    min-width: 300px;
  }

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }
  @media (max-width: 601px) {
    min-width: 100%;
  }

  ${Media({
    top: ['48px', null, '48px'],
  })}
`;

export const StyledHeader = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.formInputs.inputPadding ?? '8px'};
  border-radius: ${props => props.theme.formInputs.borderRadius};
  height: 48px;
  align-items: center;
  width: 100%;

  > label {
    cursor: pointer;
  }

  > span {
    align-self: center;
  }
`;

export const StyledDropDownContainer = styled.div<IExpandedStyled>`
  position: relative;
  color: ${props => props.theme.colors.text};
  min-width: 300px;
  border: ${({ hasError, expanded, theme }) => (hasError ? `1px solid ${theme.colors.danger}` : '1px solid #000')};
  border-radius: ${props => !props.expanded && props.theme.formInputs.borderRadius};
  border-top-left-radius: ${props => props.expanded && props.theme.formInputs.borderRadius};
  border-top-right-radius: ${props => props.expanded && props.theme.formInputs.borderRadius};

  ${Media({
    marginBottom: ['8px', null, null, '0px'],
    marginTop: ['8px', null, '0px', '0px', '0px'],
  })}

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }

  > button {
    min-height: 48px;
    height: auto;
  }
`;

export const StyledDropDownItemSeparator = styled.div`
  cursor: pointer;
  padding: ${props => props.theme.formInputs.inputPadding ?? '8px 0px 4px 4px'};

  &:hover {
    background: ${props => props.theme.colors.muted};
  }

  > label {
    cursor: pointer;
    display: flex;
    align-items: center;

    > span {
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
  }
`;

export const StyledDropDownSeparator = styled.div<IExpandedStyled>`
  border-bottom: ${props => (props.expanded ? '1px' : '0px')} solid lightgray;
  height: 16%;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -8px;
`;

export const StyledCustomSelectContainer = styled.div`
  ${Media({
    width: ['100%', null, 'auto', 'auto', 'auto'],
  })}
`;

export const StyledError = styled.div`
  color: ${props => props.theme.colors.danger};
  display: flex;
  align-items: center;
  font-weight: ${props => `${props.theme.fontWeights.medium}`};
  font-size: 0.75rem;
`;

interface LabelProps {
  isError?: boolean;
  hideFromView?: boolean;
}

export const StyledLabel = styled(Label)<LabelProps>`
  color: ${props => (props.isError ? props.theme.colors.danger : props.theme.colors.earthyGreen)};
  align-items: center;
  position: relative;
  padding-bottom: 8px;

  ${({ theme }) =>
    Media({
      fontSize: theme.formInputs?.label?.fontSizes ?? ['12px', null, '16px'],
      lineHeight: theme.formInputs?.label?.lineHeights ?? ['21px', null, '26px'],
    })}

  ${props =>
    props.hideFromView &&
    `
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `}
`;
