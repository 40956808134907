import React from 'react';

import locales from './locales';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { de, en, es, fr, nl, pl, pt } from './messages';

// export all folders from this i18n library

export { default as flags } from './flags';
export { default as locales } from './locales';
export * from './types';
export const messages: Record<string, Record<string, string>> = {
  de,
  en,
  es,
  fr,
  nl,
  pl,
  pt,
};

const defaultLocale = process.env.DEFAULT_LOCALE || locales[0];

export const getTranslations = (locale: string) => {
  return messages[locale];
};

export const IntlContext = React.createContext(defaultLocale);

export const IntlContextConsumer = IntlContext.Consumer;
export const IntlContextProvider = IntlContext.Provider;

export const TranslatedSlugContext = React.createContext([]);
export const TranslatedSlugProvider = TranslatedSlugContext.Provider;
