import { Arrow } from '@arvesta-websites/icons';
import { useIntl } from 'react-intl';

import { withErrorBoundary } from '../../ErrorBoundary';

import {
  StyledBodyRow,
  StyledCell,
  StyledDesktop,
  StyledDownloadLink,
  StyledHeaderCell,
  StyledHeaderRow,
} from './Styled';
import { ProductType } from './types';

type Props = {
  rows: Array<ProductType>;
};

const Desktop = ({ rows }: Props) => {
  const intl = useIntl();

  return (
    <StyledDesktop>
      <StyledHeaderRow>
        <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.number' })}</StyledHeaderCell>
        <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.product' })}</StyledHeaderCell>
        <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.weight' })}</StyledHeaderCell>
        <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.brochure' })}</StyledHeaderCell>
      </StyledHeaderRow>
      {rows.map((row: ProductType) => (
        <StyledBodyRow key={row.number}>
          <StyledCell>{row.number}</StyledCell>
          <StyledCell>{row.product}</StyledCell>
          <StyledCell>{row.weight}</StyledCell>
          <StyledCell>
            <StyledDownloadLink target="_blank" to={row.brochure.asset.url}>
              <span>{intl.formatMessage({ id: 'sections.product.table.download' })}</span>
              <Arrow />
            </StyledDownloadLink>
          </StyledCell>
        </StyledBodyRow>
      ))}
    </StyledDesktop>
  );
};

export default withErrorBoundary(Desktop, { componentName: 'Product/Desktop' });
