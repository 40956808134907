import { Button, ButtonProps } from 'rebass/styled-components';
import styled from 'styled-components';

type Props = ButtonProps & {
  /** The button variant as per theme definition */
  variant: string;
  fontSize: '16px';
};

const StyledButton = styled(Button)<Props>`
  ${props => ({ ...props.theme.buttons[props.variant] })};
`;

export default StyledButton;
