import React from 'react';

const Logo = () => (
  <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 98" preserveAspectRatio="xMidYMid">
    <defs>
      <clipPath id="clippath">
        <path
          fill="none"
          d="m17.28,72.28c-.28,0-.51.23-.51.51s.23.51.51.51h46.21c.28,0,.51-.23.51-.51s-.23-.51-.51-.51H17.28Z"
        />
      </clipPath>
      <linearGradient
        id="Naamloos_verloop_5"
        x1="-2161.57"
        y1="-1.54"
        x2="-2160.92"
        y2="-1.54"
        gradientTransform="translate(158107.61 -39.94) scale(73.14 -73.14)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#a1b947" />
        <stop offset=".32" stopColor="#9eb646" />
        <stop offset=".51" stopColor="#98ae43" />
        <stop offset=".67" stopColor="#8ca03f" />
        <stop offset=".81" stopColor="#7b8b38" />
        <stop offset=".93" stopColor="#667230" />
        <stop offset="1" stopColor="#58612b" />
      </linearGradient>
      <clipPath id="clippath-1">
        <path
          fill="none"
          d="m1.52,36.91c-.28,0-.51.23-.51.51s.23.51.51.51h127.62c.28,0,.51-.23.51-.51s-.23-.51-.51-.51H1.52Z"
        />
      </clipPath>
      <linearGradient
        id="Naamloos_verloop_4"
        x1="-2180.09"
        y1="-.66"
        x2="-2179.45"
        y2="-.66"
        gradientTransform="translate(434272.1 -94.71) scale(199.2 -199.2)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#a1b947" />
        <stop offset=".28" stopColor="#9eb646" />
        <stop offset=".47" stopColor="#97ad43" />
        <stop offset=".62" stopColor="#8b9e3e" />
        <stop offset=".76" stopColor="#7a8938" />
        <stop offset=".88" stopColor="#636f2f" />
        <stop offset=".94" stopColor="#58612b" />
        <stop offset="1" stopColor="#58612b" />
      </linearGradient>
    </defs>
    <g fill="none" clipPath="url(#clippath)">
      <rect fill="url(#Naamloos_verloop_5)" x="16.77" y="72.28" width="47.23" height="1.02" />
    </g>
    <path fill="#58612b" d="m114.63,27.81c0,.25-.2.46-.46.46s-.46-.2-.46-.46.2-.46.46-.46.46.2.46.46" />
    <path fill="#58612b" d="m135.89,27.36c0,.29-.23.52-.52.52s-.52-.23-.52-.52.23-.52.52-.52.52.23.52.52" />
    <path fill="#58612b" d="m121.61,29.1c0,.25-.2.46-.46.46s-.46-.2-.46-.46.2-.46.46-.46.46.2.46.46" />
    <path
      fill="#a1b947"
      d="m1.17,70.04h1.47c.5,0,1.05-.54,1.05-1.05v-19.65c0-.46-.55-1.13-1.05-1.13h-1.63v-3.69h7.5c.67,0,1.09.5,1.21,2.56,1.63-1.63,3.77-2.89,6.7-2.89,4.15,0,8.29,3.02,8.29,10.85s-4.82,11.06-9.47,11.06c-2.51,0-4.19-.59-5.45-1.51v5.45h2.81v3.27H1.17v-3.27Zm8.63-20.65v12.28c.92.59,2.01.96,3.56.96,2.51,0,5.15-1.17,5.15-7.04s-2.39-7-5.07-7c-1.09,0-2.43.34-3.64.8"
    />
    <path
      fill="#a1b947"
      d="m25.51,63.04h1.47c.5,0,1.05-.59,1.05-1.05v-12.65c0-.46-.55-1.13-1.05-1.13h-1.63v-3.69h7.5c.71,0,1.17.63,1.26,3.18,1.47-1.8,3.18-3.39,6.07-3.39.46,0,.92.08,1.34.21v4.52h-1.05c-3.18,0-5.11.67-6.33,1.34v12.65h3.06v3.48h-11.69v-3.48Z"
    />
    <path
      fill="#a1b947"
      d="m41,55.63c0-7.08,5.15-11.44,11.56-11.44s11.56,4.36,11.56,11.44-5.15,11.19-11.56,11.19-11.56-4.15-11.56-11.19m16.93.04c0-4.99-2.3-7.46-5.36-7.46s-5.36,2.47-5.36,7.46,2.3,7.29,5.36,7.29,5.36-2.35,5.36-7.29"
    />
    <path
      fill="#58612b"
      d="m87.67,60.15c0-4.61,3.6-6.7,8.67-6.7,1.84,0,3.1.17,4.48.5v-2.3c0-2.22-1.21-3.27-3.81-3.27s-3.98,1.01-5.07,1.97h-2.81v-4.48c2.05-.92,4.82-1.68,9.09-1.68,5.7,0,8.71,1.84,8.71,6.87v10.93c0,.5.55,1.05,1.05,1.05h1.17v2.93c-.84.5-2.09.84-3.69.84-2.56,0-3.85-1.01-4.4-2.76-1.63,2.01-3.69,2.72-6.37,2.72-4.11,0-7.04-2.26-7.04-6.62m13.15,1.47v-4.69c-.96-.17-2.05-.25-3.23-.25-2.47,0-3.98,1.01-3.98,3.23s1.3,3.27,3.35,3.27c1.55,0,2.81-.59,3.85-1.55"
    />
    <path
      fill="#58612b"
      d="m110.97,63.09h1.47c.5,0,1.05-.59,1.05-1.05v-12.65c0-.46-.55-1.13-1.05-1.13h-1.63v-3.69h7.5c.71,0,1.13.59,1.26,2.85,2.05-1.93,4.86-3.18,8.17-3.18,3.06,0,5.7,1.22,5.7,5.49v12.32c0,.5.54,1.05,1.05,1.05h1.17v3.48h-10.85v-3.48h2.51v-11.27c0-2.18-.84-2.81-2.89-2.81-1.76,0-3.39.38-4.82.84v13.24h2.56v3.48h-11.19v-3.48Z"
    />
    <path
      fill="#58612b"
      d="m137.6,63.1h1.59c.5,0,1.01-.59,1.01-1.05v-12.65c0-.46-.5-1.13-1.01-1.13h-1.76v-3.69h7.92c.67,0,1.01.67,1.01,2.35v15.12c0,.5.54,1.05,1.05,1.05h1.59v3.48h-11.39v-3.48Zm2.3-23.46c0-1.8,1.47-3.27,3.31-3.27s3.31,1.47,3.31,3.27-1.47,3.23-3.31,3.23-3.31-1.42-3.31-3.23"
    />
    <path
      fill="#58612b"
      d="m79.71,74.41c-.2.17-.5.25-.8.25-.74,0-1.16-.41-1.16-1.14v-2h-.62v-.54h.62v-.8h.66v.8h1.05v.54h-1.05v1.98c0,.39.21.61.57.61.19,0,.38-.06.52-.17l.21.48Z"
    />
    <path
      fill="#58612b"
      d="m84.98,72.53v2.1h-.66v-2.02c0-.72-.36-1.07-.98-1.07-.7,0-1.16.42-1.16,1.21v1.88h-.66v-5.11h.66v1.98c.28-.35.73-.55,1.29-.55.89,0,1.52.51,1.52,1.58"
    />
    <path
      fill="#58612b"
      d="m90.52,73.02h-2.96c.08.64.6,1.07,1.32,1.07.43,0,.78-.14,1.05-.44l.36.43c-.33.39-.83.59-1.44.59-1.18,0-1.96-.78-1.96-1.87s.78-1.86,1.83-1.86,1.8.76,1.8,1.88c0,.05,0,.14-.01.2m-2.96-.48h2.34c-.07-.61-.53-1.05-1.17-1.05s-1.1.43-1.17,1.05"
    />
    <path
      fill="#58612b"
      d="m96.07,70.66v.32h1.06v.54h-1.05v3.1h-.66v-3.1h-.62v-.54h.62v-.32c0-.71.43-1.18,1.2-1.18.28,0,.56.07.74.21l-.2.5c-.14-.1-.32-.17-.51-.17-.39,0-.59.22-.59.64"
    />
    <path
      fill="#58612b"
      d="m101.99,73.02h-2.96c.08.64.6,1.07,1.32,1.07.43,0,.78-.14,1.05-.44l.36.43c-.33.39-.83.59-1.44.59-1.18,0-1.96-.78-1.96-1.87s.78-1.86,1.83-1.86,1.8.76,1.8,1.88c0,.05,0,.14-.01.2m-2.96-.48h2.34c-.07-.61-.53-1.05-1.17-1.05s-1.1.43-1.17,1.05"
    />
    <path
      fill="#58612b"
      d="m107.22,73.02h-2.96c.08.64.6,1.07,1.32,1.07.43,0,.78-.14,1.05-.44l.36.43c-.33.39-.83.59-1.44.59-1.18,0-1.96-.78-1.96-1.87s.78-1.86,1.83-1.86,1.8.76,1.8,1.88c0,.05,0,.14-.01.2m-2.96-.48h2.34c-.07-.61-.53-1.05-1.17-1.05s-1.1.43-1.17,1.05"
    />
    <path
      fill="#58612b"
      d="m112.6,69.52v5.11h-.63v-.58c-.3.41-.76.62-1.3.62-1.07,0-1.85-.75-1.85-1.87s.78-1.86,1.85-1.86c.52,0,.97.19,1.27.59v-2.01h.66Zm-.65,3.28c0-.78-.53-1.28-1.23-1.28s-1.23.5-1.23,1.28.53,1.29,1.23,1.29,1.23-.51,1.23-1.29"
    />
    <path
      fill="#58612b"
      d="m121.01,73.02h-2.96c.08.64.6,1.07,1.32,1.07.43,0,.78-.14,1.05-.44l.36.43c-.33.39-.83.59-1.44.59-1.18,0-1.96-.78-1.96-1.87s.78-1.86,1.83-1.86,1.8.76,1.8,1.88c0,.05,0,.14-.01.2m-2.96-.48h2.34c-.07-.61-.53-1.05-1.17-1.05s-1.1.43-1.17,1.05"
    />
    <polygon
      fill="#58612b"
      points="125.17 74.63 124.12 73.23 123.05 74.63 122.31 74.63 123.75 72.76 122.38 70.98 123.12 70.98 124.12 72.29 125.12 70.98 125.85 70.98 124.47 72.76 125.92 74.63 125.17 74.63"
    />
    <path
      fill="#58612b"
      d="m131.43,72.8c0,1.12-.78,1.87-1.85,1.87-.52,0-.97-.2-1.27-.59v1.89h-.66v-4.98h.63v.58c.3-.41.76-.61,1.3-.61,1.07,0,1.85.74,1.85,1.86m-.67,0c0-.77-.53-1.28-1.23-1.28s-1.23.51-1.23,1.28.53,1.29,1.23,1.29,1.23-.51,1.23-1.29"
    />
    <path
      fill="#58612b"
      d="m136.64,73.02h-2.96c.08.64.6,1.07,1.32,1.07.43,0,.78-.14,1.05-.44l.36.43c-.33.39-.83.59-1.44.59-1.18,0-1.96-.78-1.96-1.87s.78-1.86,1.83-1.86,1.8.76,1.8,1.88c0,.05,0,.14-.01.2m-2.96-.48h2.34c-.07-.61-.53-1.05-1.17-1.05s-1.1.43-1.17,1.05"
    />
    <path
      fill="#58612b"
      d="m140.54,70.94v.64c-.05,0-.1,0-.15,0-.71,0-1.15.43-1.15,1.23v1.82h-.66v-3.65h.63v.61c.23-.43.69-.65,1.33-.65"
    />
    <path
      fill="#58612b"
      d="m144.57,74.41c-.2.17-.5.25-.8.25-.74,0-1.16-.41-1.16-1.14v-2h-.62v-.54h.62v-.8h.66v.8h1.05v.54h-1.05v1.98c0,.39.21.61.57.61.19,0,.38-.06.52-.17l.21.48Z"
    />
    <path
      fill="#58612b"
      d="m145.92,74.24l.28-.52c.31.22.81.38,1.28.38.61,0,.87-.19.87-.5,0-.82-2.31-.11-2.31-1.56,0-.65.59-1.09,1.52-1.09.48,0,1.01.12,1.33.33l-.28.52c-.33-.21-.7-.29-1.05-.29-.58,0-.86.21-.86.5,0,.86,2.31.16,2.31,1.58,0,.66-.61,1.08-1.58,1.08-.61,0-1.21-.19-1.51-.43"
    />
    <path
      fill="#58612b"
      d="m129.09,37.93c-.28,0-.51-.23-.51-.51s.23-.51.51-.51c.01,0,1.28-.02,1.94-1.02.49-.75,1.11-1.95,1.37-2.53.38-.85.92-1.27,2.85-1.21.17,0,.36.01.56.02.95.04,2.33.09,2.98-.13l-1.28-1,1.73.15s.38.02.87-.22c.38-.19.84-1.34.58-1.93-.13-.28-.81-.65-1.37-.95-.65-.35-1.32-.71-1.77-1.18-.54-.57-.76-.89-1.05-1.3-.19-.28-.41-.59-.78-1.05-1.21-1.52-2.32-1.4-2.65-1.22-.71.38-.9.51-1.03.59-.1.06-.17.1-.33.19l-.06.03c-.25.14-.56.05-.7-.2-.14-.25-.05-.56.2-.7l.08-.04c.14-.07.19-.11.27-.15.14-.09.35-.22,1.09-.62.67-.36,2.35-.52,3.94,1.48.39.49.62.81.82,1.1.27.39.47.67.96,1.18.34.36.94.68,1.51.99.8.43,1.55.83,1.82,1.44.49,1.11-.2,2.83-1.06,3.26-.22.11-.43.18-.61.23,0,0,0,0,0,.01,0,.16-.07.46-.44.69-.73.45-2.07.43-3.74.37-.19,0-.38-.01-.55-.02-1.59-.04-1.73.24-1.89.61-.27.61-.93,1.88-1.45,2.67-.96,1.46-2.72,1.48-2.79,1.48"
    />
    <path
      fill="#58612b"
      d="m127.71,26.29c-.23,0-.44-.16-.5-.4-.06-.27.12-.53.38-.61.14-.04.28-.09.42-.15.09-.04,1.05.64.76.77-.13.06-.25.12-.36.16-.18.08-.37.15-.57.2-.05.01-.1.02-.15.02"
    />
    <path
      fill="#58612b"
      d="m125.92,26.54c-.31,0-.51-.02-.53-.02-.28-.03-.48-.28-.45-.56.03-.28.28-.49.56-.46,0,0,1.04.1,2.07-.21.27-.08.56.07.64.34s-.07.56-.34.64c-.74.22-1.47.27-1.94.27"
    />
    <path
      fill="#58612b"
      d="m119.79,36.34c-.13,0-.27-.05-.37-.16-.01-.01-1.25-1.29-2.26-1.9-.89-.54-2.67-1.08-3.81-1.28-.31-.05-.58-.09-.81-.13-.72-.11-1.16-.18-1.53-.47-.17-.14-.27-.28-.32-.41-.23-.03-.52-.09-.87-.21-.93-.31-1.21-1.84-1.27-2.29-.12-.91,0-1.49.36-1.74.41-.27.81-.12,1.05-.03.26.09.35.12.53.02.7-.42,1.6-1.39,2.41-2.59.16-.23.48-.3.71-.14.23.16.3.48.14.71-.9,1.34-1.9,2.4-2.73,2.9-.6.36-1.11.18-1.41.06-.02,0-.05-.02-.07-.03-.04.35.03,1.12.29,1.72.14.32.27.43.31.44.67.22.99.18,1,.18l1.69-.24-1.04.93c.2.08.49.12.9.19.24.04.51.08.83.13,1.24.22,3.13.79,4.16,1.41,1.12.68,2.41,2.01,2.47,2.07.2.2.19.53-.01.72-.1.1-.23.14-.35.14m-8.22-4.47h0s0,0,0,0"
    />
    <path
      fill="#58612b"
      d="m122.56,25.3c-.13,0-.26-.05-.36-.15-1.33-1.32-3.46-1.57-4.61-1.61-.28,0-.5-.25-.49-.53,0-.28.24-.51.53-.5,1.3.04,3.72.34,5.3,1.9.2.2.2.52,0,.72-.1.1-.23.15-.36.15"
    />
    <path
      fill="#58612b"
      d="m116.47,26.28c-1.34,0-3.42-.41-4.37-1.13-1.11-.83-1.46-1.16-1.47-1.59-.01-.36.24-.57.41-.71.37-.31.84-.28,1.34-.24.36.03.74.05,1.03-.05.85-.29,2.59-.9,3.84-.2,1.3.73,1.79,1.91,1.24,3.01-.23.47-.7.65-1.04.78-.22.08-.56.12-.98.12m-4.67-2.67c.18.16.48.4.91.72,1.05.79,3.92,1.04,4.34.88.25-.1.46-.18.52-.29h0c.43-.87-.52-1.49-.83-1.66-.85-.48-2.3.03-3,.27-.5.17-1.02.14-1.44.11-.15-.01-.37-.03-.5-.02"
    />
    <path
      fill="#58612b"
      d="m119.86,37.56s-.3.06-.33.05c-.28-.04-.58.02-.55-.26.32-2.55,1.02-3.4,1.49-3.97.11-.13.18-.22.23-.3-.13.02-.27.02-.4,0-.36-.04-.66-.2-.87-.48-.28-.35-.33-.84-.3-1.31-.63.05-1.37.07-1.6,0-.28-.09-.38-.3-.42-.41-.11-.36.1-.63.41-1.05l.06-.08c.23-.3.77-.85,1.24-1.3.08-.62.31-1.01.58-1.26-.3-.56-.33-1.23-.06-1.74.28-.53.82-.77,1.44-.65.14.03.29.09.42.16.26-.36.7-.68,1.34-.68h.01c.74,0,1.19.47,1.43.93.48-.12,1.09-.09,1.61.41.7.67.48,1.42.31,1.82.33.38.91,1.06,1.24,1.73l.24.48c.44.87.76,1.5.99,2.72.05.28-.13.55-.41.6-.28.05-.55-.13-.6-.41-.2-1.08-.47-1.59-.9-2.44l-.24-.48c-.29-.59-.88-1.26-1.16-1.58l-.1-.11c-.2-.16-.21-.42-.14-.59.02-.05.05-.11.08-.18.19-.4.21-.57-.02-.8-.38-.36-.87-.08-.93-.05l-.64.39-.13-.74c-.01-.06-.14-.67-.63-.67h0c-.49,0-.63.45-.66.54l-.21.81-.63-.55c-.14-.12-.36-.27-.47-.29-.22-.04-.29.03-.34.12-.13.25-.06.73.25,1.05l.59.58-.78.27c-.11.04-.43.15-.48.91v.2s-.16.14-.16.14c-.6.58-1.05,1.04-1.23,1.27.37-.01.85-.05,1.22-.11l.76-.13-.18.75c-.15.61-.11,1.01,0,1.14.03.04.07.08.18.09.2.02.44-.08.54-.16l.71-.58.12.91c.03.2.03.39.02.56,0,.58-.28.91-.55,1.23-.41.5-.98,1.19-1.26,3.44-.03.26-.32-.14-.58-.14"
    />
    <g fill="none" clipPath="url(#clippath-1)">
      <rect fill="url(#Naamloos_verloop_4)" x="1.01" y="36.91" width="128.64" height="1.02" />
    </g>
    <path
      fill="#58612b"
      d="m130.05,26.28c-.84,0-1.66-.47-2.08-.77h0c-.15-.11-1.5-1.06-1.28-1.7.15-.42,1.1-1,2.75-.97,1.75.03,2.61,1.13,2.64,1.18v.02c.4.57.32,1.01.19,1.28-.27.55-1.02.89-2.1.96-.04,0-.08,0-.13,0Zm-2.59-2.25c.12.18.43.53.94.87h0c.63.44,1.25.66,1.74.63.92-.05,1.37-.33,1.48-.54.07-.13.03-.31-.12-.53-.07-.08-.73-.85-2.06-.88-1.14-.02-1.76.28-1.98.44Zm-.08.07h0Z"
    />
    <path
      fill="#58612b"
      d="m86.53,63.05h.25v3.48h-10.7v-3.48h2.43l-3.94-4.91-2.18,2.81c-.92,1.17-.71,2.1.5,2.1h.55v3.48h-8.89v-3.48h.42c.59,0,1.34-.38,1.76-.8l5.41-6.5-5.37-6.38c-.67-.8-1.05-1.17-1.64-1.17h-.75v-3.69h10.95v3.69h-2.35l3.82,4.74,2.48-3.1c.67-.84.59-1.64-.5-1.64h-.8v-3.69h8.64v3.69h-.46c-.55,0-1.17.34-1.55.8l-5.33,6.42,5.45,6.46c.67.8,1.22,1.17,1.8,1.17"
    />
  </svg>
);

export default Logo;
