export default {
  'articleListing.applyFilters': 'Filter anwenden',
  'articleListing.filters': 'Filter',
  'articleListing.loadMore': 'Mehr laden',
  'brandListing.all': 'Alles',
  'brandListing.byCategories': 'Nach Kategorien',
  'brandListing.clear': 'Auswahl aufheben',
  'brandListing.productsAndServices': 'Produkte und Dienstleistungen',
  'brandListing.seeProductsFor': 'Siehe Produkte für',
  'brandListing.similarBrands': 'Marken mit ähnlichen Produkten und Dienstleistungen',
  'brandListing.visitWebsite': 'Website besuchen',
  'cards.advice.link': 'Artikel lesen',
  'cards.category.link': 'Lesen Sie mehr',
  'cards.event.link': 'Ereignis anzeigen',
  'cards.news.link': 'Lesen Sie weiter',
  'cards.product.link': 'Mehr sehen',
  'events.loadmore': 'Laden Sie weitere Ratschläge',
  'events.loadmorepast': 'Laden Sie mehr vergangene Ereignisse',
  'footer.copyright': '© {year} Arvesta. Alle Rechte vorbehalten.',
  'footer.follow_us': 'Folgen sie uns',
  'footer.newsletter.error':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'footer.newsletter.heading':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'footer.newsletter.placeholder': 'E-Mail Adresse eingeben',
  'footer.newsletter.subscribe':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'footer.newsletter.success': 'Sie sind abonniert, danke. Sie werden in Kürze von uns hören.',
  'footer.subscribe.enteremail': 'E-Mail Adresse eingeben',
  'footer.subscribe.failure':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'footer.subscribe.invalid_email': 'Können Sie Ihre E-Mail-Adresse eingeben?',
  'footer.subscribe.subscribe': 'abonnieren',
  'footer.subscribe.subscribing': 'Bitte warten Sie, während wir versuchen, eine Verbindung zum Server herzustellen',
  'footer.subscribe.success': 'Sie sind abonniert, danke. Sie werden in Kürze von uns hören.',
  'forms.contact.description':
    'Füllen Sie das unten stehende Formular aus und unser Team wird sich mit Ihnen in Verbindung setzen.',
  'forms.contact.email.label': 'E-Mail-Adresse',
  'forms.contact.email.placeholder': 'Bitte geben Sie Ihre E-Mail-Adresse an',
  'forms.contact.errors.email': 'Können Sie Ihre E-Mail-Adresse eingeben?',
  'forms.contact.errors.required': 'Bitte füllen Sie dieses Pflichtfeld aus.',
  'forms.contact.errors.telephone': `Bitte geben Sie nur Zahlen, Leerzeichen und das '+'-Symbol ein. Das erste Zeichen muss ein "+" oder eine Zahl sein`,
  'forms.contact.failure':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'forms.contact.fullname.label': 'Vor- und Nachname',
  'forms.contact.fullname.placeholder': 'Bitte tragen sie Ihren vollständigen Namen ein',
  'forms.contact.message.label': 'Ihre Nachricht',
  'forms.contact.message.placeholder': 'Bitte geben Sie hier Ihre Nachricht ein',
  'forms.contact.telephone.label': 'Telefonnummer',
  'forms.contact.telephone.placeholder': 'Geben Sie Ihre Telefonnummer ein',
  'forms.contact.other': 'Andere',
  'forms.contact.newsletter.title':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'forms.contact.submit': 'Senden',
  'forms.contact.success': 'Vielen Dank, wir prüfen bereits Ihre Nachricht.',
  'forms.contact.title': 'Kontaktieren Sie uns',
  'globals.download': 'Download',
  'globals.featuredProducts': 'vorgestellte Produkte',
  'globals.no': 'Nein',
  'globals.yes': 'Ja',
  'header.search.placeholder': 'Suche',
  'header.selectlanguage': 'Sprache auswählen',
  'header.allAbout': 'Alles über',
  'legacy.cookie.description': 'Wenn Sie weiterhin auf der Website surfen, stimmen Sie zu der Verwendung von',
  'legacy.footer.headoffice': 'Hauptsitz',
  'legacy.footer.headquarters': 'Firmensitz',
  'legacy.header.about': 'über uns',
  'legacy.header.contact': 'Kontaktieren Sie uns',
  'legacy.header.jobs': 'jobs',
  'legacy.header.news': 'Nachrichten & Presse',
  'legacy.header.products': 'Produkte und Dienstleistungen',
  'newsletter.email.error': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  'newsletter.email.success': 'Sie haben sich erfolgreich angemeldet. Sie werden in Kürze von uns hören.',
  'newsletter.input.placeholder': 'E-Mail-Addresse',
  'newsletter.terms.error': 'Bitte stimmen Sie den allgemeinen Datenschutzbedingungen zu',
  'newsletter.terms.privacyPolicy': 'Datenschutzerklärung',
  'newsletter.terms.sectionOne': 'Ich bestätige, dass ich die ',
  'newsletter.terms.sectionTwo': 'erhalten und gelesen habe.',
  'newsletter.terms.subscribe': 'Abonnieren',
  'owner.storecard.contact': 'Kontaktieren Sie uns',
  'owner.storecard.route': 'Wegbeschreibung',
  'owner.storecard.openinghours': 'Öffnungszeiten',
  'owner.storecard.services': 'Dienstleistungen',
  'product.certifications.title': 'Produktzertifizierungen',
  'sections.advice.loadmore': 'Laden Sie weitere Ratschläge',
  'sections.category.loadmore': 'Mehr laden',
  'sections.contact.offices': 'Büros',
  'sections.contact.openinghours': 'Öffnungszeiten',
  'sections.contact.salesteam': 'Ihre Ansprechpartner',
  'sections.contact.viewonmap': 'Ansicht auf Karte',
  'sections.dealer.clear': 'Auswahl aufheben',
  'sections.dealer.invalidaddress': 'Wir konnten keine Adresse finden',
  'sections.dealer.loadmore': 'Zeige alles',
  'sections.dealer.maplink': 'Ansicht auf Karte',
  'sections.dealer.placeholder': 'Geben Sie ihre Postleitzahl und Gemeinde ein',
  'sections.dealer.search': 'Suche',
  'sections.dealer.total': 'Ergebnisse für',
  'sections.dealerOverview.details': 'Angaben',
  'sections.dealerOverview.route': 'Wegbeschreibung',
  'sections.dealerOverview.no_results': 'Keine Ergebnisse',
  'sections.dealerOverview.show_all': 'Zurück zur Liste aller Geschäfte',
  'sections.dealerOverview.clear_filters': 'Alle Filter löschen',
  'sections.dealerOverview.remove_filter': 'Filter entfernen',
  'sections.dealerOverview.services': 'Dienstleistung',
  'sections.events.loadmore': 'Laden Sie mehr Produkte',
  'sections.events.loadmorepast': 'Laden Sie mehr vergangene Ereignisse',
  'sections.news.loadmore': 'Weitere Nachrichten laden',
  'sections.product.loadmore': 'Laden Sie mehr',
  'sections.product.noproductsfoundfor': 'Keine Produkte gefunden für',
  'sections.product.table.brochure': 'Flugblatt',
  'sections.product.table.download': 'Download',
  'sections.product.table.number': 'art.nr',
  'sections.product.table.product': 'Produkt',
  'sections.product.table.weight': 'Gewicht',
  'sections.search.events': 'Ereignisse',
  'sections.search.news': 'Nachrichten',
  'sections.search.noncategorised': 'Nicht kategorisiert',
  'sections.search.noresults.title': 'Entschuldigung, wir haben keine Ergebnisse für gefunden ',
  'sections.search.products': 'products',
  'sections.search.results.title': 'Ergebnisse für',
  'sections.search.seeallevents': 'Alle Veranstaltungen anzeigen',
  'sections.search.seeallnews': 'Alle Nachrichtenn anzeigen',
  'sections.search.seeallproducts': 'Alle Produkten anzeigen',
};
