import * as React from 'react';

const Filter = () => (
  <svg id="CTA" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <defs />
    <title>Filters</title>
    <g id="Group_2205" data-name="Group 2205">
      <path
        id="filter"
        fill="currentColor"
        className="cls-1"
        d="M41.33,29H38.67a1.34,1.34,0,0,1-1.34-1.33h0V22.61l-4.94-4.94a1.33,1.33,0,0,1-.39-.94v-2.4A1.34,1.34,0,0,1,33.33,13H46.67A1.34,1.34,0,0,1,48,14.33h0v2.4a1.33,1.33,0,0,1-.39.94l-4.94,4.94v5.06A1.34,1.34,0,0,1,41.33,29Zm-8-14.67v2.4l5.34,5.33v5.61h2.66V22.06l5.34-5.33v-2.4Z"
        transform="translate(-32 -13)"
      />
    </g>
  </svg>
);

export default Filter;
