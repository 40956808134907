import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Kasper" transform="translate(0.000000, 11.000000)">
        <path
          d="M96.1279,38.0404 C104.1319,38.1864 111.0849,38.3134 115.9759,38.2664 C115.9979,38.0834 116.0049,37.9004 115.9969,37.7184 C115.9699,37.2094 115.8169,36.7124 115.5449,36.2294 C111.1399,36.5034 102.6629,36.3754 92.3769,36.2204 C76.9099,35.9834 57.6629,35.6914 41.2429,36.5254 C29.1849,37.1374 15.0239,38.3094 2.5019,39.4774 C1.6509,40.1554 1.0049,40.8454 0.5829,41.5374 C13.7509,40.1394 28.5769,38.7874 41.3039,38.1904 C58.9429,37.3624 79.5619,37.7374 96.1279,38.0404"
          id="Fill-1"
          fill="#7BBA58"
        />
        <path
          d="M96.0928,40.0052 C79.5538,39.7022 58.9708,39.3282 41.3948,40.1522 C28.5088,40.7572 13.3618,42.1512 -0.0002,43.5822 C-0.0002,43.5892 -0.0002,43.5982 -0.0002,43.6072 C0.0458,44.4882 0.4698,45.3312 1.2378,46.1262 C1.5448,46.4452 1.9068,46.7562 2.3208,47.0612 C2.7498,47.3742 3.2358,47.6802 3.7748,47.9772 C3.8748,48.0312 3.9778,48.0872 4.0828,48.1432 C16.7388,47.3512 36.6928,43.1092 47.8838,42.3192 C72.7238,40.5662 106.3668,42.0472 113.8448,41.5582 C114.0918,41.3472 114.3188,41.1332 114.5238,40.9192 C114.7418,40.6922 114.9388,40.4642 115.1108,40.2352 C110.2298,40.2632 103.6228,40.1432 96.0928,40.0052"
          id="Fill-4"
          fill="#004489"
        />
        <path
          d="M31.127,46.349 L31.127,46.349 C24.883,47.241 19.553,48.138 16.127,48.744 C16.127,48.744 27.767,52.714 47.388,51.853 C67.01,50.993 81.388,43.635 83.196,43.328 C71.443,43.281 77.72,43.248 67.28,43.2449889 C55.405,43.24 41.066,44.929 31.127,46.349"
          id="Fill-7"
          fill="#7BBA58"
        />
        <polygon
          id="Fill-9"
          fill="#7BBA58"
          points="10.8096 25.5511 16.4406 25.5511 16.4406 27.1371 13.0526 27.1371 13.0526 28.9741 16.2956 28.9741 16.2956 30.5591 13.0526 30.5591 13.0526 33.9341 10.8096 33.9341"
        />
        <path
          d="M23.8071,27.2806 L23.7831,27.2806 L22.6981,30.5586 L24.8071,30.5586 L23.8071,27.2806 Z M22.4321,25.5516 L25.1821,25.5516 L28.4251,33.9336 L25.9651,33.9336 L25.3381,32.1446 L22.1791,32.1446 L21.5161,33.9336 L19.2381,33.9336 L22.4321,25.5516 Z"
          id="Fill-11"
          fill="#7BBA58"
        />
        <path
          d="M38.9141,30.7513 C38.9141,33.0463 37.4191,34.0773 35.2251,34.0773 C33.0311,34.0773 31.5351,33.0463 31.5351,30.7513 L31.5351,25.5513 L33.7781,25.5513 L33.7781,30.3793 C33.7781,31.4833 34.0801,32.4203 35.2251,32.4203 C36.3701,32.4203 36.6711,31.4833 36.6711,30.3793 L36.6711,25.5513 L38.9141,25.5513 L38.9141,30.7513 Z"
          id="Fill-13"
          fill="#7BBA58"
        />
        <polygon
          id="Fill-15"
          fill="#7BBA58"
          points="42.748 25.5511 45.605 25.5511 48.451 31.2431 48.475 31.2431 48.438 25.5511 50.488 25.5511 50.488 33.9341 47.703 33.9341 44.761 28.2041 44.737 28.2041 44.81 33.9341 42.748 33.9341"
        />
        <path
          d="M58.3247,27.2806 L58.3007,27.2806 L57.2157,30.5586 L59.3247,30.5586 L58.3247,27.2806 Z M56.9507,25.5516 L59.6997,25.5516 L62.9427,33.9336 L60.4837,33.9336 L59.8567,32.1446 L56.6977,32.1446 L56.0337,33.9336 L53.7557,33.9336 L56.9507,25.5516 Z"
          id="Fill-17"
          fill="#7BBA58"
        />
        <polygon
          id="Fill-19"
          fill="#7BBA58"
          points="66.1133 25.5511 71.7443 25.5511 71.7443 27.1371 68.3563 27.1371 68.3563 28.9741 71.5993 28.9741 71.5993 30.5591 68.3563 30.5591 68.3563 33.9341 66.1133 33.9341"
        />
        <path
          d="M79.1348,32.4203 C80.4968,32.4203 81.0398,31.1233 81.0398,29.7423 C81.0398,28.2893 80.4968,27.0653 79.1348,27.0653 C77.7738,27.0653 77.2298,28.2893 77.2298,29.7423 C77.2298,31.1233 77.7738,32.4203 79.1348,32.4203 M79.1348,25.4073 C81.9078,25.4073 83.3548,27.0533 83.3548,29.7423 C83.3548,32.4083 81.8838,34.0773 79.1348,34.0773 C76.3858,34.0773 74.9148,32.4083 74.9148,29.7423 C74.9148,27.0533 76.3618,25.4073 79.1348,25.4073"
          id="Fill-21"
          fill="#7BBA58"
        />
        <path
          d="M90.8662,32.4203 C92.2282,32.4203 92.7702,31.1233 92.7702,29.7423 C92.7702,28.2893 92.2282,27.0653 90.8662,27.0653 C89.5042,27.0653 88.9612,28.2893 88.9612,29.7423 C88.9612,31.1233 89.5042,32.4203 90.8662,32.4203 M90.8662,25.4073 C93.6392,25.4073 95.0862,27.0533 95.0862,29.7423 C95.0862,32.4083 93.6142,34.0773 90.8662,34.0773 C88.1172,34.0773 86.6462,32.4083 86.6462,29.7423 C86.6462,27.0533 88.0932,25.4073 90.8662,25.4073"
          id="Fill-23"
          fill="#7BBA58"
        />
        <path
          d="M100.9336,32.2767 L101.8016,32.2767 C103.4776,32.2767 104.4176,31.4117 104.4176,29.7427 C104.4176,28.0727 103.4416,27.2087 101.8016,27.2087 L100.9336,27.2087 L100.9336,32.2767 Z M98.6906,25.5507 L101.7526,25.5507 C104.6836,25.5507 106.7336,26.4647 106.7336,29.7077 C106.7336,32.8407 104.6226,33.9337 101.7526,33.9337 L98.6906,33.9337 L98.6906,25.5507 Z"
          id="Fill-25"
          fill="#7BBA58"
        />
        <path
          d="M29.9121,21.663 L30.9801,17.706 L36.8361,17.706 L37.8261,21.663 L43.5231,21.663 L38.8941,4.105 C38.3401,1.928 37.5871,0 35.4911,0 C33.1171,0 32.3651,1.78 31.7311,4.303 L27.3011,21.663 L29.9121,21.663 Z M33.9481,6.034 L34.0271,6.034 L36.0841,14.739 L31.7711,14.739 L33.9481,6.034 Z"
          id="Fill-27"
          fill="#004489"
        />
        <path
          d="M51.2778,3.3627 C50.4078,3.3627 49.2998,3.4617 49.2998,5.1437 C49.2998,9.0997 56.9758,5.4407 56.9758,14.1457 C56.9758,19.1407 54.2858,21.6627 50.7628,21.6627 L44.5908,21.6627 L44.5908,18.4977 L49.4188,18.4977 C51.0018,18.4977 52.0298,18.3497 52.0298,15.9257 C52.0298,11.9687 43.7998,15.1837 43.7998,7.0727 C43.7998,2.5717 46.3328,0.1967 49.7348,0.1967 L55.1958,0.1967 L55.1958,3.3627 L51.2778,3.3627 Z"
          id="Fill-30"
          fill="#004489"
        />
        <path
          d="M86.79,12.5624 L81.408,12.5624 L81.408,17.0144 C81.408,17.9534 81.884,18.4974 82.833,18.4974 L87.7,18.4974 L87.7,21.6634 L78.876,21.6634 C77.057,21.6634 75.711,20.0314 75.711,17.4094 L75.711,4.7974 C75.711,1.8794 77.531,0.1974 79.588,0.1974 L87.423,0.1974 L87.423,3.3624 L82.436,3.3624 C81.686,3.3624 81.408,4.1054 81.408,5.2914 L81.408,9.3964 L86.79,9.3964 L86.79,12.5624 Z"
          id="Fill-32"
          fill="#004489"
        />
        <path
          d="M90.4697,0.1971 L99.4507,0.1971 C102.4577,0.1971 105.1887,2.6711 105.1887,6.7261 C105.1887,9.4951 104.1587,11.4741 101.9447,12.1661 L106.8907,21.6631 L100.4797,21.6631 L96.5237,11.1281 L96.6017,11.1281 C98.6997,11.1281 99.4907,9.5451 99.4907,7.0231 C99.4907,4.5501 98.2637,3.1641 96.7997,3.1641 L96.1667,3.1641 L96.1667,21.6631 L90.4697,21.6631 L90.4697,0.1971 Z"
          id="Fill-34"
          fill="#004489"
        />
        <path
          d="M59.4404,0.1971 L68.4244,0.1971 C71.4314,0.1971 74.1624,2.6711 74.1624,6.7261 C74.1624,9.4951 72.8864,13.8951 67.2634,14.0551 L65.4974,11.1281 L65.5764,11.1281 C67.6724,11.1281 68.4634,9.5451 68.4634,7.0231 C68.4634,4.5501 67.2384,3.1641 65.7734,3.1641 L65.1404,3.1641 L65.1404,21.6631 L59.4404,21.6631 L59.4404,0.1971 Z"
          id="Fill-36"
          fill="#004489"
        />
        <polygon id="Fill-38" fill="#004489" points="10.703 21.6634 16.382 21.6634 16.382 0.1974 10.703 0.1974" />
        <path
          d="M26.8638,0.5931 L20.4538,0.5931 L16.4968,11.1281 L19.3718,18.7821 C20.3748,21.5571 23.1448,21.6621 23.1448,21.6621 L23.1438,21.6631 L26.8638,21.6631 L21.6228,11.1501 L26.8638,0.5931 Z"
          id="Fill-40"
          fill="#004489"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
