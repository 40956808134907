import styled from 'styled-components';

export const StyledImageWrapper = styled.div`
  height: 122px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
  padding: 10px;
  &:hover {
    transform: scale(1.2);
  }
`;
export const StyledTitle = styled.div`
  ${props => props.theme.cards.logo};
  font-size: 18px;
  word-break: break-all;
  padding-top: 22px;
  text-align: center;
`;
export const StyledWrapper = styled.a`
  background-color: white;
  padding: 18px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  height: 100%;
`;
