import * as React from 'react';

const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#3D3D3D"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42z"
    />
  </svg>
);

export default Check;
