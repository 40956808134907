import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const StyledCardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)`
  max-width: 605px;
`;

export const StyledLoadMoreWrapper = styled(Box)`
  align-self: flex-start;

  ${Media({
    marginTop: ['2.5rem', null, null, '3.5rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3.0625rem', null, null, '3.25rem'],
  })}
`;

export const StyledModuleHeading = styled(Heading)`
  font-weight: ${props => props.theme.adviceCardModule.titleFontWeight || 'bold'};
  word-break: break-word;

  ${Media({
    marginBottom: ['1rem', null, null, '1.5rem'],
  })}
`;
