import { type Document } from '@contentful/rich-text-types';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { BoxProps } from 'rebass/styled-components';

import { RichText, Video, withErrorBoundary } from '../../components';

import { StyledContainerBrandX, StyledImageWrapperBrandX, StyledTextWrapperBrandX } from './Styled';

export interface ImageAndTextModuleProps extends BoxProps {
  image?: {
    alt?: string;
    title: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  videoUrl?: string;
  richText: {
    json: Document;
  };
  alignImageRight?: boolean;
  textAlignment?: 'Left' | 'Center' | 'Right';
  className?: string;
}

const ImageAndTextModule = ({
  className,
  image,
  videoUrl,
  richText,
  alignImageRight,
  textAlignment,
}: ImageAndTextModuleProps) => (
  <StyledContainerBrandX alignImageRight={alignImageRight} className={className}>
    <StyledImageWrapperBrandX alignImageRight={alignImageRight}>
      {image?.asset && (
        <GatsbyImage
          alt={image?.alt ? image.alt : image.title}
          image={image.asset?.gatsbyImageData}
          loading="eager"
          style={{ width: '100%' }}
          objectFit="contain"
        />
      )}
      {!image && videoUrl && <Video videoUrl={videoUrl} />}
    </StyledImageWrapperBrandX>
    <StyledTextWrapperBrandX alignImageRight={alignImageRight}>
      <RichText alignment={textAlignment} data={richText.json} isImageAndText />
    </StyledTextWrapperBrandX>
  </StyledContainerBrandX>
);

export default withErrorBoundary(ImageAndTextModule, { componentName: 'ImageAndTextModule' });
