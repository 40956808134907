import { Arrow } from '@arvesta-websites/icons';
import { format } from 'date-fns';
import { de, enGB as en, fr, nl } from 'date-fns/locale';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { Flex } from 'rebass';

import { Locales } from '../../../../types';
import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';

import { NewsCardProps } from './NewsCard';
import {
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBoxLink,
  StyledNewsDate,
  StyledTitle,
} from './Styled';

const HorizontalNewsCard = ({ title, shortDescription, image, link, date }: NewsCardProps) => {
  const intl = useIntl();
  const dateLocales: Locales = { de, en, fr, nl };
  const dateLocale = dateLocales[intl.locale] || dateLocales.en;

  return (
    <Flex>
      <StyledContentBox className={image ? '' : 'no-image'}>
        {date && <StyledNewsDate>{format(new Date(date), 'MMMM do, yyyy', { locale: dateLocale })}</StyledNewsDate>}

        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>

        <StyledDescription>{shortDescription}</StyledDescription>

        <StyledCtaWrapper>
          <StyledCta to={link}>
            <Arrow />
            <span>{intl.formatMessage({ id: 'cards.news.link' })}</span>
          </StyledCta>
        </StyledCtaWrapper>
      </StyledContentBox>

      {image && image.asset ? (
        <StyledImageBoxLink to={link}>
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={image.alt ? image.alt : image.title}
            style={{ height: '100%', margin: '0 auto' }}
            objectFit="cover"
          />
        </StyledImageBoxLink>
      ) : null}
    </Flex>
  );
};

export default withErrorBoundary(HorizontalNewsCard, { componentName: 'HorizontalNewsCard' });
