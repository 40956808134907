import { type LocaleType, TranslatedSlugContext, locales } from '@arvesta-websites/i18n';
import { ChevronRight } from '@arvesta-websites/icons';
import { navigate } from 'gatsby-link';
import { useContext, useEffect, useRef, useState } from 'react';
import { ButtonProps } from 'rebass';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../../ErrorBoundary';

interface SlugType {
  node_locale: string;
}

export interface LangSwitcherDefaultProps extends ButtonProps {
  /** Default locale */
  defaultLocale: string;
  /** List of available locales */
  activeLocales: string[];
  /** Controls open behaviour */
  isMobileMenu?: boolean;
}
const languageSwitcher = tv({
  slots: {
    buttonHeader: 'flex items-center border-none cursor-pointer pt-0 pb-0 pl-1 pr-1 font-normal h-11',
    chevron: 'inline-block ml-3 w-4 text-content transform rotate-90',
    chevronRight: 'w-[14px] h-[14px]',
    countryId: 'ml-1 uppercase text-countryId font-black lg:font-medium leading-[18px]',
    dropdown: 'w-full absolute top-0 left-0 text-content bg-header',
    list: 'list-none m-0 h-auto py-0 pl-2 pr-1',
    listItem: 'cursor-pointer my-1 mx-0 flex items-center uppercase',
    wrapper: 'relative h-5 w-16 z-10 shrink-0',
  },
  variants: {
    isMobileMenu: {
      true: {
        buttonHeader: 'font-bold leading-6 h-auto',
        dropdown: 'bg-transparent',
      },
    },
    open: {
      true: { chevron: '-rotate-90' },
    },
  },
});

const renderListItem = (enabledLocales: LocaleType[], handleLocaleChange: (locale: LocaleType) => void) => {
  return enabledLocales.map((locale: LocaleType) => {
    const { listItem } = languageSwitcher();
    return (
      <li className={listItem()} key={`item-${locale.id}`} onClick={() => handleLocaleChange(locale)}>
        {locale.id}
      </li>
    );
  });
};

const LangSwitcherDefault = ({ defaultLocale, activeLocales, isMobileMenu = false }: LangSwitcherDefaultProps) => {
  const node = useRef(null);
  const enabledLocales: LocaleType[] = [];
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(defaultLocale);

  const translatedSlugs = useContext(TranslatedSlugContext);

  const handleClickOutside = (e: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (node.current?.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLocaleChange = (locale: any) => {
    setActive(locale.id);
    setOpen(false);

    if (typeof window !== 'undefined') {
      window.location.href = `/${locale.id}${locale.slug ? `/${locale.slug}` : ''}`;
    } else {
      navigate(`/${locale.id}${locale.slug ? `/${locale.slug}` : ''}`);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const mergedRoutes = translatedSlugs.map((slug: SlugType) => {
    return {
      ...slug,
      ...locales[slug.node_locale],
    };
  });

  mergedRoutes.forEach((slug: any) => {
    if (slug.node_locale !== active && activeLocales.includes(slug.node_locale)) {
      enabledLocales.push(slug);
    }
  });

  if (!activeLocales || activeLocales.length === 1) {
    return null;
  }

  const { wrapper, dropdown, buttonHeader, countryId, chevron, chevronRight, list } = languageSwitcher({
    isMobileMenu,
    open,
  });

  return (
    <div className={wrapper()}>
      <div className={dropdown()} ref={node}>
        <button className={buttonHeader()} onClick={() => setOpen(!open)}>
          <span className={countryId()}>{active}</span>
          <span className={chevron()}>
            <ChevronRight className={chevronRight()} />
          </span>
        </button>
        {open && <ul className={list()}>{renderListItem(enabledLocales, handleLocaleChange)}</ul>}
      </div>
    </div>
  );
};

export default withErrorBoundary(LangSwitcherDefault, { componentName: 'LangSwitcherDefault' });
