import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import breakpoints from '../../../../utils/breakpoints';
import Media from '../../../../utils/Media';

import type { CtaBannerFocusTextProps } from './CtaBannerFocusText';

const [_, mediumBreakpoint, largeBreakpoint, xlargeBreakpoint] = breakpoints;

type StyledFocusTextProps = Pick<CtaBannerFocusTextProps, 'orientation'>;
type StyledWrapperProps = StyledFocusTextProps & {
  mobileBackgroundBottomOffset: number;
};
type StyledImageProps = StyledFocusTextProps & {
  maskUrl: string;
};

const StyledWrapper = styled(Box)<StyledWrapperProps>`
  display: flex;
  position: relative;

  ${props => {
    let [paddingLeft, paddingRight] = [
      [null, null, '45px', '116px'],
      [null, null, '0px', null],
    ];

    if (props.orientation !== 'left') {
      [paddingLeft, paddingRight] = [paddingRight, paddingLeft];
    }

    return Media({
      paddingLeft,
      paddingRight,
    });
  }}

  ${Media({
    alignItems: [null, null, 'center', null],
    flexDirection: ['column', null, 'row', null],
    gap: ['30px', null, '45px', '116px'],
    paddingLeft: ['45px', null, null, null],
    paddingRight: ['45px', null, null, null],
  })}

  &:before {
    content: '';
    position: absolute;
    flex-shrink: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.ctaBanner.focusText.backgroundColor};

    ${props => {
      const offsets = [null, null, '22px', '40px'];

      return Media({
        clipPath:
          props.orientation === 'left'
            ? offsets.map(offset =>
                offset ? `polygon(0% 0%, calc(100% - ${offset}) 0%, calc(100% - ${offset}) 100%, 0% 100%)` : null,
              )
            : offsets.map(offset => (offset ? `polygon(${offset} 0, 100% 0%, 100% 100%, ${offset} 100%)` : null)),
      });
    }}
    ${props => {
      const bottomOffset = `calc(100% - ${props.mobileBackgroundBottomOffset}px)`;
      return Media({
        clipPath: [
          `polygon(13px 0%, calc(100% - 13px) 0%, calc(100% - 13px) ${bottomOffset}, 13px ${bottomOffset})`,
          null,
          null,
          null,
        ],
      });
    }}
    display: inline-block;
  }

  > * {
    position: relative;
  }
`;

const StyledImage = styled(Box)<StyledImageProps>`
  flex-shrink: 0;
  height: fit-content;

  ${props =>
    Media({
      margin: [null, null, '20px 0', null],
      order: [1, null, props.orientation === 'left' ? 1 : 0, null],
      transform: ['translate(-45px)', null, 'translate(0)', null],
      width: ['calc(100% + 90px)', null, '50%', null],
      [props.orientation === 'left' ? 'marginLeft' : 'marginRight']: [null, null, 'auto', null],
    })}

  > :first-child {
    max-width: 100%;
    mask-image: url('data:image/svg+xml,${props => props.maskUrl}');
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-composite: exclude;
  }
`;
const StyledContent = styled(Box)`
  color: ${props => props.theme.ctaBanner.focusText.color};
  ${Media({
    margin: ['30px 0 0', null, '40px 0', '50px 0'],
  })}
`;
const StyledContentTitle = styled.div`
  color: ${props => props.theme.ctaBanner.focusText.titleColor ?? props.theme.colors.text};
  width: 100%;
  font-weight: ${props => props.theme.ctaBanner.focusText.titleFontWeight || 900};
  ${Media({
    fontSize: ['25px', null, '30px', '40px'],
    letterSpacing: ['-0.25px', null, '-0.3px', '-0.4px'],
    lineHeight: ['31px', null, '36px', '48px'],
    marginBottom: ['20px', null, null, '30px'],
  })}
`;
const StyledContentOneLiner = styled(Box)`
  ${Media({
    fontWeight: ['900', null, null, '700'],
    lineHeight: ['26px', null, '21px', '18px'],
    marginTop: ['30px', null, null, '40px'],
  })}
`;
const StyledCta = styled(Box)`
  ${Media({
    marginTop: ['15px', null, '30px', '40px'],
  })}
  > a > button {
    ${Media({
      width: ['100%', null, 'initial'],
    })}
  }
`;
const StyledRichText = styled(Box)`
  > &:first-child {
    margin-top: 0;
  }
  && {
    > * {
      color: ${props => props.theme.ctaBanner.focusText.color};
    }
  }
  ul li::before {
    background-color: ${props => props.theme.ctaBanner.focusText.color} !important;
  }
`;

export {
  StyledContent,
  StyledContentOneLiner,
  StyledContentTitle,
  StyledCta,
  StyledImage,
  StyledRichText,
  StyledWrapper,
};
