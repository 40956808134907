import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const GreyBoxContainer = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${props =>
    Media({
      backgroundColor: [
        `${props.theme.colors.superLightGrey}`,
        `${props.theme.colors.superLightGrey}`,
        'transparent',
        `${props.theme.colors.superLightGrey}`,
      ],
      marginTop: ['56px', null, null, '81px'],
      paddingBottom: ['56px', null, null, '84px'],
      paddingLeft: ['1rem', null, '32px', '58px'],
      paddingRight: ['1rem', null, '32px', '58px'],
      paddingTop: ['56px', null, null, '76px'],
    })}
`;

export const StyledCardContainer = styled(Flex)`
  align-items: top;
  justify-content: left;

  ${Media({
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginTop: ['1rem', null, null, '3.25rem'],
    position: ['relative', null, null, 'static'],
  })}

  > *:not(:last-child) {
    ${Media({
      marginBottom: ['1rem', null, null, '0'],
    })}
  }

  > .vertical {
    max-width: none;

    ${Media({
      width: ['100%', null, 'calc(50% - 1rem)', '33.33%'],
    })}
  }
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;

  ${Media({
    marginTop: ['1rem', null, null, '0.5rem'],
  })}
`;

export const StyledModuleHeading = styled.h2`
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  font-weight: ${props => props.theme.productListing.titleFontWeight || 'normal'};
  margin-bottom: 0px;
  word-break: break-word;
  margin-top: 0px;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
  })}
`;

export const StyledModuleHeadingNoDescription = styled.h2`
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  font-weight: normal;
  margin-bottom: 40px;
  word-break: break-word;
  margin-top: 0px;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
  })}
`;

export const StyledVerticalCardContainer = styled(Flex)`
  display: flex;
  align-items: top;
  justify-content: left;
  margin: 0 -16px;

  ${Media({
    width: ['auto', null, null, '100%'],
  })}

  &.cards-2 {
    ${Media({
      width: ['100%', null, '100%', '66.66%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, '50%', '33.33%'],
    })}
  }

  ${Media({
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginBottom: ['0', null, '1rem', '3.25rem'],
    position: ['relative', null, 'static'],
  })}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledVerticalProductWrapper = styled(Box)`
  ${Media({
    margin: ['0 16px 1rem 16px', null, null, '0 16px 0 16px'],
    width: ['auto', null, '50%', '33.33%'],
  })}

  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);

  &.cards-3 {
    width: 100%;
  }

  &.cards-2 {
    ${Media({
      width: ['auto', null, 'calc(50% - 32px)'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', 'calc(100% - 16px)'],
    })}
  }

  > .vertical {
    max-width: none;
    height: 100%;
  }
`;
