import fetchPonyfill from 'fetch-ponyfill';

const { fetch, Headers } = fetchPonyfill();

const queryParams = (params: { [key: string]: string }) =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

const handleNetlifyFormSubmission = async (
  name: string,
  values: { [key: string]: string },
): Promise<{ status: number }> => {
  const method = 'POST';

  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  const options: RequestInit = {
    method,
    headers,
    credentials: 'include',
  };

  options.body = queryParams({ 'form-name': name, ...values });

  const result = fetch('/', options)
    .then(response => {
      return {
        status: response.status || 500,
      };
    })
    .catch(() => {
      return { status: 500 };
    });

  return result;
};

export default handleNetlifyFormSubmission;
