import { BoxProps } from 'rebass';

import type { NavItemType, SubnavItem } from '../../../../../types';
import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';
import matchUrl from '../../matchUrl';

import { StyledNavItem, StyledNavigation } from './Styled';

interface SecondaryNavigationLightProps extends BoxProps {
  items?: SubnavItem[];
  activeMenu: string | null;
}

const SecondaryNavigationLight = ({ items, activeMenu }: SecondaryNavigationLightProps) => {
  return (
    <StyledNavigation>
      {items?.map((item: NavItemType) => (
        <StyledNavItem className={matchUrl(activeMenu as string, item) ? 'active' : 'inactive'} key={item.to}>
          <Cta to={item.to}>{item.label}</Cta>
        </StyledNavItem>
      ))}
    </StyledNavigation>
  );
};

export default withErrorBoundary(SecondaryNavigationLight, { componentName: 'SecondaryNavigationLight' });
