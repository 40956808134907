import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { CardProps as RebassCardProps } from 'rebass';
import type { BoxProps } from 'rebass/styled-components';

import { withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared/Styled';

import {
  StyledCardContainer,
  StyledColorBox,
  StyledCustomBlockCard,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingContainer,
  StyledModuleHeadingNoDescription,
} from './Styled';
import { Vertical } from './Vertical/Vertical';

interface CardProps extends RebassCardProps {
  /** category id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: IGatsbyImageData;
  /** card cta */
  link?: string;
  linkLabel?: string;
  /** display link text */
  showLink?: boolean;
  displayCtaCopy?: boolean;
}

export interface CustomBlocksModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** customBlocks */
  customBlocks: CardProps[];
  twoColumns?: boolean;
  displayCtaCopy: boolean;
}

const Heading = ({ title, shortDescription }: Pick<CustomBlocksModuleProps, 'title' | 'shortDescription'>) => (
  <StyledModuleHeadingContainer>
    {!shortDescription ? (
      <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
    ) : (
      <StyledModuleHeading>{title}</StyledModuleHeading>
    )}

    {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
  </StyledModuleHeadingContainer>
);

const CustomBlocksModule = ({
  title,
  shortDescription,
  customBlocks,
  twoColumns,
  displayCtaCopy = false,
}: CustomBlocksModuleProps) => (
  <StyledModuleContainer>
    <Heading title={title} shortDescription={shortDescription} />

    {twoColumns ? (
      <Vertical customBlocks={customBlocks} twoColumns={twoColumns} displayCtaCopy={displayCtaCopy} />
    ) : (
      <StyledCardContainer className={`cards-${customBlocks.length}`}>
        {customBlocks.map((card: CardProps) => (
          <StyledCustomBlockCard
            id={card.id}
            key={`customBlock-${card.id}`}
            title={card.title}
            shortDescription={card.shortDescription || null}
            link={card.link}
            linkLabel={card.linkLabel}
            image={card.image}
            className={`cards-${customBlocks.length}`}
            displayCtaCopy={displayCtaCopy}
            showLink={card.showLink}
          />
        ))}

        <StyledColorBox />
      </StyledCardContainer>
    )}
  </StyledModuleContainer>
);

export default withErrorBoundary(CustomBlocksModule, { componentName: 'CustomBlocksModule' });
