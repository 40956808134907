import * as React from 'react';

const HorizontalLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 661 65">
    <title>Middel 1</title>
    <g id="Laag_2" data-name="Laag 2">
      <g id="Laag_1-2" data-name="Laag 1">
        <rect x="0.5" y="60" width="660" height="1" />
      </g>
    </g>
  </svg>
);

export default HorizontalLine;
