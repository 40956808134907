import { NewsLetterHeader } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  NewsletterHeader: {
    SVG: NewsLetterHeader.AveveAgrarisch,
    className: 'newsletter-svg',
  },
  breadcrumb: {
    display: true,
  },
  button: {
    ctaGroup: 'primary',
    shortBanner: 'secondary',
    ctaBanner: { image: 'primary-with-background', text: 'primary' },
  },
  footer: {
    version: 'V2',
  },
});

export default config;
