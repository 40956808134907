import { tv } from 'tailwind-variants';

import { Heading, HeadingTag, withErrorBoundary } from '../../components';
import { ImageAndTextModule, ImageAndTextModuleProps } from '../ImageAndTextModule';
import { MosaicSectionModule, MosaicSectionModuleInterface } from '../MosaicSectionModule';

type ProductCollectionListerProps = {
  id: string;
  title?: string;
  titleTag?: HeadingTag;
  mosaicSection?: MosaicSectionModuleInterface;
  imageAndTextCollection?: Array<ImageAndTextModuleProps>;
};

const styles = tv({
  slots: {
    container: 'my-5 lg:my-9 xl:my-[57px]',
    imageAndText: 'mb-[39px] lg:mb-[87px] !mt-0',
    mosaic: 'mb-[30px] lg:mb-[50px] !mt-0',
    titleWrapper: 'block px-[30px] py-[20px] bg-productCollectionLister-title mb-[18px] lg:mb-6',
    titleText: '!text-productCollectionLister-text max-lg:!text-base !font-normal !leading-none',
  },
});

const ProductCollectionLister = ({
  title,
  titleTag,
  mosaicSection,
  imageAndTextCollection,
}: ProductCollectionListerProps) => {
  const showImageAndTexts: boolean | null = (!mosaicSection && !!imageAndTextCollection?.length) || null;
  const { container, imageAndText, mosaic, titleText, titleWrapper } = styles();
  return (
    <div className={container()}>
      {title ? (
        <div className={titleWrapper()}>
          <Heading tag={titleTag ?? 'h2'} variant="h4" className={titleText()}>
            {title}
          </Heading>
        </div>
      ) : null}
      {!mosaicSection ? null : <MosaicSectionModule {...mosaicSection} className={mosaic()} />}
      {showImageAndTexts &&
        imageAndTextCollection?.map((elem: ImageAndTextModuleProps) => (
          <ImageAndTextModule key={elem.key} {...elem} className={imageAndText()} />
        ))}
    </div>
  );
};
export default withErrorBoundary(ProductCollectionLister, { componentName: 'ProductCollectionLister' });
