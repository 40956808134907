import * as React from 'react';

const Proxani = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="23 0 659 94" {...props}>
    <g id="Animals_Logo">
      <g id="Group_21">
        <path
          id="Path_29"
          className="cls-1"
          d="m554.52,45.68c0,1.18-.95,2.13-2.13,2.13-1.18,0-2.13-.95-2.13-2.13,0-1.17.95-2.13,2.13-2.13,1.18,0,2.13.95,2.13,2.13"
        />
      </g>
      <g id="Group_22">
        <path
          id="Path_30"
          className="cls-1"
          d="m653.92,43.57c0,1.35-1.1,2.45-2.45,2.45s-2.45-1.1-2.45-2.45,1.1-2.45,2.45-2.45h0c1.35,0,2.45,1.1,2.45,2.45"
        />
      </g>
      <g id="Group_23">
        <path
          id="Path_31"
          className="cls-1"
          d="m587.18,51.71c0,1.17-.95,2.13-2.13,2.13s-2.13-.95-2.13-2.13c0-1.17.95-2.13,2.13-2.13,1.17,0,2.13.95,2.13,2.13h0"
        />
      </g>
      <g id="Group_24">
        <path
          id="Path_32"
          className="cls-1"
          d="m622.12,93.01c-1.32,0-2.39-1.07-2.39-2.39s1.07-2.39,2.39-2.39c.05,0,6-.11,9.05-4.76,2.4-3.79,4.54-7.74,6.42-11.81,1.77-3.96,4.32-5.9,13.33-5.67.82.02,1.7.06,2.62.1,4.43.18,10.91.43,13.94-.62l-5.98-4.67,8.08.72c1.41-.02,2.8-.37,4.05-1.03,1.77-.89,3.95-6.27,2.72-9.04-.59-1.33-3.81-3.06-6.4-4.44-3.03-1.62-6.16-3.3-8.28-5.53-1.82-1.88-3.46-3.91-4.92-6.08-.9-1.28-1.92-2.74-3.64-4.91-5.65-7.12-10.85-6.54-12.37-5.71-3.3,1.78-4.22,2.36-4.82,2.74-.45.28-.77.49-1.56.9l-.3.16c-1.16.63-2.61.19-3.24-.97,0,0,0,0,0,0-.63-1.16-.19-2.61.97-3.24l.34-.18c.64-.33.89-.5,1.25-.72.66-.41,1.65-1.04,5.09-2.9,3.12-1.69,10.97-2.41,18.4,6.94,1.81,2.28,2.88,3.8,3.81,5.14,1.32,1.97,2.82,3.82,4.47,5.53,1.59,1.68,4.38,3.17,7.07,4.61,3.73,2,7.25,3.89,8.51,6.72,2.3,5.19-.95,13.25-4.96,15.26-.91.46-1.87.82-2.85,1.09v.05c-.09,1.37-.88,2.59-2.08,3.23-3.42,2.09-9.67,2.03-17.5,1.72-.91-.04-1.77-.07-2.57-.09-7.46-.24-8.06,1.13-8.83,2.84-1.99,4.31-4.25,8.48-6.79,12.49-4.49,6.83-12.7,6.91-13.05,6.91"
        />
      </g>
      <g id="Group_25">
        <path
          id="Path_33"
          className="cls-1"
          d="m615.67,38.61c-1.32,0-2.39-1.07-2.39-2.39,0-1.06.7-1.99,1.71-2.29.72-.21,1.42-.46,2.11-.76.45-.19.95-.43,1.47-.68,1.18-.58,2.61-.1,3.19,1.08,0,0,0,.01,0,.02.58,1.18.09,2.62-1.09,3.2,0,0,0,0,0,0-.59.29-1.16.55-1.67.77-.87.37-1.75.69-2.66.95-.22.06-.45.1-.68.1"
        />
      </g>
      <g id="Group_26">
        <path
          id="Path_34"
          className="cls-1"
          d="m607.29,39.76c-1.47,0-2.4-.09-2.5-.11-1.31-.14-2.26-1.31-2.12-2.62.14-1.31,1.31-2.26,2.62-2.13,3.26.23,6.54-.09,9.69-.97,1.27-.38,2.6.34,2.98,1.61.38,1.26-.34,2.59-1.6,2.97,0,0,0,0,0,0-2.95.84-6,1.27-9.06,1.25"
        />
      </g>
      <g id="Group_27">
        <path
          id="Path_35"
          className="cls-1"
          d="m578.66,85.58c-.65,0-1.27-.26-1.72-.73-.06-.06-5.84-6.05-10.55-8.9-4.14-2.5-12.46-5.05-17.81-5.99-1.46-.26-2.71-.44-3.79-.61-3.39-.51-5.44-.82-7.16-2.2-.66-.5-1.18-1.17-1.51-1.93-1.38-.2-2.73-.53-4.05-.98-4.36-1.45-5.64-8.57-5.93-10.71-.57-4.26,0-6.99,1.7-8.12,1.51-.89,3.37-.94,4.93-.14,1.19.44,1.65.57,2.46.08,3.27-1.96,7.47-6.49,11.26-12.12.74-1.1,2.22-1.39,3.32-.65,1.1.73,1.39,2.22.66,3.32,0,0,0,0,0,0-4.22,6.27-8.88,11.21-12.77,13.55-2,1.2-4.48,1.31-6.58.3-.11-.04-.23-.08-.35-.13-.14,2.74.33,5.49,1.38,8.03.66,1.52,1.27,2.01,1.44,2.07,1.48.6,3.07.88,4.66.85l7.9-1.13-4.85,4.33c1.37.43,2.78.73,4.21.87,1.11.17,2.39.36,3.9.62,5.82,1.02,14.62,3.68,19.46,6.61,5.25,3.18,11.27,9.41,11.52,9.67.92.95.89,2.46-.06,3.38-.45.43-1.04.67-1.66.67m-38.42-20.89h0s0,0,0,0"
        />
      </g>
      <g id="Group_28">
        <path
          id="Path_36"
          className="cls-1"
          d="m591.58,33.95c-.63,0-1.23-.25-1.68-.69-6.2-6.15-16.15-7.35-21.53-7.52-1.32-.04-2.36-1.14-2.32-2.46,0,0,0,0,0,0,0-1.28,1.03-2.32,2.31-2.32.05,0,.1,0,.15,0,6.08.19,17.38,1.6,24.75,8.9.94.93.95,2.44.02,3.38-.45.46-1.06.71-1.7.71"
        />
      </g>
      <g id="Group_29">
        <path
          id="Path_37"
          className="cls-1"
          d="m563.16,38.54c-6.26,0-15.98-1.94-20.42-5.26-5.18-3.88-6.8-5.41-6.86-7.44.06-1.36.77-2.6,1.91-3.34,1.71-1.45,3.93-1.29,6.26-1.12,1.6.23,3.22.15,4.79-.22,3.95-1.37,12.09-4.2,17.95-.92,6.08,3.4,8.36,8.92,5.81,14.05-1.09,2.2-3.26,3.04-4.85,3.66-1.49.45-3.04.65-4.59.59m-21.81-12.47c.83.75,2.25,1.88,4.25,3.38,4.92,3.68,18.33,4.86,20.28,4.1,1.18-.46,2.17-.84,2.43-1.38h0c2.01-4.07-2.45-6.96-3.87-7.75-3.98-2.23-10.76.12-14.01,1.25-2.19.61-4.48.78-6.74.49-.78-.07-1.57-.1-2.35-.08"
        />
      </g>
      <g id="Group_30">
        <path
          id="Path_38"
          className="cls-1"
          d="m577.25,93.01c-.1,0-.2,0-.3-.02-1.31-.17-2.24-1.36-2.07-2.67,1.5-11.92,4.79-15.9,6.96-18.54.39-.45.75-.93,1.08-1.42-.62.09-1.25.1-1.88.03-1.61-.15-3.08-.95-4.08-2.22-1.2-1.8-1.69-3.98-1.39-6.12-2.97.25-6.41.31-7.48-.05-.93-.27-1.67-.99-1.96-1.92-.52-1.66.46-2.96,1.93-4.92l.28-.37c1.81-2.15,3.75-4.18,5.81-6.09.18-2.22,1.15-4.31,2.72-5.88-1.42-2.51-1.53-5.56-.3-8.17,1.27-2.46,4.06-3.71,6.74-3.02.68.15,1.33.39,1.94.73,1.44-2.03,3.79-3.23,6.28-3.19h.06c2.88.04,5.49,1.73,6.68,4.36,2.66-.73,5.52,0,7.51,1.91,3.28,3.14,2.24,6.64,1.44,8.51,2.24,2.45,4.18,5.16,5.79,8.07l1.12,2.22c2.23,3.95,3.8,8.24,4.64,12.7.25,1.3-.6,2.55-1.9,2.8-1.3.25-2.55-.6-2.8-1.9-.96-5.03-2.18-7.44-4.21-11.44l-1.15-2.26c-1.52-2.67-3.33-5.15-5.41-7.4l-.45-.52c-.79-.7-1.05-1.82-.65-2.79.09-.22.22-.5.36-.81.89-1.87,1-2.68-.09-3.72-1.78-1.7-4.09-.37-4.35-.21l-2.99,1.83-.63-3.44c-.06-.3-.67-3.11-2.94-3.12h-.02c-1.48.04-2.74,1.08-3.07,2.52l-1,3.79-2.92-2.58c-.63-.59-1.38-1.04-2.19-1.35-1-.2-1.35.16-1.57.58-.62,1.72-.15,3.64,1.19,4.88l2.75,2.7-3.64,1.27c-.5.18-2.03.71-2.27,4.24l-.06.93-.67.64c-2.02,1.87-3.95,3.85-5.77,5.92,1.91-.06,3.82-.23,5.71-.51l3.56-.6-.84,3.51c-.68,2.85-.5,4.74-.02,5.35.2.25.51.41.84.41.9.05,1.78-.21,2.52-.73l3.32-2.73.57,4.26c.1.87.14,1.75.11,2.62-.02,2.69-1.32,4.25-2.56,5.77-1.93,2.34-4.58,5.55-5.91,16.09-.15,1.19-1.16,2.09-2.37,2.09m29.42-47.87h0s0,0,0,0"
        />
      </g>
      <g id="Group_31">
        <path
          id="Path_39"
          className="cls-1"
          d="m622.34,93.01H25.9c-1.32,0-2.39-1.07-2.39-2.39h0c0-1.32,1.07-2.39,2.39-2.39h596.45c1.32,0,2.39,1.07,2.39,2.39s-1.07,2.39-2.39,2.39"
        />
      </g>
      <g id="Group_32">
        <path
          id="Path_40"
          className="cls-1"
          d="m626.62,39.19c-4.11,0-8.05-2.29-10.09-3.7-1.74-1.21-7.36-5.42-6.21-8.7.88-2.5,5.92-5.02,13.5-4.97,4.92-.05,9.62,2.05,12.85,5.76,2.07,3.04,1.66,5.35.96,6.76-1.38,2.8-5.07,4.52-10.38,4.83-.21.01-.43.02-.64.02m-11.17-11.01c1.12,1.29,2.4,2.42,3.81,3.38h0c2.78,1.93,5.58,2.96,7.71,2.84,3.75-.22,5.93-1.28,6.37-2.17.26-.53-.23-1.39-.55-1.86-2.36-2.47-5.64-3.83-9.05-3.77-4.38-.09-7.03.88-8.29,1.58Z"
        />
      </g>
    </g>
  </svg>
);

const AveveAgrarisch = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660.92 59.61" {...props}>
    <g id="Icon_ion-mail-open-outline" data-name="Icon ion-mail-open-outline">
      <path
        id="Path_115"
        data-name="Path 115"
        d="M608.18,10.17,590.37,1.38a2.46,2.46,0,0,0-2.21,0l-17.81,8.79a4.07,4.07,0,0,0-2.29,3.64V32.63a4.11,4.11,0,0,0,4.13,4.08h34.15a4.11,4.11,0,0,0,4.13-4.07h0V13.81A4.08,4.08,0,0,0,608.18,10.17Z"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M603.67,30.18,590.49,19.94a2.43,2.43,0,0,0-3,0L574.31,30.18m20.39-7.43,13.86-10.5m-39.14,0L583.59,23"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
      />
    </g>
    <g id="Icon_akar-newspaper" data-name="Icon akar-newspaper">
      <path
        id="Path_96"
        data-name="Path 96"
        d="M628.16,36.12H652a7.93,7.93,0,0,0,7.94-7.94h0V4.36a4,4,0,0,0-4-4H636.1a4,4,0,0,0-4,4h0V30.17C632.13,33.46,631.45,36.12,628.16,36.12Z"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
      />
      <path
        id="Path_97"
        data-name="Path 97"
        d="M624.19,14.28a4,4,0,0,1,4-4h4V31.16c0,2.74-1.23,5-4,5s-4-2.22-4-5Z"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2px' }}
      />
      <path
        id="Path_98"
        data-name="Path 98"
        d="M644,10.31a2,2,0,1,1-2-2h0A2,2,0,0,1,644,10.31Z"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeWidth: '2px' }}
      />
      <path
        id="Path_99"
        data-name="Path 99"
        d="M640.07,22.23H652m-11.91,5.95h6"
        transform="translate(0 0.61)"
        style={{ fill: 'none', strokeLinecap: 'round', strokeWidth: '2px' }}
      />
    </g>
    <rect y="58.61" width="660" height="1" />
  </svg>
);

export default { AveveAgrarisch, Proxani };
