import { useConfiguration } from '@arvesta-websites/configuration';
import type { Document } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { Button, Cta, RichText } from '../../../components';
/**
 * Do not merge the errorboundary import, else webpack will think it is undefined
 * Could be because it is also imported by button, cta but unclear...most often that seems to be a circular dependency thing, but i'm not seeing the circle
 * @Todo: further investigate
 * */
import { withErrorBoundary } from '../../../components/ErrorBoundary';
import { StyledModuleContainer } from '../../../shared/Styled';
import type { CommonProps } from '../CommonProps';

import {
  StyledContent,
  StyledContentOneLiner,
  StyledContentTitle,
  StyledCta,
  StyledImage,
  StyledRichText,
  StyledWrapper,
} from './Styled';

type CtaBannerFocusTextProps = CommonProps & {
  ctaOneliner?: string;
  shortDescription?: {
    json: Document;
  };
};

const CtaBannerFocusText = ({
  image,
  shortDescription,
  title,
  cta,
  ctaOneliner,
  orientation = 'left',
  ...rest
}: CtaBannerFocusTextProps) => {
  const config = useConfiguration();
  const { CtaMask } = useConfiguration();
  const imageRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const handleResize = () => {
      if (imageRef.current?.clientHeight) {
        setImageHeight(imageRef.current.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledModuleContainer {...rest}>
      <StyledWrapper orientation={orientation} mobileBackgroundBottomOffset={imageHeight / 2}>
        {image?.asset?.gatsbyImageData && (
          <StyledImage
            orientation={orientation}
            ref={imageRef}
            maskUrl={encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<CtaMask />))}
          >
            <GatsbyImage
              image={image?.imageToBeMasked ?? image?.imageWithFocalPoint}
              alt={image?.alt ? image.alt : title}
              loading="eager"
            />
          </StyledImage>
        )}

        <StyledContent>
          <StyledContentTitle>{title}</StyledContentTitle>
          {shortDescription && (
            <StyledRichText>
              <RichText data={shortDescription.json} widthSize="Large" />
            </StyledRichText>
          )}
          {ctaOneliner && <StyledContentOneLiner>{ctaOneliner}</StyledContentOneLiner>}
          {cta && (
            <StyledCta>
              <Cta to={cta.to}>
                <Button variant={config.button?.ctaBanner?.text ?? 'primary-with-background'}>{cta.label}</Button>
              </Cta>
            </StyledCta>
          )}
        </StyledContent>
      </StyledWrapper>
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(CtaBannerFocusText, { componentName: 'CtaBannerModule/CtaBannerFocusText' });
export type { CtaBannerFocusTextProps };
