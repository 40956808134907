import { Document } from '@contentful/rich-text-types';
import React from 'react';
import { BoxProps } from 'rebass';

import { withErrorBoundary } from '../ErrorBoundary';
import { documentToReactComponents } from '../RichText';

import {
  StyledAuthor,
  StyledAuthorContentWrapper,
  StyledAuthorWrapper,
  StyledBox,
  StyledImage,
  StyledInfo,
  StyledQuote,
  StyledSeparator,
} from './Styled';

export interface BlockquoteProps extends BoxProps {
  /** quote text */
  quote: string | Document;
  /** optional author */
  author?: string | null;
  /** author info */
  authorInfo?: string;
  imageUrl?: string;
}

const Blockquote = ({ quote, author, authorInfo, imageUrl, ...props }: BlockquoteProps) => (
  <StyledBox {...props} as="blockquote">
    {typeof quote === 'string' ? (
      <StyledQuote>{quote}</StyledQuote>
    ) : (
      <StyledQuote>{documentToReactComponents(quote)}</StyledQuote>
    )}
    {author && (
      <StyledAuthorWrapper>
        {imageUrl && (
          <StyledImage
            sx={{
              backgroundImage: `url(${imageUrl}?f=face)`,
            }}
          />
        )}
        <StyledAuthorContentWrapper>
          <StyledSeparator />
          <StyledAuthor>{author}</StyledAuthor>
          {authorInfo && <StyledInfo>{authorInfo}</StyledInfo>}
        </StyledAuthorContentWrapper>
      </StyledAuthorWrapper>
    )}
  </StyledBox>
);

export default withErrorBoundary<BlockquoteProps>(Blockquote, { componentName: 'Blockquote' });
