import axios from 'axios';

const handleNewsletterFormSubmission = (email: string, country: string, locale: string, campaign?: string) => {
  return axios
    .post('/.netlify/functions/subscribe', { campaign, country, email, locale: locale.toUpperCase() })
    .then(response => response.status)
    .catch(error => error.response.status);
};

export default handleNewsletterFormSubmission;
