import { useState } from 'react';

import { FieldType } from '../../../../types';
import { withErrorBoundary } from '../../ErrorBoundary';
import { Option } from '../MultiSelect';

import CustomSelect from './CustomSelect';
import CustomSelectContainer from './CustomSelectContainer';
import { StyledError } from './Styled';

export interface SelectFieldProps {
  label: string;
  options: Option[];
  onSelect: (option: { label: string; value: string }) => void;
  contactForm?: boolean;
  field: FieldType;
}

const SelectField = ({ onSelect, options, label, contactForm, field }: SelectFieldProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <CustomSelectContainer>
      <CustomSelect
        onSelect={onSelect}
        options={options}
        label={label}
        setExpanded={setExpanded}
        expanded={expanded}
        contactForm={contactForm}
        field={field}
      />
      {field.error && <StyledError>{field.error}</StyledError>}
    </CustomSelectContainer>
  );
};

export default withErrorBoundary(SelectField, { componentName: 'SelectField' });
