import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Belcrop" transform="translate(0.000000, 16.000000)">
        <path
          d="M6.9185,11.6833 C6.4115,11.6833 5.9815,11.6903 5.5525,11.6803 C5.3365,11.6753 5.2145,11.7053 5.21647579,11.9743 C5.2285,13.5093 5.2235,15.0453 5.2225,16.5803 C5.2215,16.7323 5.2335,16.8553 5.4355,16.8473 C6.5225,16.8063 7.6185,16.9563 8.6975,16.7563 C9.8705,16.5373 10.6155,15.6053 10.6945,14.3283 C10.7685,13.1333 10.1445,12.2353 8.9875,11.8503 C8.2845,11.6153 7.5595,11.7033 6.9185,11.6833 M5.2215,24.2853 C5.2215,25.1303 5.2245,25.9753 5.2195,26.8193 C5.2185,27.0253 5.2355,27.1733 5.5085,27.1643 C6.9805,27.1183 8.4585,27.2623 9.9255,27.0873 C11.5055,26.8983 12.4895,25.7873 12.5355,24.2013 C12.5745,22.8493 11.5985,21.7813 10.0425,21.5463 C8.5175,21.3153 6.9775,21.4903 5.4445,21.4453 C5.1645,21.4363 5.2235,21.6423 5.2235,21.7983 C5.2185,22.6273 5.2215,23.4563 5.2215,24.2853 M12.6795,18.3023 C13.4155,18.4753 14.0505,18.6413 14.6405,18.9473 C17.0935,20.2223 18.2725,22.6793 17.8765,25.4963 C17.3465,29.2713 14.7435,31.1793 11.5645,31.6173 C10.4335,31.7733 9.2965,31.7543 8.1605,31.7443 C6.2255,31.7263 4.2875,31.8273 2.3545,31.6923 C0.9315,31.5943 0.0155,30.5483 0.0155,29.1113 C0.0145,22.7023 0.0345,16.2933 0.000429635934,9.8853 C-0.0075,8.4823 0.6655,7.1353 2.6165,7.1063 C5.1955,7.0693 7.7935,6.8823 10.3555,7.1843 C15.5775,7.7983 16.8595,12.7363 15.2935,15.9623 C14.7675,17.0453 13.8335,17.7183 12.6795,18.3023"
          id="Fill-1"
          fill="#83BB2B"
        />
        <path
          d="M110.9473,22.6194 C110.9573,22.0614 110.8823,21.5144 110.7523,20.9774 C110.5543,20.1654 110.2343,19.4034 109.6893,18.7584 C108.1203,16.8994 105.3863,17.1024 104.0513,19.1724 C102.8013,21.1074 102.9283,24.3464 104.3263,26.1824 C105.7213,28.0154 108.3233,28.0334 109.7553,26.2214 C110.5903,25.1634 110.8903,23.9314 110.9473,22.6194 M103.1953,30.4644 L103.1953,31.5974 C103.1953,33.2114 103.2063,34.8244 103.1913,36.4384 C103.1823,37.3974 102.9303,38.2714 102.0853,38.8464 C100.5393,39.8954 98.4783,38.9664 98.2323,37.1144 C98.1983,36.8564 98.1783,36.5944 98.1783,36.3344 C98.1763,29.5724 98.1773,22.8094 98.1763,16.0484 C98.1763,15.2764 98.3203,14.5554 98.8223,13.9374 C99.8353,12.6914 102.4063,12.7684 103.0333,14.7054 C103.0703,14.8204 103.1143,14.9324 103.1353,14.9914 C103.4183,14.7384 103.7013,14.4624 104.0073,14.2154 C105.3343,13.1414 106.8793,12.7924 108.5413,12.9304 C111.3423,13.1614 113.3723,14.6224 114.5853,17.0894 C116.4953,20.9734 116.5793,24.8774 114.1723,28.6124 C112.1043,31.8214 108.2183,32.9984 104.8983,31.5234 C104.3113,31.2634 103.7723,30.9204 103.1953,30.4644"
          id="Fill-3"
          fill="#83BB2B"
        />
        <path
          d="M57.4907,6.6711 C59.6207,6.7311 61.4377,6.9721 63.1297,7.7971 C63.8597,8.1531 64.4967,8.6221 64.8917,9.3601 C65.6317,10.7451 64.9617,12.4831 63.5417,12.8331 C63.0377,12.9581 62.5397,12.8911 62.0497,12.7191 C61.1687,12.4091 60.3177,12.0211 59.3897,11.8381 C55.7377,11.1181 52.8307,12.7861 51.7317,16.3411 C50.9457,18.8811 51.0307,21.4051 52.2347,23.8231 C53.3707,26.1041 55.7397,27.3631 58.2747,27.1311 C59.3777,27.0301 60.4057,26.7191 61.4027,26.2581 C61.9297,26.0131 62.4557,25.7471 63.0507,25.7031 C63.9707,25.6351 64.6557,26.0401 65.1307,26.8051 C65.6767,27.6801 65.6617,28.5821 65.1587,29.4661 C64.6477,30.3661 63.8107,30.8981 62.8737,31.2361 C59.7427,32.3711 56.5697,32.5641 53.4197,31.3641 C50.0037,30.0641 47.7597,27.5651 46.6087,24.1411 C45.3317,20.3401 45.4877,16.5521 47.3387,12.9651 C49.5047,8.7691 53.0907,6.7881 57.4907,6.6711"
          id="Fill-6"
          fill="#83BB2B"
        />
        <path
          d="M28.6245,20.8635 L32.2205,20.8635 C32.4065,20.8635 32.6185,20.8945 32.5535,20.5905 C32.3295,19.5285 31.9285,18.5545 31.0375,17.8775 C29.8515,16.9755 28.5185,16.8275 27.1605,17.3655 C25.7415,17.9295 25.0285,19.0995 24.7325,20.5555 C24.6675,20.8735 24.8555,20.8655184 25.0745,20.8655184 C26.2575,20.8605 27.4415,20.8635 28.6245,20.8635 M30.3265,24.2105 C28.6365,24.2105 26.9465,24.2185 25.2565,24.2035 C24.8975,24.2005 24.7755,24.2525 24.8805,24.6545 C25.3345,26.3845 26.8285,27.5585 28.7585,27.7365 C30.1125,27.8615 31.3415,27.5295 32.5145,26.8975 C33.0675,26.5995 33.6095,26.2765 34.1795,26.0135 C35.2035,25.5405 36.1015,25.7825 36.7415,26.6605 C37.3735,27.5255 37.3225,28.4685 36.5855,29.3105 C35.8625,30.1385 34.9385,30.6805 33.9395,31.0995 C31.5125,32.1165 29.0045,32.5155 26.4295,31.8215 C22.7175,30.8195 20.5815,28.3025 19.8675,24.5875 C19.3385,21.8395 19.8105,19.2255 21.3815,16.8825 C24.8945,11.6435 32.1035,11.5535 35.7815,16.6825 C36.8715,18.2025 37.4945,19.8875 37.5995,21.7645 C37.6905,23.4055 36.9575,24.2055 35.3035,24.2095 C33.6445,24.2145 31.9855,24.2105 30.3265,24.2105"
          id="Fill-8"
          fill="#83BB2B"
        />
        <path
          d="M91.4209,22.5569 C91.4429,21.4119 91.1969,20.3309 90.6149,19.3419 C89.2259,16.9809 86.0959,16.8429 84.5699,19.0669 C83.2749,20.9569 83.2369,23.9549 84.4849,25.8779 C85.9839,28.1879 89.1779,28.1299 90.5949,25.7649 C91.1849,24.7799 91.4439,23.7029 91.4209,22.5569 M96.4439,22.4979 C96.4589,25.0469 95.7079,27.4949 93.8099,29.4909 C91.8369,31.5639 89.3719,32.3559 86.5429,32.0949 C82.2559,31.6999 79.1859,28.2389 78.6679,23.9909 C78.3089,21.0429 78.9899,18.3769 80.8739,16.0619 C84.4689,11.6439 91.2549,11.9029 94.5349,16.5629 C95.7539,18.2959 96.3819,20.2179 96.4439,22.4979"
          id="Fill-10"
          fill="#83BB2B"
        />
        <path
          d="M44.2661,18.551 C44.2661,22.054 44.2651,25.557 44.2681234,29.06 C44.2691,29.401 44.2381,29.737 44.1531,30.063 C43.8481,31.239 42.8271,32.019 41.6911,31.953 C40.4151,31.879 39.4921,31.047 39.3081,29.796 C39.2711,29.539 39.2511,29.277 39.2511,29.017 C39.2481,22.026 39.2491,15.035 39.2491,8.045 C39.2491,7.156 39.4181,6.322 40.1151,5.701 C41.5341,4.435 43.8071,5.209 44.1781,7.081 C44.2381,7.38 44.2621,7.691 44.2631,7.996 C44.2681234,11.514 44.2661,15.032 44.2661,18.551"
          id="Fill-12"
          fill="#83BB2B"
        />
        <path
          d="M72.7969,15.1032 C72.9869,14.8712 73.1729,14.6352 73.3689,14.4092 C74.3219,13.3122 75.8919,12.8302 77.1099,13.2562 C78.3399,13.6862 79.0779,15.3262 78.5759,16.5262 C78.3369,17.0982 77.8369,17.3732 77.3019,17.5842 C76.3739,17.9512 75.4489,18.3192 74.5669,18.7862 C73.3299,19.4412 72.6829,20.3932 72.7199,21.8552 C72.7789,24.1742 72.7389,26.4952 72.7329,28.8152 C72.7319,29.1832 72.7189,29.5582 72.6459,29.9152 C72.3759,31.2282 71.2739,32.0682 70.0269,31.9422 C68.6349,31.8012 67.7249,30.7532 67.7219,29.2592 C67.7159,25.9402 67.7199,22.6212 67.7199,19.3022 C67.7189,18.0882 67.7179,16.8742 67.7209,15.6612 C67.7249,14.1422 68.7969,13.0822 70.3199,13.1112 C70.7319,13.1192 71.1429,13.1602 71.5389,13.3132 C72.2249,13.5772 72.5939,14.0702 72.6929,14.7872 C72.7059,14.8852 72.6589,15.0032 72.7969,15.1032"
          id="Fill-14"
          fill="#83BB2B"
        />
        <path
          d="M95.0176,11.8772 C94.6936,11.8322 94.5226,11.6682 94.3376,11.5382 C93.9356,11.2552 93.5406,10.9622 93.1426,10.6742 C90.9206,9.0632 88.4636,8.8062 85.8576,9.4142 C84.3196,9.7732 82.7926,10.2142 81.1866,10.1002 C78.0026,9.8732 75.7416,8.2852 74.3866,5.4172 C73.6006,3.7522 73.1886,1.9802 73.0216,0.0002 C73.6216,0.8722 74.2826,1.4922 75.0576,1.9922 C76.4556,2.8942 77.9956,3.2542 79.6436,3.2972 C81.9296,3.3572 84.2216,3.3822 86.4756,3.8462 C88.8946,4.3442 91.0796,5.2852 92.6686,7.2692 C93.7536,8.6232 94.4956,10.1612 95.0176,11.8772"
          id="Fill-16"
          fill="#0072B9"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
