import { ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { withErrorBoundary } from '../..';
import { useClickOutside } from '../../../../utils';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledDropDown,
  StyledDropDownClear,
  StyledDropDownContainer,
  StyledDropDownItemSeparator,
  StyledDropDownSeparator,
  StyledHeader,
} from './StyledMultiSelectFilter';

interface ArrowProps {
  expanded?: boolean;
}

const Arrow = ({ expanded }: ArrowProps) => {
  return (
    <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d={expanded ? 'M18 15 12 9 6 15' : 'M6 9L12 15 18 9'} />
    </svg>
  );
};

const RoundedCheckBox = ({ checked, ...props }: any) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckboxContainer disabled={props.disabled}>
        <StyledCheckbox checked={checked} disabled={props.disabled} />
      </StyledCheckboxContainer>
    </CheckboxContainer>
  );
};

interface DropdownHeaderProps {
  label: string;
}
const DropdownHeader = ({ label }: DropdownHeaderProps) => <span>{label}</span>;

interface MultiSelectProps {
  onSelect: (options: Option[]) => void;
  options: Option[];
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  label: string;
  fullWidth: boolean;
  value?: Option[] | null;
  maxSelectionSize?: number;
}
const MultiSelect = ({
  onSelect,
  options,
  expanded,
  setExpanded,
  label,
  fullWidth,
  value,
  maxSelectionSize,
}: MultiSelectProps) => {
  const [checkedOptions, setCheckedOptions] = useState<Option[]>(value ?? []);
  const intl = useIntl();

  useEffect(() => {
    setCheckedOptions(value ?? []);
  }, [value]);

  const isSelected = (value: string) => checkedOptions.some(option => option.value === value);

  const canInteract = (value: string) => {
    if (isSelected(value)) return true;
    return maxSelectionSize ? checkedOptions.length < maxSelectionSize : true;
  };

  const handleCheckboxChange = (event: any, option: Option) => {
    const wasSelected = isSelected(option.value);
    const update = wasSelected
      ? checkedOptions.filter(item => item.value !== option.value)
      : checkedOptions.concat(option);
    setCheckedOptions(update);
    onSelect(update);
  };

  const clear = () => {
    setCheckedOptions([]);
    onSelect([]);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const expandOnSpace = (ev: any) => {
    if (ev.key !== 'Space' && ev.key !== ' ') return;
    ev.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <StyledDropDownContainer expanded={expanded} fullWidth={fullWidth}>
      <StyledHeader
        onClick={toggleExpanded}
        onKeyDown={expandOnSpace}
        id="MultiSelect-label"
        tabIndex={0}
        aria-expanded={expanded}
      >
        <DropdownHeader label={label} />
        <Arrow expanded={expanded} />
      </StyledHeader>
      <StyledDropDownSeparator expanded={expanded} />
      {expanded && (
        <StyledDropDown expanded={expanded} aria-labelledby="MultiSelect-label" role="listbox">
          {options.map((option: any, index: any) => {
            return (
              <StyledDropDownItemSeparator key={option.value} disabled={!canInteract(option.value)}>
                <label htmlFor={`checkbox-${option.value}`}>
                  <RoundedCheckBox
                    id={`checkbox-${option.value}`}
                    checked={isSelected(option.value)}
                    disabled={!canInteract(option.value)}
                    onChange={(e: any) => handleCheckboxChange(e, option)}
                  />
                  <span style={{ marginLeft: 8 }}>{option.label}</span>
                </label>
              </StyledDropDownItemSeparator>
            );
          })}
          <StyledDropDownClear onClick={clear}>
            {intl.formatMessage({ id: 'sections.dealer.clear' })}
          </StyledDropDownClear>
        </StyledDropDown>
      )}
    </StyledDropDownContainer>
  );
};

interface MultiSelectContainerProps {
  setExpanded: (val: boolean) => void;
  children: ReactNode;
  className?: string;
}
const MultiSelectContainer = ({ children, setExpanded, className }: MultiSelectContainerProps) => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setExpanded(false));

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
};

export interface Option {
  label: string;
  value: string;
}
interface MultiSelectFilterProps {
  onSelect: any;
  options: Option[];
  value?: Option[] | null;
  label: string;
  fullWidth?: boolean;
  className?: string;
  maxSelectionSize?: number;
}
const MultiSelectFilter = ({
  onSelect,
  options,
  value,
  label,
  className,
  fullWidth = false,
  maxSelectionSize,
}: MultiSelectFilterProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <MultiSelectContainer setExpanded={setExpanded} className={className}>
      <MultiSelect
        onSelect={onSelect}
        options={options}
        label={label}
        setExpanded={setExpanded}
        expanded={expanded}
        fullWidth={fullWidth}
        value={value}
        maxSelectionSize={maxSelectionSize}
      />
    </MultiSelectContainer>
  );
};

export default withErrorBoundary(MultiSelectFilter, { componentName: 'Forms/MultiSelectFilter' });
