import React from 'react';

import { Video } from '../../components';

import { StyledVideoWrapper, StyledWrapper } from './Styled';

export interface VideoModuleProps {
  videoUrl: string;
  size?: string;
  position?: string;
}

const VideoModule = ({ videoUrl, size, position }: VideoModuleProps) => {
  return (
    <StyledWrapper position={position}>
      <StyledVideoWrapper size={size}>
        <Video videoUrl={videoUrl} />
      </StyledVideoWrapper>
    </StyledWrapper>
  );
};

export default VideoModule;
