import React from 'react';

const FlagNl = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-nl" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-nl" fill="#fff">
        <use xlinkHref="#a-nl" />
      </mask>
      <rect width="33.1" height="23.5" x="0.25" y="0.25" stroke="#F5F5F5" strokeWidth="0.5" rx="2" />
      <path fill="#CA2B39" d="M0 0h33.6v8H0z" mask="url(#b-nl)" />
      <path fill="#2C56A2" d="M0 16h33.6v8H0z" mask="url(#b-nl)" />
    </g>
  </svg>
);

export default FlagNl;
