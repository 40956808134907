import type { BoxProps } from 'rebass/styled-components';

import { mediumBreakpoint } from '../../../../utils/breakpoints';
import { chunkArrayCategories } from '../../../../utils/common';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import { CustomBlockCard, type CustomBlockCardProps, withErrorBoundary } from '../../../components';

import { StyledBlueBox, StyledVerticalCardContainer, StyledVerticalCardWrapper, StyledWrapper } from './Styled';

interface VerticalProps extends BoxProps {
  customBlocks: CustomBlockCardProps[];
  twoColumns?: boolean;
  displayCtaCopy?: boolean;
}

export const Vertical = ({ customBlocks, twoColumns, displayCtaCopy = false }: VerticalProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const categoryChunkSize = currentBreakpoint === mediumBreakpoint || twoColumns ? 2 : 3;
  const chunkedCustomBlocks = chunkArrayCategories(customBlocks, categoryChunkSize);

  return (
    <StyledWrapper>
      {chunkedCustomBlocks.map(({ chunk, key }) => (
        <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
          {chunk.map(({ id, ...categoryRest }) => (
            <StyledVerticalCardWrapper key={id} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
              <CustomBlockCard id={id} {...categoryRest} displayCtaCopy={displayCtaCopy} />
              <StyledBlueBox />
            </StyledVerticalCardWrapper>
          ))}
        </StyledVerticalCardContainer>
      ))}
    </StyledWrapper>
  );
};

export default withErrorBoundary(Vertical, { componentName: 'CustomBlocksModule/Vertical' });
