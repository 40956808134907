import React, { ErrorInfo, JSXElementConstructor, ReactNode } from 'react';

type BoundaryProps = {
  fallback?: ReactNode;
  componentName?: string;
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<BoundaryProps, State> {
  displayName = 'ErrorBoundary';

  constructor(props: BoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(
      '%c ERROR BOUNDARY TRIGGERED',
      'color: #FFF; background: #F00',
      this.props.componentName,
      error,
      errorInfo,
    );
  }
  render() {
    const { componentName, fallback, children } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        fallback ?? (
          <p>
            Something went wrong with {componentName ? <em>{componentName}</em> : <em>the component rendered here</em>}.
          </p>
        )
      );
    }
    return children;
  }
}
/* eslint-disable react/function-component-definition, react/display-name */
export const withErrorBoundary =
  <T extends object>(Component: JSXElementConstructor<T>, boundaryProps: BoundaryProps) =>
  (props: T): JSX.Element => (
    <ErrorBoundary {...boundaryProps}>
      <Component {...props} />
    </ErrorBoundary>
  );
/* eslint-enable react/function-component-definition, react/display-name */

export default ErrorBoundary;
