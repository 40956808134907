import { useEffect, useRef } from 'react';

interface ContainsOrIsNode {
  containerNode: HTMLElement;
  containedNode: HTMLElement;
}

const containsOrIsNode = ({ containerNode, containedNode }: ContainsOrIsNode) =>
  containerNode && containedNode && (containerNode.contains(containedNode) || containerNode === containedNode);

interface UseOverlay {
  setIsOpen: (isOpen: boolean) => void;
}

export default ({ setIsOpen }: UseOverlay) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const documentClickHandler = (e: MouseEvent) => {
      if (ref.current) {
        const target = e.target as HTMLElement;
        const targetIsInBody = document.body.contains(target);

        const containsContent = containsOrIsNode({
          containerNode: ref.current,
          containedNode: target,
        });

        if (targetIsInBody && !containsContent) {
          setIsOpen(false);
        }
      }
    };

    document.addEventListener('click', documentClickHandler, false);

    return () => {
      document.removeEventListener('click', documentClickHandler, false);
    };
  }, [setIsOpen, ref]);

  return ref;
};
