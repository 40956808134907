import * as React from 'react';

interface Props {
  className?: string;
}
const Close = ({ className }: Props) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      fill="#3D3D3D"
    />
  </svg>
);

export default Close;
