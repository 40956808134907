import { LocationPin } from '@arvesta-websites/icons';
import { AdvancedMarker, Map, MapEvent, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../../components';
import { Address, Dealer } from '../DealerOverviewModule';

export type MapBoundsValue = google.maps.LatLngBounds | null;

const styles = tv({
  slots: {
    pin: 'w-10 h-10 pin',
  },
  variants: {
    active: {
      true: {
        pin: 'scale-150 active',
      },
    },
  },
});

interface Props {
  dealers: Dealer[];
  isActiveLocation: (id: string) => boolean;
  activeLocationId: string | null;
  onMarkerClick: (id: string) => void;
  className?: string;
  boundsDetermined?: (data: MapBoundsValue) => void;
}

const toGoogleFormat = (location: Address['location']) =>
  location && { lat: Number(location.lat), lng: Number(location.lon) };

const getLocation = (id: string, dealers: Dealer[]) => {
  const dealer = dealers.find(dealer => dealer.id === id);
  return dealer?.address?.location && toGoogleFormat(dealer.address.location);
};

const CENTER_ON_BELGIUM = { lat: 50.5254784, lng: 4.4194201 };

const InteractiveMap = ({
  className,
  dealers,
  isActiveLocation,
  activeLocationId,
  onMarkerClick,
  boundsDetermined,
}: Props) => {
  const MapsLibrary = useMapsLibrary('maps');
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [mapCenter, setMapCenter] = useState<ReturnType<typeof toGoogleFormat> | undefined>(undefined);
  const boundsDeterminedCalled = useRef<google.maps.LatLngBounds | undefined>(undefined);

  useEffect(() => {
    if (!activeLocationId || !dealers?.length) return;
    const location = getLocation(activeLocationId, dealers);
    if (!location) return;
    if (!map) setMapCenter(location);
    else map.panTo(location);
  }, [dealers, activeLocationId]);

  useEffect(() => {
    if (!map) return;
    if (!dealers.length && boundsDeterminedCalled.current) {
      map.fitBounds(boundsDeterminedCalled.current);
      return;
    }
  }, [map, boundsDeterminedCalled.current, dealers]);

  useEffect(() => {
    if (!dealers?.length || !map) return;
    const coords = dealers.map(dealer => toGoogleFormat(dealer.address?.location)).filter(pos => !!pos);
    const bounds = new google.maps.LatLngBounds();
    coords.forEach(position => bounds.extend(position as google.maps.LatLngLiteral));
    map.fitBounds(bounds);

    if (!boundsDeterminedCalled.current && boundsDetermined) {
      boundsDetermined(bounds);
      boundsDeterminedCalled.current = bounds;
    }
  }, [dealers, map, boundsDeterminedCalled.current]);

  const handleMapIdle = useCallback((ev: MapEvent) => {
    setMap(ev.map);
  }, []);

  const { pin } = styles();

  return (
    <Map
      mapId={process.env.MAP_ID ?? process.env.STORYBOOK_MAP_ID ?? MapsLibrary?.DEMO_MAP_ID}
      className="dealer-overview-map"
      defaultCenter={CENTER_ON_BELGIUM}
      center={mapCenter}
      reuseMaps={true}
      onIdle={handleMapIdle}
      maxZoom={15}
      defaultZoom={5}
      isFractionalZoomEnabled={true}
    >
      {dealers.map(
        ({ address, name, id }) =>
          address?.location && (
            <AdvancedMarker
              key={id}
              title={name}
              position={toGoogleFormat(address?.location)}
              onClick={() => onMarkerClick(id)}
              zIndex={isActiveLocation(id) ? dealers?.length : undefined}
            >
              <LocationPin className={pin({ active: isActiveLocation(id) })} />
            </AdvancedMarker>
          ),
      )}
    </Map>
  );
};

export default withErrorBoundary(InteractiveMap, { componentName: 'DealerOverviewModule/InteractiveMap' });
