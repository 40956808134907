import { Input, Label, Textarea } from '@rebass/forms';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

import Media from '../../../../utils/Media';

const inputStyles = css`
  border: 1px solid ${props => `${props.theme.colors.darkGray}`};
  border-radius: ${props => props.theme.formInputs.borderRadius};
  outline: none;
  font-family: ${props => props.theme.fonts.body};
  font-size: 1rem;

  &::-webkit-input-placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }
  &::-moz-placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }
  &:-ms-input-placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }
  &:-moz-placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }
  &::placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }

  &:focus,
  &:hover {
    border: ${props => `1px solid ${props.theme.formInputs.activeColor}`};

    &::-webkit-input-placeholder {
      color: ${props => `${props.theme.formInputs.activeColor}`};
    }
    &::-moz-placeholder {
      color: ${props => `${props.theme.formInputs.activeColor}`};
    }
    &:-ms-input-placeholder {
      color: ${props => `${props.theme.formInputs.activeColor}`};
    }
    &:-moz-placeholder {
      color: ${props => `${props.theme.formInputs.activeColor}`};
    }
    &::placeholder {
      color: ${props => `${props.theme.formInputs.activeColor}`};
    }
  }
`;

export const StyledContainer = styled(Box)`
  color: ${props => props.theme.colors.text};
`;

export const StyledFormRow = styled(Box)`
  ${Media({
    fontSize: ['1rem', null, '13px', '1rem'],
    marginBottom: ['1.5rem', null, '2rem'],
  })}

  > label {
    margin-bottom: 0.625rem;
  }
  > input {
    padding: 0 1.5rem;
  }
  > textarea {
    padding: 1rem 1.5rem;
  }

  &.error input {
    border: 1px solid ${props => props.theme.colors.danger};
  }

  > button {
    width: 100%;
  }
`;

export const StyledFormRowRadio = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;

  ${Media({
    marginBottom: ['1.5rem', null, '2rem'],
  })}

  > label {
    width: auto;
    margin-right: 1rem;

    &:first-child {
      flex-basis: 100%;
      margin-right: 0;
      ${Media({
        fontSize: ['0.875rem', null, '0.75rem'],
        marginBottom: ['1.5rem', null, '1rem'],
      })}
    }
  }

  &.error input {
    border: 1px solid ${props => props.theme.colors.danger};
  }
`;

export const StyledHeading = styled.h2`
  font-weight: ${props => props.theme.fontWeights.headingAlt};
  font-size: ${props => props.theme.fontSizes[3]};
  margin: 0;
  margin-bottom: 0.75rem;
`;

export const StyledDescription = styled(Text)`
  margin-bottom: 2.5rem;
`;

export const StyledLabel = styled(Label)`
  font-size: ${props => props.theme.fontSizes[0]};
  align-items: center;
  position: relative;
  > input {
    opacity: 0;
    width: 0px;
    position: absolute;
  }
  > input:checked {
    width: 9000px;
  }
`;

export const StyledRadioSpan = styled.span`
  top: 0;
  margin-right: 12px;
  left: 0;

  ${Media({
    height: ['32px', '32px', '24px', '24px'],
    width: ['32px', '32px', '24px', '24px'],
  })}

  border: 1px solid ${props => props.theme.colors.darkGray};
  &:hover {
    border: 1px solid ${props => props.theme.formInputs.activeColor};
  }
  border-radius: ${props => props.theme.formInputs.radioInputBorderRadius};
  cursor: pointer;

  &.true {
    &::after {
      display: block;
      position: absolute;
      content: '';

      ${Media({
        height: ['22px', '22px', '16px', '16px'],
        left: ['5px', '5px', '4px', '4px'],
        top: ['5px', '5px', '4px', '4px'],
        width: ['22px', '22px', '16px', '16px'],
      })}

      background: ${props => props.theme.formInputs.activeColor};
      border-radius: ${props => `${props.theme.formInputs.radioSpanBorderRadius}`};
    }
  }

  &.false {
    &::after {
      display: none;
      position: absolute;
      background: ${props => props.theme.formInputs.activeColor};
      border-radius: ${props => props.theme.formInputs.radioSpanBorderRadius};
    }
  }
`;

export const StyledTextInput = styled(Input)`
  ${inputStyles}
  height: 3rem;
`;

export const StyledTextArea = styled(Textarea)`
  ${inputStyles}
  height: 178px;
  resize: none;
`;

export const StyledForm = styled(Box)``;

export const StyledRadio = styled.input``;

export const StyledError = styled.div`
  color: ${props => props.theme.colors.danger};
  display: flex;
  align-items: center;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
  font-size: 0.75rem;
  margin-top: 0.75rem;

  span {
    margin-left: 0.5rem;
  }

  svg path {
    fill: ${props => props.theme.colors.danger};
  }
`;

export const StyledSuccess = styled.div`
  color: ${props => props.theme.colors.success};
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 0.75rem;
  font-weight: bold;

  span {
    margin-left: 0.5rem;
  }

  svg {
    min-width: 24px;
  }

  svg path {
    fill: ${props => props.theme.colors.success};
  }
`;
