import { useConfiguration } from '@arvesta-websites/configuration';
import { type Document } from '@contentful/rich-text-types';

import { Button, Cta, HeadingTag, withErrorBoundary } from '../../components';
import { documentToReactComponents } from '../../components/RichText/helpers';

import {
  InfoBlockWrapper,
  StyledColoredBox,
  StyledCta,
  StyledDescription,
  StyledTitleColored,
  StyledWrapper,
} from './Styled';

interface InfoBlockProps {
  title: string;
  titleTag: HeadingTag;
  description: Document;
  cta: {
    id: string;
    label: string;
    to: string;
  };
}

const InfoBlockModule = ({ title, titleTag, description, cta }: InfoBlockProps) => {
  const config = useConfiguration();

  return (
    <StyledWrapper>
      <InfoBlockWrapper>
        <StyledColoredBox>
          <StyledTitleColored variant="h4" tag={titleTag}>
            {title}
          </StyledTitleColored>
          <StyledDescription>{documentToReactComponents(description)}</StyledDescription>
          {!!cta && (
            <StyledCta>
              <Cta to={cta?.to}>
                <Button variant={config?.button?.infoBlock}>{cta.label}</Button>
              </Cta>
            </StyledCta>
          )}
        </StyledColoredBox>
      </InfoBlockWrapper>
    </StyledWrapper>
  );
};
export default withErrorBoundary(InfoBlockModule, { componentName: 'InfoBlockModule' });
