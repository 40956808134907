import { Label } from '@rebass/forms';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

export const StyledFormRowRadio = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;

  ${Media({
    marginBottom: ['1.5rem', null, '2rem'],
  })}

  > label {
    width: auto;
    margin-right: 1rem;

    &:first-child {
      flex-basis: 100%;
      margin-right: 0;
      ${Media({
        fontSize: ['0.875rem', null, '0.75rem'],
        marginBottom: ['1.5rem', null, '1rem'],
      })}
    }
  }

  &.error input {
    border: 1px solid ${props => props.theme.colors.danger};
  }
`;

export const StyledLabel = styled(Label)`
  font-size: ${props => props.theme.fontSizes[0]};
  align-items: center;
  position: relative;
  > input {
    opacity: 0;
    width: 0px;
    position: absolute;
  }
  > input:checked {
    width: 9000px;
  }
`;

export const StyledRadioSpan = styled.span`
  top: 0;
  margin-right: 12px;
  left: 0;

  ${Media({
    height: ['32px', '32px', '24px', '24px'],
    width: ['32px', '32px', '24px', '24px'],
  })}

  border: 1px solid ${props => props.theme.colors.darkGray};
  &:hover {
    border: 1px solid ${props => props.theme.formInputs.activeColor};
  }
  border-radius: ${props => props.theme.formInputs.radioInputBorderRadius};
  cursor: pointer;

  &.true {
    &::after {
      display: block;
      position: absolute;
      content: '';

      ${Media({
        height: ['22px', '22px', '16px', '16px'],
        left: ['5px', '5px', '4px', '4px'],
        top: ['5px', '5px', '4px', '4px'],
        width: ['22px', '22px', '16px', '16px'],
      })}

      background: ${props => props.theme.formInputs.activeColor};
      border-radius: ${props => `${props.theme.formInputs.radioSpanBorderRadius}`};
    }
  }

  &.false {
    &::after {
      display: none;
      position: absolute;
      background: ${props => props.theme.formInputs.activeColor};
      border-radius: ${props => props.theme.formInputs.radioSpanBorderRadius};
    }
  }
`;

export const StyledError = styled.div`
  color: ${props => props.theme.colors.danger};
  display: flex;
  align-items: center;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
  font-size: 0.75rem;
  margin-top: 0.75rem;

  span {
    margin-left: 0.5rem;
  }

  svg path {
    fill: ${props => props.theme.colors.danger};
  }
`;
