import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

export const StyledWrapper = styled(Flex)`
  flex-direction: column;
`;

export const StyledCertificationsList = styled(Flex)`
  flex-wrap: wrap;
`;

export const StyledHeading = styled.h2`
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: normal;
  color: ${props => props.theme.text.heading.color ?? props.theme.colors.text};
`;

export const StyledCertificationsListItem = styled(Box)`
  margin-right: 24px;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.darkGrey};
  width: 100px;

  a {
    text-decoration: none;
    font-size: 11px;
    margin-top: 25px;
    color: inherit;
  }
`;

export const StyledCertificationText = styled(Box)`
  color: ${props => props.theme.colors.darkGrey};
  font-size: 11px;
  line-height: 18px;
  margin-top: 25px;
`;

export const StyledImage = styled(Box)`
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 100px;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;
