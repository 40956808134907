import { getDestination } from '../../../../utils';
import { withErrorBoundary } from '../../../components';
import type { FeaturesSectionLink } from '../FeaturesSectionModule';

import { CardTitle, CardWrapper, Gradient, StyledChevron, StyledImage, TitleWrapper } from './Styled';

const BottomCard = ({ item, inColumn }: { item: FeaturesSectionLink; inColumn?: boolean }) => {
  const { title, id, image, cardLink } = item;

  return (
    <CardWrapper key={id} to={cardLink?.externalUrl ?? getDestination(cardLink?.internalEntry)}>
      <TitleWrapper inColumn={inColumn}>
        <CardTitle>{title}</CardTitle>
        <StyledChevron />
      </TitleWrapper>
      <StyledImage
        alt={image?.altText ? image.altText : image.title}
        className="bottomCardImage"
        image={image.asset?.gatsbyImageData}
        objectFit="cover"
      />
      <Gradient />
    </CardWrapper>
  );
};

export default withErrorBoundary(BottomCard, { componentName: 'FeaturesSectionModule/BottomCard' });
