import { FooterEquilannoo, NewsLetterHeader } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  NewsletterHeader: {
    SVG: NewsLetterHeader.AveveAgrarisch,
    className: 'newsletter-svg',
  },
  breadcrumb: {
    display: true,
  },
  button: {
    ctaBanner: {
      image: 'primary-with-background',
      text: 'primary',
    },
    dealerOverview: {
      details: 'primary',
      navigation: 'secondary',
    },
    infoSection: 'tertiary',
    shortBanner: 'primary-with-background',
  },
  carousel: {
    controlHeight: '50px',
  },
  footer: {
    svg: {
      Desktop: FooterEquilannoo.Desktop,
      Mobile: FooterEquilannoo.Mobile,
      Tablet: FooterEquilannoo.Tablet,
    },
    version: 'V2',
  },
  googleSiteVerification: 'm3tjjstoRlgtAHm0YEJEq8EFP0L-Lzd9pdFAVFOZEMo',
  header: {
    height: '110px',
    mobileLogo: true,
  },
  heroBanner: {
    customMask: true,
    customOverlay: true,
    mask: false,
  },
  mapSearchRadius: 25000,
});

export default config;
