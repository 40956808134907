import * as React from 'react';

const Calendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#3D3D3D"
      d="M9.5 11.167H7.833v1.666H9.5v-1.666zm3.333 0h-1.666v1.666h1.666v-1.666zm3.334 0H14.5v1.666h1.667v-1.666zm1.666-5.834H17V3.667h-1.667v1.666H8.667V3.667H7v1.666h-.833A1.66 1.66 0 004.508 7L4.5 18.667c0 .916.742 1.666 1.667 1.666h11.666c.917 0 1.667-.75 1.667-1.666V7c0-.917-.75-1.667-1.667-1.667zm0 13.334H6.167V9.5h11.666v9.167z"
    />
  </svg>
);

export default Calendar;
