import { useConfiguration } from '@arvesta-websites/configuration';
import { useIntl } from 'react-intl';

import type { FormSubmitResponseType } from '../../../../types';
import { ContactsFormDefault, withErrorBoundary } from '../../../components';
import {
  StyledContactsFormModule,
  StyledNoResultsAside,
  StyledNoResultsContainer,
  StyledNoResultsMain,
} from '../Styled';

type NoResultsProps = {
  /** Title for the contacts form */
  title: string;
  /** Short description for the contacts form */
  shortDescription?: string;
  /** submit handler for contacts form */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => FormSubmitResponseType;
  /** the search query */
  query: string;
  /** shows email subscribe option on contact form */
  showContactFormSubscribeOption: boolean;
};

const NoResults = ({
  title,
  shortDescription,
  handleFormSubmit,
  query,
  showContactFormSubscribeOption,
}: NoResultsProps) => {
  const intl = useIntl();
  const { searchContactFormName } = useConfiguration();

  return (
    <StyledNoResultsContainer>
      <StyledNoResultsMain>
        <p>{intl.formatMessage({ id: 'sections.search.noresults.title' })}</p>
        <h2>{`"${query}"`}</h2>
      </StyledNoResultsMain>

      <StyledNoResultsAside>
        <StyledContactsFormModule>
          <ContactsFormDefault
            title={title}
            shortDescription={shortDescription}
            handleFormSubmit={handleFormSubmit}
            showSubscribeOptions={showContactFormSubscribeOption}
            NETLIFY_FORM_NAME={searchContactFormName}
            basicPageTitle="Search > No Results"
          />
        </StyledContactsFormModule>
      </StyledNoResultsAside>
    </StyledNoResultsContainer>
  );
};

export default withErrorBoundary(NoResults, { componentName: 'SearchResultsModule/NoResults' });
