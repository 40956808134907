import styled from 'styled-components';

import Media from '../../../../utils/Media';

export const StyledContentWrapper = styled.div`
  padding: 27px 32px;
`;

export const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.dark};
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5);
  height: 240px;
  width: 100%;
`;

export const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 12px;
  color: ${props => props.theme.colors.dark};
`;

export const StyledWrapper = styled.button`
  background: none;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 22px);
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  outline: none;

  ${Media({
    width: ['100%', null, 'calc(50% - 22px)', 'calc(33.33% - 22px)'],
  })}

  &:focus-visible {
    outline: 1px solid blue;
  }

  &:hover {
    background-color: rgba(61, 61, 61, 0.1);
  }
`;
