import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const StyledHorizontalCardContainer = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`;

export const StyledTitle = styled(Heading)`
  color: ${props => props.theme.upComingEvents.color};
  margin-bottom: 16px;
  font-weight: ${props => props.theme.upComingEvents.titleFontWeight || 'normal'};
  margin-top: 2rem;
  padding-right: 1rem;
  word-break: break-word;
  margin-top: 0px;
`;

export const StyledModuleDescription = styled(Text)`
  font-size: 20px;
  line-height: 1.5;
  color: ${props => props.theme.upComingEvents.color};
  padding-bottom: 200px;
  padding-right: 1rem;
`;

export const StyledEventCardWrapper = styled(Box)`
  margin-bottom: 16px;

  > .horizontal,
  > .horizontal div {
    max-width: none;
  }

  ${Media({
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledBlueBox = styled.div`
  min-height: 402px;
  background-color: ${props => props.theme.upComingEvents.backgroundColor};
  z-index: -4;
  padding-top: 56px;
  padding-bottom: 2rem;

  ${Media({
    marginBottom: ['-220px', null, null, '-250px'],
    marginLeft: ['-1rem', null, '-2rem', '-117px'],
    paddingLeft: ['1rem', null, '2rem', '58px'],
    transform: [null, null, null, 'translate(59px, -2rem)'],
    width: ['calc(100% + 2rem)', null, 'calc(100% + 4rem)', 'calc(1150 / 1440 * 100%)'],
  })}
`;

export const StyledHorizontalEventsCardWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`;

export const StyledHorizontalEventCardWrapper = styled(Flex)`
  ${Media({
    marginBottom: ['1rem', null, null, '0'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }

  > .horizontal,
  > .horizontal div {
    max-width: none;
  }

  &:first-child {
    > .horizontal {
      margin-top: 0px;
    }
  }

  > .horizontal {
    ${Media({
      marginTop: ['0px', null, null, '1rem'],
    })}
  }
`;

export const StyledModuleContainer = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginLeft: ['1rem', null, '32px', '58px'],
    marginRight: ['1rem', null, '32px', '58px'],
    marginTop: ['73px', null, null, '114px'],
  })}
`;
