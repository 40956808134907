import React from 'react';

const Logo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 555.93 345.45"
    xmlSpace="preserve"
    width="120px"
    height="70px"
  >
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="eventodd"
          fill="#D38B25"
          d="M344.68,27.61c-1.39,9.6-4.78,20.45-6.4,30.94c5.45-5.63,9.19-12.52,19.17-14.77
          c-2.51,10.88-8.26,19.33-11.1,29.95c5.65,7.29,12.74,14.25,14.65,24.39c1.16,6.17,1.09,12.44,2.62,18.07
          c2.7,9.98,9.52,18.76,14.93,25.99c2.67,3.57,5.65,7.45,8.82,11.32c4.43,5.39,9.64,9.81,8.88,19.26
          c-1.25,15.47-34.44,24.88-44.73,11.29c-2.96-3.9-5.93-10.14-8.82-14.27c-5.4-7.72-13.67-14.33-27.13-14.23
          c-5.72,0.04-12.49-0.42-18.71-0.45c-6.9-0.03-12.76-2.86-16-6.56c-7.78-8.88-5.03-26.02-2.52-35.69
          c2.54,6.06,7.2,18.62,12.3,23.68c5.61,5.57,13.72,8.28,21.49,10.2c15.5,3.83,22.24,0.89,36.67,19.07
          c9.24,11.64,28.64,15.37,36.34,1.25c4.12-7.56-7.96-20.4-13.64-30.14c-6.51-11.17-13.47-18.39-18.93-33.18
          c-4.5-12.18-14.27-22.15-25.54-28c2.35-8.57,4.02-17.64,5.26-27.04c-9.08,3.45-14.18,12.59-17.74,21.21
          c-15.26-5.79-31.89-9.46-51.76-4.03c-19.13,5.23-35.73,17.98-44.86,30.73c0,0-17.25,23.25-27.52,66.43
          c-5.05,21.23-29.65,27.14-29.65,27.14c6.27-26.11,19.17-71.82,35.28-94.84c15.14-21.62,30.17-33.57,59.23-42.11
          c17.93-5.27,37.03-3.14,51.9,0.75C316.64,42.92,327.39,32.82,344.68,27.61"
        />
        <path
          fillRule="evenodd"
          clipRule="eventodd"
          fill="#D38B25"
          d="M324.6,98.82c5.82,2.63,8.67,4.89,16.68,8.69c1.49,0.71,0.81,2.06-0.13,2.79c-2.81,1.46-6.37,0.93-10.12,1.11
          c-2.63-0.59-1.34-3.61-3.08-6.63C326.31,101.95,322.17,102.13,324.6,98.82"
        />
      </g>
    </g>
    <g>
      <path
        d="M75.63,267.58H49.92c-8.16,0-13.77-1.38-16.83-4.14c-3.06-2.76-4.28-7.63-3.67-14.61l4.57-52.26h47.85l-1.08,12.37H47.09
        l-1.46,16.66H69.2l-1.1,12.37H44.55l-0.86,9.87c-0.24,2.79,0.23,4.72,1.43,5.78c1.2,1.06,3.5,1.6,6.9,1.6h24.69L75.63,267.58z"
      />
      <path
        d="M145.04,230.08l-0.36,4.09c-0.99,11.3-4.56,19.12-10.72,23.44c3.76-0.2,11.28-0.76,14.55-1.69l-1.07,12.27h-34.61
        c-4.22,0-8.2-0.68-11.96-2.04c-3.76-1.36-6.93-3.42-9.51-6.18c-2.58-2.76-4.11-6.07-4.59-9.92c-0.48-3.86-0.42-9.21,0.18-16.06
        l0.33-3.79c0.6-6.85,1.48-12.2,2.63-16.06c1.15-3.86,3.26-7.16,6.33-9.92c3.06-2.76,6.59-4.82,10.59-6.18
        c4-1.36,8.1-2.04,12.32-2.04c4.22,0,8.19,0.68,11.91,2.04c3.72,1.36,6.91,3.46,9.55,6.28c2.64,2.83,4.2,6.07,4.66,9.72
        C145.74,217.68,145.66,223.04,145.04,230.08z M130.56,233.57l0.42-4.79c0.73-8.31,0.05-13.76-2.04-16.36
        c-2.07-2.72-5.7-4.09-10.86-4.09s-9.1,1.4-11.79,4.19c-1.95,2.06-3.28,5.65-4,10.77c-0.28,1.66-0.55,3.96-0.81,6.88l-0.32,3.69
        c-0.55,7.05-0.43,11.87,0.37,14.46c1.47,4.99,5.6,7.48,12.4,7.48c5.92,0,10.01-1.66,12.27-4.99c1.5-2.39,2.49-4.72,2.96-6.98
        C129.74,240.99,130.21,237.56,130.56,233.57z"
      />
      <path
        d="M214.68,196.57l-4.08,46.68c-0.68,7.71-3.88,13.8-9.61,18.25c-5.73,4.46-12.51,6.68-20.33,6.68
        c-7.82,0-14.21-2.23-19.16-6.68c-4.95-4.45-7.09-10.54-6.41-18.25l4.08-46.68h14.18l-4.04,46.18c-0.38,4.39,0.57,7.66,2.86,9.82
        c2.29,2.16,5.48,3.24,9.56,3.24s7.47-1.08,10.18-3.24c2.7-2.16,4.25-5.44,4.63-9.82l4.04-46.18H214.68z"
      />
      <path d="M237.44,267.58h-14.18l6.21-71.01h14.18L237.44,267.58z" />
      <path
        d="M307.36,208.94h-25.61c-2.99,0-5.13,0.57-6.42,1.7c-1.29,1.13-2.06,3.09-2.3,5.88l-0.86,9.87h26.63l-1.1,12.37h-26.61
        l-2.52,28.82h-14.18l4.95-56.55c0.46-5.25,1.96-8.98,4.5-11.17c2.54-2.19,6.66-3.29,12.38-3.29h32.24L307.36,208.94z"
      />
      <path d="M328.75,267.58h-14.18l6.21-71.01h14.18L328.75,267.58z" />
      <path
        d="M400.68,267.58h-16.43l-11.88-26.33h-10.2l-2.3,26.33h-14.18l6.13-71.21c0,0,10.59,0.2,16.51,0.2h12.04
        c7.21,0,12.75,2.14,16.63,6.43c3.88,4.29,5.53,9.72,4.95,16.31c-0.42,4.79-2.06,8.93-4.91,12.42c-2.86,3.49-6.21,5.9-10.07,7.23
        L400.68,267.58z M384.21,226.64c2.12-2.03,3.31-4.55,3.57-7.58c0.26-3.02-0.46-5.47-2.16-7.33c-1.71-1.86-4.13-2.79-7.25-2.79
        h-13.38c0,0-0.37,4.4-0.53,6.28l-1.26,14.46h13.37C379.54,229.69,382.1,228.67,384.21,226.64z"
      />
      <path
        d="M463.21,246.64c-0.59,6.72-3.57,11.99-8.93,15.81c-5.37,3.82-11.96,5.73-19.78,5.73c-6.19,0-12.03-1.66-17.52-4.99
        c-2.71-1.73-4.84-4.17-6.4-7.33c-1.56-3.16-2.16-7.76-1.81-11.82l12.91,0.4c-0.31,3.59,1,6.38,3.96,8.38
        c2.95,1.99,6.33,2.99,10.14,2.99c8.5,0,13.01-2.92,13.52-8.78c0.42-4.79-2.23-7.61-7.93-8.48l-11.73-1.2
        c-5.84-0.86-10.35-2.93-13.54-6.18c-3.18-3.26-4.51-7.94-3.97-14.06c0.54-6.12,3.46-11.17,8.77-15.16
        c5.31-3.99,11.76-5.98,19.35-5.98c7.58,0,13.52,1.88,17.82,5.63c4.3,3.76,6.19,9.59,5.67,15.51l-12.7-0.4
        c-0.65-5.78-4.38-8.68-11.18-8.68c-4.15,0-7.47,0.87-9.97,2.59c-2.5,1.73-3.85,3.77-4.06,6.13c-0.21,2.36,0.48,4.22,2.06,5.58
        c1.58,1.36,3.61,2.21,6.1,2.54l11.53,1.2C458.36,227.82,464.26,234.67,463.21,246.64z"
      />
      <path d="M525.07,208.94h-19.49l-5.13,58.64h-14.18l5.13-58.64h-19.38l1.08-12.37h53.05L525.07,208.94z" />
    </g>
    <g>
      <path
        fill="#D38B25"
        d="M156.89,289.87h-2.13l0.35-3.34h2.13l0.3-2.86c0.22-2.06,0.93-3.58,2.14-4.55c1.17-0.9,2.57-1.36,4.21-1.36
        h2.73l-0.43,4.09h-2.41c-1.27-0.04-1.94,0.56-2.01,1.8l-0.3,2.88h4.23l-0.35,3.34h-4.23l-1.93,18.33h-4.21L156.89,289.87z"
      />
      <path
        fill="#D38B25"
        d="M170.31,299.01c-0.18,1.74,0.16,3.07,1.04,3.97c0.81,0.92,1.95,1.38,3.42,1.38c1.71,0,3.32-0.7,4.82-2.11
        l2.77,2.7c-2.39,2.36-5.04,3.53-7.93,3.51c-1.11,0-2.17-0.17-3.18-0.5c-0.51-0.18-0.99-0.42-1.46-0.73
        c-0.5-0.27-0.96-0.59-1.37-0.98c-0.8-0.81-1.41-1.93-1.81-3.38c-0.46-1.39-0.57-3.2-0.34-5.43c0.23-2.14,0.68-3.93,1.35-5.35
        c0.68-1.45,1.47-2.59,2.38-3.42c0.92-0.85,1.93-1.46,3-1.84c1.06-0.38,2.09-0.56,3.09-0.56c2.4,0.03,4.34,0.84,5.83,2.44
        c1.51,1.63,2.13,3.87,1.86,6.72l-0.38,3.59H170.31z M179.57,295.42c0.12-1.69-0.18-2.97-0.91-3.84c-0.72-0.83-1.72-1.24-2.99-1.24
        s-2.35,0.41-3.25,1.24c-0.89,0.87-1.46,2.15-1.72,3.84H179.57z"
      />
      <path
        fill="#D38B25"
        d="M191.69,299.01c-0.18,1.74,0.16,3.07,1.04,3.97c0.81,0.92,1.95,1.38,3.42,1.38c1.71,0,3.32-0.7,4.82-2.11
        l2.77,2.7c-2.39,2.36-5.04,3.53-7.93,3.51c-1.11,0-2.17-0.17-3.18-0.5c-0.51-0.18-0.99-0.42-1.46-0.73
        c-0.5-0.27-0.96-0.59-1.37-0.98c-0.8-0.81-1.41-1.93-1.81-3.38c-0.46-1.39-0.57-3.2-0.34-5.43c0.23-2.14,0.68-3.93,1.35-5.35
        c0.68-1.45,1.47-2.59,2.38-3.42c0.92-0.85,1.93-1.46,3-1.84c1.06-0.38,2.09-0.56,3.09-0.56c2.4,0.03,4.34,0.84,5.83,2.44
        c1.51,1.63,2.13,3.87,1.86,6.72l-0.38,3.59H191.69z M200.95,295.42c0.12-1.69-0.18-2.97-0.91-3.84c-0.72-0.83-1.72-1.24-2.99-1.24
        s-2.35,0.41-3.25,1.24c-0.89,0.87-1.46,2.15-1.72,3.84H200.95z"
      />
      <path
        fill="#D38B25"
        d="M221.73,305.94c-1.56,1.62-3.46,2.46-5.7,2.51c-1.26-0.03-2.32-0.3-3.17-0.81c-0.89-0.5-1.61-1.17-2.15-2.01
        c-0.51-0.68-0.79-1.61-0.86-2.78c-0.11-1.12-0.03-2.95,0.24-5.52c0.27-2.61,0.58-4.46,0.93-5.56c0.31-1.12,0.78-2.01,1.43-2.7
        c1.38-1.81,3.35-2.75,5.92-2.82c1.08,0,2.05,0.23,2.92,0.68c0.8,0.49,1.55,1.11,2.25,1.87l1.16-11.05h4.21l-3.2,30.44h-4.21
        L221.73,305.94z M219.19,290.6c-1,0-1.78,0.17-2.34,0.52c-0.62,0.38-1.1,0.89-1.44,1.55c-0.32,0.66-0.57,1.37-0.73,2.14
        c-0.17,0.82-0.3,1.67-0.39,2.54c-0.09,0.82-0.13,1.63-0.13,2.41c-0.01,0.82,0.08,1.56,0.27,2.22c0.2,0.63,0.57,1.15,1.11,1.55
        c0.48,0.39,1.23,0.59,2.22,0.59c1,0,1.81-0.21,2.43-0.63c0.62-0.41,1.08-0.94,1.36-1.59c0.34-0.66,0.6-1.39,0.78-2.2
        c0.11-0.8,0.21-1.58,0.29-2.35c0.09-0.84,0.16-1.64,0.21-2.41c-0.01-0.77-0.12-1.48-0.32-2.14c-0.15-0.66-0.49-1.19-1.03-1.59
        S220.19,290.6,219.19,290.6z"
      />
      <path
        fill="#D38B25"
        d="M233.54,286.53h4.21l-2.28,21.67h-4.21L233.54,286.53z M234.46,277.76h4.21l-0.46,4.34H234L234.46,277.76z"
      />
      <path
        fill="#D38B25"
        d="M243.67,286.53h4.21l-0.24,2.3h0.08c1.57-1.71,3.48-2.57,5.73-2.57c1.74,0,3.26,0.63,4.57,1.9
        c1.29,1.28,1.84,3.16,1.65,5.64l-1.51,14.41h-4.21l1.36-12.92c0.16-1.52-0.11-2.68-0.81-3.47c-0.68-0.81-1.63-1.21-2.85-1.21
        s-2.25,0.4-3.12,1.21c-0.88,0.79-1.4,1.95-1.56,3.47l-1.36,12.92h-4.21L243.67,286.53z"
      />
      <path
        fill="#D38B25"
        d="M276.39,305.94c-1.56,1.62-3.45,2.46-5.68,2.51c-1.36-0.03-2.46-0.33-3.3-0.9c-0.89-0.54-1.55-1.13-1.98-1.76
        c-0.48-0.71-0.78-1.58-0.92-2.61c-0.15-1.02-0.07-2.95,0.23-5.81c0.31-2.91,0.64-4.88,1.01-5.91c0.35-1.02,0.84-1.87,1.46-2.57
        c0.57-0.68,1.36-1.29,2.36-1.83c0.96-0.51,2.12-0.78,3.48-0.81c2.09,0,3.8,0.86,5.15,2.57l0.24-2.3h4.21l-2.22,21.15
        c-0.4,3.26-1.56,5.65-3.48,7.18c-1.94,1.57-4.15,2.36-6.63,2.36c-2.83-0.05-5.13-1.26-6.89-3.63l3.56-2.69
        c0.48,0.52,1.06,0.96,1.72,1.32c0.67,0.41,1.39,0.62,2.15,0.65c1.44,0,2.64-0.41,3.61-1.24c0.95-0.84,1.52-2,1.7-3.48
        L276.39,305.94z M268.96,297.38c-0.17,1.66-0.24,2.81-0.2,3.44c0.03,0.66,0.19,1.2,0.5,1.63c0.24,0.39,0.64,0.75,1.18,1.07
        c0.51,0.39,1.18,0.59,2,0.59s1.53-0.19,2.12-0.59c0.59-0.32,1.04-0.68,1.37-1.07c0.4-0.43,0.69-0.98,0.88-1.63
        c0.15-0.63,0.31-1.77,0.48-3.44c0.18-1.69,0.26-2.86,0.25-3.52c-0.05-0.66-0.23-1.19-0.54-1.59c-0.24-0.39-0.62-0.77-1.13-1.15
        c-0.52-0.32-1.19-0.5-2.01-0.52c-0.83,0.03-1.53,0.2-2.12,0.52c-0.62,0.38-1.09,0.76-1.42,1.15c-0.39,0.41-0.67,0.94-0.84,1.59
        C269.31,294.52,269.13,295.69,268.96,297.38z"
      />
      <path
        fill="#D38B25"
        d="M296.06,286.53h4.47l3.38,15.18h0.08l6.55-15.18h4.49l-11.78,26.16c-0.75,1.63-1.67,2.76-2.76,3.38
        c-1.1,0.6-2.3,0.9-3.61,0.9h-1.39l0.43-4.09h1.23c0.55,0,1.08-0.15,1.58-0.46c0.5-0.32,0.88-0.79,1.14-1.42l1.53-3.64
        L296.06,286.53z"
      />
      <path
        fill="#D38B25"
        d="M315.63,297.34c0.24-2.29,0.61-4.04,1.1-5.27c0.5-1.2,1.19-2.21,2.06-3.03c0.66-0.72,1.53-1.36,2.63-1.93
        c1.08-0.54,2.43-0.82,4.03-0.85c1.66,0.03,2.97,0.31,3.93,0.86c0.95,0.57,1.67,1.22,2.14,1.94c0.75,0.82,1.23,1.83,1.46,3.03
        c0.21,1.22,0.19,2.98-0.05,5.26c-0.24,2.28-0.59,4.02-1.05,5.22c-0.48,1.2-1.18,2.24-2.11,3.11c-0.3,0.32-0.67,0.63-1.09,0.94
        c-0.39,0.35-0.87,0.65-1.45,0.9c-1.09,0.6-2.46,0.91-4.12,0.94c-1.6-0.03-2.88-0.34-3.84-0.94c-0.98-0.54-1.73-1.15-2.23-1.84
        c-0.69-0.88-1.16-1.91-1.41-3.11C315.39,301.37,315.39,299.63,315.63,297.34z M328.72,297.38c0.16-1.55,0.21-2.68,0.15-3.39
        c-0.06-0.68-0.28-1.28-0.64-1.8c-0.26-0.49-0.68-0.87-1.26-1.15c-0.55-0.29-1.21-0.44-1.98-0.44c-0.72,0-1.39,0.15-2.03,0.44
        c-0.61,0.28-1.12,0.66-1.54,1.15c-0.42,0.52-0.75,1.12-0.98,1.8c-0.24,0.71-0.44,1.83-0.6,3.35c-0.16,1.51-0.19,2.61-0.1,3.31
        c0.09,0.71,0.29,1.34,0.59,1.89c0.32,0.42,0.75,0.79,1.3,1.11c0.58,0.31,1.22,0.46,1.94,0.46c0.77,0,1.46-0.15,2.08-0.46
        c0.64-0.32,1.14-0.69,1.49-1.11c0.48-0.54,0.82-1.17,1.03-1.89C328.38,299.96,328.57,298.86,328.72,297.38z"
      />
      <path
        fill="#D38B25"
        d="M339.2,286.53h4.21l-1.36,12.92c-0.15,1.46,0.12,2.61,0.83,3.43c0.7,0.82,1.65,1.23,2.86,1.23
        s2.25-0.41,3.1-1.23c0.87-0.82,1.38-1.97,1.53-3.43l1.36-12.92h4.21l-2.28,21.67h-4.21l0.24-2.32h-0.08
        c-1.57,1.71-3.47,2.57-5.71,2.57c-1.74-0.03-3.24-0.68-4.5-1.96c-1.39-1.25-1.97-3.1-1.74-5.54L339.2,286.53z"
      />
      <path
        fill="#D38B25"
        d="M362.17,286.53h4.21l-0.24,2.3h0.09c1.57-1.71,3.48-2.57,5.73-2.57c1.68,0.03,3.09,0.58,4.24,1.66l-3.47,3.77
        c-0.82-0.73-1.7-1.09-2.66-1.09c-1.08,0-2.04,0.36-2.89,1.09c-0.94,0.71-1.5,1.86-1.7,3.45l-1.37,13.07h-4.21L362.17,286.53z"
      />
      <path
        fill="#D38B25"
        d="M399.57,305.94c-1.56,1.62-3.46,2.46-5.7,2.51c-1.26-0.03-2.32-0.3-3.17-0.81c-0.89-0.5-1.61-1.17-2.15-2.01
        c-0.51-0.68-0.79-1.61-0.86-2.78c-0.11-1.12-0.03-2.95,0.24-5.52c0.27-2.61,0.58-4.46,0.93-5.56c0.31-1.12,0.78-2.01,1.44-2.7
        c1.38-1.81,3.35-2.75,5.92-2.82c1.08,0,2.05,0.23,2.92,0.68c0.8,0.49,1.55,1.11,2.25,1.87l1.16-11.05h4.21l-3.2,30.44h-4.21
        L399.57,305.94z M397.04,290.6c-1,0-1.78,0.17-2.34,0.52c-0.62,0.38-1.1,0.89-1.44,1.55c-0.32,0.66-0.57,1.37-0.73,2.14
        c-0.17,0.82-0.3,1.67-0.39,2.54c-0.09,0.82-0.13,1.63-0.13,2.41c-0.01,0.82,0.08,1.56,0.27,2.22c0.2,0.63,0.58,1.15,1.11,1.55
        c0.48,0.39,1.23,0.59,2.22,0.59c1,0,1.81-0.21,2.43-0.63c0.62-0.41,1.08-0.94,1.36-1.59c0.34-0.66,0.6-1.39,0.78-2.2
        c0.11-0.8,0.21-1.58,0.29-2.35c0.09-0.84,0.16-1.64,0.21-2.41c-0.01-0.77-0.12-1.48-0.32-2.14c-0.15-0.66-0.49-1.19-1.03-1.59
        S398.03,290.6,397.04,290.6z"
      />
      <path
        fill="#D38B25"
        d="M411.26,286.53h4.21l-0.24,2.3h0.09c1.57-1.71,3.48-2.57,5.73-2.57c1.68,0.03,3.09,0.58,4.24,1.66l-3.47,3.77
        c-0.82-0.73-1.7-1.09-2.66-1.09c-1.08,0-2.04,0.36-2.89,1.09c-0.94,0.71-1.5,1.86-1.7,3.45l-1.37,13.07h-4.21L411.26,286.53z"
      />
      <path
        fill="#D38B25"
        d="M428.98,299.01c-0.18,1.74,0.16,3.07,1.04,3.97c0.81,0.92,1.95,1.38,3.42,1.38c1.71,0,3.32-0.7,4.82-2.11
        l2.77,2.7c-2.39,2.36-5.04,3.53-7.93,3.51c-1.11,0-2.17-0.17-3.18-0.5c-0.51-0.18-0.99-0.42-1.46-0.73
        c-0.5-0.27-0.96-0.59-1.37-0.98c-0.8-0.81-1.41-1.93-1.81-3.38c-0.46-1.39-0.57-3.2-0.34-5.43c0.23-2.14,0.68-3.93,1.35-5.35
        c0.68-1.45,1.47-2.59,2.38-3.42c0.92-0.85,1.92-1.46,3-1.84c1.06-0.38,2.09-0.56,3.09-0.56c2.4,0.03,4.34,0.84,5.83,2.44
        c1.51,1.63,2.13,3.87,1.86,6.72l-0.38,3.59H428.98z M438.24,295.42c0.12-1.69-0.18-2.97-0.91-3.84c-0.72-0.83-1.72-1.24-2.99-1.24
        s-2.35,0.41-3.25,1.24c-0.89,0.87-1.46,2.15-1.72,3.84H438.24z"
      />
      <path
        fill="#D38B25"
        d="M457.46,306.28h-0.08c-0.47,0.74-1.13,1.28-1.99,1.63c-0.85,0.36-1.98,0.54-3.41,0.54
        c-2.34-0.03-4.08-0.67-5.22-1.92c-1.21-1.22-1.71-2.79-1.51-4.7c0.19-1.82,0.93-3.33,2.21-4.53c1.29-1.22,3.05-1.85,5.29-1.88h5.84
        l0.24-2.29c0.23-1.92-1.11-2.85-4.01-2.79c-1.05,0-1.89,0.11-2.52,0.34c-0.66,0.27-1.21,0.71-1.64,1.34l-3.04-2.61
        c1.83-2.16,4.3-3.21,7.42-3.16c2.64-0.03,4.7,0.49,6.16,1.54c1.46,1.09,2.07,2.95,1.82,5.6l-1.56,14.8h-4.21L457.46,306.28z
        M458.22,299.01h-5.06c-2.46,0.06-3.77,0.93-3.92,2.61c-0.08,0.74,0.17,1.37,0.75,1.88c0.55,0.57,1.52,0.86,2.93,0.86
        c1.77,0.03,3.04-0.19,3.82-0.65c0.71-0.46,1.15-1.5,1.32-3.13L458.22,299.01z"
      />
      <path
        fill="#D38B25"
        d="M469.72,286.53h4.22l-0.24,2.3h0.08c1.61-1.71,3.59-2.57,5.93-2.57c1.5,0.03,2.68,0.29,3.55,0.77
        c0.85,0.54,1.6,1.23,2.27,2.07c1.9-1.92,4.28-2.87,7.13-2.84c1.8,0,3.37,0.63,4.73,1.9c1.34,1.28,1.91,3.16,1.71,5.64l-1.51,14.41
        h-4.22l1.36-12.92c0.16-1.52-0.12-2.68-0.85-3.47c-0.72-0.81-1.72-1.21-3-1.21c-1.27,0-2.37,0.4-3.27,1.21
        c-0.92,0.79-1.46,1.95-1.62,3.47l-1.36,12.92h-4.21l1.36-12.92c0.16-1.52-0.12-2.68-0.85-3.47c-0.74-0.81-1.74-1.21-3.02-1.21
        s-2.36,0.4-3.25,1.21c-0.93,0.79-1.48,1.95-1.64,3.47l-1.36,12.92h-4.22L469.72,286.53z"
      />
    </g>
  </svg>
);

export default Logo;
