/* eslint-disable no-useless-escape */
import * as Utils from './common';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export { default as Config } from './config';
export * from './hooks';
export * from './link';
export { default as handleNetlifyFormSubmission } from './netlify';
export { default as handleNewsletterFormSubmission } from './newsletter';
export { default as search } from './search';
export { default as useScrollDirection } from './useScrollDirection';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default Utils;
