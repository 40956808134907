import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';

import { StyledFooterMainNavigationList, StyledFooterMainNavigationListItem } from './Styled';

type Link = {
  to: string;
  label: string;
};

export type MainNavigationLightProps = {
  navigation: Link[];
};

const MainNavigationLight = ({ navigation }: MainNavigationLightProps) => {
  return (
    <StyledFooterMainNavigationList>
      {navigation.map((item, idx) => (
        <StyledFooterMainNavigationListItem key={`footernav-${item.to}-${idx}`}>
          <Cta to={item.to}>{item.label}</Cta>
        </StyledFooterMainNavigationListItem>
      ))}
    </StyledFooterMainNavigationList>
  );
};

export default withErrorBoundary(MainNavigationLight, { componentName: 'MainNavigationLight' });
