import { ChevronRight } from '@arvesta-websites/icons';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

const HideNonRelevantMobileItems = `
  &:not(:nth-last-of-type(-n + 2)) {
    display: none;
  }
`;

const StyledHomeCrumb = styled(Box)`
  width: 13px;
  height: 12px;
  fill: ${({ theme }) => theme.breadcrumb.highlight};
`;

const StyledBreadcrumb = styled(Box)`
  display: flex;
  gap: 13px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1340px;

  > svg {
    width: 9px;
    height: 9px;
  }

  @media (max-width: 600px) {
    > svg {
      ${HideNonRelevantMobileItems}
    }
  }

  ${Media({
    margin: [null, null, null, null, '0 auto'],
  })}
`;

interface IExpandedStyled {
  firstNotLast?: boolean;
}
const StyledCrumb = styled.a<IExpandedStyled>`
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${({ firstNotLast, theme }) => (firstNotLast ? theme.breadcrumb.highlight : theme.colors.bodyCopy)};
  text-decoration: underline;

  ${Media({
    fontSize: ['10px', null, '12px', null],
    lineHeight: ['12px', null, '14px', null],
  })}

  &:hover {
    text-decoration: none;
  }

  &:last-child {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    ${HideNonRelevantMobileItems}
  }
`;

const StyledBreadcrumbWrapper = styled(Box)`
  background-color: ${props => props.theme.breadcrumb.backgroundColor};
  ${props =>
    Media({
      marginTop: props?.marginTop ?? [],
    })}
  ${Media({
    padding: ['11px 20px', '10px 20px', null, '10px 5px'],
  })}
`;

const StyledChevronRight = styled(ChevronRight)`
  stroke: ${({ theme }) => theme.breadcrumb.highlight};
`;

export { StyledBreadcrumb, StyledBreadcrumbWrapper, StyledChevronRight, StyledCrumb, StyledHomeCrumb };
