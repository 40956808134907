import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import { Button, HeadingTag, type ProductCardProps, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

import Horizontal from './Horizontal/Horizontal';
import {
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';
import Vertical from './Vertical/Vertical';

interface ProductListingModuleProps extends BoxProps {
  title: string;
  titleTag: HeadingTag;
  shortDescription?: string | null;
  products: ProductCardProps[];
  variant?: 'vertical' | 'horizontalLeft' | 'horizontalRight';
  initialAmount?: number;
  incrementAmount?: number;
  categorySearchTerm?: string;
}

const NoProductsFound = ({ searchTerm, titleTag }: { searchTerm?: string }) => {
  const intl = useIntl();

  return (
    <StyledHeadingAndDescriptionContainer>
      <StyledModuleHeading variant="h1" tag={titleTag}>
        {intl.formatMessage({ id: 'sections.product.noproductsfound' })} {searchTerm}
      </StyledModuleHeading>
    </StyledHeadingAndDescriptionContainer>
  );
};

const ProductList = ({
  products,
  variant,
}: {
  products: ProductCardProps[];
  variant: 'horizontalLeft' | 'horizontalRight' | 'vertical';
}) => {
  if (variant === 'vertical') {
    return <Vertical products={products} />;
  }

  return <Horizontal products={products} variant={variant} />;
};

const ProductListingModule = ({
  title,
  titleTag,
  shortDescription,
  products = [],
  variant = 'vertical',
  initialAmount = 6,
  incrementAmount = 8,
  categorySearchTerm,
  ...rest
}: ProductListingModuleProps) => {
  const [amountOfProductsToDisplay, setDisplayLimit] = React.useState(initialAmount);
  const intl = useIntl();

  if (products.length === 0) {
    return <NoProductsFound searchTerm={categorySearchTerm} titleTag={titleTag} />;
  }

  return (
    <StyledModuleContainer {...rest} data-appsearch-ignore="true">
      <StyledHeadingAndDescriptionContainer>
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading variant="h1" tag={titleTag ?? 'h1'}>
            {title}
          </StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
      </StyledHeadingAndDescriptionContainer>

      <ProductList products={products.slice(0, amountOfProductsToDisplay)} variant={variant} />

      {products.length > amountOfProductsToDisplay ? (
        <StyledLoadMoreWrapper>
          <Button
            onClick={e => {
              e.preventDefault();
              setDisplayLimit(amountOfProductsToDisplay + incrementAmount);
            }}
          >
            {intl.formatMessage({ id: 'sections.product.loadmore' })}
          </Button>
        </StyledLoadMoreWrapper>
      ) : null}
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(ProductListingModule, { componentName: 'ProductListingModule' });
