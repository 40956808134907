import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledCenteredText = styled.div`
  ${Media({
    margin: ['48px 1rem auto 1rem', '48px auto auto 20px', '48px auto auto 20%', '56px auto auto auto'],
  })}

  blockquote {
    ${Media({
      width: ['auto', 'auto', 'auto', '1042px'],
    })}
  }
`;

const calucateWidth = (size?: 'Small' | 'Medium' | 'Large') => {
  switch (size) {
    case 'Small':
      return ['100%', '645px', '645px', '645px'];
    case 'Medium':
      return ['100%', null, '950px'];
    case 'Large':
      return '100%';
    default:
      return '100%';
  }
};

type StyledCenteredTextBrandXProps = {
  size?: 'Small' | 'Medium' | 'Large';
};

export const StyledCenteredTextBrandX = styled.div<StyledCenteredTextBrandXProps>`
  ${props =>
    Media({
      margin: ['0 auto', null, '56px auto auto auto'],
      maxWidth: calucateWidth(props.size),
    })}

  blockquote {
    ${Media({
      width: ['auto', 'auto', 'auto', '1042px'],
    })}
  }
`;

export const StyledDocumentDownload = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledImage = styled(Box)`
  ${Media({
    marginBottom: ['20px', null, '25px'],
  })}
`;

export const StyledInlineAsset = styled.div`
  padding: 1rem 2rem;
`;

export const StyledMedia = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledShortBanner = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledCtaBanner = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;
export const StyledTable = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
    paddingRight: ['1rem', null, null, '0'],
  })}
`;

export const StyledListRight = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: last baseline;
`;
export const StyledListCenter = styled.ul`
  && {
    width: fit-content;
    margin: 0 auto;
  }
`;

export const StyledInlineTable = styled.table`
  td {
    width: 50%;
  }
`;

type StyledTextProps = {
  alignment?: 'Left' | 'Center' | 'Right';
  size?: 'Small' | 'Medium' | 'Large';
  isBrandX?: boolean;
  textHighlight?: boolean;
  isFooter?: boolean;
  isImageAndText: boolean;
};

export const StyledText = styled.div<StyledTextProps>`
  color: ${props => (props.textHighlight ? props.theme.styles.highlight.color : props.theme.colors.text)};
  margin-top: 1rem;

  ${props =>
    props.alignment &&
    `
    text-align: ${props.alignment.toLowerCase()};
  `}

  p {
    ${({ theme }) => ({ ...theme.styles.p })};
    ${({ theme }) =>
      theme.styles.p.fontSize?.desktop &&
      Media({
        fontSize: [
          theme.styles.p.fontSize.mobile,
          theme.styles.p.fontSize.tablet,
          null,
          theme.styles.p.fontSize.desktop,
        ],
      })}
    ${({ theme }) =>
      theme.styles.p.lineHeight?.desktop &&
      Media({
        lineHeight: [
          theme.styles.p.lineHeight.mobile,
          theme.styles.p.lineHeight.tablet,
          null,
          theme.styles.p.lineHeight.desktop,
        ],
      })}
  }

  h1 {
    ${props => ({ ...props.theme.styles.h1 })};

    ${props =>
      Media({
        fontSize: [
          props.theme.styles.h1.fontSize.mobile,
          props.theme.styles.h1.fontSize.tablet,
          null,
          props.theme.styles.h1.fontSize.desktop,
        ],
        lineHeight: props.isImageAndText
          ? '1'
          : [
              props.theme.styles.h1.lineHeight.mobile,
              props.theme.styles.h1.lineHeight.tablet,
              props.theme.styles.h1.lineHeight.desktop,
            ],
      })}
  }

  h2 {
    ${props => ({ ...props.theme.styles.h2 })};

    ${props =>
      Media({
        fontSize: [
          props.theme.styles.h2.fontSize.mobile,
          props.theme.styles.h2.fontSize.tablet,
          null,
          props.theme.styles.h2.fontSize.desktop,
        ],
        lineHeight: props.isImageAndText
          ? '1'
          : [
              props.theme.styles.h2.lineHeight.mobile,
              props.theme.styles.h2.lineHeight.tablet,
              props.theme.styles.h2.lineHeight.desktop,
            ],
        marginBottom: ['1rem', null, '1rem'],
        marginTop: '0rem',
      })}
  }

  h3 {
    ${props => ({ ...props.theme.styles.h3 })};

    ${props =>
      Media({
        fontSize: [
          props.theme.styles.h3.fontSize.mobile,
          props.theme.styles.h3.fontSize.tablet,
          null,
          props.theme.styles.h3.fontSize.desktop,
        ],
        lineHeight: props.isImageAndText
          ? '1'
          : [
              props.theme.styles.h3.lineHeight.mobile,
              props.theme.styles.h3.lineHeight.tablet,
              props.theme.styles.h3.lineHeight.desktop,
            ],
        marginBottom: ['1rem', null, '1rem'],
        marginTop: '0rem',
      })}
  }

  h4 {
    ${props => ({ ...props.theme.styles.h4 })};

    ${props =>
      Media({
        fontSize: [
          props.theme.styles.h4.fontSize.mobile,
          props.theme.styles.h4.fontSize.tablet,
          null,
          props.theme.styles.h4.fontSize.desktop,
        ],
        lineHeight: props.isImageAndText
          ? '1'
          : [
              props.theme.styles.h4.lineHeight.mobile,
              props.theme.styles.h4.lineHeight.tablet,
              props.theme.styles.h4.lineHeight.desktop,
            ],
      })}
  }

  h5 {
    ${props => ({ ...props.theme.styles.h5 })};

    ${props =>
      Media({
        fontSize: [
          props.theme.styles.h5.fontSize.mobile,
          props.theme.styles.h5.fontSize.tablet,
          null,
          props.theme.styles.h5.fontSize.desktop,
        ],
        lineHeight: props.isImageAndText
          ? '1'
          : [
              props.theme.styles.h5.lineHeight.mobile,
              props.theme.styles.h5.lineHeight.tablet,
              props.theme.styles.h5.lineHeight.desktop,
            ],
      })}
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-right: 0;
    white-space: break-spaces;
  }

  ${props =>
    props.alignment === 'Right' &&
    `
    p, h1, h2, h3, h4, h5, h6 {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `}

  a {
    font-weight: ${({ theme }) => theme.styles.rteHighlight.fontWeight};
    text-decoration: ${({ theme }) => theme.styles.rteHighlight.textDecoration};
    color: ${props =>
      props.isFooter ? props.theme.styles.rteHighlight.isFooter : props.theme.styles.rteHighlight.color};

    &:hover {
      color: ${({ theme }) => theme.styles.rteHighlight.hoverFooter ?? theme.styles.rteHighlight.hoverColor};
    }
  }

  b {
    font-weight: ${({ theme }) => theme.styles.rteHighlight.fontWeight || 900};
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    ${Media({
      marginBottom: ['1rem', null, '1.7rem'],
    })}

    li {
      > p {
        margin: 0 0 0.2rem;
      }
    }
  }

  ol {
    counter-reset: new-counter;

    li {
      counter-increment: new-counter;
      padding-left: 2.25rem;
      position: relative;

      &::before {
        content: counter(new-counter) '.';
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 2.25rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: ${props => props.theme.styles.li.bulletColor};
        border-radius: ${props => props.theme.styles.li.bulletBorderRadius};
      }
    }
  }

  blockquote {
    margin: 0;

    ${Media({
      marginBottom: ['1rem', null, '1.7rem'],
      marginRight: ['-70px', null, null, '0px'],
      paddingLeft: ['2rem', null, null, '56px'],
    })}
  }

  hr {
    margin: 0.75rem 0;
  }
`;

export const StyledTextHighlight = styled(Box)`
  ${props => props.theme.styles.highlight};
  position: relative;
  margin-bottom: 2rem;
  padding-top: 1rem;

  p {
    font-size: 16px;
    line-height: 1.75;
  }

  ul li::before {
    background-color: ${props => props.theme.styles.li.bulletColor};
  }

  ${Media({
    paddingBottom: ['2.875rem', null, '2rem', '2rem'],
    paddingLeft: ['1rem', null, '24px', '32px'],
    paddingRight: ['1rem', null, '1.5rem', '2rem'],
  })}
`;

export const StyledVideo = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;
