import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../../../components';
import { StyledCarouselWrapper } from '../../Styled';
import { type CarouselWrapperProps } from '../CarouselWrapper';

const heroBannerCarousel = tv({
  slots: {
    imageWrapper: 'relative inline-block w-full',
    overlay: 'absolute w-full h-full top-0 left-0  mt-px z-10',
  },
  variants: {
    height: {
      Large: {
        imageWrapper: 'h-[420px]',
      },
      Regular: {
        imageWrapper: 'h-[327px]',
      },
    },
  },
});

type HeroBannerWrapperProps = Pick<
  CarouselWrapperProps,
  'isHero' | 'windowWidth' | 'shouldBeInfinite' | 'children' | 'height'
>;

const HeroBannerWrapper = ({
  isHero,
  windowWidth,
  shouldBeInfinite: isInfinite,
  children,
  height,
}: HeroBannerWrapperProps) => {
  if (isHero) {
    const { imageWrapper } = heroBannerCarousel({ height: height });
    return <div className={imageWrapper()}>{children}</div>;
  }

  return (
    <StyledCarouselWrapper isHero={isHero} windowWidth={windowWidth} isInfinite={isInfinite}>
      {children}
    </StyledCarouselWrapper>
  );
};

export default withErrorBoundary(HeroBannerWrapper, { componentName: 'HeroBannerWrapper' });
