import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Cta, Heading, ProductBasicTable } from '../../components';

export const StyledModuleHeading = styled(Heading)`
  font-weight: ${props => props.theme.productListing.titleFontWeight || 'normal'};
  word-break: break-word;

  ${Media({
    marginBottom: ['1rem', null, null, '1.5rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3.0625rem', null, null, '3.25rem'],
  })}
`;

export const StyledHeadingAndDescriptionContainer = styled(Flex)`
  justify-content: center;
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)`
  max-width: 760px;
`;

export const StyledContainer = styled(Box)`
  max-width: ${props => props.theme.sizes.xl};
  ${Media({
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
    marginTop: ['20px', null, null, '58px'],
  })}
`;

export const StyledContentContainer = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
`;

export const StyledProductBasicTableWrapper = styled.div`
  ${Media({
    marginBottom: ['3rem', null, null, '0'],
    width: ['100%', null, null, '50%'],
  })}
`;

export const StyledSidebar = styled(Box)`
  max-width: 420px;
  ${Media({
    marginLeft: ['0', null, null, '3.25rem'],
    width: ['100%', null, null, 'calc(50% - 3.25rem)'],
  })}
`;

export const StyledSidebarHeading = styled.h2`
  margin-top: 0;
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const StyledSidebarDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.75rem;
  font-size: 1rem;

  ${Media({
    marginBottom: ['1.5rem', null, null, '1.75rem'],
  })}
`;

export const StyledCta = styled(Cta)`
  display: inline-block;
`;

export const StyledDocumentDownloadWrapper = styled(Box)`
  margin-bottom: 2rem;
`;
