import { useConfiguration } from '@arvesta-websites/configuration';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { BoxProps } from 'rebass/styled-components';

import { Button, Cta, withErrorBoundary } from '../../components';
import { StyledModuleContainerInfoVariant } from '../../shared';

import {
  StyledCategory,
  StyledImage,
  StyledText,
  StyledTextBox,
  StyledTextBoxCta,
  StyledTextBoxTitle,
  StyledTextBoxWrapper,
} from './Styled';

type CtaProps = {
  label: string;
  to: string;
};

interface CategoryCardModuleProps extends BoxProps {
  /** title */
  title?: string;
  /** optional module short description */
  shortDescription?: string;
  /** section category */
  sectionCategory?: string;
  /** card cta */
  cta?: CtaProps | null;
  /** the background image */
  image: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const InfoSectionModule = ({
  title,
  shortDescription,
  cta,
  sectionCategory,
  image,
  ...rest
}: CategoryCardModuleProps) => {
  const config = useConfiguration();

  return (
    <StyledModuleContainerInfoVariant {...rest} data-appsearch-ignore="true">
      <StyledTextBoxWrapper>
        <StyledImage>
          {image?.asset && (
            <GatsbyImage
              image={getImage(image.asset) as IGatsbyImageData}
              alt={image.alt ? image.alt : image.title}
              loading="eager"
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </StyledImage>
        {title ? (
          <StyledTextBox>
            {sectionCategory && <StyledCategory>{sectionCategory}</StyledCategory>}

            <StyledTextBoxTitle>{title}</StyledTextBoxTitle>

            {shortDescription && <StyledText>{shortDescription}</StyledText>}

            {cta && (
              <StyledTextBoxCta>
                <Cta to={cta.to}>
                  <Button variant={config.button.infoSection ?? 'primary-with-background'}>{cta.label}</Button>
                </Cta>
              </StyledTextBoxCta>
            )}
          </StyledTextBox>
        ) : null}
      </StyledTextBoxWrapper>
    </StyledModuleContainerInfoVariant>
  );
};

export default withErrorBoundary(InfoSectionModule, { componentName: 'InfoSectionModule' });
