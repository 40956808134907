import styled from 'styled-components';

interface StyledWrapperProps {
  position?: string;
}

interface StyledVideoProps {
  size?: string;
}

export const StyledVideoWrapper = styled.div<StyledVideoProps>`
  width: ${props => (props.size === 'Small' ? '50%' : props.size === 'Medium' ? '75%' : '100%')};
  margin: 20px 0;
  @media (max-width: 900px) {
    width: ${props => (props.size === 'Large' ? '100%' : '75%')};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  justify-content: ${props => (props.position === 'Left' ? 'flex-start' : 'center')};
`;
