const HeroBannerCustomMaskDesktopInverted = () => (
  <svg width="100%" viewBox="0 0 1440 419" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1440 0H0V372.17H589.221C596.344 372.177 603.456 372.719 610.498 373.792C628.482 376.467 645.722 382.817 661.145 392.446L690.82 410.915C699.478 416.197 709.424 418.992 719.566 418.992C729.708 418.992 739.655 416.197 748.312 410.915L777.994 392.446C793.413 382.818 810.649 376.469 828.629 373.792C835.669 372.719 842.779 372.177 849.9 372.17H1440V0Z"
      fill="#000000"
    />
  </svg>
);

const HeroBannerCustomMaskMobileInverted = () => (
  <svg width="100%" viewBox="0 0 385 420" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M385 0H0V372.17H62.2188C69.3417 372.177 76.4544 372.719 83.4961 373.792C101.48 376.467 118.72 382.817 134.143 392.446L163.818 410.915C172.476 416.197 182.422 418.992 192.564 418.992C202.707 418.992 212.653 416.197 221.311 410.915L250.992 392.446C266.411 382.818 283.647 376.469 301.627 373.792C308.667 372.719 315.777 372.177 322.898 372.17H384.998V419.998L385 420V0Z"
      fill="#000000"
    />
  </svg>
);

const HeroBannerCustomMaskTabletInverted = () => (
  <svg width="100%" viewBox="0 0 768 420" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M768 0H0V372.17H-0.0019989V420H0V372.17H254.215C261.338 372.177 268.452 372.719 275.494 373.792C293.477 376.468 310.716 382.818 326.139 392.446L355.814 410.915C364.473 416.198 374.421 418.993 384.564 418.993C394.708 418.993 404.655 416.198 413.314 410.915L442.99 392.446C458.411 382.82 475.649 376.47 493.629 373.792C500.668 372.719 507.778 372.177 514.898 372.17H767.998V419.998V420H768V0Z"
      fill="#000000"
    />
  </svg>
);

export default {
  Desktop: HeroBannerCustomMaskDesktopInverted,
  Mobile: HeroBannerCustomMaskMobileInverted,
  Tablet: HeroBannerCustomMaskTabletInverted,
};
