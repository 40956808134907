import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const ContentWrapper = styled(Flex)`
  gap: 30px;

  ${Media({
    margin: ['2rem 0', null, '2.5rem 0', '2.5rem 0rem'],
  })}
`;

export const StyledTitle = styled(Heading)`
  text-align: center;
  font-weight: ${({ theme }) => theme.categorySection.fontWeight || 900};

  ${Media({
    lineHeight: ['31px', null, '40px', '48px'],
  })}
`;

export const TopCards = styled(Box)`
  gap: 18px;
  overflow-x: auto;

  ${Media({
    display: ['flex', null, 'grid'],
    gridTemplateColumns: [null, null, 'repeat(3, 1fr)'],
    marginRight: ['-1.3rem', null, 0],
    overflowX: ['scroll', null, 'hidden'],
  })}
`;

export const BottomCards = styled(Box)`
  gap: 18px;

  ${Media({
    display: ['flex', null, 'grid'],
    flexDirection: ['column', null],
    gridTemplateColumns: [null, null, 'repeat(2, 1fr)'],
  })}
`;
