import React from 'react';

const Logo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="162px"
    height="60px"
    viewBox="0 20 640 340"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,396.000000) scale(0.100000,-0.100000)" fill="#5ea443" stroke="none">
      <path
        d="M0 1982 l0 -1979 28 4 c15 1 815 59 1777 129 963 69 1760 127 1773
130 l22 4 0 1845 0 1845 -1800 0 -1800 0 0 -1978z m1799 1502 c87 -36 132
-118 206 -369 25 -88 55 -179 65 -203 31 -69 96 -137 206 -213 180 -124 218
-183 216 -339 -1 -105 -28 -215 -80 -330 -35 -77 -129 -221 -198 -305 -28 -33
-57 -73 -66 -90 -26 -48 -48 -130 -48 -179 0 -56 21 -80 82 -96 50 -14 78 -36
78 -62 0 -13 7 -18 26 -18 51 0 113 -22 141 -52 23 -24 28 -38 28 -79 0 -44
-4 -54 -31 -78 l-31 -28 23 -20 c13 -12 28 -41 35 -67 6 -25 15 -46 20 -46 5
0 9 -20 9 -45 l0 -45 -85 0 c-61 0 -85 3 -85 13 -1 47 -21 142 -32 148 -32 21
-48 10 -48 -31 0 -33 3 -40 20 -40 17 0 20 -7 20 -45 l0 -45 -120 0 -120 0 0
44 c0 35 4 45 20 49 20 5 20 12 18 139 l-3 133 -30 0 -30 0 -6 -130 c-7 -153
-19 -184 -82 -216 -31 -16 -60 -22 -112 -23 -86 0 -133 15 -171 55 -29 30 -29
32 -32 167 -3 129 -8 151 -34 152 -5 0 -8 -20 -8 -45 l0 -45 -49 0 -48 0 -7
40 c-3 22 -11 40 -16 40 -6 0 -10 -53 -10 -135 0 -113 2 -135 15 -135 11 0 15
-12 15 -45 l0 -45 -115 0 -115 0 0 45 c0 33 4 45 15 45 13 0 15 22 15 135 0
74 -4 135 -9 135 -11 0 -21 -25 -21 -56 0 -22 -4 -24 -50 -24 l-50 0 0 90 0
90 100 0 c93 0 101 2 124 25 25 25 25 26 6 45 -31 31 -24 76 30 189 45 96 48
106 35 130 -7 15 -16 45 -20 66 -4 22 -18 69 -32 104 -43 111 -44 317 -1 596
15 102 41 163 99 230 77 91 90 123 96 235 3 54 3 114 -1 134 -9 47 -39 78 -69
71 -12 -2 -40 -7 -62 -10 -22 -3 -50 -12 -62 -20 -27 -18 -55 -19 -71 -3 -32
32 5 160 66 232 21 23 51 63 69 89 17 25 50 58 72 73 67 44 207 53 290 18z
m-1302 -2312 l62 -107 1 63 c0 55 -2 62 -20 62 -17 0 -20 7 -20 45 l0 45 95 0
95 0 0 -44 c0 -30 -5 -46 -15 -50 -13 -4 -15 -35 -15 -186 l0 -181 -72 3 -72
3 -68 115 -68 115 0 -72 c0 -66 2 -73 20 -73 17 0 20 -7 20 -45 l0 -45 -100 0
-100 0 0 44 c0 35 4 45 20 49 19 5 20 14 20 137 0 123 -1 132 -20 137 -16 4
-20 14 -20 49 l0 44 98 0 97 0 62 -108z m555 53 c9 -27 36 -110 59 -182 27
-87 46 -133 55 -133 10 0 14 -13 14 -45 l0 -45 -120 0 -120 0 0 45 c0 39 3 45
21 45 15 0 20 5 17 23 -3 20 -8 22 -63 22 -50 0 -60 -3 -63 -18 -3 -12 4 -20
17 -24 17 -4 21 -13 21 -49 l0 -44 -100 0 -100 0 0 45 c0 32 4 45 14 45 15 0
19 9 71 154 39 110 42 126 20 126 -11 0 -15 12 -15 45 l0 46 127 -3 128 -3 17
-50z m1818 -77 c73 -237 71 -233 105 -233 l30 0 3 133 c2 127 2 134 -18 139
-16 4 -20 14 -20 49 l0 44 120 0 120 0 0 -45 c0 -41 -2 -45 -25 -45 l-25 0 0
-135 0 -135 39 0 c40 0 51 11 51 51 0 16 7 19 50 19 l50 0 0 -85 0 -85 -310 0
-310 0 0 45 c0 41 2 45 24 45 20 0 22 4 18 25 -4 25 -7 26 -66 23 -53 -3 -61
-5 -61 -23 0 -13 8 -21 23 -23 19 -3 22 -9 22 -48 l0 -44 -100 0 -100 0 0 41
c0 25 6 46 15 53 8 6 34 69 59 139 36 104 42 129 30 134 -9 3 -14 20 -14 49
l0 44 124 0 125 0 41 -132z m480 -418 c0 -7 -525 -10 -1555 -10 -1030 0 -1555
3 -1555 10 0 7 525 10 1555 10 1030 0 1555 -3 1555 -10z m-3026 -86 c29 -11
18 -32 -16 -30 -30 1 -33 -2 -36 -30 -4 -39 20 -63 43 -44 18 15 30 7 22 -15
-8 -18 -67 -21 -85 -3 -7 7 -12 31 -12 54 0 57 37 86 84 68z m138 -16 c4 -7 6
-22 2 -33 -7 -26 -7 -35 1 -62 10 -32 -18 -30 -33 2 -6 14 -16 25 -22 25 -5 0
-10 -11 -10 -25 0 -16 -6 -25 -15 -25 -12 0 -15 15 -15 71 l0 72 41 -6 c23 -3
46 -11 51 -19z m128 7 c0 -9 -9 -15 -25 -15 -16 0 -25 -6 -25 -15 0 -8 9 -15
20 -15 11 0 20 -4 20 -10 0 -5 -9 -10 -20 -10 -13 0 -20 -7 -20 -20 0 -16 7
-20 30 -20 17 0 30 -4 30 -10 0 -5 -20 -10 -45 -10 l-45 0 0 70 0 70 40 0 c29
0 40 -4 40 -15z m121 -40 c22 -63 23 -85 4 -85 -8 0 -15 7 -15 15 0 9 -9 15
-25 15 -16 0 -25 -6 -25 -15 0 -8 -7 -15 -15 -15 -19 0 -19 4 6 79 26 76 45
77 70 6z m139 40 c0 -8 -9 -15 -20 -15 -18 0 -20 -7 -20 -55 0 -42 -3 -55 -15
-55 -12 0 -15 13 -15 55 0 48 -2 55 -20 55 -11 0 -20 7 -20 15 0 12 13 15 55
15 42 0 55 -3 55 -15z m60 -55 c0 -56 -3 -70 -15 -70 -12 0 -15 14 -15 70 0
56 3 70 15 70 12 0 15 -14 15 -70z m88 33 l17 -36 5 33 c3 18 11 35 18 38 9 3
12 -15 12 -67 0 -85 -17 -94 -52 -28 l-23 42 -3 -42 c-5 -70 -22 -49 -22 27 0
82 19 95 48 33z m176 31 c29 -11 18 -32 -16 -30 -30 1 -33 -2 -36 -33 -2 -20
2 -37 10 -42 19 -13 28 -11 28 6 0 8 -4 15 -10 15 -5 0 -10 5 -10 10 0 6 11
10 25 10 22 0 25 -4 25 -35 0 -34 -1 -35 -39 -35 -47 0 -65 19 -65 67 0 52 43
84 88 67z m163 -36 l7 -43 11 40 c7 22 18 40 25 40 7 0 18 -18 25 -40 l11 -40
7 43 c5 27 12 42 22 42 18 0 18 3 -1 -75 -16 -63 -38 -85 -49 -50 -16 49 -17
50 -27 18 -12 -44 -35 -42 -47 5 -23 85 -24 102 -7 102 11 0 18 -13 23 -42z
m183 -28 c0 -56 -3 -70 -15 -70 -12 0 -15 14 -15 70 0 56 3 70 15 70 12 0 15
-14 15 -70z m94 28 l26 -43 0 43 c0 23 5 42 10 42 6 0 10 -30 10 -70 0 -81
-13 -90 -47 -32 l-22 37 -1 -37 c0 -27 -4 -38 -15 -38 -12 0 -15 14 -15 70 0
84 16 92 54 28z m143 4 l22 -37 1 38 c0 26 4 37 15 37 12 0 15 -14 15 -70 0
-84 -16 -92 -54 -27 l-26 42 0 -42 c0 -32 -4 -43 -15 -43 -12 0 -15 14 -15 70
0 60 2 70 18 70 9 0 27 -17 39 -38z m181 21 c2 -9 -7 -13 -27 -13 -21 0 -31
-5 -31 -15 0 -8 9 -15 20 -15 11 0 20 -4 20 -10 0 -5 -9 -10 -20 -10 -13 0
-20 -7 -20 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0 -5 -20 -10 -45 -10
l-45 0 0 71 0 70 42 -3 c23 -2 43 -9 46 -15z m96 7 c23 -8 26 -15 24 -52 -1
-24 2 -51 6 -60 6 -13 4 -18 -8 -18 -9 0 -22 11 -28 25 -6 14 -15 25 -20 25
-4 0 -8 -11 -8 -25 0 -16 -6 -25 -15 -25 -12 0 -15 14 -15 70 0 77 6 82 64 60z
m130 4 c27 -10 20 -35 -8 -28 -13 3 -27 1 -30 -5 -4 -6 4 -13 19 -17 34 -8 49
-37 35 -65 -9 -16 -19 -19 -47 -17 -20 2 -39 9 -41 17 -4 11 2 13 26 8 42 -9
50 9 12 28 -58 30 -27 103 34 79z m216 -9 c0 -10 -10 -15 -30 -15 -20 0 -30
-5 -30 -15 0 -9 9 -15 25 -15 14 0 25 -4 25 -10 0 -5 -11 -10 -25 -10 -18 0
-25 -5 -25 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0 -5 -20 -10 -45 -10
l-45 0 0 63 c0 35 3 67 7 70 3 4 24 7 45 7 27 0 38 -4 38 -15z m70 -31 l13
-46 16 42 c17 45 42 66 40 33 0 -10 -8 -40 -18 -68 -13 -35 -23 -50 -36 -50
-13 0 -23 15 -36 50 -22 62 -23 85 -5 85 7 0 19 -20 26 -46z m190 31 c0 -10
-10 -15 -30 -15 -20 0 -30 -5 -30 -15 0 -8 9 -15 20 -15 11 0 20 -4 20 -10 0
-5 -9 -10 -20 -10 -13 0 -20 -7 -20 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10
0 -5 -20 -10 -45 -10 l-45 0 0 70 0 70 45 0 c33 0 45 -4 45 -15z m122 -7 c4
-7 6 -22 2 -33 -7 -26 -7 -35 1 -62 10 -32 -18 -30 -33 2 -6 14 -16 25 -22 25
-5 0 -10 -11 -10 -25 0 -16 -6 -25 -15 -25 -12 0 -15 14 -15 63 0 35 3 67 8
71 10 11 74 -1 84 -16z m72 -5 l14 -28 13 28 c7 15 18 27 26 27 17 0 17 -4 -7
-50 -11 -22 -20 -51 -20 -65 0 -16 -6 -25 -15 -25 -10 0 -15 10 -15 28 0 15
-9 42 -20 60 -24 39 -25 52 -5 52 8 0 21 -12 29 -27z m263 5 c18 -15 16 -83
-3 -102 -9 -9 -32 -16 -55 -16 l-39 0 0 71 0 72 42 -7 c23 -4 48 -12 55 -18z
m122 -40 c12 -34 21 -66 21 -70 0 -14 -28 -9 -34 7 -7 20 -36 19 -52 -2 -20
-28 -28 -7 -15 39 34 111 50 116 80 26z m70 35 l13 -28 14 28 c15 28 34 36 34
15 -1 -7 -7 -24 -15 -38 -8 -14 -14 -40 -15 -57 0 -23 -5 -33 -15 -33 -8 0
-15 9 -15 20 0 12 -9 38 -20 60 -11 22 -20 44 -20 50 0 21 27 9 39 -17z"
      />
      <path
        d="M1635 3453 c54 -5 85 -22 85 -47 0 -15 3 -17 8 -8 10 15 43 6 55 -15
5 -7 12 -11 18 -8 10 7 12 -35 3 -59 -3 -9 -15 -16 -25 -16 -25 0 -24 -11 3
-56 15 -26 18 -39 10 -42 -7 -2 -12 -11 -12 -20 0 -10 3 -13 8 -7 4 6 15 11
26 13 14 3 17 -1 13 -12 -4 -9 -3 -16 2 -16 16 0 19 13 9 42 -8 21 -7 33 1 41
8 8 11 4 11 -17 0 -15 4 -25 10 -21 6 3 10 -3 10 -14 0 -22 26 -29 32 -10 2 7
4 -5 5 -25 1 -20 -2 -34 -7 -31 -4 2 -5 -16 -2 -42 3 -30 1 -44 -5 -40 -6 4
-13 -6 -15 -23 -3 -17 -1 -30 3 -30 12 0 20 -32 10 -44 -6 -7 -7 -22 -4 -35 4
-17 8 -19 14 -9 6 9 9 4 9 -16 0 -24 -2 -27 -15 -16 -12 10 -15 9 -15 -8 0
-11 7 -25 15 -32 17 -14 21 -35 4 -24 -6 3 -18 23 -27 43 l-16 36 -7 -40 c-4
-22 -5 -51 -2 -64 4 -17 2 -22 -6 -17 -6 4 -11 15 -11 24 0 14 -2 14 -9 3 -5
-7 -13 -11 -19 -7 -5 3 -15 -8 -22 -24 -14 -34 -35 -38 -62 -13 -12 10 -18 12
-18 4 0 -7 -5 -9 -10 -6 -6 4 -4 12 5 21 9 9 16 37 17 67 3 100 -4 129 -24 93
-8 -15 -12 -16 -19 -5 -12 19 -11 32 1 24 15 -9 12 2 -6 30 -11 16 -12 25 -5
25 19 0 24 29 7 45 -8 9 -19 15 -23 15 -4 -1 -9 3 -11 8 -2 6 6 8 17 5 13 -3
21 -1 21 6 0 7 -4 10 -8 7 -5 -3 -7 11 -6 30 2 23 0 32 -8 28 -6 -4 -9 -1 -6
7 3 8 11 13 18 11 9 -2 11 7 6 33 -6 28 -4 35 9 35 10 0 4 10 -18 30 -34 29
-76 45 -49 18 16 -16 15 -34 -4 -53 -13 -12 -19 -13 -30 -4 -12 9 -13 9 -9 -3
4 -10 -2 -14 -22 -13 -15 0 -28 -6 -29 -12 -1 -7 -2 -17 -3 -22 0 -5 -11 -12
-23 -14 -13 -2 -2 -5 23 -6 55 -1 74 -19 95 -87 28 -93 10 -264 -36 -352 -15
-29 -31 -52 -35 -50 -14 5 -83 -105 -100 -157 -27 -87 -40 -223 -40 -420 0
-175 2 -193 21 -230 12 -22 25 -60 29 -85 4 -25 16 -64 26 -87 l20 -42 -35
-78 c-20 -43 -29 -69 -20 -58 16 19 16 19 11 -10 -7 -38 7 -68 16 -34 4 13 9
20 13 17 3 -4 -1 -27 -9 -52 -18 -52 -15 -59 7 -18 l15 27 1 -27 c0 -21 5 -28
20 -28 11 0 20 5 20 12 0 9 3 9 12 0 22 -22 28 -13 28 42 0 45 -5 60 -25 82
-27 29 -34 89 -14 125 13 25 2 102 -13 93 -7 -5 -9 3 -4 22 4 18 3 24 -2 16
-7 -11 -10 -10 -16 3 -15 40 -6 88 23 120 35 40 68 146 82 261 11 84 7 113
-11 84 -8 -13 -11 -13 -22 2 -11 14 -32 21 -80 27 -4 1 -14 8 -22 17 -9 8 -16
11 -16 6 0 -6 15 -22 33 -37 53 -45 73 -91 35 -80 -22 6 -22 6 -4 -9 23 -18
42 -20 51 -6 9 15 25 12 25 -4 0 -25 -24 -36 -58 -26 -25 7 -32 6 -32 -6 0 -8
5 -14 10 -14 6 0 10 -7 10 -15 0 -9 10 -15 26 -15 14 0 23 -4 19 -10 -10 -16
-57 -11 -71 8 -13 16 -13 16 -14 0 0 -21 -16 -24 -23 -5 -4 8 -6 7 -6 -3 -1
-21 24 -51 39 -48 6 2 19 -7 27 -19 14 -22 14 -23 -5 -23 -33 0 -72 41 -79 83
-4 25 -2 37 6 37 7 0 1 15 -13 33 -15 19 -26 44 -26 56 0 30 17 18 25 -18 6
-30 31 -58 41 -48 3 3 -1 20 -10 37 -11 23 -13 37 -5 51 7 13 6 25 -4 41 -8
13 -17 39 -21 58 -5 33 -5 34 10 15 16 -20 16 -20 10 1 -6 18 -3 21 18 17 13
-3 26 0 30 5 6 10 -1 18 -39 50 -8 7 -15 25 -15 40 l0 27 20 -25 20 -25 0 43
c0 24 4 41 9 37 17 -10 23 16 7 28 -17 13 -12 57 7 57 6 0 7 3 4 6 -9 9 11 44
25 44 6 0 5 -6 -3 -17 -12 -14 -12 -16 0 -8 11 6 13 4 8 -8 -4 -11 -2 -14 7
-11 19 7 32 -16 15 -27 -7 -4 -10 -11 -7 -15 4 -3 19 7 33 22 l27 29 -7 -28
c-7 -25 3 -36 18 -20 4 3 18 7 32 8 21 1 25 -4 25 -30 1 -27 2 -28 14 -12 18
23 25 22 19 -3 -3 -11 0 -20 6 -20 6 0 11 12 12 28 0 15 4 36 9 47 9 19 9 19
16 -2 9 -32 21 -29 38 10 l14 32 2 -30 c3 -38 0 -59 -9 -65 -4 -3 -4 -16 0
-30 6 -23 7 -23 8 -3 1 12 6 25 12 29 7 4 9 21 5 45 -7 41 8 55 18 17 4 -19 7
-20 18 -9 10 11 10 14 1 18 -15 5 -18 43 -4 43 5 0 12 -10 16 -22 6 -20 8 -20
12 -5 6 24 19 21 28 -5 5 -13 11 -32 15 -43 8 -19 8 -19 9 2 1 12 -3 24 -9 28
-18 11 1 33 27 30 31 -3 58 -29 48 -45 -4 -7 -10 -8 -14 -4 -3 5 -14 15 -23
23 -16 12 -18 11 -18 -8 0 -15 6 -21 21 -21 16 0 19 -4 14 -17 -16 -40 -18
-56 -6 -49 6 4 19 2 28 -6 14 -11 16 -10 11 7 -3 11 -9 28 -13 38 -4 10 -3 17
4 17 6 0 13 -12 17 -27 10 -46 14 -51 10 -10 -4 31 -2 37 15 37 21 0 26 15 7
24 -10 5 -10 7 -1 12 7 3 9 13 6 22 -4 10 5 5 20 -11 15 -15 27 -35 27 -43 0
-7 4 -14 9 -14 4 0 6 12 4 28 -4 21 -2 24 6 12 6 -8 11 -30 11 -49 0 -19 3
-31 6 -28 10 10 12 87 3 87 -5 0 -9 7 -9 15 0 13 3 13 21 3 15 -10 19 -20 15
-40 -5 -27 -5 -27 15 -10 12 9 25 28 30 41 8 19 12 21 24 11 8 -7 15 -19 15
-27 0 -8 5 -11 10 -8 6 3 10 -3 10 -14 0 -10 -5 -21 -12 -23 -7 -3 -5 -7 6
-11 17 -7 18 -3 12 40 l-6 47 20 -22 c12 -12 29 -22 38 -22 10 0 30 -14 45
-31 36 -41 44 -33 12 11 -14 19 -25 30 -25 25 0 -5 -7 1 -16 14 -9 12 -60 51
-113 86 -103 69 -163 131 -204 212 -15 28 -49 128 -77 223 -90 305 -137 362
-297 358 -42 -1 -53 -3 -28 -5z m160 -173 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-150 -60 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-9 -97 c-7 -7 -26 7 -26 19 0 6 6 6
15 -2 9 -7 13 -15 11 -17z m294 -28 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0
8 5 15 10 15 6 0 10 -7 10 -15z m-13 -167 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m-207 -30 c-1 -7 -7 -24 -15 -38 l-14 -25 -1 31 c0 17 3 34
7 37 10 11 23 8 23 -5z m240 -39 c0 -20 -15 -26 -24 -10 -8 13 4 42 15 35 5
-3 9 -14 9 -25z m-280 -10 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10
9 6 -3 10 -10 10 -16z m257 -61 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-267 -7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0
10 -4 10 -9z m250 -42 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6
-3 10 -10 10 -16z m-110 -15 c0 -14 -4 -23 -9 -20 -5 3 -7 15 -4 26 7 28 13
25 13 -6z m40 -15 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3
10 -10 10 -16z m-4 -77 c-12 -11 -18 7 -10 30 l8 23 4 -23 c2 -13 1 -26 -2
-30z m71 16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-20 -10
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m133 -8 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-246 -35 c3 -8 1
-15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m266 -10 c0 -8
-4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m295 5 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-895 -372
c0 -4 -7 -5 -15 -2 -8 4 -15 16 -15 28 1 21 1 21 15 2 8 -11 15 -23 15 -28z
m120 -188 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4
21 -10z m-92 -58 c-2 -11 3 -26 11 -35 19 -23 1 -21 -28 3 -25 21 -23 50 4 50
10 0 15 -7 13 -18z m62 -22 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m-5 -524 c-4 -20 -12 -36 -17 -36 -6 0 -7 7 -4 16 3
9 9 27 12 40 10 38 16 21 9 -20z"
      />
      <path
        d="M1548 3434 c-24 -12 -98 -102 -98 -119 0 -4 7 -4 16 -1 10 4 25 -1
37 -11 19 -17 19 -17 13 5 -5 15 -3 24 8 28 9 3 16 10 16 16 0 5 -3 7 -7 5 -5
-3 -14 1 -21 9 -11 12 -8 18 14 39 15 14 33 24 41 21 7 -3 15 -1 18 4 10 16
-10 18 -37 4z"
      />
      <path d="M1621 3344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path
        d="M1653 3343 c2 -12 0 -24 -7 -27 -7 -3 -7 -5 2 -5 18 -1 27 28 12 42
-10 10 -12 7 -7 -10z"
      />
      <path d="M1698 3283 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
      <path d="M1510 3230 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
      <path
        d="M1352 3183 c-20 -52 -24 -73 -13 -73 6 0 11 10 11 21 0 12 7 27 16
34 13 9 14 16 7 29 -9 16 -11 15 -21 -11z"
      />
      <path
        d="M1430 3195 c0 -8 -4 -14 -8 -12 -5 1 -22 -14 -38 -33 l-29 -35 45 27
c24 14 51 35 58 47 13 19 13 21 -7 21 -12 0 -21 -6 -21 -15z"
      />
      <path d="M1858 3095 c2 -20 9 -34 14 -33 11 4 11 8 2 43 -9 37 -21 30 -16 -10z" />
      <path d="M1722 2950 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M1723 2775 c-8 -22 6 -33 17 -15 13 20 12 30 0 30 -6 0 -13 -7 -17
-15z"
      />
      <path d="M1891 2556 c-16 -19 -11 -29 8 -17 7 5 12 14 9 21 -3 9 -8 7 -17 -4z" />
      <path
        d="M2231 2561 c-7 -5 -11 -17 -9 -27 4 -17 5 -17 17 4 14 27 12 35 -8
23z"
      />
      <path d="M1651 2514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M1755 2519 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path d="M2092 2510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path d="M2201 2514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path
        d="M1882 2506 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
      />
      <path
        d="M2241 2507 c-1 -10 -5 -15 -11 -12 -5 3 -10 2 -10 -2 0 -14 36 -31
49 -23 9 5 10 10 2 14 -6 4 -11 4 -12 -1 -1 -4 -5 3 -9 17 -6 18 -8 20 -9 7z"
      />
      <path d="M1941 2484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M2110 2480 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z" />
      <path d="M2051 2474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path
        d="M2180 2480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
      />
      <path
        d="M2420 2473 c0 -15 -2 -16 -10 -3 -9 13 -10 13 -10 0 0 -13 -1 -13
-10 0 -9 14 -15 7 -11 -14 1 -6 -2 -19 -7 -30 -21 -44 -25 -63 -28 -130 -1
-38 -7 -73 -13 -79 -16 -16 -13 -31 3 -18 12 10 28 53 30 83 1 3 4 10 9 14 4
4 7 16 7 26 0 11 4 17 9 13 5 -3 12 5 15 17 3 13 3 -4 0 -37 -3 -32 -10 -62
-16 -66 -6 -4 -11 -23 -13 -42 -1 -19 -9 -43 -18 -52 -19 -19 -22 -13 -7 15 8
16 8 18 -5 14 -10 -4 -15 -19 -15 -46 0 -23 -4 -37 -10 -33 -5 3 -10 1 -10 -4
0 -6 4 -11 9 -11 6 0 9 -9 8 -20 -1 -11 -5 -20 -8 -20 -4 0 -6 -11 -3 -24 2
-15 -2 -26 -11 -30 -8 -3 -15 -15 -15 -26 0 -31 -18 -24 -30 13 l-10 32 5 -35
c4 -33 -16 -125 -26 -118 -7 5 -45 -35 -52 -54 -15 -42 42 -2 85 61 25 35 28
45 17 53 -11 8 -11 9 2 5 33 -10 109 147 150 311 20 76 21 94 11 101 -11 7
-11 12 -2 28 9 13 10 35 6 66 -7 46 -26 75 -26 40z m0 -53 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
      />
      <path d="M2141 2444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path d="M2112 2430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M1520 2398 c0 -7 9 -28 20 -46 25 -40 25 -45 3 -33 -13 6 -22 5 -31
-5 -10 -11 -8 -16 12 -30 36 -23 61 -15 62 21 0 17 -6 38 -14 48 -10 11 -11
17 -4 17 7 0 5 8 -5 20 -20 22 -43 27 -43 8z"
      />
      <path
        d="M2290 2348 c-1 -10 -2 -35 -2 -55 4 -140 0 -196 -14 -210 -16 -16
-18 -51 -5 -72 6 -9 10 -3 14 20 3 19 11 68 18 109 9 55 10 95 2 150 -6 41
-11 67 -13 58z"
      />
      <path
        d="M1901 1862 c-6 -12 -11 -27 -11 -34 1 -7 8 0 16 15 9 16 13 31 11 34
-3 2 -10 -4 -16 -15z"
      />
      <path
        d="M1850 1821 c0 -7 -6 -18 -12 -26 -7 -7 -22 -25 -33 -40 -40 -52 -55
-62 -83 -57 -15 3 -30 9 -33 15 -4 5 -10 1 -13 -11 -8 -22 -43 -36 -50 -18 -2
6 -5 4 -5 -5 -1 -9 6 -19 14 -23 8 -3 15 -1 15 4 0 6 5 10 11 10 6 0 8 -9 4
-20 -4 -11 -2 -20 4 -20 6 0 11 10 12 23 0 12 3 16 6 8 5 -11 7 -11 15 0 7 10
8 8 3 -6 -4 -11 -8 -42 -9 -70 -3 -74 -16 -125 -32 -125 -8 0 -14 -9 -14 -20
0 -14 -7 -20 -22 -21 l-23 0 23 -11 c13 -7 22 -20 22 -35 0 -18 5 -23 25 -23
25 0 33 -14 15 -25 -5 -3 -10 -13 -10 -22 0 -11 4 -13 13 -6 6 6 46 9 88 9 75
-2 75 -2 65 21 -8 16 -12 18 -14 8 -5 -20 -29 -19 -41 3 -10 16 -10 16 -11 0
0 -13 -8 -18 -31 -18 -24 0 -30 4 -27 18 2 9 -3 22 -11 28 -16 13 -6 38 12 27
6 -4 21 -8 33 -9 26 -3 37 18 54 102 22 108 32 117 118 117 17 -1 32 3 32 8 0
5 -12 6 -27 3 l-28 -7 21 24 c12 13 25 33 28 45 4 12 18 26 31 32 14 7 28 19
31 27 4 8 11 15 16 15 6 0 7 -7 4 -16 -7 -17 -5 -17 41 11 18 11 33 25 33 30
0 14 -41 17 -57 3 -9 -7 -13 -7 -13 0 0 6 -18 -7 -41 -29 -22 -21 -46 -39 -52
-39 -6 0 -2 7 9 15 16 12 16 14 3 15 -9 0 -27 -9 -40 -20 -23 -18 -23 -19 -4
-20 19 -1 19 -1 2 -14 -10 -8 -26 -12 -36 -9 -11 3 -21 -2 -24 -11 -4 -9 -12
-16 -18 -16 -7 0 -21 -8 -32 -17 -15 -13 -21 -15 -24 -5 -3 6 -9 10 -14 6 -13
-8 -11 2 10 49 11 24 30 49 43 55 31 17 55 69 39 85 -8 8 -11 8 -11 -2z m-96
-373 c-5 -8 -2 -9 10 -5 14 6 17 2 14 -16 -3 -23 -34 -32 -58 -17 -16 10 5 50
26 50 9 0 12 -5 8 -12z"
      />
      <path
        d="M2145 1771 c-4 -18 -11 -28 -19 -25 -6 2 -23 -8 -36 -22 -14 -15 -20
-25 -15 -21 6 3 17 2 25 -3 12 -8 9 -15 -19 -40 -18 -17 -31 -34 -27 -37 3 -3
1 -12 -5 -20 -9 -10 -8 -13 0 -13 7 0 9 -4 6 -10 -3 -5 -3 -10 2 -10 4 1 22
31 40 68 18 37 43 81 57 97 21 26 23 32 11 48 -12 17 -14 16 -20 -12z"
      />
      <path d="M1972 1560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
      <path
        d="M1889 1563 c-19 -4 -39 -43 -39 -76 -1 -12 -7 -39 -14 -59 l-14 -38
32 0 c17 0 48 -3 68 -6 l38 -7 -6 64 c-9 101 -25 131 -65 122z"
      />
      <path d="M2036 1473 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
      <path
        d="M1400 1461 c-5 -11 -9 -35 -9 -53 l1 -33 8 35 c4 19 11 43 15 53 10
23 -2 22 -15 -2z"
      />
      <path
        d="M2050 1431 c0 -5 -7 -11 -15 -13 -13 -2 -12 -6 5 -16 18 -11 20 -10
20 13 0 14 -2 25 -5 25 -3 0 -5 -4 -5 -9z"
      />
      <path
        d="M1410 1380 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
      />
      <path
        d="M2070 1364 c0 -36 50 -92 70 -79 17 11 11 33 -10 40 -12 4 -30 16
-40 28 -11 11 -20 16 -20 11z"
      />
      <path
        d="M1870 1330 c6 -11 12 -20 14 -20 2 0 2 9 -1 20 -3 11 -9 20 -15 20
-5 0 -4 -9 2 -20z"
      />
      <path
        d="M1923 1336 c-36 -17 -28 -21 40 -22 45 -1 55 1 34 7 -16 4 -34 12
-40 16 -7 6 -21 5 -34 -1z"
      />
      <path d="M2057 1311 c3 -12 9 -21 14 -21 14 0 11 16 -5 29 -12 10 -13 8 -9 -8z" />
      <path
        d="M1622 1290 c-30 -25 -28 -31 8 -19 29 11 38 23 22 32 -4 3 -17 -3
-30 -13z"
      />
      <path d="M2168 1303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
      <path
        d="M1447 1278 c3 -17 -4 -28 -24 -41 -15 -10 -20 -16 -11 -13 9 2 44 8
77 12 34 3 61 10 61 15 0 5 -7 6 -15 3 -10 -4 -15 0 -15 10 0 13 -4 15 -19 6
-26 -14 -28 -13 -34 10 -7 28 -25 25 -20 -2z"
      />
      <path
        d="M1807 1243 c-4 -3 -7 -17 -7 -30 0 -16 -6 -23 -20 -23 -19 0 -20 -7
-20 -116 0 -134 7 -154 53 -154 53 0 57 11 57 147 0 116 -1 123 -20 123 -16 0
-20 7 -20 30 0 29 -9 38 -23 23z"
      />
      <path
        d="M2230 1130 c0 -43 3 -50 20 -50 25 0 53 41 43 66 -8 23 -22 34 -45
34 -15 0 -18 -9 -18 -50z"
      />
      <path
        d="M898 1090 l-16 -50 33 0 33 0 -14 47 c-8 25 -15 48 -17 50 -2 1 -10
-20 -19 -47z"
      />
      <path
        d="M2692 1091 c-16 -52 -16 -51 19 -51 24 0 29 4 25 18 -3 9 -8 29 -12
45 -9 37 -19 34 -32 -12z"
      />
      <path d="M400 605 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16 -13z" />
      <path d="M671 584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
      <path
        d="M2000 605 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
      />
      <path
        d="M2700 605 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
      />
      <path
        d="M3030 576 c0 -49 5 -54 34 -33 26 19 18 71 -11 75 -21 3 -23 -1 -23
-42z"
      />
      <path
        d="M3175 580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
      />
    </g>
  </svg>
);

export default Logo;
