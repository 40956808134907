import React from 'react';

const Logo = () => {
  return (
    <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.99 101.43">
      <g>
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M87.48,92.78s-.01-.03-.02-.05c-.17-.34-.54-.47-.86-.64-.03-.02-.06-.03-.1-.05h0c-.18-.09-.37-.21-.56-.36-.02-.02-.04-.04-.07-.06-.23-.19-.42-.43-.58-.69-.48-.82-.68-1.71-.81-2.67v-6.57l-2.14-6.3h-.08v12.86c-.13.95-.32,1.84-.8,2.66-.22.36-.5.68-.85.91-.11.08-.23.15-.34.21,0,0,0,0,0,0h0c-.25.14-.49.24-.7.4-.02.02-.04.03-.05.05-.09.08-.17.18-.21.3-.49,1.42,1.31,1.57,1.91,1.51,0,0,4.35,0,4.35,0,.6.06,2.4-.09,1.91-1.51"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M79.64,91.63c.23-.4.43-.85.44-1.31,0-.53-.61-1.11-1.11-1.11-.92,0-1.03,1.11-1.64,1.11-.5,0-.38-.76-.38-1.11,0-.81,0-2.54,0-3.36,0-.86-.2-1.56-.5-2.31h0s-.04-.04-.05-.07c-.78-1.58-2.46-2.31-4.08-2.75h-.02c-.68-.15-1.36-.3-2.07-.4v-.08c2.33-.55,4.76-1.57,5.97-3.76h-.04s0,0,0,0h.04c.67-1.28.85-2.79.71-4.25h0c-.59-5.85-6.58-6.76-11.41-6.5v2.25c1.91.69,2.33,1.6,2.33,3.51v4.62c.04,1.72-.35,3.2-2.33,3.32v2.25c2.1.12,2.4,2.45,2.33,4.12.02,1.32-.07,3.06.11,4.36.07.65.28,1.26.54,1.86h0s0,0,0,0h-.01s.03.05.04.07c1.75,3.3,6.72,3.58,9.46,1.38.48-.37,1-.92,1.39-1.44,0,0,0,0,0-.01h0c.1-.13.18-.27.26-.4"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M50.38,85.36c-.69,0-.92.61-1.18,1.18-.26.75-.6,1.45-1.02,2.11-1.22,1.87-3.24,3.16-5.47,3.43v2.21h8.78v-7.64c0-.15.11-1.3-1.11-1.3"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M47.59,75.54c0-.3.19-1.68-.99-1.68-.8,0-1.03.61-1.22,1.26-.16.68-.41,1.36-.79,1.95-.43.68-1.1,1.19-1.88,1.41v2.37c1.54.4,2.35,1.91,2.67,3.36.19.65.42,1.26,1.22,1.26,1.18,0,.99-1.38.99-1.68"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M108.7,92.08c-.25-.08-.46-.15-.68-.26-.09-.04-.17-.09-.24-.15-.07-.05-.13-.11-.17-.18-.02-.03-.04-.06-.06-.09-.02-.04-.03-.09-.05-.13-.01-.04-.03-.07-.04-.11-.02-.1-.03-.2-.04-.3-.02-.29-.02-.53-.02-.84,0,0,0-20,0-20,0-1.41-.04-1.64,1.3-2.06.5-.15.84-.5.84-1.03,0-1.11-1.26-1.18-1.41-1.18h-10.08v24.28c0,.17,0,.33,0,.47,0,.04,0,.07,0,.11,0,.14,0,.26-.03.39-.01.1-.02.2-.06.29-.02.05-.03.09-.05.14-.05.07-.09.13-.15.2-.29.25-.65.36-1.01.48-.5.15-.84.5-.84,1.03,0,1.11,1.26,1.18,1.41,1.18h10.8c.15,0,1.41-.08,1.41-1.18,0-.53-.34-.88-.84-1.03"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M12.79,92.08c-.14-.04-.25-.08-.36-.12-.24-.09-.47-.19-.66-.36-.16-.17-.24-.38-.26-.61,0-.04-.01-.08-.02-.13-.04-5.67,0-15.11-.02-20.85,0-1.41-.04-1.64,1.3-2.06.5-.15.84-.5.84-1.03,0-1.11-1.26-1.18-1.41-1.18H1.41c-.15,0-1.41.08-1.41,1.18,0,.53.34.88.84,1.03,1.34.42,1.3.65,1.3,2.06,0,1.92.01,19.15,0,20.57,0,.14,0,.26-.03.39,0,.07-.01.13-.03.19,0,.04-.02.07-.03.1-.06.22-.23.38-.42.5-.25.14-.51.22-.79.32-.5.15-.84.5-.84,1.03,0,1.11,1.26,1.18,1.41,1.18h10.8c.15,0,1.41-.08,1.41-1.18,0-.53-.34-.88-.84-1.03"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M27.22,92.08c-.37-.12-.72-.23-1.01-.48-.03-.03-.06-.07-.08-.11-.05-.06-.09-.14-.11-.22-.24-2.07-.02-18.78-.09-21.26,0-1.41-.04-1.64,1.3-2.06.5-.15.84-.5.84-1.03,0-1.11-1.26-1.18-1.41-1.18h-10.8c-.15,0-1.41.08-1.41,1.18,0,.53.34.88.84,1.03,1.34.42,1.3.65,1.3,2.06v8.67h-3.36v2.21h3.36v6.9c-.04.85.1,2.67-.09,3.47-.02.05-.03.09-.05.14-.04.07-.09.13-.15.19-.29.25-.65.36-1.01.48-.5.15-.84.5-.84,1.03,0,1.11,1.26,1.18,1.41,1.18h10.8c.15,0,1.41-.08,1.41-1.18,0-.53-.34-.88-.84-1.03"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M42.7,65.73v2.21c2.55.35,4.85,1.94,5.85,4.34.44.71.37,2.13,1.48,2.11,1.15,0,1.18-.73,1.18-.92v-7.75h-8.51"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M118.09,65.53c-1.54.38-3.03,1.02-4.3,1.96-1.66,1.22-2.94,2.89-3.83,4.74h0,0c-.61,1.34-1.03,2.77-1.3,4.24h.01s0,0,0,0h-.01c-.6,3.78-.3,7.81,1.3,11.31h0s0,0,0,0h0c.75,1.62,1.84,3.06,3.18,4.24h.02s0,0,0,0h-.03c2.01,1.66,4.49,2.59,7.11,2.83v-2.21c-.45-.17-.83-.37-1.17-.68-.61-.52-.79-1.31-.78-2.07,0,0,0-19.78,0-19.78,0-.95.15-2.1,1.95-2.75v-2.21c-.76.07-1.45.19-2.16.37"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M96.26,71.12l-1.72,6.34h-.08l-4.09-11.72h-9.7c-.46,0-1.6,0-1.6,1.18,0,.92,1.03.99,1.49,1.03.65.04.8.5,1.14,1.07.31.5.5,1.07.69,1.64l7.98,23.63h1.11l4.85-16.22v-6.95h-.08"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M30.91,65.73c-.15,0-1.41.08-1.41,1.18,0,.53.34.88.84,1.03,1.34.42,1.3.65,1.3,2.06,0,1.93,0,19.14,0,20.58,0,.14,0,.26-.02.39-.02.39-.26.71-.62.85-.21.11-.42.17-.65.25-.5.15-.84.5-.84,1.03,0,1.11,1.26,1.18,1.41,1.18h10.08v-28.56h-10.08"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M65.78,92.08c-.14-.04-.25-.08-.36-.12-.24-.09-.47-.19-.66-.36-.16-.17-.24-.38-.26-.61-.08-1.4,0-23.22-.03-25.25h-10.08c-.15,0-1.41.08-1.41,1.18,0,.53.34.88.84,1.03,1.34.42,1.3.65,1.3,2.06-.01,3.79.02,17.1-.01,20.85-.02.14-.02.28-.08.41-.02.05-.03.09-.05.14-.24.41-.73.52-1.16.67-.5.15-.84.5-.84,1.03,0,1.11,1.26,1.18,1.41,1.18h10.8c.15,0,1.41-.08,1.41-1.18,0-.53-.34-.88-.84-1.03"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M133.83,80.72c.05-1.42-.07-2.84-.28-4.25h0c-.27-1.47-.69-2.89-1.3-4.24,0,0-.01-.02-.02-.03-.89-1.89-2.22-3.56-3.91-4.8-1.86-1.31-4.08-2.03-6.36-2.25v2.21c1.8.65,1.95,1.79,1.95,2.75v19.78c0,.35-.03.7-.12,1.04,0,.02,0,.04-.01.06-.05.16-.13.32-.22.47-.03.04-.05.08-.08.12-.36.51-.9.84-1.51,1.06v2.21c2.61-.24,5.1-1.16,7.1-2.83h0c1.33-1.19,2.42-2.62,3.17-4.24h0c.61-1.35,1.03-2.77,1.3-4.24h-.01s0,0,0,0h.01c.14-.94.24-1.88.28-2.83h-.01s.01,0,.01,0"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M145.45,70.12c0-.96.15-2.1,1.95-2.75v-2.21c-4.41.31-8.46,2.99-10.27,7.04,0,.01-.01.02-.02.03h0,0c-.61,1.34-1.03,2.77-1.3,4.24h.01s-.01,0-.01,0c-.21,1.4-.33,2.82-.28,4.24h0c.04.95.14,1.89.28,2.83h0c.27,1.47.69,2.89,1.3,4.24h0s0,0,0,0c0,.01.01.02.02.03.75,1.61,1.83,3.03,3.16,4.2h.02s0,0,0,0h-.03c2.01,1.66,4.5,2.59,7.11,2.83v-2.21c-.65-.23-1.23-.6-1.59-1.18-.09-.15-.17-.31-.22-.47,0-.02,0-.04-.01-.05-.1-.34-.12-.7-.12-1.05"
        />
        <path
          fill="#1d1d1b"
          strokeWidth="0px"
          d="M160.98,80.72c.05-1.42-.07-2.84-.28-4.25h0c-.27-1.47-.69-2.89-1.3-4.24,0-.01-.01-.02-.02-.03-.88-1.84-2.16-3.5-3.81-4.72-1.87-1.38-4.14-2.1-6.46-2.33v2.21c1.8.65,1.95,1.79,1.95,2.75v19.78c0,.35-.03.7-.13,1.04,0,.02,0,.04-.01.06-.05.16-.13.32-.22.47-.36.58-.94.94-1.59,1.17v2.21c2.61-.24,5.1-1.16,7.1-2.83h0c1.33-1.19,2.42-2.62,3.17-4.24h0s.02-.07.04-.1c.6-1.31.99-2.71,1.26-4.14h-.01s.01,0,.01,0c.14-.94.23-1.88.28-2.83h-.01s.01,0,.01,0"
        />
        <polygon
          fill="#009353"
          strokeWidth="0px"
          points="5.23 98.07 150.19 98.07 149.67 101.43 5.4 101.43 5.23 98.07"
        />
        <path
          fillRule="evenodd"
          fill="#009353"
          strokeWidth="0px"
          d="M71.7,58l1.06-10.14s-17.85-25.62-16.62-26.52c1.94-1.41,17.32,17.15,17.32,17.15l1.58-38.49c-16.75.89-30.04,14.22-30.04,30.54,0,12.72,7.07,22.85,18.56,27.46h8.13"
        />
        <polygon
          fillRule="evenodd"
          fill="#009353"
          strokeWidth="0px"
          points="6.28 58.59 6.97 60.97 71.2 60.97 71.9 58.59 6.28 58.59"
        />
        <polygon
          fillRule="evenodd"
          fill="#009353"
          strokeWidth="0px"
          points="83.33 58.59 84.02 60.97 143.81 60.97 144.51 58.59 83.33 58.59"
        />
        <path
          fillRule="evenodd"
          fill="#009353"
          strokeWidth="0px"
          d="M83.37,58.12h6.36c11.33-4.68,18.92-14.97,18.92-27.58C108.65,14.33,95.53,1.07,78.95.02l2.3,26.45s9.37-9.37,11.02-9.7c3.28-.65-10.56,16.42-10.56,16.42l1.66,24.92"
        />
      </g>
      <path
        fill="#009353"
        strokeWidth="0px"
        d="M148.56,62.63c-2.05,0-3.11-1.29-3.11-3.01,0-1.95,1.4-3.14,3.14-3.14,1.92,0,3.11,1.19,3.11,3.03s-1.28,3.13-3.14,3.13ZM148.59,57.15c-1.36,0-2.42.91-2.42,2.45,0,1.46.92,2.35,2.42,2.35s2.4-.92,2.4-2.4c0-1.58-1.03-2.4-2.4-2.4ZM150.09,61.1h-.72l-.74-1.26h-.42v1.26h-.66v-3.21h1.14c.89,0,1.11.44,1.11.91s-.24.71-.57.84l.86,1.46ZM148.42,59.33c.4,0,.71-.08.71-.5,0-.35-.24-.44-.59-.44h-.34v.94h.22Z"
      />
    </svg>
  );
};

export default Logo;
