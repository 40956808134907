import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Sabe" transform="translate(20.000000, 0.000000)" fill="#E20613">
        <polygon
          id="Fill-1"
          points="0 0 0 22.228 27.64 22.228 27.64 26.241 0 26.241 0 34.746 37.981 34.405 37.595 13.24 9.085 13.24 9.085 8.601 37.981 8.601 37.981 0"
        />
        <path
          d="M39.3345,0 L39.3345,34.405 L47.8385,34.405 L47.8385,21.649 L66.3935,21.649 L66.3935,22.036 L66.3935,34.405 L75.2855,34.405 L75.2855,0 L39.3345,0 Z M47.8385,13.142 L66.3935,13.142 L66.3935,8.311 L47.8385,8.311 L47.8385,13.142 Z"
          id="Fill-2"
        />
        <polygon id="Fill-3" points="49.1789 26.6558 49.0959 34.4058 65.0059 34.4058 65.0879 26.6558" />
        <polygon
          id="Fill-4"
          points="39.3345 35.7579 39.3345 71.9999 75.2855 71.9999 75.2855 62.7219 48.2245 62.7219 48.2245 58.9039 75.2855 58.9039 75.2855 49.4809 48.0315 49.4809 48.0315 44.8429 75.2855 44.8429 75.2855 35.7579"
        />
        <path
          d="M9.3753,35.7579 L0.0003,35.7579 L0.0973,71.9999 L9.0843,71.9999 L37.5943,71.9999 L37.5943,63.3989 L37.5943,58.7589 L32.8593,54.5069 L37.5943,49.7719 L37.5943,35.7579 L9.3753,35.7579 Z M9.3753,49.6499 L28.1713,49.6499 L28.1713,45.1329 L9.3753,45.1329 L9.3753,49.6499 Z M9.3753,62.9159 L9.3753,59.2799 L28.1233,59.2799 L28.1233,63.0109 L9.3753,62.9159 Z"
          id="Fill-5"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
