import resolveResponse from 'contentful-resolve-response';

import {
  ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulCallToActionContentfulEmbeddedContentContentfulEventContentfulIconCardsContentfulImageContentfulImageAndTextContentfulImageCardContentfulListingHighlightedContentContentfulListingManualContentfulMagentoCategoryContentfulMagentoPageContentfulMagentoProductContentfulMagentoStoreContentfulMediaBlockContentfulNewsContentfulNewsletterSubscriptionContentfulProductContentfulRecipeContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
  ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
  ContentfulAsset,
  ContentfulRichTextSectionRichText,
} from '../types';

/**
 * Detect if a reference has a rich text field
 */
const hasRichText = (
  reference: Exclude<
    ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
    ContentfulAsset
  >,
) => {
  return Object.keys(reference).find(key => {
    return (
      reference[key as keyof typeof reference] &&
      Object.keys(reference[key as keyof typeof reference]).find(fieldKey => fieldKey === 'raw')
    );
  });
};

/**
 * Expand all rich text fields inside a reference
 */
const expandRichTextFields = (
  reference: Exclude<
    ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
    ContentfulAsset
  >,
  locale: string,
) =>
  Object.keys(reference).reduce((result, key) => {
    const newResult = { ...result };
    if (newResult[key as keyof typeof reference]?.raw) {
      newResult[key as keyof typeof reference] = {
        ...newResult[key as keyof typeof reference],
        // eslint-disable-next-line no-use-before-define
        ...resolve(newResult[key as keyof typeof reference], locale)?.json,
      };

      delete newResult[key as keyof typeof reference].raw;
      delete newResult[key as keyof typeof reference].references;
    }

    return newResult;
  }, reference);

type ValueType = Omit<ContentfulRichTextSectionRichText, 'references'> & {
  references: Array<
    Omit<
      ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulCallToActionContentfulEmbeddedContentContentfulEventContentfulIconCardsContentfulImageContentfulImageAndTextContentfulImageCardContentfulListingHighlightedContentContentfulListingManualContentfulMagentoCategoryContentfulMagentoPageContentfulMagentoProductContentfulMagentoStoreContentfulMediaBlockContentfulNewsContentfulNewsletterSubscriptionContentfulProductContentfulRecipeContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
      'node_locale' | 'children' | 'internal' | 'id'
    >
  >;
};

const resolve = (valueToResolve: ValueType, locale: string) => {
  if (!valueToResolve) {
    return undefined;
  }
  const { raw, references } = valueToResolve;
  const richText = JSON.parse(raw as string);

  // If no references are given, there is no need to resolve them
  if (!references || !references.length) {
    return { json: richText };
  }

  // Create dummy response so we can use official libraries for resolving the
  // entries
  const dummyResponse = {
    includes: {
      Asset: (
        references as ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion[]
      )
        .filter(({ __typename }) => __typename === `ContentfulAsset`)
        .map(reference => {
          return {
            ...reference,
            sys: { id: reference.contentful_id, type: `Asset` },
          };
        }),
      Entry: (
        references as ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion[]
      )
        .filter(
          ({
            __typename,
          }: ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion) =>
            __typename !== `ContentfulAsset`,
        )
        .map(reference => {
          if (
            hasRichText(
              reference as Exclude<
                ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
                ContentfulAsset
              >,
            )
          ) {
            return expandRichTextFields(
              reference as Exclude<
                ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
                ContentfulAsset
              >,
              locale,
            );
          }

          return reference;
        })
        .map(reference => {
          return {
            ...(reference as Exclude<
              ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
              ContentfulAsset
            >),
            sys: {
              ...(
                reference as Exclude<
                  ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
                  ContentfulAsset
                >
              ).sys,
              id: (
                reference as Exclude<
                  ContentfulAdviceContentfulAssetContentfulAttachmentContentfulBasicPageContentfulBlockquoteContentfulEventContentfulImageContentfulMediaBlockContentfulNewsContentfulProductContentfulRichTextSectionContentfulShortBannerContentfulTableContentfulTextSectionHighlightContentfulVideoUnion,
                  ContentfulAsset
                >
              ).contentful_id,
              type: `Entry`,
            },
          };
        }),
    },
    items: [
      {
        richText,
        sys: { type: `Entry` },
      },
    ],
  };

  const resolved = resolveResponse(dummyResponse, {
    removeUnresolved: true,
  });

  // Create legacy format, unset json key
  const result = resolved[0].richText;

  // WIP: Try to cope without this tomfoolery
  // result.content = result.content.map((item: any) => {
  //   if (item?.data?.target) {
  //     // Process
  //     const { sys, metadata, ...fields } = item.data.target;

  //     const processedFields = Object.keys(fields).reduce((acc: Record<string, any>, fieldKey: string) => {
  //       if (!['__typename', 'contentful_id'].includes(fieldKey)) {
  //         acc[fieldKey] = {
  //           [locale]: fields[fieldKey],
  //         };
  //       }

  //       return acc;
  //     }, {});

  //     return {
  //       ...item,
  //       data: {
  //         ...item.data,
  //         target: {
  //           fields: processedFields,
  //           metadata,
  //           sys,
  //         },
  //       },
  //     };
  //   }

  //   return item;
  // });

  return { json: result };
};

export default resolve;
