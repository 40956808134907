import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { withErrorBoundary } from '../../components';

import { StyledImageWrapper, StyledTitle, StyledWrapper } from './Styled';

type CTA = {
  to: string;
  label: string;
};

export type LogoCardProps = {
  key: string;
  title: string;
  image: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  cta: CTA;
};

const LogoCard = ({ title, image, cta, ...rest }: LogoCardProps) => {
  return (
    <StyledWrapper href={cta?.to} {...rest}>
      <StyledImageWrapper>
        <GatsbyImage
          alt={image?.alt ? image.alt : image.title}
          image={image.asset.gatsbyImageData}
          objectFit="contain"
          style={{ height: '100%', margin: '0 auto', width: '100%' }}
        />
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
    </StyledWrapper>
  );
};
export default withErrorBoundary(LogoCard, { componentName: 'LogoCard' });
