import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const StyledModuleHeading = styled(Heading)`
  font-weight: ${({ theme }) => theme.heroSection?.titleFontWeight || 'normal'};
  word-break: break-word;
  margin-top: 0px;
  margin-bottom: 0px;
`;

interface StyledModuleDescription {
  wide?: boolean;
}

export const StyledModuleDescription = styled(Text)<StyledModuleDescription>`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginTop: ['1rem', null, null, '1.5rem'],
  })}

  ${props =>
    props.wide &&
    `
    max-width: 646px;
  `}
`;

interface StyledHeadingAndDescriptionWrapper {
  wide?: boolean;
}

export const StyledHeadingAndDescriptionWrapper = styled(Box)<StyledHeadingAndDescriptionWrapper>`
  max-width: ${props => (props.wide ? '720px' : '646px')};
  ${Media({
    paddingRight: ['0px', '0px', '4rem', '0px'],
  })}
`;

export const StyledContainer = styled(Flex)`
  justify-content: center;

  ${Media({
    marginLeft: ['1rem', '1rem', '214px', '1rem'],
    marginRight: ['1rem', '1rem', null, '1rem'],
    marginTop: ['40px', '40px', null, '81px'],
  })}
`;

export const StyledHeroCtaWrapper = styled.div`
  ${Media({
    marginTop: ['41px', null, '32px', '32px'],
  })}
`;
