import { MutableRefObject, useEffect } from 'react';

const useCustomSelectContainer = (
  ref: MutableRefObject<HTMLDivElement | null>,
  setExpanded: (value: boolean) => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setExpanded]);
};

export default useCustomSelectContainer;
