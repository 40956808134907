import React from 'react';

const CheckBoxCheck = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.152" height="13.379" viewBox="0 0 14.152 13.379" {...props}>
    <path
      id="Path_43"
      data-name="Path 43"
      d="M3.564,10.293l5.088,3.948,3.586-6.447,2.689-4.041"
      transform="translate(-2.161 -2.367)"
      fill="none"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default CheckBoxCheck;
