import { ChevronRightList } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

interface StyledTitleProps {
  inColumn?: boolean;
}

export const CardTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-weight: bold;
  filter: drop-shadow(1px 1px 0px #0000004d);

  &:hover {
    text-decoration: none;
  }

  ${Media({
    fontSize: ['21px', null, '21px', '28px'],
    lineHeight: ['27px', null, '27px', '45px'],
  })}
`;

export const StyledChevron = styled(ChevronRightList)`
  align-self: flex-end;
  color: #f8faf2;
  opacity: 0.9;

  ${Media({
    height: [null, null, null, '32px'],
    width: [null, null, null, '32px'],
  })}
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
`;

export const TitleWrapper = styled(Box)<StyledTitleProps>`
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${props =>
    Media({
      minHeight: props.inColumn ? ['100px', '200px', '200px', '200px'] : ['100px', null, null, '200px'],
      padding: ['0.75rem', null, null, '0.75rem 1.5rem'],
    })}
`;

export const Gradient = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(0deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  box-shadow: 3px 3px 3px #00000029;
  overflow: hidden;

  &:hover {
    text-decoration: none;

    ${StyledImage} {
      transform: scale(1.05);
    }

    ${StyledChevron} {
      color: white;
      opacity: 1;
    }
  }
`;
