import * as React from 'react';

const RadioNested = () => (
  <svg height="12" id="radio-button-nested-active" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25,2.25a6,6,0,1,0,6,6A6,6,0,0,0,8.25,2.25Zm0,11.143A5.143,5.143,0,1,1,13.393,8.25,5.143,5.143,0,0,1,8.25,13.393Z"
      data-name="Path 2223"
      fill="#536121"
      id="Path_2223"
      transform="translate(-2.25 -2.25)"
    />
    <rect
      data-name="Rectangle 478"
      fill="#536121"
      height="1.2"
      id="Rectangle_478"
      transform="translate(3.43 5.4)"
      width="5.14"
    />
  </svg>
);

export default RadioNested;
