import { useConfiguration } from '@arvesta-websites/configuration';
import { ThemeConsumer } from 'styled-components';

import themes from '../../../themes';
import Cta from '../Cta';
import { withErrorBoundary } from '../ErrorBoundary';

import StyledLogo from './Styled';

type LogoProps = {
  siteName: string;
  variant?: string;
  defaultLocale?: { id?: string } | null | undefined;
  height?: string;
  className?: string;
  isMobile?: boolean;
};

const Logo = ({ siteName, variant, defaultLocale = { id: '' }, height, className, isMobile }: LogoProps) => {
  const config = useConfiguration();

  return (
    <ThemeConsumer>
      {theme => (
        <StyledLogo variant={variant} className={className} height={height}>
          <Cta to="/" aria-current="page" title={siteName} className={'opacity-100'} forceLocale>
            {typeof themes[theme?.name].logo === 'string' ? (
              <img src={themes[theme?.name].logo} alt="Logo" height={height ?? '84px'} />
            ) : config.header.mobileLogo && isMobile ? (
              themes[theme?.name]?.logoMobile()
            ) : (
              themes[theme?.name].logo()
            )}
          </Cta>
        </StyledLogo>
      )}
    </ThemeConsumer>
  );
};

export default withErrorBoundary(Logo, { componentName: 'Logo' });
