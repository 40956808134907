import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 850.39 574.43">
    <g id="Saddle">
      <path
        fill="#25002d"
        strokeWidth="0px"
        d="M425.22,574.43c32.07,0,65.56-8.89,93.78-26.68l96.81-61.02c30.47-19.21,63.21-34.55,97.47-45.69h0c43.42-14.11,91.46-21.3,137.11-21.3V0s-850.39,0-850.39,0v419.75c45.65,0,93.74,7.19,137.16,21.3h0c34.26,11.13,67,26.48,97.47,45.69l96.81,61.02c28.22,17.79,61.7,26.68,93.78,26.68Z"
      />
    </g>
    <g id="logo">
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M239.5,410.6v8.77s-5.14,0-5.14,0v-22.25s12.73,0,12.73,0v4.26s-7.59,0-7.59,0v4.96s7.6,0,7.6,0v4.26s-7.59,0-7.59,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M252.36,419.36v-22.25s14.61,0,14.61,0v4.26s-9.47,0-9.47,0v4.42s9.48,0,9.48,0v4.26s-9.47,0-9.47,0v5.05s9.48,0,9.48,0v4.26s-14.62,0-14.62,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M272.71,419.35v-22.25s14.61,0,14.61,0v4.26s-9.47,0-9.47,0v4.42s9.48,0,9.48,0v4.26s-9.47,0-9.47,0v5.05s9.48,0,9.48,0v4.26s-14.62,0-14.62,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M293.05,397.09h6.67c5.79,0,12.31,2.98,12.31,11.02,0,8.23-6.41,11.23-12.58,11.23h-6.39s-.01-22.25-.01-22.25ZM298.21,415.08h.29c4.77,0,8.07-1.82,8.07-6.68,0-4.61-2.71-7.06-7.35-7.05h-1.02v13.73Z"
      />
      <path fill="#fff" strokeWidth="0px" d="M317.64,419.33v-22.25s5.14,0,5.14,0v22.25s-5.14,0-5.14,0Z" />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M343.78,419.31l-9.42-12.9v12.91s-5.14,0-5.14,0v-22.25s4.53,0,4.53,0l9.51,12.96v-12.97s5.14,0,5.14,0v22.25s-4.63,0-4.63,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M365.4,410.52v-4.26s10.36,0,10.36,0v8.77c-2.41,3.27-6,4.64-9.91,4.65-6.71,0-11.83-4.7-11.83-11.5,0-6.48,5.27-11.51,11.79-11.51,3.91,0,7.09,1.55,9.67,4.54l-3.56,3.12c-1.53-1.84-3.56-3.21-6.07-3.21-3.69,0-6.36,3.15-6.35,7.06,0,4.1,2.45,7.06,6.33,7.05,1.62,0,3.47-.7,4.8-1.97v-2.73s-5.22,0-5.22,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M396.6,419.28v-9.25s-7.04-13-7.04-13h6.33s3.47,7.5,3.47,7.5h.06l3.87-7.5h5.98s-7.52,13.19-7.52,13.19v9.06s-5.15,0-5.15,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M423.53,419.65c-6.64,0-11.73-5.14-11.74-11.5,0-6.36,5.08-11.51,11.72-11.51,6.64,0,11.73,5.14,11.74,11.5,0,6.36-5.08,11.51-11.72,11.52ZM423.52,401.08c-3.62,0-6.26,3.12-6.26,7.06,0,3.94,2.64,7.06,6.27,7.05,3.62,0,6.26-3.12,6.26-7.06,0-3.94-2.64-7.06-6.27-7.05Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M449.9,419.63c-6.33,0-9.03-3.87-9.03-10.87v-11.76s5.14,0,5.14,0v12.3c0,3.97,1.54,5.88,3.95,5.88s3.94-1.91,3.94-5.88v-12.3s5.14,0,5.14,0v11.76c.01,6.99-2.69,10.87-9.14,10.88Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M465.47,396.99h7.25c3.66,0,8.33,1.87,8.33,6.76,0,3.34-2.19,5.25-4.61,6.2,1.97,2.73,5.22,7.25,6.71,9.28h-6.42s-5.89-8.67-5.89-8.67h-.22v8.68s-5.14,0-5.14,0v-22.25ZM470.62,406.3h1.37c2.07,0,3.59-.73,3.59-2.45,0-1.56-1.21-2.61-2.99-2.61h-1.97s0,5.06,0,5.06Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M497.65,396.97h6.67c5.79,0,12.31,2.98,12.31,11.02,0,8.23-6.41,11.23-12.58,11.23h-6.39s-.01-22.25-.01-22.25ZM502.81,414.96h.29c4.77,0,8.07-1.82,8.07-6.68,0-4.61-2.71-7.06-7.35-7.05h-1.02s0,13.74,0,13.74Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M522.23,396.96h7.25c3.66,0,8.33,1.87,8.33,6.76,0,3.34-2.19,5.25-4.61,6.2,1.97,2.73,5.22,7.25,6.71,9.28h-6.42s-5.89-8.67-5.89-8.67h-.22v8.68s-5.14,0-5.14,0v-22.25ZM527.38,406.27h1.37c2.07,0,3.59-.73,3.59-2.45,0-1.56-1.21-2.61-2.99-2.61h-1.97s0,5.06,0,5.06Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M544.57,419.2v-22.25s14.61,0,14.61,0v4.26s-9.47,0-9.47,0v4.42s9.48,0,9.48,0v4.26s-9.47,0-9.47,0v5.05s9.48,0,9.48,0v4.26s-14.62,0-14.62,0Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M579.22,419.18l-1.31-3.91h-7.34s-1.4,3.91-1.4,3.91h-5.69s9.24-22.25,9.24-22.25h4.1s8.31,22.25,8.31,22.25h-5.91ZM572.06,411.17h4.45s-2.13-6.42-2.13-6.42h-.03l-2.29,6.42Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M604.73,419.16h-3.69s-6.08-15.38-6.08-15.38v15.39s-5.14,0-5.14,0v-22.25s7.42,0,7.42,0l5.7,14.81,5.9-14.82h7.25s.01,22.25.01,22.25h-5.15s0-15.26,0-15.26l-6.22,15.26Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M71.64,365.19l-.04-71.28,46.84-.03v13.65s-30.34.02-30.34.02v14.15s30.35-.02,30.35-.02v13.64s-30.34.02-30.34.02v16.19s30.35-.02,30.35-.02v13.64s-46.83.03-46.83.03Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M195.27,372.45l-10.19-10.08c-5.09,2.55-10.89,3.98-17.1,3.98-21.28.01-37.58-16.47-37.6-36.84-.01-20.37,16.27-36.87,37.55-36.88,21.28-.01,37.58,16.47,37.6,36.84,0,9.47-3.55,18.02-9.35,24.54l8.76,8.75-9.67,9.68ZM184.56,342.52c2.13-3.67,3.46-8.15,3.45-13.04,0-12.63-8.47-22.6-20.07-22.6-11.61,0-20.06,9.99-20.05,22.62,0,12.63,8.46,22.6,20.07,22.59,2.04,0,4.07-.31,5.91-.92l-8.15-8.14,9.67-9.68,9.17,9.16Z"
      />
      <path
        fill="#fff"
        strokeWidth="0px"
        d="M248.63,366.31c-20.26.01-28.93-12.41-28.94-34.81l-.02-37.68h16.5s.02,39.4.02,39.4c0,12.73,4.9,18.84,12.64,18.83,7.74,0,12.62-6.12,12.62-18.85l-.02-39.41h16.5s.02,37.67.02,37.67c.01,22.4-8.64,34.83-29.31,34.84Z"
      />
      <path fill="#fff" strokeWidth="0px" d="M294.76,365.06l-.04-71.28h16.5s.04,71.27.04,71.27h-16.5Z" />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M328.06,365.05l-.04-71.28h16.5s.03,57.63.03,57.63l28.51-.02v13.64s-45,.03-45,.03Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M433.45,364.99l-4.18-12.52h-23.52s-4.47,12.54-4.47,12.54h-18.23s29.59-71.29,29.59-71.29h13.14s26.62,71.26,26.62,71.26h-18.94ZM410.53,339.34h14.26s-6.83-20.57-6.83-20.57h-.1l-7.32,20.57Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M509.73,364.94l-30.16-41.33.02,41.34h-16.5s-.04-71.27-.04-71.27h14.56s30.47,41.52,30.47,41.52l-.02-41.55h16.5s.04,71.27.04,71.27h-14.87Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M588.04,364.9l-30.16-41.32.02,41.34h-16.5s-.04-71.27-.04-71.27h14.56s30.47,41.52,30.47,41.52l-.02-41.55h16.5s.04,71.27.04,71.27h-14.87Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M654.64,366.08c-21.28.01-37.58-16.48-37.6-36.84-.01-20.37,16.27-36.87,37.55-36.88,21.28-.01,37.58,16.47,37.6,36.84.01,20.37-16.27,36.87-37.55,36.88ZM654.6,306.61c-11.61,0-20.06,9.99-20.05,22.62,0,12.63,8.46,22.6,20.07,22.59,11.61,0,20.05-9.99,20.05-22.62,0-12.63-8.47-22.6-20.07-22.59Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M741.3,366.03c-21.28.01-37.58-16.48-37.6-36.84-.01-20.37,16.27-36.87,37.55-36.88,21.28-.01,37.59,16.47,37.6,36.84.01,20.37-16.27,36.87-37.55,36.88ZM741.26,306.56c-11.61,0-20.05,9.99-20.05,22.62,0,12.63,8.46,22.6,20.07,22.59,11.61,0,20.06-9.99,20.05-22.62,0-12.63-8.47-22.6-20.07-22.59Z"
      />
      <path
        fill="#bd8336"
        strokeWidth="0px"
        d="M541.89,163.14c-.14,5.63-2.84,11-7.41,14.73l-2.18,1.77c-3.59,2.93-8.01,4.45-12.48,4.45-3.07,0-6.15-.71-9-2.17l-.4-.22-28.59-17.1c-5.88.71-19.51,1.26-30.04-6.6-3.88-2.9-6.9-6.56-9.07-10.96l12.06-7.4c1.24,2.97,3.01,5.34,5.39,7.12,8.36,6.24,21.52,3.69,21.65,3.67l2.69-.57,32.87,19.66c1.99.94,4.35.66,6.06-.74l2.18-1.77c1.41-1.15,2.22-2.65,2.26-4.21.04-1.56-.69-3.1-2.06-4.33-7.69-6.94-14.29-15.22-20.67-23.22-5.55-6.96-10.79-13.53-16.26-18.64l-.54-.51-1.24-1.77c-1.72-2.47-2.63-5.36-2.63-8.37v-15.13c-3.25,1.75-6.16,4.41-8.52,7.83-2.39,3.47-5.88,5.3-9.91,5.11-1.32-.06-32.44-1.16-59.22,24.28-34.83,33.08-56.13,36.13-87.98,30.33-25.98-4.74-39.98-6.59-49.38-4.98l22.09-13.54c8.2.96,17.94,2.57,29.81,4.73,24.9,4.54,42.42,5,75.81-26.71,28.03-26.62,59.85-28.24,67.94-28.16,6.24-8.54,14.96-13.87,24.67-15.08l8.72-1.09v32.4c0,.12.04.24.11.34l.4.57c6.02,5.72,11.66,12.79,17.11,19.63,6.03,7.56,12.27,15.39,19.1,21.56,4.38,3.96,6.82,9.46,6.68,15.09ZM388.97,180.56h25.22v22.82h-62.43l-22.86,14.02h85.29v22.82h-122.5l-22.86,14.02h159.38v-87.7h-16.38l-22.86,14.02ZM558.76,240.22h-106.21v-65.13l-14.02-8.6v87.75h143.09l-22.86-14.02Z"
      />
    </g>
  </svg>
);

export default Logo;
