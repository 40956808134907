import { BoxProps, CardProps } from 'rebass';

import { Image } from '../../../../types';
import { withErrorBoundary } from '../../ErrorBoundary';

import HorizontalNewsCard from './HorizontalNewsCard';
import { StyledCard } from './Styled';
import VerticalNewsCard from './VerticalNewsCard';

export interface NewsCardProps extends CardProps {
  /** product id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: Image;
  /** card cta */
  link: string;
  /** event display date */
  date?: string;
  /** Card variant, vertical or horizontal */
  variant?: 'vertical' | 'horizontal';
}

const NewsCard = ({ id, title, shortDescription, image, link, date, variant = 'vertical', ...rest }: NewsCardProps) => {
  const cardProps = {
    date,
    id,
    image,
    link,
    shortDescription,
    title,
    variant,
  };

  return (
    <StyledCard {...(rest as BoxProps)} className={variant}>
      {variant === 'vertical' ? <VerticalNewsCard {...cardProps} /> : <HorizontalNewsCard {...cardProps} />}
    </StyledCard>
  );
};

export default withErrorBoundary(NewsCard, { componentName: 'NewsCard' });
