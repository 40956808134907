import { useConfiguration } from '@arvesta-websites/configuration';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';

import { Button, Cta } from '../../../components';
/**
 * Do not merge the errorboundary import, else webpack will think it is undefined
 * Could be because it is also imported by button, cta but unclear...most often that seems to be a circular dependency thing, but i'm not seeing the circle
 * @Todo: further investigate
 * */
import { withErrorBoundary } from '../../../components/ErrorBoundary';
import { StyledModuleContainer } from '../../../shared/Styled';
import type { CommonProps } from '../CommonProps';

import {
  StyledImage,
  StyledTextBox,
  StyledTextBoxDescription,
  StyledTextBoxTitle,
  StyledTextBoxWrapper,
} from './Styled';

export interface CtaFocusImageModuleProps extends CommonProps {
  /** optional module short description */
  shortDescription?: string;
}

const CtaFocusImageModule = ({
  title,
  shortDescription,
  cta,
  orientation = 'left',
  image,
  ...rest
}: CtaFocusImageModuleProps) => {
  const config = useConfiguration();
  return (
    <StyledModuleContainer {...rest}>
      <StyledTextBoxWrapper>
        {image?.asset?.gatsbyImageData && (
          <StyledImage>
            <GatsbyImage
              image={image?.imageWithFocalPoint}
              alt={image?.alt ? image.alt : title}
              loading="eager"
              style={{ height: '100%', width: '100%' }}
            />
          </StyledImage>
        )}
        <StyledTextBox className={orientation}>
          <StyledTextBoxTitle>{title}</StyledTextBoxTitle>

          {shortDescription && <StyledTextBoxDescription>{shortDescription}</StyledTextBoxDescription>}

          {cta && (
            <Box>
              <Cta to={cta.to}>
                <Button variant={config.button?.ctaBanner?.image ?? 'primary-with-background'}>{cta.label}</Button>
              </Cta>
            </Box>
          )}
        </StyledTextBox>
      </StyledTextBoxWrapper>
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(CtaFocusImageModule, { componentName: 'CtaBannerModule/CtaFocusImageModule' });
