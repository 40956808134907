import { ThemeConsumer } from 'styled-components';

import themes from '../../../themes';
import Cta from '../Cta';
import { withErrorBoundary } from '../ErrorBoundary';

import StyledLogo from './Styled';

type LogoProps = {
  siteName: string;
  variant?: string;
  defaultLocale?: { id?: string } | null | undefined;
  height?: string;
  className?: string;
};

const FooterLogo = ({ siteName, variant, defaultLocale = { id: '' }, height, className }: LogoProps) => {
  return (
    <ThemeConsumer>
      {theme => (
        <StyledLogo variant={variant} className={className} height={height}>
          <Cta to="/" aria-current="page" title={siteName} className={'opacity-100'} forceLocale>
            {typeof themes[theme?.name].footerLogo === 'string' ? (
              <img src={themes[theme?.name].footerLogo} alt="Logo" height={height ?? '84px'} />
            ) : themes[theme?.name]?.footerLogo ? (
              themes[theme?.name]?.footerLogo()
            ) : null}
          </Cta>
        </StyledLogo>
      )}
    </ThemeConsumer>
  );
};

export default withErrorBoundary(FooterLogo, { componentName: 'FooterLogo' });
