/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

// BASICS
export const baseColors = {
  black: '#000',
  darkGreen: '#006C3D',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#989796',
    '#3d3d3d',
  ],
  green: '#017945',
  red: '#f00101',
  white: '#fff',
  yellow: '#ffcc32',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  danger: baseColors.red,
  dark: baseColors.gray[4],
  darkGray: baseColors.gray[4],
  info: baseColors.green,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.green,
  primaryWhite: baseColors.white,
  secondary: baseColors.gray[3],
  tertiary: baseColors.gray[1],
  success: baseColors.green,
  superLightGrey: baseColors.gray[1],
  text: baseColors.gray[4],
  textMuted: baseColors.gray[3],
  warning: baseColors.yellow,
};

export const fonts = {
  body: 'Lato, sans-serif',
  heading: 'inherit',
  monospace: 'Menlo, monospace',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '.75rem', // 12px (80%),
  1: '.875rem',
  2: '1rem', // 16px
  3: '1.125rem',
  4: '1.25rem',
  5: '1.625rem',
  6: '1.875rem',
  7: '2rem',
  8: '2.1875rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.8125rem',
  12: '3.125rem',
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '1150px',

  md: '720px',
  // container widths
  sm: '540px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  lg: '0.3rem',
  pill: '50rem',
  sm: '0.2rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  a: {
    '&:hover': {
      color: colors.secondary,
    },
    color: colors.primary,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.green,
    color: colors.white,
    fontStyle: 'italic',
    fontWeight: 'normal',
    textTransform: 'normal',
  },
  h1: {
    ...heading,
    fontSize: { desktop: fontSizes[12], mobile: fontSizes[9], tablet: fontSizes[11] },
    fontWeight: 700,
    lineHeight: { desktop: '4.125rem', mobile: '2.5rem', tablet: '3.5rem' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: fontSizes[10], mobile: fontSizes[7], tablet: fontSizes[8] },
    fontWeight: 200,
    lineHeight: { desktop: '2.6875rem', mobile: '2.25rem', tablet: '2.375rem' },
  },
  h3: {
    ...heading,
    fontSize: { desktop: fontSizes[6], mobile: fontSizes[4], tablet: fontSizes[5] },
    fontWeight: 700,
    lineHeight: { desktop: '2.1875rem', mobile: '1.625rem', tablet: '1.875rem' },
  },
  h4: {
    ...heading,
    fontSize: { desktop: fontSizes[4], mobile: fontSizes[3], tablet: fontSizes[3] },
    fontWeight: 700,
    lineHeight: { desktop: '1.5rem', mobile: '1.375rem', tablet: '1.375rem' },
  },
  h5: {
    ...heading,
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[2], tablet: fontSizes[2] },
    fontWeight: 400,
    lineHeight: { desktop: '1.25rem', mobile: '1.25rem', tablet: '1.25rem' },
  },
  highlight: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.darkGray,
  },
  li: {
    bulletBorderRadius: '0',
    bulletColor: colors.green,
  },
  p: {
    fontSize: fontSizes[3],
    lineHeight: '1.75rem',
    margin: '0',
    marginBottom: '1.7rem',
  },
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  rteHighlight: {
    hoverColor: colors.secondary,
    hoverFooter: colors.secondary,
    color: colors.primary,
    fontWeight: '600',
    textDecoration: 'underline',
    isFooter: colors.white,
  },
  table: {
    borderCollapse: 'collapse',

    color: colors.gray[3],

    marginBottom: '3rem',
    // todo
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '16px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: '1.125rem',
  fontWeight: fontWeights.bold,
  height: '3.625rem',
  minWidth: '194px',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.darkGreen,
      color: colors.white,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
  },
  'primary-with-background': {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.gray[1],
      borderColor: colors.gray[1],
      borderWidth: '1px',
      color: colors.primary,
    },
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
  },
  secondary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.darkGreen,
      color: colors.white,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
  },
  tertiary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
    backgroundColor: colors.white,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.green,
  },
};

export const adviceCardModule = {
  backgroundColor: colors.primary,
};

export const articleDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  title: colors.primary,
};

export const articleListing = {
  background: colors.primary,
  filter: colors.text,
  hover: colors.secondary,
  mobile: {
    background: colors.tertiary,
    title: colors.primary,
    text: colors.text,
  },
  title: colors.primary,
  text: colors.white,
};

export const breadcrumb = {
  backgroundColor: colors.tertiary,
};

export const cardCtas = {
  arrowColor: colors.green,
  arrowHoverColor: colors.darkGreen,
  color: colors.green,
  fontSize: '0.75rem',
  hoverColor: colors.darkGreen,
  letterSpacing: '2px',
  lineHeight: '13px',
  textTransform: 'uppercase',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      '&:hover': {
        color: colors.secondary,
        opacity: 1,
        textDecoration: 'underline',
      },
      color: colors.primary,
    },
    title: colors.primary,
  },
  logo: {
    color: colors.text,
  },
};

export const carousel = {
  controls: {
    hero: {
      backgroundColor: colors.white,
      color: colors.primary,
      stroke: colors.white,
      opacity: '0.8',
      hover: {
        backgroundColor: colors.white,
        color: colors.primary,
        stroke: colors.white,
      },
    },
    regular: {
      backgroundColor: colors.primary,
      color: colors.white,
      hover: {
        color: colors.white,
        backgroundColor: colors.darkGreen,
      },
    },
  },
  indicator: colors.primary,
  link: {
    '&:hover': {
      color: colors.secondary,
      opacity: 1,
      textDecoration: 'underline',
    },
    color: colors.primary,
  },
  text: colors.text,
};

export const categoryCards = {
  backgroundColor: colors.green,
};

export const categorySection = {
  background: colors.primary,
  boxShadow: null,
  chevron: {
    fill: colors.tertiary,
    stroke: colors.primary,
  },
  color: colors.white,
  date: colors.primary,
  dateColor: colors.white,
  headingColor: colors.primary,
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.primary,
  infoBlock: {
    background: colors.tertiary,
    button: {
      ...buttonDefaults,
      '&:hover': {
        backgroundColor: colors.secondary,
        textDecoration: 'none',
      },
      backgroundColor: colors.primary,
      color: colors.tertiary,
    },
    description: colors.text,
    title: colors.primary,
  },
};

export const contactForm = {
  heading: {
    color: colors.primary,
  },
};

export const contacts = {
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '0.875rem',
    mapTextFontWeight: 'normal',
    mapTextLetterSpacing: '2.33px',
    mapTextLineHeight: '1.71',
    mapTextTransform: 'uppercase',
    openingHoursFontSize: '1rem',
  },
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.green,
    color: colors.white,
    titleColor: colors.white,
  },
  focusText: {
    backgroundColor: colors.tertiary,
    color: colors.text,
  },
};

export const ctaGroup = {
  chevron: {
    opacity: 1,
  },
  title: colors.primary,
};

export const customBlockCards = {
  backgroundColor: colors.green,
};

export const dealer = {
  textColor: colors.primary,
};

export const documentDownload = {
  arrowColor: colors.green,
  arrowHoverColor: colors.darkGreen,
  color: colors.green,
  hoverColor: colors.darkGreen,
};

export const footer = {
  activeColor: colors.secondary,
  backgroundColor: colors.white,
  color: colors.white,
  minHeight: '160px',
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '10px',
  radioInputBorderRadius: '8px',
  radioSpanBorderRadius: '6px',
};

export const header = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.darkGray,
};

export const headerBottom = {
  activeColor: colors.white,
  activeFontWeight: 'bold',
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: '12px',
  fontWeight: 'normal',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: colors.darkGreen,
      borderColor: colors.darkGreen,
      color: colors.white,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderRadius: '16px',
    borderWidth: '1px',
    color: colors.white,
    justifyContent: 'center',
    minHeight: '42px',
    padding: '13px 35px 12px',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: colors.darkGreen,
      borderColor: colors.darkGreen,
      color: colors.white,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderRadius: '16px',
    borderWidth: '1px',
    color: colors.white,
    justifyContent: 'center',
    minHeight: '42px',
    padding: '13px 35px 12px',
  },
  arrow: {
    display: 'none',
  },
};

export const heroSection = {
  titleFontWeight: 'normal',
};

export const imageAndText = {
  backgroundColor: colors.primary,
  color: colors.primary,
};

export const infoBlock = {
  background: colors.tertiary,
  button: {
    '&:hover': {
      backgroundColor: colors.secondary,
      textDecoration: 'none',
    },
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  description: colors.text,
  title: colors.primary,
};

export const infoSection = {
  backgroundColor: colors.green,
  color: colors.white,
  title: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.secondary,
};

export const media = {
  titleFontWeight: 'normal',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.green,
  chevronColor: colors.green,
};

export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '2px',
};

export const newsListing = {
  titleFontWeight: 'normal',
};

export const ownerDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.tertiary,
  },
  title: colors.primary,
};

export const product = {
  backgroundColor: colors.secondary,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'normal',
};

export const salesPerson = {
  borderColor: colors.primary,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '6px',
};

export const shortBanner = {
  backgroundColor: colors.green,
  color: colors.white,
  titleFontWeight: 'normal',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.secondary,
};

export const upComingEvents = {
  backgroundColor: colors.green,
  color: colors.white,
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Hermoo',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
