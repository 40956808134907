export const getDestination = (link: any) => {
  if (!link) return '/';

  const { slug, cta } = link;

  if (slug) {
    return slug === 'homepage' ? '/' : slug;

    return {
      id: item.cta.id,
      label: item.cta.title,
      to,
    };
  }

  return cta?.externalUrl;
};

export const createLink = (href: string, locale: string) => `/${locale}/${href}`.replace('//', '/');
