import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.29 21.42" width="180px">
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M4,8.4l2-.08V21l-2-.08V25q3.43-.09,5.16-.09t5.07.09V20.91L12.44,21V16.2h5.3V21l-1.83-.08V25c2.26-.06,3.92-.09,5-.09s2.9,0,5.19.09V20.91l-2,.08V8.32l2,.08v-4c-2.38,0-4.19.06-5.39.06s-2.72,0-4.9-.06v4l1.86-.08v4.07h-5.3V8.32l1.86.08v-4c-2.24,0-3.87.06-5,.06s-2.95,0-5.3-.06Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M37.38,20.91A3.79,3.79,0,0,1,35,22a1.73,1.73,0,0,1-1.77-1.78,1.83,1.83,0,0,1,1.89-1.86,4.12,4.12,0,0,1,2.29.77Zm0-4.73A9.46,9.46,0,0,0,35,15.52a13.06,13.06,0,0,0-1.49-.09c-3.61,0-6.13,2-6.13,5s2.23,4.85,5.42,4.85a7.39,7.39,0,0,0,3.55-.92l1-.6V25c1.78-.06,3.13-.06,4-.06s2.2,0,3.92.06v-4.1l-1.95.06V15.06c0-2.15-.51-3.39-1.8-4.25a9.67,9.67,0,0,0-5.88-1.49,11.09,11.09,0,0,0-5.73,1.26c-1.35.75-1.84,1.44-2.47,3.24l4.91.46A2.48,2.48,0,0,1,35,12.65a2.19,2.19,0,0,1,2.43,2.44Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M46.27,25c2.41-.06,4.25-.09,5.45-.09s3,0,5.42.09v-4.1l-2.75.06v-7.2l2.75.06V9.67l-2.73.05V9.27c0-1,.49-1.61,1.27-1.61a2.21,2.21,0,0,1,.6.08,3.19,3.19,0,0,1,.51.14l.63.37.26.17,1.78-3.17a6.67,6.67,0,0,0-2.21-1,9.27,9.27,0,0,0-2.46-.31c-4.07,0-6.31,1.89-6.34,5.31l0,.48-2.15-.05v4.1l2.15-.06v7.23l-2.15-.06Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M58,25c2.29-.06,4-.09,5.13-.09s2.72,0,4.9.09v-4.1l-2,.06V15.06a3.88,3.88,0,0,1,2.23-1,1.48,1.48,0,0,1,1.18.55c.34.43.34.51.34,1.83V25c1.69-.06,3-.06,3.81-.06s2.3,0,4.1.06v-4.1l-1.95.06V15.37c0-2.55-.17-3.38-1.06-4.44a4.75,4.75,0,0,0-3.75-1.58,6.87,6.87,0,0,0-3.61,1,15.16,15.16,0,0,0-1.29.86V9.69c-1.75,0-3,.06-3.87.06s-2.27,0-4.1-.06v4.13l2-.05v7.17L58,20.88Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M87.41,13.14c1.52,0,2.4,1,2.55,2.69H84.68A2.68,2.68,0,0,1,87.41,13.14Zm8.37,5.36c0-3.44-.43-4.93-2-6.57a8.52,8.52,0,0,0-6.34-2.58c-5,0-8.65,3.36-8.65,8.09s3.49,7.91,8.71,7.91A9.29,9.29,0,0,0,93,23.75a7.32,7.32,0,0,0,2.4-2.81l-4.9-1.21a3,3,0,0,1-2.87,1.72,2.62,2.62,0,0,1-2.26-1,3,3,0,0,1-.72-1.95Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M97,25c2.35-.06,4.16-.09,5.33-.09s2.93,0,5.25.09v-4.1l-2.5.06V19.62c0-2.78,1.78-4.65,4.39-4.65a11,11,0,0,1,1.23.09V9.46c-2,0-2.78.23-3.9,1.07a7.09,7.09,0,0,0-2.09,2.69V9.69c-1.66,0-2.92.06-3.78.06s-2.18,0-3.93-.06v4.13l2.15-.05v7.17L97,20.88Z"
      transform="translate(-4.01 -3.93)"
    />
    <rect fill="#1f92cc" x="108.37" y="10.12" width="9.8" height="5.56" />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M123.67,25c2.44-.06,4.28-.09,5.48-.09s3,0,5.36.09V20.91l-2.38.08V16.35l4,.06V12.68l-4,0V8.4h5v2.76h4.24c0-1.49,0-2.64,0-3.39s0-1.89,0-3.41c-3.9,0-6.82.06-8.77.06s-5,0-8.89-.06V8.43l2-.11V21l-2-.08Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M141.94,13.8l2,0v5.7c0,2.1.23,3.1,1,4.11a4.53,4.53,0,0,0,3.7,1.74,7.47,7.47,0,0,0,3.81-1.11,11.53,11.53,0,0,0,1.15-.81V25c1.81,0,3.13-.06,4-.06s2.21,0,4,.06v-4.1l-2,.06V9.69c-1.75,0-3,.06-3.9.06s-2.23,0-4-.06v4.13l1.92-.05v5.88a3.36,3.36,0,0,1-2.21.91c-1.11,0-1.52-.54-1.52-2.09V9.69c-1.72,0-3,.06-3.92.06s-2.24,0-4-.06Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M162.47,13.8l1.72,0v6.74c0,2,.32,2.89,1.23,3.67A5.15,5.15,0,0,0,169,25.3a7.51,7.51,0,0,0,3.87-1V20.08a2.87,2.87,0,0,1-1.52.54,1,1,0,0,1-.92-.4c-.2-.29-.23-.55-.23-1.53v-5l2.7.06V9.67l-2.7,0V5.19l-6,1.44V9.69l-1.72,0Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M173.89,13.8l1.72,0v6.74c0,2,.31,2.89,1.23,3.67a5.14,5.14,0,0,0,3.58,1.12,7.48,7.48,0,0,0,3.87-1V20.08a2.85,2.85,0,0,1-1.52.54,1,1,0,0,1-.91-.4c-.2-.29-.23-.55-.23-1.53v-5l2.69.06V9.67l-2.69,0V5.19l-6,1.44V9.69l-1.72,0Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M194,13.14c1.52,0,2.41,1,2.55,2.69h-5.27A2.67,2.67,0,0,1,194,13.14Zm8.37,5.36c0-3.44-.43-4.93-2-6.57A8.5,8.5,0,0,0,194,9.35c-5.05,0-8.66,3.36-8.66,8.09s3.5,7.91,8.72,7.91a9.25,9.25,0,0,0,5.53-1.6A7.25,7.25,0,0,0,202,20.94l-4.9-1.21a3,3,0,0,1-2.87,1.72,2.64,2.64,0,0,1-2.27-1,3,3,0,0,1-.71-1.95Z"
      transform="translate(-4.01 -3.93)"
    />
    <path
      fill="#1f92cc"
      fillRule="evenodd"
      d="M203.59,25c2.35-.06,4.16-.09,5.34-.09s2.92,0,5.24.09v-4.1l-2.49.06V19.62c0-2.78,1.78-4.65,4.38-4.65a11.19,11.19,0,0,1,1.24.09V9.46c-2,0-2.78.23-3.9,1.07a7.09,7.09,0,0,0-2.09,2.69V9.69c-1.67,0-2.93.06-3.79.06s-2.18,0-3.93-.06v4.13l2.15-.05v7.17l-2.15-.06Z"
      transform="translate(-4.01 -3.93)"
    />
  </svg>
);

export default Logo;
