import { darken } from 'polished';
import { Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

export const StyledOffice = styled.div`
  padding-right: 1rem;
  ${Media({
    width: ['100%', null, '50%'],
  })}

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledOfficeTitle = styled(Text)`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.5rem;
  font-weight: ${props => props.theme.fontWeights.headingAlt};
`;

export const StyledOfficeAddress = styled(Text)`
  font-size: 1rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 1.5rem;
  line-height: 1.75;
`;

export const StyledMapText = styled.div`
  text-transform: ${props => props.theme.contacts.office.mapTextTransform};
  font-size: ${props => props.theme.contacts.office.mapTextFontSize};
  letter-spacing: ${props => props.theme.contacts.office.mapTextLetterSpacing};
  line-height: ${props => props.theme.contacts.office.mapTextLineHeight};
  color: ${props => props.theme.contacts.office.mapTextColor};
  font-weight: ${props => props.theme.contacts.office.mapTextFontWeight};
`;

export const StyledMapButtonContainer = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1.5rem;
  text-decoration: none;

  &:hover {
    ${StyledMapText} {
      color: ${props => darken(0.1, props.theme.dealer.textColor)};
    }

    svg {
      fill: ${props => darken(0.1, props.theme.dealer.textColor)};

      > * {
        fill: ${props => darken(0.1, props.theme.dealer.textColor)};
      }
    }
  }
`;

export const StyledMapIcon = styled.div`
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    fill: ${props => props.theme.dealer.textColor};

    > * {
      fill: ${props => props.theme.dealer.textColor};
    }
  }
`;

export const StyledTelephoneNumber = styled.div`
  font-size: 1rem;
  line-height: 1.71;
  color: ${props => props.theme.colors.text};
  ${Media({
    marginBottom: ['20px', '2rem'],
  })}
`;

export const StyledOpeningHoursTitle = styled(Text)`
  font-size: ${props => props.theme.contacts.office.openingHoursFontSize};
  line-height: 1.75;
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-bottom: 8px;
`;

export const StyledOpeningHoursText = styled(Text)`
  font-size: 1rem;
  color: ${props => props.theme.colors.text};
  line-height: 1.75;
  ${Media({
    marginBottom: ['20px', null, 0],
  })}
`;
