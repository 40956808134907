import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { HEADERLIGHT_VARIANTS } from '../Header/HeaderLight/HeaderLight';

const StyledLogo = styled(Box)`
  ${props =>
    Media({
      marginRight: [
        0,
        0,
        0,
        props.variant === HEADERLIGHT_VARIANTS.secondary ? '60px' : 0,
        props.variant === HEADERLIGHT_VARIANTS.secondary ? '60px' : 0,
      ],
      transform: ['scale(0.8)', null, null, 'scale(1)'],
    })}

  a, svg {
    height: ${props => props.height ?? '76px'};
    width: auto;
    display: block;
    max-width: 100%;
  }
`;

export default StyledLogo;
