import React from 'react';

const FlagFr = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-fr" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-fr" fill="#fff">
        <use xlinkHref="#a-fr" />
      </mask>
      <rect width="33.1" height="23.5" x="0.25" y="0.25" stroke="#F5F5F5" strokeWidth="0.5" rx="2" />
      <path fill="#F44653" d="M22.4 0h11.2v24H22.4z" mask="url(#b-fr)" />
      <path fill="#1035BB" d="M0 0h11.2v24H0z" mask="url(#b-fr)" />
    </g>
  </svg>
);

export default FlagFr;
