import { useEffect, useState } from 'react';

import breakpoints from '../breakpoints';

const getMedia = (index: number) => {
  const minWidth = index === 0 ? '(min-width: 0px)' : `(min-width: ${breakpoints[index]}px)`;
  const maxWidth = index < breakpoints.length - 1 ? `(max-width: ${breakpoints[index + 1] - 1}px)` : '';

  if (maxWidth) {
    return `${minWidth} and ${maxWidth}`;
  }

  return minWidth;
};

export const useCurrentBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(breakpoints[breakpoints.length - 1]);

  useEffect(() => {
    const mediaQueryLists = breakpoints.map((breakpoint, index) => {
      const handler = (e: any) => {
        if (e.matches) {
          setCurrentBreakpoint(breakpoint);
        }
      };

      const media = getMedia(index);
      const mql = window.matchMedia(media);

      mql.addListener(handler);

      if (mql.matches) {
        setCurrentBreakpoint(breakpoint);
      }

      return {
        mql,
        handler,
      };
    });

    return () => {
      mediaQueryLists.forEach(({ mql, handler }) => {
        mql.removeListener(handler);
      });
    };
  }, []);

  return currentBreakpoint;
};

export default useCurrentBreakpoint;
