import { IGatsbyImageData } from 'gatsby-plugin-image';

import { HeadingTag, withErrorBoundary } from '../../components';

import {
  StyledActionsWrapper,
  StyledComponentWrapper,
  StyledContent,
  StyledDesktopBanner,
  StyledGatsbyImage,
  StyledImageWrapper,
  StyledInnerButton,
  StyledItem,
  StyledMobileBanners,
  StyledTitle,
} from './Styled';

interface Image {
  title?: string;
  altText?: string;
  imageWithFocalPoint: IGatsbyImageData;
}

interface ContentfulCTA {
  item?: {
    internalEntry?: { slug?: string };
    id: string;
    title?: string;
    externalUrl?: string;
  };
}

interface BannerContent {
  id: string;
  image?: Image;
  title?: string;
  titleTag: HeadingTag;
  callToAction?: ContentfulCTA;
  alignContent: string;
}

export interface HeroDuoBannerProps {
  key: string;
  bannerContent: any;
  desktopImage: Image;
}

const isAlignedContent = (alignContent: string) => {
  if (alignContent === 'Top') {
    return 'flex-start';
  }
  if (alignContent === 'Bottom') {
    return 'flex-end';
  }
  if (alignContent === 'Center') {
    return 'center';
  }
};

const extractCta = (item: any) => {
  if (item?.internalEntry?.slug) {
    const to = item.internalEntry.slug === 'homepage' ? '/' : item.internalEntry.slug;

    return {
      id: item.id,
      label: item.title,
      to,
    };
  }

  if (item?.externalUrl) {
    return {
      id: item.id,
      label: item.title,
      to: item.externalUrl,
    };
  }

  return null;
};

const Cta = (item: { item?: ContentfulCTA }) => {
  const extractedItem = extractCta(item?.item);

  if (!extractedItem) return null;

  return <StyledInnerButton to={extractedItem.to}>{extractedItem.label}</StyledInnerButton>;
};

const renderMobileBanner = ({ bannerContent }: Pick<HeroDuoBannerProps, 'bannerContent'>) => {
  return bannerContent.map((item: BannerContent) => {
    return (
      <StyledImageWrapper key={item.id + Math.random().toString()}>
        {item.image?.imageWithFocalPoint && (
          <StyledGatsbyImage
            alt={item.image?.altText ? item.image.altText : item.image?.title ? item.image.title : ''}
            image={item.image?.imageWithFocalPoint}
          />
        )}
        <StyledComponentWrapper>
          <StyledItem>
            {item.title && (
              <StyledTitle variant="h2" tag={bannerContent[0].titleTag}>
                {item.title}
              </StyledTitle>
            )}
            {item?.callToAction && (
              <StyledActionsWrapper>
                <Cta item={item?.callToAction} />
              </StyledActionsWrapper>
            )}
          </StyledItem>
        </StyledComponentWrapper>
      </StyledImageWrapper>
    );
  });
};

const HeroDuoBanner = ({ desktopImage, bannerContent }: HeroDuoBannerProps) => {
  return (
    <>
      <StyledMobileBanners>{renderMobileBanner({ bannerContent })}</StyledMobileBanners>
      <StyledDesktopBanner>
        <StyledImageWrapper>
          <StyledGatsbyImage
            alt={desktopImage?.altText ? desktopImage.altText : desktopImage.title ? desktopImage.title : ''}
            image={desktopImage?.imageWithFocalPoint}
          />
          <StyledComponentWrapper>
            {bannerContent?.length > 0 &&
              bannerContent.map((contentBlock: BannerContent, index: number) => (
                <StyledContent
                  alignContent={isAlignedContent(contentBlock.alignContent)}
                  alignBlock={index === 1 ? 'right' : 'left'}
                  key={`${contentBlock.title}-index`}
                >
                  {contentBlock.title && (
                    <StyledTitle variant="h2" tag="div">
                      {contentBlock.title}
                    </StyledTitle>
                  )}
                  {contentBlock.callToAction &&
                    (index === 1 ? (
                      <StyledActionsWrapper>
                        <Cta item={contentBlock?.callToAction} />
                      </StyledActionsWrapper>
                    ) : (
                      <StyledActionsWrapper alignLeft>
                        <Cta item={contentBlock?.callToAction} />
                      </StyledActionsWrapper>
                    ))}
                </StyledContent>
              ))}
          </StyledComponentWrapper>
        </StyledImageWrapper>
      </StyledDesktopBanner>
    </>
  );
};

export default withErrorBoundary(HeroDuoBanner, { componentName: 'HeroDuoBanner' });
