import React from 'react';

const FlagUk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-uk" width="34" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-uk" fill="#fff">
        <use xlinkHref="#a-uk" />
      </mask>
      <use fill="#FFF" xlinkHref="#a-uk" />
      <path fill="#0A17A7" d="M0 0h34v24H0z" mask="url(#b-uk)" />
      <path
        fill="#FFF"
        d="M36.178-2L38 .69 26.454 8.443l8.156.001v8.112h-8.155L38 24.31 36.178 27l-14.605-9.808v9.098h-8.147v-9.098L-1.178 27-3 24.31l11.545-7.754H.39V8.444l8.155-.001L-3 .69-1.178-2l14.604 9.807V-1.29h8.148l-.001 9.097L36.178-2z"
        mask="url(#b-uk)"
      />
      <path stroke="#DB1F35" strokeLinecap="round" strokeWidth="0.667" d="M23 8L38-2" mask="url(#b-uk)" />
      <path
        stroke="#DB1F35"
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M24 26l14-9"
        mask="url(#b-uk)"
        transform="matrix(1 0 0 -1 0 43)"
      />
      <path stroke="#DB1F35" strokeLinecap="round" strokeWidth="0.667" d="M10 8L-4-2" mask="url(#b-uk)" />
      <path
        stroke="#DB1F35"
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M11 27L-5 17"
        mask="url(#b-uk)"
        transform="matrix(1 0 0 -1 0 44)"
      />
      <path fill="#E6273E" d="M0 14.4h14.571V24h4.858v-9.6H34V9.6H19.429V0H14.57v9.6H0z" mask="url(#b-uk)" />
    </g>
  </svg>
);

export default FlagUk;
