/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

// BASICS
export const baseColors = {
  black: '#000',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#1A1F0A',
    '#F5F5F0',
  ],
  green: '#546223',
  lightGreen: '#84bd00',
  red: '#f00101',
  white: '#fff',
  yellow: '#ffcc32',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  danger: baseColors.red,
  dark: baseColors.gray[4],
  darkGray: baseColors.gray[4],
  info: baseColors.lightGreen,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.lightGreen,
  primaryWhite: baseColors.white,
  secondary: baseColors.green,
  success: baseColors.lightGreen,
  superLightGrey: baseColors.gray[1],
  tertiary: baseColors.gray[5],
  text: baseColors.gray[4],
  textMuted: baseColors.gray[3],
  warning: baseColors.yellow,
};

export const fonts = {
  body: "'Encode Sans', sans-serif",
  heading: 'inherit',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '12px',
  1: '14px',
  2: '16px',
  3: '20px',
  4: '24px',
  5: '28px',
  6: '32px',
  7: '40px',
  8: '56px',
  9: '72px',
  10: '88px',
  11: '96px',
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '71.875rem',
  md: '45rem',
  sm: '33.75rem',
  xl: '90rem',
};

export const radii = {
  default: '4px',
  pill: '800px',
  lg: '4.8px',
  sm: '3.2px',
};

export const shadows = {
  default: '0 8px 16px rgba(0, 0, 0, .15)',
  lg: '0 16px 48px rgba(0, 0, 0, .175)',
  sm: '0 2px 4px rgba(0, 0, 0, .075)',
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    color: colors.secondary,
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    textTransform: 'uppercase',
  },
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  color: text.heading.color,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const styles = {
  a: {
    '&:hover': {
      color: '#83A237',
    },
    color: colors.primary,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.primary,
    color: colors.black,
    fontWeight: 400,
  },
  h1: {
    ...heading,
    fontSize: { desktop: '50px', tablet: '40px', mobile: '36px' },
    fontWeight: 700,
    lineHeight: { desktop: '66px', tablet: '56px', mobile: '40px' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: '40px', tablet: '35px', mobile: '32px' },
    fontWeight: 700,
    lineHeight: { desktop: '43px', tablet: '38px', mobile: '36px' },
  },
  h3: {
    ...heading,
    fontSize: { desktop: '30px', tablet: '26px', mobile: '20px' },
    fontWeight: 700,
    lineHeight: { desktop: '35px', tablet: '22px', mobile: '22px' },
  },
  h4: {
    ...heading,
    fontSize: { desktop: '20px', tablet: '18px', mobile: '18px' },
    fontWeight: 700,
    lineHeight: { desktop: '24px', tablet: '22px', mobile: '22px' },
  },
  h5: {
    ...heading,
    fontSize: { desktop: '16', tablet: '16px', mobile: '16px' },
    fontWeight: 700,
    lineHeight: { desktop: '20px', tablet: '20px', mobile: '20px' },
  },
  highlight: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.text,
  },
  li: {
    bulletBorderRadius: '.3125rem',
    bulletColor: colors.primary,
  },
  p: {
    fontSize: '1rem',
    lineHeight: '28px',
    margin: '0',
    marginBottom: '27.2px',
  },
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  rteHighlight: {
    hoverColor: '#83A237',
    color: colors.primary,
    fontWeight: '700',
    textDecoration: 'underline',
    isFooter: colors.white,
  },
  table: {
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '48px',
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '12px',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '.125rem',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '.125rem',
    padding: '12px',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '5px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: '14px',
  fontWeight: 600,
  height: '3.625rem',
  minWidth: '12.125rem',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: '#75A603',
      borderColor: '#75A603',
      color: colors.text,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '.0625rem',
    color: colors.text,
  },
  'primary-with-background': {
    ...buttonDefaults,
    '&:hover': {
      color: '#3A4417',
    },
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '.0625rem',
    color: colors.secondary,
  },
  secondary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: '#3A4417',
      borderColor: '#3A4417',
      color: colors.white,
    },
    backgroundColor: colors.secondary,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderWidth: '.0625rem',
    color: colors.white,
  },
  tertiary: {
    ...buttonDefaults,
    '&:hover': {
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
      color: colors.white,
    },
    backgroundColor: colors.white,
    borderColor: colors.secondary,
    borderStyle: 'solid',
    borderWidth: '.0625rem',
    color: colors.secondary,
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
};

export const articleDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  title: colors.text,
};

export const articleListing = {
  background: colors.primary,
  filter: colors.text,
  hover: colors.secondary,
  mobile: {
    title: colors.primary,
    text: colors.text,
    background: colors.tertiary,
  },
  text: colors.white,
  title: colors.text,
};

export const breadcrumb = {
  backgroundColor: colors.tertiary,
};

export const cardCtas = {
  arrowColor: colors.primary,
  arrowHoverColor: '#75A603',
  color: colors.primary,
  fontSize: '.875rem',
  hoverColor: '#75A603',
  letterSpacing: '0rem',
  lineHeight: '.875rem',
  textTransform: 'normal',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      '&:hover': {
        color: '#75A603',
        textDecoration: 'underline',
        opacity: 1,
      },
      color: colors.primary,
    },
    title: colors.text,
  },
  logo: {
    color: colors.text,
  },
};

export const carousel = {
  controls: {
    regular: {
      backgroundColor: colors.primary,
      color: colors.white,
      hover: {
        backgroundColor: '#75A603',
        color: colors.white,
      },
    },
    hero: {
      opacity: '0.8',
      backgroundColor: colors.white,
      color: '#75A603',
      hover: {
        color: '#75A603',
        backgroundColor: colors.white,
      },
    },
  },
  indicator: colors.primary,
  link: {
    '&:hover': {
      color: '#75A603',
      opacity: 1,
      textDecoration: 'underline',
    },
    color: colors.primary,
  },
  text: colors.text,
};

export const categoryCards = {
  backgroundColor: colors.primary,
};

export const categorySection = {
  background: colors.primary,
  boxShadow: null,
  chevron: {
    fill: colors.primary,
    stroke: colors.text,
  },
  color: colors.white,
  date: colors.primary,
  dateColor: colors.text,
  headingColor: colors.secondary,
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.text,
  infoBlock: {
    background: colors.tertiary,
    description: colors.text,
    title: colors.secondary,
    titleFontWeight: 400,
  },
};

export const contactForm = {
  heading: {
    color: colors.secondary,
  },
};

export const contacts = {
  office: {
    mapTextColor: colors.green,
    mapTextFontSize: '.875rem',
    mapTextFontWeight: 'bold',
    mapTextLetterSpacing: '0rem',
    mapTextLineHeight: '2',
    mapTextTransform: 'normal',
    openingHoursFontSize: '1rem',
  },
  description: colors.text,
  title: colors.text,
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.tertiary,
    color: colors.text,
    titleFontWeight: 400,
    titleColor: colors.secondary,
  },
  focusText: {
    backgroundColor: colors.tertiary,
    color: colors.text,
    titleFontWeight: 400,
    titleColor: colors.secondary,
  },
};

export const ctaGroup = {
  title: colors.secondary,
  titleFontWeight: 400,
  chevron: {
    opacity: 1,
    fill: colors.white,
    stroke: '#006C3D',
  },
};

export const customBlockCards = {
  backgroundColor: colors.primary,
  color: colors.primary,
  titleFontWeight: 400,
};

export const dealer = {
  textColor: colors.primary,
  titleFontWeight: 'bold',
};

export const documentDownload = {
  arrowColor: colors.green,
  arrowHoverColor: colors.green,
  color: colors.green,
  hoverColor: colors.green,
};

export const footer = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.text,
  minHeight: '10rem',
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '5px',
  label: {
    fontSizes: ['.75rem', null, '1rem'],
    lineHeights: ['1.3125rem', null, '1.625rem'],
  },
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
};

export const header = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.text,
};

export const headerBottom = {
  activeColor: colors.white,
  activeFontWeight: '700',
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: '.875rem',
  titleFontWeight: '400',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.secondary,
    },
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '.125rem',
    color: colors.text,
    height: '2.625rem',
    justifyContent: 'center',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: '#83A237',
      borderColor: '#83A237',
      color: colors.text,
    },
    backgroundColor: colors.primary,
    borderRadius: '5px',
    color: colors.text,
    justifyContent: 'center',
    minHeight: '2.625rem',
    padding: '.8125rem 2.1875rem .75rem',
  },
  arrow: {
    display: 'none',
  },
};

export const heroSection = {
  titleFontWeight: 'normal',
};

export const imageAndText = {
  color: colors.primary,
};

export const infoBlock = {
  background: colors.tertiary,
  button: {
    '&:hover': {
      backgroundColor: '#75A603',
      textDecoration: 'none',
    },
    backgroundColor: colors.primary,
    color: colors.white,
  },
  description: colors.text,
  title: colors.primary,
  titleFontWeight: 400,
};

export const infoSection = {
  backgroundColor: colors.tertiary,
  color: '#3A4417',
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: '#75A603',
};

export const media = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.lightGray,
  chevronColor: colors.darkGray,
};

export const navItems = {
  fontSize: '.875rem',
  fontWeight: 400,
  letterSpacing: '0rem',
};

export const newsListing = {
  titleFontWeight: 'bold',
};

export const ownerDetail = {
  introduction: colors.text,
  tags: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  title: colors.text,
};

export const product = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'bold',
};

export const salesPerson = {
  borderColor: colors.primary,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '1.875rem',
};

export const shortBanner = {
  backgroundColor: colors.tertiary,
  color: colors.text,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.primary,
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Arvesta',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
