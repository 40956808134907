import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { withErrorBoundary } from '../../components';

import { StyledImageWrapper, StyledWrapper } from './Styled';

export interface ImageModuleProps {
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
  altText?: string;
  size?: string;
  position?: string;
}

const ImageModule = ({ asset, size, position, altText }: ImageModuleProps) => {
  if (!asset) {
    return null;
  }

  return (
    <StyledWrapper position={position}>
      <StyledImageWrapper size={size}>
        <GatsbyImage
          alt={altText ?? ''}
          image={asset.gatsbyImageData}
          objectFit="cover"
          style={{ height: '100%', margin: '0 auto' }}
        />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};

export default withErrorBoundary(ImageModule, { componentName: 'ImageModule' });
