import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const TopCards = styled(Box)`
  display: grid;

  ${Media({
    gap: ['15px', null, '18px'],
    gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
  })}
`;

export const BottomCards = styled(Box)`
  display: grid;

  ${Media({
    gap: ['10px', null, '18px'],
    gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
  })}
`;

export const ContentWrapper = styled(Flex)`
  padding: 20px 0;
  gap: 36px;
`;

export const CardsWrapper = styled(Flex)`
  gap: 18px;
`;

export const StyledTitle = styled(Heading)`
  color: ${({ theme }) => theme.categorySection.headingColor};
  text-align: center;
  font-weight: 700;

  ${Media({
    lineHeight: ['31px', null, '40px', '50px'],
  })}
`;

export const StyledDescription = styled.p`
  color: ${({ theme }) => theme.categorySection.headingColor};
  text-align: center;
  font-weight: bold;
  max-width: 660px;
  margin: 0 auto;
  margin-top: 12px;
  ${Media({
    fontSize: ['16px', null, null, '18px'],
  })};
`;
