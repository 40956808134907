import * as React from 'react';

const Pdf = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="32">
      <g fill="#3D3D3D">
        <path d="M6.5 20.448c0-.594-.355-.948-.98-.948-.256 0-.43.03-.52.057v1.906c.107.028.239.037.42.037.668 0 1.08-.392 1.08-1.052zm4.114-.948c-.303 0-.498.026-.614.053v3.92c.116.027.303.027.472.027 1.227.009 2.028-.667 2.028-2.098.009-1.244-.721-1.902-1.886-1.902z" />
        <path d="M18.272 0H5.199c-1.91 0-3.465 1.53-3.465 3.406V16h-.338C.626 16 0 16.614 0 17.372v8.324c0 .758.625 1.372 1.396 1.372h.338v1.526C1.734 30.473 3.29 32 5.2 32h17.838c1.908 0 3.463-1.527 3.463-3.406V8.061L18.272 0zM3.662 18.874c.41-.067.984-.119 1.795-.119.818 0 1.402.154 1.794.463.375.29.627.77.627 1.335s-.191 1.045-.54 1.37c-.453.42-1.123.608-1.907.608-.175 0-.331-.008-.454-.025v2.064H3.662v-5.696zm19.375 11.04H5.199c-.74 0-1.342-.592-1.342-1.32v-1.526h16.629c.77 0 1.396-.614 1.396-1.372v-8.324c0-.758-.625-1.372-1.396-1.372H3.857V3.406c0-.726.602-1.318 1.342-1.318l12.28-.013v4.463c0 1.303 1.075 2.361 2.402 2.361l4.446-.012.05 19.707c0 .728-.6 1.32-1.34 1.32zm-14.31-5.37v-5.67a12.192 12.192 0 011.794-.119c1.115 0 1.838.197 2.404.616.61.446.993 1.156.993 2.176 0 1.105-.409 1.867-.976 2.338-.618.505-1.559.745-2.708.745a11.65 11.65 0 01-1.508-.086zm9.507-3.357v1.062h-2.109v2.321h-1.332v-5.772h3.589v1.07h-2.257v1.32h2.109z" />
      </g>
    </svg>
  );
};

export default Pdf;
