import React from 'react';

const FlagPl = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 31.66">
    <g id="Vlaggen">
      <g>
        <g>
          <path fill="#07a651" d="M0,15.83c0,6.99,4.53,12.91,10.81,15.01V.82C4.53,2.92,0,8.84,0,15.83Z" />
          <path
            fill="#e52528"
            d="M20.85,.82c-1.58-.53-3.26-.82-5.02-.82s-3.44,.29-5.02,.82V30.84c1.58,.53,3.26,.82,5.02,.82s3.44-.29,5.02-.82c6.28-2.1,10.81-8.03,10.81-15.01S27.13,2.92,20.85,.82Z"
          />
        </g>
        <g>
          <circle fill="#feca08" cx="9.88" cy="15.83" r="5.02" />
          <path fill="#e52528" d="M6.9,16.26c0,1.65,1.34,2.99,2.99,2.99s2.99-1.34,2.99-2.99v-2.66H6.9v2.66Z" />
          <path fill="#fafafa" d="M7.9,16.31c0,1.1,.89,1.98,1.98,1.98s1.98-.89,1.98-1.98v-1.76h-3.97v1.76Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default FlagPl;
