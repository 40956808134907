import * as React from 'react';

const Facebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24">
    <path
      fill="#3D3D3D"
      d="M11.655 13.5l.645-4.343H8.27V6.338c0-1.188.563-2.347 2.367-2.347h1.832V.293S10.807 0 9.217 0C5.9 0 3.73 2.08 3.73 5.846v3.31H.04V13.5h3.69V24h4.54V13.5h3.385z"
    />
  </svg>
);

export default Facebook;
