import { Table, type TableProps, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

const TableModule = ({ ...rest }: TableProps) => (
  <StyledModuleContainer>
    <Table {...rest} />
  </StyledModuleContainer>
);

export default withErrorBoundary(TableModule, { componentName: 'TableModule' });
