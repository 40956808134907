import styled from 'styled-components';

interface StyledCheckbox {
  isSelected: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const StyledCheckbox = styled.div<StyledCheckbox>`
  width: 19px;
  height: 19px;
  border: 1px solid ${props => props.theme.colors.dark};
  border-radius: 4px;
  position: relative;
  margin-right: 12px;

  &::after {
    width: 13px;
    height: 13px;
    border-radius: 2px;
    content: ' ';
    background-color: ${props => props.theme.colors.secondary};
    position: absolute;
    top: 2px;
    left: 2px;
    display: ${props => (props.isSelected ? 'block' : 'none')};
  }
`;
