import { House } from '@arvesta-websites/icons';
import { Fragment } from 'react';

import type { LocaleType } from '../../../types';
import { createLink } from '../../../utils';
import Cta from '../Cta';
import { withErrorBoundary } from '../ErrorBoundary';

import { StyledBreadcrumb, StyledBreadcrumbWrapper, StyledChevronRight, StyledCrumb, StyledHomeCrumb } from './Styled';

interface Link {
  to: string;
  label: string;
}

export interface BreadcrumbProps {
  locale: LocaleType;
  crumbs: Link[];
  marginTop?: Array<string | null>;
}

const Breadcrumb = ({ crumbs, locale: { path: locale }, marginTop }: BreadcrumbProps) => (
  <StyledBreadcrumbWrapper marginTop={marginTop}>
    <StyledBreadcrumb>
      <StyledHomeCrumb>
        <Cta to="/" forceLocale>
          <House />
        </Cta>
      </StyledHomeCrumb>

      <StyledChevronRight />
      {crumbs.map(({ label, to }, index) => (
        <Fragment key={`${label}-${index}`}>
          <StyledCrumb href={createLink(to, locale)} firstNotLast={index === 0 && index !== crumbs.length - 1}>
            {label}
          </StyledCrumb>
          {index < crumbs.length - 1 && <StyledChevronRight />}
        </Fragment>
      ))}
    </StyledBreadcrumb>
  </StyledBreadcrumbWrapper>
);

export default withErrorBoundary(Breadcrumb, { componentName: 'Breadcrumb' });
