import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

export const StyledWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 13px;
`;

interface StyledVerticalCardContainer {
  twoColumns?: boolean;
}

export const StyledVerticalCardContainer = styled(Flex)<StyledVerticalCardContainer>`
  display: flex;
  align-items: top;
  justify-content: left;
  justify-content: space-between;

  &.cards-2 {
    ${props =>
      Media({
        width: ['auto', null, null, props.twoColumns ? '100%' : 'calc(66.66% + 24px)'],
      })}
  }

  &.cards-1 {
    ${props =>
      Media({
        width: ['auto', null, 'calc(50% - 16px)', props.twoColumns ? 'calc(50% - 16px)' : 'calc(33.33% - 21px)'],
      })}
  }

  ${Media({
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginBottom: ['1rem', '1rem', '32px'],
    position: ['relative', null, 'static'],
  })}

  &:last-child {
    margin-bottom: 0;
  }
`;

interface StyledVerticalCardWrapper {
  twoColumns?: boolean;
}

export const StyledVerticalCardWrapper = styled(Box)<StyledVerticalCardWrapper>`
  ${Media({
    marginBottom: ['1rem', null, '0'],
    width: ['auto', null, 'calc(50% - 32px)', 'calc(33.33% - 16px)'],
  })}

  > div {
    max-width: none;
    margin-right: none;
    height: 100%;

    ${props =>
      props.twoColumns &&
      `
      a:before {
        padding-top: calc((311 / 441) * 68.27%);
      }
    `}
  }

  &.cards-3 {
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &.cards-2 {
    ${Media({
      width: ['100%', 'auto', 'calc(50% - 16px)'],
    })}

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &.cards-1 {
    ${Media({
      width: ['100%'],
    })}
  }

  > .vertical {
    max-width: none;
    height: 100%;
  }
`;

export const StyledBlueBox = styled(Box)`
  position: absolute;
  width: calc(591 / 1440 * 100%);
  height: 402px !important;
  top: 0px;
  right: 0px;
  background-color: ${props => props.theme.customBlockCards.backgroundColor};
  z-index: -1;

  ${Media({
    marginTop: ['0px', null, '95px', '0px'],
    transform: ['translate(15px, -2.125rem)', null, 'translate(59px, -2rem)'],
    width: [`calc(266 / 375 * 100%)`, null, `calc(591 / 1440 * 100%)`],
  })}
`;
