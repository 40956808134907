import * as React from 'react';

const Youtube = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="24">
    <path
      fill="#3D3D3D"
      d="M33.42 3.755A4.289 4.289 0 0030.402.718C27.74 0 17.067 0 17.067 0S6.393 0 3.73.718A4.29 4.29 0 00.712 3.755C0 6.435 0 12.025 0 12.025s0 5.59.713 8.268c.393 1.478 1.55 2.594 3.018 2.99C6.393 24 17.067 24 17.067 24s10.673 0 13.335-.718c1.469-.395 2.626-1.51 3.018-2.989.713-2.679.713-8.269.713-8.269s0-5.59-.713-8.269zM13.576 17.1V6.95l8.92 5.075-8.92 5.075z"
    />
  </svg>
);

export default Youtube;
