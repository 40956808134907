import { Play } from '@arvesta-websites/icons';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../components';

const videoWrapper = tv({
  slots: {
    wrapper: 'relative pt-[50%]',
  },
});

type propsType = {
  videoUrl: string;
  imageUrl: string;
};

const VideoBanner = ({ videoUrl, imageUrl }: propsType) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

  const { wrapper } = videoWrapper();

  return (
    <div className={wrapper()}>
      <ReactPlayer
        ref={videoPlayerRef}
        playing={isVideoPlaying}
        url={videoUrl}
        width="100%"
        height="100%"
        style={{ left: 0, position: 'absolute', top: 0 }}
        light={imageUrl}
        playIcon={<Play />}
        controls={true}
        muted={true}
        onEnded={() => {
          if (videoPlayerRef.current) {
            setIsVideoPlaying(false);
            videoPlayerRef.current.showPreview();
          }
        }}
        onClickPreview={() => {
          if (videoPlayerRef.current) {
            setIsVideoPlaying(true);
          }
        }}
      />
    </div>
  );
};

export default withErrorBoundary(VideoBanner, { componentName: 'VideoBanner' });
