const HeroBannerMaskDesktopInverted = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1440" height="260.44">
    <path
      d="M0,0.440063 L1440,0.440063 L1440,260.44 L0,260.44 L0,0.440063 M1440,3.05176e-05 C1289.6,159.35 1061.75,260.47 703.65,260.44 L1440,260.44 L1440,3.05176e-05 M0,164.85 L0,260.44 L703.63,260.44 C510,260.42 278.06,230.8 0,164.85 Z"
      fill="#000"
      stroke="none"
    />
  </svg>
);

const HeroBannerMaskMobileInverted = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="425" height="12.39">
    <path
      id="shapePath1"
      d="M9.15527e-05,1.90735e-06 L425,1.90735e-06 L425,12 L9.15527e-05,12 L9.15527e-05,1.90735e-06 M425.01,-0.39 C353.94,7.73 277.24,12 194.34,12 L425.01,12 L425.01,-0.39 M194,12 C132.78,12 68.1134,9.63333 6.10352e-05,4.9 L6.10352e-05,12 L194,12 Z"
      fill="#000"
      stroke="none"
    />
  </svg>
);

const HeroBannerMaskTabletInverted = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="54.69">
    <path
      d="M0,0 L768,0 L768,54 L0,54 L0,0 M768,-0.690002 C656.61,34.55 524.63,54.01 367.65,54 L768,54 L768,-0.690002 M0,24.22 L0,54 L367.63,54 C257.83,53.99 135.79,44.49 0,24.22 Z"
      stroke="none"
      fill="#000"
    />
  </svg>
);

export default {
  Desktop: HeroBannerMaskDesktopInverted,
  Mobile: HeroBannerMaskMobileInverted,
  Tablet: HeroBannerMaskTabletInverted,
};
