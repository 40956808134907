import { EMAIL_REGEX } from '../../../utils';

import { NewsLetterProps } from './NewsLetter';

type NewsletterState = {
  checked: boolean;
  email: string;
  emailError: boolean;
  success: boolean;
  termsError: boolean;
};

type NewsletterAction =
  | {
      type: 'SET_CHECKED' | 'SET_TERMS_ERROR' | 'SET_EMAIL_ERROR' | 'SET_SUCCESS';
      payload: boolean;
    }
  | {
      type: 'SET_EMAIL';
      payload: string;
    };

export const newsletterReducer = (state: NewsletterState, action: NewsletterAction) => {
  switch (action.type) {
    case 'SET_CHECKED':
      return {
        ...state,
        checked: action.payload,
      };
    case 'SET_TERMS_ERROR':
      return {
        ...state,
        termsError: action.payload,
      };
    case 'SET_EMAIL_ERROR':
      return {
        ...state,
        emailError: action.payload,
      };
    case 'SET_SUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};

export const newsletterInitialState = {
  checked: false,
  email: '',
  emailError: false,
  success: false,
  termsError: false,
};

export const createNewsletterFormSubmitHandler =
  (
    newsletterState: any,
    dispatch: React.Dispatch<NewsletterAction>,
    handleNewsletterFormSubmission: (...args: any[]) => Promise<any>,
    locale: any,
    newsletter?: Omit<NewsLetterProps, 'submitHandler' | 'state' | 'setChecked' | 'setEmail'>,
  ) =>
  async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!EMAIL_REGEX.test(newsletterState.email) || newsletterState.email === '') {
      dispatch({ payload: '', type: 'SET_EMAIL' });
      dispatch({ payload: true, type: 'SET_EMAIL_ERROR' });
    } else {
      dispatch({ payload: false, type: 'SET_EMAIL_ERROR' });
    }

    if (!newsletterState.checked) {
      dispatch({ payload: true, type: 'SET_TERMS_ERROR' });
    } else {
      dispatch({ payload: false, type: 'SET_TERMS_ERROR' });
    }

    if (EMAIL_REGEX.test(newsletterState.email) && newsletterState.checked) {
      const res = await handleNewsletterFormSubmission(
        newsletterState.email,
        'BE',
        locale.id,
        newsletter?.campaignName ?? '',
      );
      if (res === 200) {
        dispatch({ payload: '', type: 'SET_EMAIL' });
        dispatch({ payload: true, type: 'SET_SUCCESS' });
      }
    }
  };
