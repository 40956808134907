import { Box } from 'rebass';

import { withErrorBoundary } from '../../ErrorBoundary';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { ProductType } from './types';

export interface ProductBasicTableProps {
  rows: Array<ProductType>;
}

const ProductBasicTable = ({ rows }: ProductBasicTableProps) => (
  <Box>
    <Desktop rows={rows} />
    <Mobile rows={rows} />
  </Box>
);

export default withErrorBoundary(ProductBasicTable, { componentName: 'ProductBasicTable' });
