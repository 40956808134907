import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  Options,
  RenderMark,
  RenderNode,
  documentToReactComponents as contentfulDocumentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Document, INLINES, Inline } from '@contentful/rich-text-types';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React, { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import extractFileInforamtion from '../../../utils/file';
import { CtaBannerModule } from '../../modules/CtaBannerModule';
import Blockquote from '../Blockquote/Blockquote';
import Button from '../Button';
import Cta from '../Cta';
import DocumentDownload from '../DocumentDownload';
import EmbeddedContent from '../EmbeddedContent';
import Media from '../Media/Media';
import ShortBanner from '../ShortBanner/ShortBanner';
import Table from '../Table/Table';
import Video from '../Video/Video';

import RichText from './RichText';
import {
  StyledCtaBanner,
  StyledDocumentDownload,
  StyledImage,
  StyledInlineAsset,
  StyledInlineTable,
  StyledListCenter,
  StyledListRight,
  StyledMedia,
  StyledShortBanner,
  StyledTable,
  StyledTextHighlight,
  StyledVideo,
} from './Styled';

export const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

const inlineHyperlink = (hyperlink: Inline) => {
  const { uri } = hyperlink.data;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { value } = hyperlink.content && hyperlink.content[0];

  if (!uri || !value) {
    return null;
  }

  return <Cta to={uri}>{value}</Cta>;
};

const assetHyperlink = (hyperlink: Inline) => {
  const file = hyperlink && hyperlink.data && hyperlink.data.target && hyperlink.data.target.file;
  const url = file && file.url;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const value = hyperlink && hyperlink.content && hyperlink.content[0] && hyperlink.content[0].value;

  if (!url || !value) {
    return null;
  }

  return <Cta to={url}>{value}</Cta>;
};

const inlineText = (text: string) => {
  return /^\s+$/.test(text) ? '' : text.replace(/\xA0/g, ' '); // Replace &nbsp;
};

const inlineAsset = (node: Inline) => {
  const { file, title } = (node && node.data && node.data.target) || {};

  if (!file || !file.url) {
    return null;
  }

  return (
    <StyledInlineAsset>
      <a href={file.url} rel="noopener noreferrer" target="_blank" title={title}>
        {title}
      </a>
    </StyledInlineAsset>
  );
};

const inlineTable = (node: Block, children) => (
  <StyledInlineTable>
    <tbody>{children}</tbody>
  </StyledInlineTable>
);

const inlineEntryBlock = (node: Block) => {
  const { sys, ...fields } = (node && node.data && node.data.target) || {};
  const { id } = (sys && sys.contentType && sys.contentType.sys) || {};
  if (!fields || !id) {
    console.log('!! UNKNOWN ENTRY BLOCK !!');
    return null;
  }

  if (id === 'blockquote') {
    // const quote: string = (fields.quote && fields.quote.text) || '';
    const quote: string | Document = fields?.copy ? fields?.copy : fields?.quote?.text || '';
    const author: string = fields && fields.author ? fields.author : undefined;
    const authorInfo: string = fields && fields.authorInfo ? fields.authorInfo : undefined;

    const imageUrl = fields.image && fields.image.asset && fields.image.asset.file && fields.image.asset.file.url;
    // TODO url in BlockQuote component adds query param, but that doesn't work with this url
    // const image = getSrc(fields.image?.asset?.gatsbyImageData);

    if (!quote) {
      return null;
    }

    return <Blockquote quote={quote} author={author} authorInfo={authorInfo} imageUrl={imageUrl} />;
  }

  if (id === 'navigationLink') {
    const ctaTo = fields?.internalEntry?.slug || fields?.externalUrl || null;

    return (
      <Cta to={ctaTo}>
        <Button>{fields?.title}</Button>
      </Cta>
    );
  }

  if (id === 'ctaSection') {
    const { title, image, description, orientation = '', type, ctaOneliner } = fields;

    if (!image) {
      return null;
    }

    const ctaLabel = fields.cta?.title;

    const ctaTo = fields.cta?.internalEntry?.slug || fields.cta?.externalUrl || null;

    const cta = ctaLabel && ctaTo ? { label: ctaLabel, to: ctaTo } : null;

    const shortDescription = description?.raw
      ? type === 'focus text'
        ? { json: JSON.parse(description.raw) }
        : documentToPlainTextString(JSON.parse(description.raw))
      : '';

    return (
      <StyledCtaBanner>
        <CtaBannerModule
          title={title}
          cta={cta}
          image={image}
          shortDescription={shortDescription}
          orientation={orientation?.toLowerCase()}
          type={type}
          ctaOneliner={ctaOneliner}
        />
      </StyledCtaBanner>
    );
  }

  if (id === 'embeddedContent') {
    return <EmbeddedContent type={fields.embedType} code={fields.embedCode?.text} />;
  }

  if (id === 'textSectionHighlight') {
    const contents = fields.richTextContents;

    if (!contents) {
      return null;
    }

    return (
      <StyledTextHighlight className="textHighlight">
        <RichText data={contents} textHighlight />
      </StyledTextHighlight>
    );
  }

  if (id === 'video') {
    const { videoUrl } = fields;

    if (!videoUrl) {
      return null;
    }

    return (
      <StyledVideo>
        <Video videoUrl={videoUrl} />
      </StyledVideo>
    );
  }

  if (id === 'table') {
    const tableData = (fields.table && fields.table.tableData) || [];
    const fixFirstColumn = !!fields.fixFirstColumn && fields.fixFirstColumn;

    if (!tableData.length) {
      return null;
    }

    const headings = tableData[0];
    const rows = tableData.slice(1);

    return (
      <StyledTable>
        <Table headings={headings} rows={rows} fixFirstColumn={fixFirstColumn} />
      </StyledTable>
    );
  }

  if (id === 'image') {
    const { title, alt, asset } = fields;

    if (!asset) {
      return null;
    }

    const image = getImage(asset?.gatsbyImageData);

    // const imageProps = {
    //   fluid: {
    //     src: asset.url,
    //     sizes: '(max-width: 1400px) 100vw, 1400px',
    //     srcSet: `${asset.url} 1400w`,
    //     aspectRatio: asset.details.image.width / asset.details.image.height,
    //   },
    //   alt,
    // } as GatsbyImageProps;

    return (
      <StyledImage>
        <GatsbyImage loading="eager" image={image as IGatsbyImageData} alt={alt || title} />
      </StyledImage>
    );
  }

  if (id === 'text') {
    const { richText, alignment } = fields;

    if (!richText) {
      return null;
    }

    return <RichText data={richText} alignment={alignment} />;
  }

  if (id === 'document') {
    const { title } = fields;
    const asset = fields.asset && fields.asset.file;

    if (!title || !asset) {
      return null;
    }

    const file = extractFileInforamtion({ asset, title });

    if (!file) {
      return null;
    }

    const { documentTitle, documentType, fileSize, downloadLink, showDocumentInfo } = file;

    return (
      <StyledDocumentDownload>
        <DocumentDownload
          documentTitle={documentTitle}
          documentType={documentType}
          fileSize={fileSize}
          downloadLink={downloadLink}
          showDocumentInfo={showDocumentInfo}
        />
      </StyledDocumentDownload>
    );
  }

  if (id === 'shortBanner') {
    // const asset = fields.image && fields.image.asset && fields.image.asset.file;
    const { title, image, shortDescription, orientation = '' } = fields;

    if (!image) {
      return null;
    }

    const ctaLabel = fields.cta && fields.cta.title;

    const ctaTo =
      (fields.cta && fields.cta.internalEntry && fields.cta.internalEntry.slug) ||
      (fields.cta && fields.cta.externalUrl) ||
      null;

    const cta = ctaLabel && ctaTo ? { label: ctaLabel, to: ctaTo } : null;

    // const image = {
    //   asset: {
    //     fluid: {
    //       src: asset.url,
    //       sizes: '(max-width: 800px) 100vw, 800px',
    //       srcSet: `${asset.url} 800w`, // TODO: Place actual renditions
    //       aspectRatio: asset.details.image.width / asset.details.image.height,
    //     },
    //   },
    // };

    return (
      <StyledShortBanner>
        <ShortBanner
          title={title}
          shortDescription={shortDescription && shortDescription.text}
          cta={cta}
          image={image}
          orientation={orientation.toLowerCase()}
        />
      </StyledShortBanner>
    );
  }

  if (id === 'media') {
    const { title } = fields;
    const shortDescription = fields.shortDescription && fields.shortDescription.text;
    const caption = fields.caption && fields.caption.text;
    const videoUrl = fields.media && fields.media.videoUrl;
    const orientation = fields.orientation || '';
    // const asset = fields.media && fields.media.asset && fields.media.asset.file;

    // const image = asset
    //   ? {
    //       asset: {
    //         fluid: {
    //           src: asset.url,
    //           srcSet: `${asset.url} 900w`,
    //           sizes: '(max-width: 900px) 100vw, 900px',
    //           ...(orientation === 'Portrait' && {
    //             aspectRatio: 0.74,
    //           }),
    //           ...(orientation === 'Square' && {
    //             aspectRatio: 1,
    //           }),
    //           ...((orientation === 'Landscape' || !orientation) && {
    //             aspectRatio: 1.77,
    //           }),
    //         },
    //       },
    //     }
    //   : null;

    const parseImage = (item: any) => {
      if (item.media?.__typename === 'ContentfulImage') {
        const {
          title: imageTitle,
          alt,
          asset: { landscapeImage, portraitImage, squareImage },
        } = item.media;

        if (item.orientation === 'Portrait') {
          return { alt, asset: { portraitImage }, title: imageTitle };
        }

        if (item.orientation === 'Square') {
          return { alt, asset: { squareImage }, title: imageTitle };
        }

        // Default to landscape
        return { alt, asset: { landscapeImage }, title: imageTitle };
      }

      return null;
    };

    const image = parseImage(fields);

    if (!image && !videoUrl) {
      return null;
    }

    const halfWidthImage = !!image && (orientation === 'Portrait' || orientation === 'Square');

    return (
      <StyledMedia>
        <Media
          title={title}
          shortDescription={shortDescription}
          caption={caption}
          videoUrl={videoUrl}
          image={image}
          halfWidthImage={halfWidthImage}
        />
      </StyledMedia>
    );
  }

  return null;
};

const entryHyperlink = (hyperlink: Inline): ReactNode => {
  // TODO Fix all these functions by making components out of them
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const { locale } = intl;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { value } = hyperlink && hyperlink.content && hyperlink.content[0];
  const { data } = hyperlink;

  const slugfield = data && data.target && data.target.slug;

  if (!slugfield || !value) {
    return false;
  }

  const slugKey = Object.keys(slugfield)[0];

  let slug = '';
  if (dontPrefixPaths) {
    if (slugfield) {
      slug = `/${slugfield}`;
    } else if (slugfield[slugKey]) {
      slug = `/${slugfield[slugKey]}`;
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (slugfield) {
      slug = `/${locale}/${slugfield}`;
    } else if (slugfield[slugKey]) {
      slug = `/${slugKey}/${slugfield[slugKey]}`;
    }
  }

  if (!slug) {
    return null;
  }

  return <a href={slug}>{value}</a>;
};

const entryList = (
  node: Block,
  children: React.ReactNode,
  Tag: 'ul' | 'ol',
  alignment?: 'Left' | 'Center' | 'Right',
) => {
  if (alignment === 'Right') return <StyledListRight as={Tag}>{children}</StyledListRight>;
  if (alignment === 'Center') return <StyledListCenter as={Tag}>{children}</StyledListCenter>;
  return <Tag>{children}</Tag>;
};

export const defaultNodeRenderers: RenderNode = (options: Partial<Options>) => ({
  [BLOCKS.EMBEDDED_ASSET]: node => inlineAsset(node as Inline),
  [INLINES.HYPERLINK]: node => inlineHyperlink(node as Inline),
  [INLINES.ENTRY_HYPERLINK]: node => entryHyperlink(node as Inline),
  [INLINES.ASSET_HYPERLINK]: node => assetHyperlink(node as Inline),
  [BLOCKS.EMBEDDED_ENTRY]: node => inlineEntryBlock(node as Block),
  [BLOCKS.TABLE]: (node, children) => inlineTable(node, children),
  [BLOCKS.UL_LIST]: (node, children) => entryList(node, children, 'ul', options?.alignment),
  [BLOCKS.OL_LIST]: (node, children) => entryList(node, children, 'ol', options?.alignment),
});

export const defaultMarkRenderers: RenderMark = {};

export const documentToReactComponents = (richTextDocument: Document, options: Partial<Options> = {}): ReactNode => {
  if (!richTextDocument) {
    return null;
  }

  return contentfulDocumentToReactComponents(richTextDocument, {
    renderMark: {
      ...defaultMarkRenderers,
      ...options.renderMark,
    },
    renderNode: {
      ...defaultNodeRenderers(options),
      ...options.renderNode,
    },
    renderText: (text: string) => {
      return inlineText(text)
        .split('\n')
        .reduce((children: ReactNode[], textSegment: string, index: number) => {
          return [...children, index > 0 && <br />, textSegment];
        }, [])
        .map((child: ReactNode) => {
          if ((child as ReactElement).type === 'br') {
            return React.cloneElement(child as ReactElement, { key: `${Math.random()}` });
          }

          return child;
        });
    },
  });
};
