import { Arrow } from '@arvesta-websites/icons';
import { useIntl } from 'react-intl';

import { withErrorBoundary } from '../../ErrorBoundary';

import {
  StyledBodyRow,
  StyledCell,
  StyledCtaText,
  StyledDownloadLink,
  StyledHeaderCell,
  StyledMobile,
  StyledMobileBottom,
  StyledMobileRow,
  StyledMobileTop,
} from './Styled';
import { ProductType } from './types';

type Props = {
  rows: Array<ProductType>;
};

const Mobile = ({ rows }: Props) => {
  const intl = useIntl();

  return (
    <StyledMobile>
      {rows.map((row: ProductType) => (
        <StyledBodyRow key={row.number}>
          <StyledMobileTop>
            <StyledMobileRow>
              <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.number' })}</StyledHeaderCell>
              <StyledCell>{row.number}</StyledCell>
            </StyledMobileRow>
            <StyledMobileRow>
              <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.product' })}</StyledHeaderCell>
              <StyledCell>{row.product}</StyledCell>
            </StyledMobileRow>
            <StyledMobileRow>
              <StyledHeaderCell>{intl.formatMessage({ id: 'sections.product.table.weight' })}</StyledHeaderCell>
              <StyledCell>{row.weight}</StyledCell>
            </StyledMobileRow>
          </StyledMobileTop>
          <StyledMobileBottom>
            <StyledMobileRow>
              <StyledCtaText>
                <span>
                  {intl.formatMessage({
                    id: 'sections.product.table.brochure',
                  })}
                </span>
              </StyledCtaText>
              <StyledDownloadLink target="_blank" to={row.brochure.asset.url}>
                <span>
                  {intl.formatMessage({
                    id: 'sections.product.table.download',
                  })}
                </span>
                <Arrow />
              </StyledDownloadLink>
            </StyledMobileRow>
          </StyledMobileBottom>
        </StyledBodyRow>
      ))}
    </StyledMobile>
  );
};

export default withErrorBoundary(Mobile, { componentName: 'Product/Mobile' });
