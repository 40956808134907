/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import { withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

import { StyledIframe } from './Styled';

export interface EmbeddedContentModuleProps extends BoxProps {
  id: string;
  ID: any;
  embedType: string;
  title: string;
  name: string;
}

const EmbeddedContentModule = React.forwardRef(
  ({ ID, embedType, title, name, ...rest }: EmbeddedContentModuleProps, ref) => {
    if (embedType === 'Typeform') {
      return (
        <StyledModuleContainer ref={ref}>
          <StyledIframe src={`${ID.ID}?disable-auto-focus=true`} allowFullScreen />
        </StyledModuleContainer>
      );
    }

    return null;
  },
);
EmbeddedContentModule.displayName = 'EmbeddedContentModule';

export default withErrorBoundary(EmbeddedContentModule, { componentName: 'EmbeddedContentModule' });
