import { useState } from 'react';

import type { FieldType } from '../../../../types';
import { withErrorBoundary } from '../../ErrorBoundary';
import { Option } from '../MultiSelect';

import { SelectFieldProps } from './SelectField';
import {
  StyledDropDown,
  StyledDropDownContainer,
  StyledDropDownItemSeparator,
  StyledDropDownSeparator,
  StyledHeader,
  StyledLabel,
} from './Styled';

export interface ArrowProps {
  expanded?: boolean;
}

export interface DropdownHeaderProps {
  label: string;
  htmlFor: string;
}

export interface CustomSelectProps extends SelectFieldProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  contactForm?: boolean;
  field: FieldType;
}

const Arrow = ({ expanded }: ArrowProps) => {
  return (
    <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d={expanded ? 'M18 15 12 9 6 15' : 'M6 9L12 15 18 9'} />
    </svg>
  );
};

const DropdownHeader = ({ label, htmlFor }: DropdownHeaderProps) => <label htmlFor={htmlFor}>{label}</label>;

const CustomSelect = ({ onSelect, options, expanded, setExpanded, label, contactForm, field }: CustomSelectProps) => {
  const [labelText, setLabelText] = useState(label);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onItemSelect = (option: Option) => {
    onSelect(option);
    setLabelText(option.label);
    field.value = option.value;
    toggleExpanded();
  };

  return (
    <>
      {contactForm && <StyledLabel htmlFor={field.name}>{label}</StyledLabel>}
      <StyledDropDownContainer expanded={expanded} hasError={!!field.error}>
        <StyledHeader onClick={toggleExpanded} name={field.name} value={field.value} type="button">
          <input type="text" id={`${field.name}-input`} value={field.value} name={`${field.name}-input`} hidden />
          <DropdownHeader label={labelText} htmlFor={field.name} />
          <Arrow expanded={expanded} />
        </StyledHeader>
        <StyledDropDownSeparator expanded={expanded} />
        {expanded && (
          <StyledDropDown expanded={expanded}>
            {options.map((option: { label: string; value: string }) => {
              return (
                <StyledDropDownItemSeparator key={option.value} onClick={() => onItemSelect(option)}>
                  <label
                    htmlFor={`cb-${option.value}`}
                    onClick={e => {
                      e.stopPropagation();
                      onItemSelect(option);
                    }}
                  >
                    <span
                      id={`cb-${option.value}`}
                      onClick={e => {
                        e.stopPropagation();
                        onItemSelect(option);
                      }}
                    >
                      {option.label}
                    </span>
                  </label>
                </StyledDropDownItemSeparator>
              );
            })}
          </StyledDropDown>
        )}
      </StyledDropDownContainer>
    </>
  );
};

export default withErrorBoundary(CustomSelect, { componentName: 'CustomSelect' });
