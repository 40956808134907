import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactNode } from 'react';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Cta, Heading } from '../../components';

interface IExpandedStyled {
  ctaStyle?: string;
}

export const StyledActionsWrapper = styled.div<{ alignLeft?: boolean; children: ReactNode }>`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  a.extraSpacing {
    margin-right: 7px;
  }

  justify-content: ${props => (props.alignLeft ? 'start' : 'end')};
`;

export const StyledComponentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 900;
  color: ${props => props.theme.colors.white};
  top: 0;
  width: 100%;
  height: 100%;

  ${Media({
    'justify-content': ['center', 'center', 'space-between'],
    padding: [null, null, '48px'],
    'text-align': ['center'],
  })};
`;

export const StyledGatsbyImage = styled(GatsbyImage)`
  object-fit: cover;
  height: 100%;
  margin: 0 auto;
  &:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  ${Media({
    boxShadow: [null, '3px 3px 3px #00000029'],
    height: ['227px', '227px', '377px'],
  })}
`;

export const StyledInnerButton = styled(Cta)<IExpandedStyled>`
  ${props => props.theme.heroBannerButton?.['White Background']};
  unset: all;
  text-decoration: none;
  text-transform: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  font-family: ${props => props.theme.body};
  gap: 10px;
  cursor: pointer;
`;

export const StyledTitle = styled(Heading)`
  width: 100%;
  filter: drop-shadow(1px 1px 0px #0000004d);
  color: ${props => props.theme.colors.white};
  font-weight: 700;
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledContent = styled.div<{ alignContent?: string; alignBlock?: string }>`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.alignBlock};

  ${Media({
    width: ['100%', '300px', '500px'],
  })}

  ${props =>
    Media({
      'justify-content': ['center', 'center', props.alignContent],
    })}
`;

export const StyledDesktopBanner = styled.div`
  ${Media({
    display: ['none', 'none', 'block'],
  })}
`;

export const StyledMobileBanners = styled.div`
  ${Media({
    display: ['block', 'block', 'none'],
  })}
`;
