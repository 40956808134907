import { Document } from '@contentful/rich-text-types';

import { withErrorBoundary } from '../ErrorBoundary';

import { documentToReactComponents } from './helpers';
import { StyledCenteredTextBrandX, StyledText } from './Styled';

export type RichTextProps = {
  /** Contentful rich text data object */
  data: Document;
  alignment?: 'Left' | 'Center' | 'Right';
  widthSize?: 'Small' | 'Medium' | 'Large';
  anchorSlug?: string;
  isBrandX?: boolean;
  textHighlight?: boolean;
  isFooter?: boolean;
  isImageAndText?: boolean;
};

const RichText = ({
  data,
  alignment,
  anchorSlug,
  widthSize = 'Small',
  isBrandX = false,
  textHighlight = false,
  isFooter = false,
  isImageAndText = false,
}: RichTextProps) => {
  if (!data || typeof data !== 'object') {
    return null;
  }
  return (
    <StyledText
      id={anchorSlug}
      alignment={alignment}
      size={widthSize}
      isBrandX={isBrandX}
      textHighlight={textHighlight}
      isFooter={isFooter}
      isImageAndText={isImageAndText}
    >
      {documentToReactComponents(data, { alignment })}
    </StyledText>
  );
};

const CenteredRichText = ({
  data,
  anchorSlug,
  alignment = 'Left',
  widthSize = 'Small',
  isBrandX = false,
  isFooter = false,
  isImageAndText = false,
  textHighlight = false,
}: RichTextProps) => {
  if (!data || typeof data !== 'object') {
    return null;
  }
  return (
    <StyledCenteredTextBrandX id={anchorSlug} size={widthSize}>
      <StyledText
        isBrandX
        alignment={alignment}
        textHighlight={textHighlight}
        isFooter={isFooter}
        isImageAndText={isImageAndText}
      >
        {documentToReactComponents(data, { alignment })}
      </StyledText>
    </StyledCenteredTextBrandX>
  );
};

export default withErrorBoundary(RichText, { componentName: 'RichText' });
const CenteredRichTextWithBoundary = withErrorBoundary(CenteredRichText, { componentName: 'CenteredRichText' });
export { CenteredRichTextWithBoundary as CenteredRichText };
