import { darken } from 'polished';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import Cta from '../../Cta';

export const StyledAdviceWrapper = styled(Flex)`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  ${Media({
    flexDirection: ['column-reverse', null, null, 'row'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }
`;

export const StyledAdviceContentWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

export const StyledAdviceContent = styled(Box)`
  flex: 1;

  ${Media({
    padding: ['1.5rem', null, null, '2rem'],
  })}
`;

export const StyledAdviceHeading = styled.h2`
  color: ${props => props.theme.text.heading.color ?? props.theme.colors.text};
  margin-top: 0;
  font-weight: 600;
  font-size: 1.25rem;
`;

export const StyledAdviceDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.75rem;
  font-size: 1rem;
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledImageBoxWrapper = styled(Box)`
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((1 / 1) * 100%);
  }

  > ${StyledImageBox} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${Media({
    width: ['100%', null, null, `calc(310 / 1324 * 100%)`],
  })}
`;

export const StyledAdviceCtaWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-top: 1.5rem;
`;

export const StyledAdviceCta = styled(Cta)`
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-transform: ${props => props.theme.cardCtas.textTransform};
  letter-spacing: ${props => props.theme.cardCtas.letterSpacing};
  font-size: ${props => props.theme.cardCtas.fontSize};
  line-height: ${props => props.theme.cardCtas.lineHeight};

  > span {
    vertical-align: middle;
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.colors.primary};

    > * {
      fill: ${props => props.theme.colors.primary};
    }
  }

  &:hover {
    color: ${props => darken(0.1, props.theme.colors.primary)};

    > svg {
      fill: ${props => darken(0.1, props.theme.colors.primary)};

      > * {
        fill: ${props => darken(0.1, props.theme.colors.primary)};
      }
    }
  }
`;
