import { type IGatsbyImageData } from 'gatsby-plugin-image';

import { HeadingTag, withErrorBoundary } from '../../components';

import BottomCard from './BottomCard/BottomCard';
import { BottomCards, ContentWrapper, StyledTitle, TopCards } from './Styled';
import TopCard from './TopCard/TopCard';

export type MosaicSectionLink = {
  cardLink: {
    externalUrl?: string;
    internalEntry: {
      slug: string;
      title: string;
    };
  };
  title: string;
  inColumn?: boolean;
  id: string;
  image: {
    altText?: string;
    title: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export type MosaicSectionModuleInterface = {
  title?: string;
  titleTag?: HeadingTag;
  primaryLinks?: MosaicSectionLink[];
  secondaryLinks?: MosaicSectionLink[];
  className?: string;
};

const MosaicSectionModule = ({
  title,
  titleTag,
  primaryLinks,
  secondaryLinks,
  className,
}: MosaicSectionModuleInterface) => {
  if (!primaryLinks?.length && !secondaryLinks?.length) return null;

  return (
    <ContentWrapper flexDirection="column" alignContent="center" className={className}>
      {title && (
        <StyledTitle variant="h2" tag={titleTag}>
          {title}
        </StyledTitle>
      )}
      {!!primaryLinks?.length && (
        <TopCards>
          {primaryLinks.map(item => (
            <TopCard item={item} key={item.id}></TopCard>
          ))}
        </TopCards>
      )}
      {!!secondaryLinks?.length && (
        <BottomCards>
          {secondaryLinks.map(item => (
            <BottomCard item={item} key={item.id} />
          ))}
        </BottomCards>
      )}
    </ContentWrapper>
  );
};

export default withErrorBoundary(MosaicSectionModule, { componentName: 'MosaicSectionModule' });
