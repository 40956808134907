import { BoxProps } from 'rebass/styled-components';

import { Button, Cta, EventCard, HeadingTag, withErrorBoundary } from '../../components';
import { StyledModuleCtaContainer } from '../../shared';

import {
  StyledBlueBox,
  StyledHorizontalEventCardWrapper,
  StyledHorizontalEventsCardWrapper,
  StyledModuleContainer,
  StyledModuleDescription,
  StyledTitle,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

type Event = {
  id: string;
  title: string;
  shortDescription: string;
  date: string;
  location: string;
  image: string;
  link: string;
};

export interface UpcomingEventsModuleProps extends BoxProps {
  /** title */
  title: string;
  titleTag: HeadingTag;
  /** optional module short description */
  shortDescription: string;
  /** events  */
  upcomingEvents: Array<Event>;

  cta?: CtaProps;
}

const UpcomingEventsModule = ({
  title,
  titleTag,
  shortDescription,
  upcomingEvents,
  cta,
  ...rest
}: UpcomingEventsModuleProps) => {
  if (!upcomingEvents || upcomingEvents?.length === 0) {
    return null;
  }

  return (
    <StyledModuleContainer {...rest} data-appsearch-ignore="true">
      <StyledBlueBox>
        <StyledTitle variant="h2" tag={titleTag}>
          {title}
        </StyledTitle>
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledBlueBox>
      <StyledHorizontalEventsCardWrapper>
        {upcomingEvents
          .map(event => (
            <StyledHorizontalEventCardWrapper key={event.id}>
              <EventCard
                id={event.id}
                title={event.title}
                image={event.image}
                displayDate={event.date}
                location={event.location}
                shortDescription={event.shortDescription}
                link={event.link}
                variant="horizontal"
              />
            </StyledHorizontalEventCardWrapper>
          ))
          .slice(0, 4)}
      </StyledHorizontalEventsCardWrapper>

      {cta && (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      )}
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(UpcomingEventsModule, { componentName: 'UpcomingEventsModule' });
