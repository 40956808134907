import * as React from 'react';

const HamburgerBrandX = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18" {...props}>
    <g id="menu" transform="translate(-4.5 -9)">
      <path
        id="Path_2451"
        data-name="Path 2451"
        d="M29.813,12.375H6.188A1.688,1.688,0,0,1,6.188,9H29.813a1.687,1.687,0,0,1,0,3.375Z"
      />
      <path
        id="Path_2452"
        data-name="Path 2452"
        d="M29.813,19.688H6.188a1.688,1.688,0,0,1,0-3.375H29.813a1.688,1.688,0,0,1,0,3.375Z"
      />
      <path
        id="Path_2453"
        data-name="Path 2453"
        d="M29.813,27H6.188a1.688,1.688,0,0,1,0-3.375H29.813a1.688,1.688,0,0,1,0,3.375Z"
      />
    </g>
  </svg>
);

export default HamburgerBrandX;
