import React from 'react';

const ByArvesta = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    id="by_Arvesta"
    className={props.className}
    data-name="by_Arvesta"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 529.9 97.2"
  >
    <g id="Layer_2">
      <path
        fill="#405d18"
        strokeWidth="0"
        d="M154.8,82.2c-4.6-.9-4.2-1.8-3.3-2.6,19.2-11.8,34.7-25.1,33.7-40.9-.3-5.3-3.8-14-2.5-12.2,50.9,69.6-17.4,57.4-27.9,55.7M405.1,61.7h-40.1c.5,8,7.6,12.4,14.4,12.3,9.8,0,7.8-6.7,24.2-6.7-2.4,5.5-5.9,9.7-10.1,12.5-4.2,2.9-9.1,4.4-14.2,4.4-15.3,0-26.3-12.4-26.3-26.3s11.1-26.6,26.1-26.6,14,2.8,18.6,7.6c6.1,6.3,8.4,14.1,7.4,22.8M365.3,52.2h28.2c-.5-4.7-5.4-11.3-14.1-11.2-8.8,0-13.6,6.3-14,11.2M529.9,42.1v40.1s-11.9,1.2-11.9-7.2c-3.2,5.8-9.8,8.7-16.8,8.7-16,0-25.3-12.4-25.3-26.5s11.3-26.4,25.3-26.4,14.6,4.8,16.8,8.8v-7.2c11.7,0,11.9,9.7,11.9,9.7M487.9,57.5c0,6.2,4.4,15.2,15.1,15.2s11-3.4,13.2-8c1.2-2.2,1.7-4.5,1.9-7,0-2.4-.4-4.8-1.4-7-2.1-4.7-6.7-8.8-13.9-8.8s-15,7.8-15,15.5M279.1,74.6c0,8.6,11.9,7.6,11.9,7.6v-25.2c0-8.6,4.3-14.1,12.6-14.1h.5v-10.4c-7.5,0-11.6,3.3-13.8,7.7,0,0-2-7.8-11.2-7.8v42.1h0ZM355.8,32.5c-13.1,0-14.5,6.6-14.5,6.6l-9.7,28.2-8.9-26s-2.9-8.8-15.4-8.8c4.7,13.1,15.3,41.2,15.3,41.2,4.3,10.3,14.8,8.6,14.8,8.6l18.5-49.8h0ZM445.1,45.9c-1-12-11-15-17.7-15-9.6,0-17.8,6.1-17.8,15.9,0,7.2,5.5,10.9,12.2,13,9.2,3.2,12.9,4.3,12.9,8.6,0,3.3-3.1,5.8-6.8,5.8-8.8,0-5-6.4-19.2-6.4.9,12.1,11.3,16,19,16s19-6.5,18.8-16.8c0-10.7-8.7-13.1-16.7-15.2-4.7-1.4-8.3-3.1-8.4-6.2,0-2.5,1.9-5,5.9-5s6.4,5.3,17.8,5.3M455.9,75.4c0,7.8,11.9,6.8,11.9,6.8v-40.6h8.5v-9.2h-8.5v-9.2s.9-8.1-11.9-8.1v17.3h-8.2s-2.1,9.2,8.2,9.2v33.8h0ZM273.8,42.1v40.1s-11.9,1.2-11.9-7.2c-3.2,5.8-9.8,8.7-16.8,8.7-16,0-25.3-12.4-25.3-26.5s11.3-26.4,25.3-26.4,14.6,4.8,16.8,8.8v-7.2c11.7,0,11.9,9.7,11.9,9.7M231.8,57.5c0,6.2,4.4,15.2,15.1,15.2s11-3.4,13.2-8c1.2-2.2,1.7-4.5,1.9-7,0-2.4-.4-4.8-1.4-7-2.1-4.7-6.7-8.8-13.9-8.8s-15,7.8-15,15.5"
      />
      <path
        fill="#82c341"
        strokeWidth="0"
        d="M175.9,13.7c30.9,52.6-98.2,75.8-107.1,71.6C92,72.7,121.3,12.7,148.1,1.7c9.7-4,19.1-1.5,27.8,12"
      />
      <path
        fill="#405d18"
        strokeWidth="0"
        d="M0,32.5h4.8v17.3c0,1.6-.1,2.9-.1,2.9h.1s3-6.9,12.1-6.9,15.5,7.7,15.5,18.7-6.7,18.6-16.1,18.6-11.7-6.9-11.7-6.9h-.1s.1,1.2.1,2.9v3.2H0v-49.8h0ZM16,78.9c6.3,0,11.5-5.3,11.5-14.4s-4.6-14.3-11.3-14.3-11.5,4.2-11.5,14.4,3.7,14.3,11.3,14.3h0Z"
      />
      <path
        fill="#405d18"
        strokeWidth="0"
        d="M35.4,91.2s2,1.6,4.2,1.6,5.3-2.6,6.7-5.8l2.2-5-14.9-35.3h5.3l10.5,26c.7,1.8,1.3,3.8,1.3,3.8h.1s.5-2,1.2-3.8l10.3-26h5.2l-17.6,43.1c-1.8,4.5-5.5,7.4-10.3,7.4s-6.2-2.1-6.2-2.1l1.8-3.8h0Z"
      />
    </g>
  </svg>
);

export default ByArvesta;
