import { ShortBanner, type ShortBannerProps, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

const ShortBannerModule = ({ ...rest }: ShortBannerProps) => (
  <StyledModuleContainer smallTopSpacer>
    <ShortBanner {...rest} />
  </StyledModuleContainer>
);

export default withErrorBoundary(ShortBannerModule, { componentName: 'ShortBannerModule' });
