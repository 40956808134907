import React from 'react';

import { CtaFocusImageModule as CtaFocusImage, CtaFocusImageModuleProps as CtaFocusImageProps } from './FocusImage';
import { CtaBannerFocusText, type CtaBannerFocusTextProps } from './FocusText';

export type CtaBannerModuleProps =
  | ({ type: 'focus image' } & CtaFocusImageProps)
  | ({ type: 'focus text' } & CtaBannerFocusTextProps);

type CtaBannerModulePropsGeneric<TType extends CtaBannerModuleProps['type']> = Omit<
  Extract<CtaBannerModuleProps, { type: TType }>,
  'type'
>;

const propTyping = <TType,>(type: TType, props: Omit<CtaBannerModuleProps, 'type'>) =>
  props as CtaBannerModulePropsGeneric<TType extends CtaBannerModuleProps['type'] ? TType : never>;

const CtaBannerModule = ({ type = 'focus image' as const, ...props }: CtaBannerModuleProps) => {
  if (type === 'focus image') {
    return <CtaFocusImage {...propTyping(type, props)} />;
  }

  return <CtaBannerFocusText {...propTyping(type, props)} />;
};

export default CtaBannerModule;
