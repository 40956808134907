import React, { useRef } from 'react';

import { withErrorBoundary } from '../../ErrorBoundary';

import { CustomSelectProps } from './CustomSelect';
import { StyledCustomSelectContainer } from './Styled';
import useCustomSelectContainer from './useCustomSelectContainer';

type Children = [React.ReactElement<CustomSelectProps>, JSX.Element | string];

export interface CustomSelectContainerProps {
  children: Children;
}

const CustomSelectContainer = ({ children }: CustomSelectContainerProps) => {
  const {
    props: { setExpanded },
  } = children[0];

  const wrapperRef = useRef(null);

  useCustomSelectContainer(wrapperRef, setExpanded);

  return <StyledCustomSelectContainer ref={wrapperRef}>{children}</StyledCustomSelectContainer>;
};

export default withErrorBoundary(CustomSelectContainer, { componentName: 'CustomSelectContainer' });
