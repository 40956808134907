import React from 'react';

const Logo = () => (
  <svg id="Laag_1" data-name="Laag 1" version="1.1" viewBox="0 0 487.15 138.8">
    <defs>
      <clipPath id="clippath">
        <rect fill="none" strokeWidth="0px" width="484.43" height="108.42" />
      </clipPath>
      <clipPath id="clippath-1">
        <rect fill="none" strokeWidth="0px" width="487.15" height="138.8" />
      </clipPath>
    </defs>
    <g clipPath="url(#clippath)">
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M97.94,62.47c-1.21-.4-3.22-4.39-3.22-4.39,0,0-2.82,37.44-4.43,36.63-1.01-.5-19.21-.38-32.7-.21l5.2-15.6h-8.22l.79-6.34c.42,0,.83,0,1.25-.03,10.08-.81,11.69-5.24,13.7-12.09,2.02-6.85-5.64-14.91-13.7-14.91,0,0-12.49.4-15.32,9.72-2.45,8.09,3.3,15.75,11.46,17.11l-.44,6.53h-10.48l4.5,15.75c-2,.03-3.2.05-3.2.05h-23.38c-5.24,0-3.63-2.82-3.22-8.46.4-5.64,0-33.05,0-33.05-6.05,3.22-6.05.4-3.63-2.42,2.42-2.82,46.35-37.49,47.56-37.08,1.21.4,2.02,5.64,4.03,5.64s35.47,41.11,35.47,41.11c0,0-.81,2.42-2.02,2.02M97.94,0L0,9.27l8.06,99.15,97.54-8.06L97.94,0Z"
      />
      <polygon
        fill="#004238"
        strokeWidth="0px"
        points="191.78 84.04 154.82 84.04 154.82 18.05 191.78 18.05 191.78 29.22 165.99 29.22 165.99 45.46 183.96 45.46 183.96 56.63 165.99 56.63 165.99 72.87 191.78 72.87 191.78 84.04"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M198.78,41.3h10.56v24.77c0,6.09,3.96,9.13,9.03,9.13,5.58,0,9.04-3.65,9.04-9.54v-24.36h10.46v42.74h-10.46v-4.37c-2.13,3.15-5.69,5.69-11.57,5.69-10.05,0-17.05-6.19-17.05-18.17v-25.89Z"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M277.65,41.3v10.46h-2.94c-8.42,0-14.72,4.36-14.72,15.83v16.45h-10.45v-42.74h10.15v7.72c2.13-3.25,6.5-7.72,14.42-7.72h3.55Z"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M327.09,62.41c0,12.69-9.95,22.94-22.23,22.94s-22.33-10.25-22.33-22.94,10.05-22.43,22.33-22.43,22.23,9.85,22.23,22.43M316.33,62.41c0-6.5-5.18-11.57-11.47-11.57s-11.47,5.08-11.47,11.57,5.18,12.08,11.47,12.08,11.47-5.38,11.47-12.08"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M351.76,51.75v15.63c0,3.76,1.73,6.19,5.28,6.19h4.57v10.46h-7.01c-7.71,0-13.3-5.48-13.3-12.69v-19.59h-8.43v-10.46c8.22,0,12.08-5.28,13.4-13.6h10.56c-.91,6.09-2.64,10.35-5.89,13.6h10.66v10.46h-9.85Z"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M369.31,41.3h10.56v24.77c0,6.09,3.96,9.13,9.03,9.13,5.58,0,9.04-3.65,9.04-9.54v-24.36h10.45v42.74h-10.45v-4.37c-2.13,3.15-5.69,5.69-11.57,5.69-10.05,0-17.05-6.19-17.05-18.17v-25.89Z"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M433.27,84.04h-10.56v-32.28h-4.06v-10.45h14.62v42.74ZM420.17,28c0-3.66,2.84-6.8,6.9-6.8,3.66,0,6.8,3.15,6.8,6.8,0,3.86-3.05,6.9-6.8,6.9-4.06,0-6.9-3.05-6.9-6.9"
      />
      <path
        fill="#004238"
        strokeWidth="0px"
        d="M445.55,84.04v-42.74h10.46v4.87c1.93-2.74,5.89-6.19,11.87-6.19,9.95,0,16.55,6.29,16.55,17.56v26.5h-10.45v-24.77c0-5.99-3.25-9.24-8.83-9.24s-9.13,3.86-9.13,9.24v24.77h-10.46Z"
      />
    </g>
    <g clipPath="url(#clippath-1)">
      <g>
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M154.82,125.54l.04-17.15h4.26v17.31c0,2.45,2.01,4.46,4.46,4.46s4.38-2.01,4.38-4.3v-17.47h4.3v17.51c0,2.29,1.93,4.26,4.34,4.26s4.38-2.01,4.38-4.46v-17.27h4.3v17.11c0,5.28-3.98,9.07-8.68,9.07-2.6,0-4.93-1.18-6.51-3.07-1.54,1.89-3.94,3.07-6.51,3.07-4.69,0-8.75-3.79-8.75-9.07Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M206.69,117.34v16.68h-4.1v-1.97c-.35.99-2.25,2.48-5.13,2.48-4.38,0-8.16-3.31-8.2-8.67,0-5.13,3.43-8.91,8.16-8.95,2.37-.04,4.3.87,5.17,2.21v-1.77h4.1ZM202.47,126.01v-.55c0-2.52-1.89-4.42-4.5-4.42s-4.5,1.97-4.5,4.65c0,2.84,2.09,4.69,4.5,4.69,2.52,0,4.5-2.01,4.5-4.38Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M227.75,117.34v16.68h-4.1v-1.97c-.35.99-2.25,2.48-5.13,2.48-4.38,0-8.16-3.31-8.2-8.67,0-5.13,3.43-8.91,8.16-8.95,2.37-.04,4.3.87,5.17,2.21v-1.77h4.1ZM223.53,126.01v-.55c0-2.52-1.89-4.42-4.5-4.42s-4.5,1.97-4.5,4.65c0,2.84,2.09,4.69,4.5,4.69,2.52,0,4.5-2.01,4.5-4.38Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M243.18,117.41v4.06h-1.14c-3.27,0-5.72,1.69-5.72,6.15v6.39h-4.06v-16.6h3.94v3c.83-1.26,2.52-3,5.6-3h1.38Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M258.33,134.02h-4.1v-12.54h-1.58v-4.06h5.68v16.6ZM253.24,112.25c0-1.42,1.1-2.64,2.68-2.64,1.42,0,2.64,1.22,2.64,2.64,0,1.5-1.18,2.68-2.64,2.68-1.58,0-2.68-1.18-2.68-2.68Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M279.63,108.38v25.63h-4.1v-1.97c-.35.99-2.25,2.48-5.13,2.48-4.38,0-8.16-3.55-8.2-8.67,0-5.33,3.71-8.91,8.24-8.95,3.16-.04,4.61,1.38,5.09,2.17v-10.69h4.1ZM275.4,126.21v-.67c0-2.56-2.01-4.45-4.49-4.45s-4.46,1.89-4.46,4.61,2.01,4.69,4.46,4.69,4.49-1.85,4.49-4.18Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M300.33,126.6h-12.9c.2,2.45,2.05,4.02,4.46,4.02,1.77,0,2.92-.87,3.67-1.97h4.38c-1.18,3.39-4.3,5.87-8.04,5.87-5.01,0-8.52-3.79-8.52-8.79s3.79-8.83,8.48-8.83c5.24,0,8.79,4.3,8.48,9.7ZM296,123.84c-.59-1.58-1.97-3.07-4.1-3.07-2.01,0-3.55,1.34-4.14,3.07h8.24Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M319.94,126.6h-12.89c.2,2.45,2.05,4.02,4.46,4.02,1.77,0,2.92-.87,3.67-1.97h4.38c-1.18,3.39-4.3,5.87-8.04,5.87-5.01,0-8.52-3.79-8.52-8.79s3.79-8.83,8.48-8.83c5.25,0,8.79,4.3,8.48,9.7ZM315.6,123.84c-.59-1.58-1.97-3.07-4.1-3.07-2.01,0-3.55,1.34-4.14,3.07h8.24Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M339.54,126.6h-12.89c.2,2.45,2.05,4.02,4.46,4.02,1.77,0,2.92-.87,3.67-1.97h4.38c-1.18,3.39-4.3,5.87-8.04,5.87-5.01,0-8.52-3.79-8.52-8.79s3.79-8.83,8.48-8.83c5.25,0,8.79,4.3,8.48,9.7ZM325.58,112.88c0-1.18.99-2.13,2.21-2.13s2.17.94,2.17,2.13-.99,2.21-2.17,2.21-2.21-.99-2.21-2.21ZM335.2,123.84c-.59-1.58-1.97-3.07-4.1-3.07-2.01,0-3.55,1.34-4.14,3.07h8.24ZM332.17,112.88c0-1.18.95-2.13,2.17-2.13s2.25.94,2.25,2.13c-.04,1.22-1.07,2.21-2.25,2.21s-2.17-.99-2.17-2.21Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M343.22,134.02v-16.6h4.06v1.89c.75-1.06,2.29-2.41,4.61-2.41,3.87,0,6.43,2.45,6.43,6.82v10.29h-4.06v-9.62c0-2.32-1.26-3.59-3.43-3.59s-3.55,1.5-3.55,3.59v9.62h-4.06Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M387.94,125.66c0,5.17-3.79,8.87-8.2,8.87-2.84,0-4.61-1.46-5.24-2.64v2.13h-4.06v-25.63h4.06v11.04c.94-1.18,2.92-2.56,5.6-2.52,4.38.04,7.89,3.43,7.85,8.75ZM383.72,125.69c0-2.92-1.97-4.73-4.54-4.73-2.41,0-4.57,1.85-4.57,4.53v.51c0,2.56,2.13,4.45,4.57,4.45s4.54-1.89,4.54-4.77Z"
        />
        <path fill="#004238" strokeWidth="0px" d="M395.83,134.02h-4.06v-25.63h4.06v25.63Z" />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M416.9,125.62c0,4.93-3.86,8.91-8.64,8.91s-8.68-3.98-8.68-8.91,3.9-8.72,8.68-8.72,8.64,3.83,8.64,8.72ZM412.72,125.62c0-2.52-2.01-4.49-4.46-4.49s-4.45,1.97-4.45,4.49,2.01,4.69,4.45,4.69,4.46-2.09,4.46-4.69Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M436.5,126.6h-12.9c.2,2.45,2.05,4.02,4.46,4.02,1.77,0,2.92-.87,3.67-1.97h4.38c-1.18,3.39-4.3,5.87-8.04,5.87-5.01,0-8.52-3.79-8.52-8.79s3.79-8.83,8.48-8.83c5.24,0,8.79,4.3,8.48,9.7ZM432.16,123.84c-.59-1.58-1.97-3.07-4.1-3.07-2.01,0-3.55,1.34-4.14,3.07h8.24Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M444.83,134.02h-4.1v-12.54h-1.58v-4.06h5.68v16.6ZM439.74,112.25c0-1.42,1.1-2.64,2.68-2.64,1.42,0,2.64,1.22,2.64,2.64,0,1.5-1.18,2.68-2.64,2.68-1.58,0-2.68-1.18-2.68-2.68Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M465.65,126.6h-12.89c.2,2.45,2.05,4.02,4.46,4.02,1.77,0,2.92-.87,3.67-1.97h4.38c-1.18,3.39-4.3,5.87-8.04,5.87-5.01,0-8.52-3.79-8.52-8.79s3.79-8.83,8.48-8.83c5.25,0,8.79,4.3,8.48,9.7ZM461.31,123.84c-.59-1.58-1.97-3.07-4.1-3.07-2.01,0-3.55,1.34-4.14,3.07h8.24Z"
        />
        <path
          fill="#004238"
          strokeWidth="0px"
          d="M469.32,134.02v-16.6h4.06v1.89c.75-1.06,2.29-2.41,4.61-2.41,3.87,0,6.43,2.45,6.43,6.82v10.29h-4.06v-9.62c0-2.32-1.26-3.59-3.43-3.59s-3.55,1.5-3.55,3.59v9.62h-4.06Z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
