import React from 'react';
import { useIntl } from 'react-intl';

import { Button, type CategoryCardProps, HeadingTag, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

import {
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';
import { Vertical } from './Vertical';
interface CategoryListingModuleProps {
  /** title */
  title: string;
  titleTag?: HeadingTag;
  /** optional module short description */
  shortDescription: string;
  /** events  */
  categories: CategoryCardProps[];
  initialAmount?: number;
  incrementAmount?: number;
  twoColumns?: boolean;
}

const CategoryListingModule = ({
  title,
  titleTag,
  shortDescription,
  categories,
  initialAmount = 12,
  incrementAmount = 12,
  twoColumns,
}: CategoryListingModuleProps) => {
  const [amountOfCategoriesToDisplay, setDisplayLimit] = React.useState(initialAmount);
  const intl = useIntl();

  return (
    <StyledModuleContainer data-appsearch-ignore="true">
      <StyledHeadingAndDescriptionContainer>
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading variant="h1" tag={titleTag}>
            {title}
          </StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
      </StyledHeadingAndDescriptionContainer>

      <Vertical categories={categories.slice(0, amountOfCategoriesToDisplay)} twoColumns={twoColumns} />

      {categories.length > amountOfCategoriesToDisplay ? (
        <StyledLoadMoreWrapper>
          <Button
            onClick={e => {
              e.preventDefault();
              setDisplayLimit(amountOfCategoriesToDisplay + incrementAmount);
            }}
          >
            {intl.formatMessage({ id: 'sections.category.loadmore' })}
          </Button>
        </StyledLoadMoreWrapper>
      ) : null}
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(CategoryListingModule, { componentName: 'CategoryListingModule' });
