import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

interface LabelProps {
  isError?: boolean;
}

export const StyledContainer = styled(Box)`
  color: ${props => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const StyledDescription = styled(Text)`
  color: ${({ theme }) => theme.contacts.description};

  ${Media({
    fontSize: ['12px', null, '16px'],
    lineHeight: ['21px', null, '26px'],
  })}
`;

export const StyledError = styled.div`
  color: ${props => props.theme.colors.danger};
  display: flex;
  align-items: center;
  font-weight: ${props => `${props.theme.fontWeights.medium}`};
  font-size: 0.75rem;
`;

export const StyledForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledFormRow = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const StyledHeader = styled(Box)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledHeading = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.contactForm?.heading?.color};
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  ${Media({
    fontSize: ['14px', null, '21px'],
    lineHeight: ['18px', null, '25px'],
  })}
`;

export const StyledSuccess = styled.div`
  color: ${props => props.theme.colors.success};
  text-align: center;
  margin-top: 0.75rem;
  font-weight: bold;

  ${Media({
    fontSize: ['14px', '16px', null, '18px'],
  })}
`;

export const StyledTitleWrapper = styled(Box)`
  display: flex;
  position: relative;

  > :last-child {
    fill: ${props => props.theme.contactForm?.heading?.color};
  }
`;
