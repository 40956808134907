import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../../utils/Media';

export const StyledCloseButton = styled(Box)`
  min-height: 78px;
  padding-left: 24px;
  margin-bottom: 60px;
  display: flex;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  display: flex;
  align-items: center;

  @media screen and (max-height: 700px) {
    margin-bottom: 10px;
  }
`;

export const StyledMobileLanguageSelect = styled(Box)`
  min-height: 56px;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  ${Media({
    width: ['320px', null, '70%', '320px'],
  })}

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 50px;

  @media screen and (min-height: 750px) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
`;

export const StyledSecondaryNavigationMobile = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: min-content;
  gap: 20px;
  padding: 20px;
  background-color: ${props => props.theme.colors.primaryWhite};
`;

export const StyledSelectText = styled.span`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.heading};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkGray};
`;
