import { Input, Label, Textarea } from '@rebass/forms';
import { Box } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

import Media from '../../../../utils/Media';

interface LabelProps {
  isError?: boolean;
  hideFromView?: boolean;
}

const inputStyles = css`
  outline: none;
  font-family: ${props => props.theme.fonts.body};
  font-size: 1rem;
  border-radius: ${props => props.theme.formInputs?.borderRadius};

  && {
    border: 1px solid ${props => props.theme.formInputs?.borderColor ?? 'currentColor'};
  }

  &::placeholder {
    color: ${props => `${props.theme.colors.textMuted}`};
  }

  &:focus,
  &:hover {
    border: ${props => `1px solid ${props.theme.formInputs?.activeColor}`};

    &::placeholder {
      color: ${props => `${props.theme.formInputs?.activeColor}`};
    }
  }
`;

export const StyledError = styled.div`
  color: ${props => props.theme.colors.danger};
  display: flex;
  align-items: center;
  font-weight: ${props => `${props.theme.fontWeights.medium}`};
  font-size: 0.75rem;
`;

export const StyledFormRow = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
`;

export const StyledLabel = styled(Label)<LabelProps>`
  color: ${props => (props.isError ? props.theme.colors.danger : props.theme.colors.earthyGreen)};
  align-items: center;
  position: relative;

  ${({ theme }) =>
    Media({
      fontSize: theme.formInputs?.label?.fontSizes ?? ['12px', null, '16px'],
      lineHeight: theme.formInputs?.label?.lineHeights ?? ['21px', null, '26px'],
    })}

  ${props =>
    props.hideFromView &&
    `
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `}
`;

interface ExtendedProps {
  withIcon?: boolean;
}
export const StyledTextArea = styled(Textarea)<ExtendedProps>`
  ${inputStyles}
  height: 195px;
  resize: none;
  && {
    padding: ${({ theme }) => theme.formInputs?.textareaPadding};
  }
`;

export const StyledTextInput = styled(Input)<ExtendedProps>`
  ${inputStyles}
  height: 3rem;
  && {
    padding: ${({ theme }) => theme.formInputs?.inputPadding};
    padding-right: 2.5rem;
`;

export const StyledIcon = styled.div<{ labelHidden?: boolean }>`
  position: absolute;
  right: 0.5rem;
  top: ${props => (props.labelHidden ? '0.75rem' : '2.75rem')};
  & > * {
    width: 24px;
    height: 24px;
  }
`;
