import * as React from 'react';

const Doc = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33">
      <path
        fill="#3D3D3D"
        d="M17.927 0L26 8.06v20.534c0 1.816-1.425 3.304-3.212 3.401l-.186.005H5.101c-1.812 0-3.297-1.427-3.394-3.22l-.005-.186v-1.526H1.37c-.712 0-1.297-.544-1.364-1.24L0 25.696v-8.324c0-.713.543-1.3 1.238-1.366L1.37 16h.332V3.406c0-1.814 1.425-3.304 3.212-3.4L5.101 0h12.826zm-.778 2.075L5.1 2.088c-.68 0-1.242.52-1.31 1.183l-.007.135V16H20.1c.712 0 1.297.544 1.364 1.24l.006.132v8.324c0 .713-.543 1.3-1.238 1.366l-.132.006H3.784v1.526c0 .683.52 1.246 1.182 1.313l.135.007h17.501c.68 0 1.241-.52 1.308-1.185l.007-.135-.049-19.707-4.362.012c-1.248 0-2.27-.972-2.352-2.2l-.005-.161V2.075zM10.8 18.82c-1.456 0-2.112 1.064-2.112 2.888 0 1.824.656 2.888 2.112 2.888s2.112-1.064 2.112-2.888c0-1.824-.656-2.888-2.112-2.888zm4.944 0c-1.456 0-2.144 1.048-2.144 2.888 0 1.84.688 2.888 2.144 2.888 1.112 0 1.664-.624 1.904-1.496l-.96-.272c-.144.52-.36.912-.96.912-.72 0-1.016-.592-1.016-1.552v-.96c0-.96.296-1.552 1.016-1.552.6 0 .816.392.96.912l.96-.272c-.24-.872-.792-1.496-1.904-1.496zm-9.824.096H4.184V24.5H5.92c1.448 0 2.152-.984 2.152-2.792 0-1.808-.704-2.792-2.152-2.792zm4.88.76c.744 0 1 .592 1 1.552v.952c0 .968-.256 1.56-1 1.56s-1-.592-1-1.552v-.96c0-.96.256-1.552 1-1.552zm-4.952.08c.768 0 1.128.52 1.128 1.464v.968c0 .952-.36 1.472-1.128 1.472h-.632v-3.904h.632z"
        transform="translate(-421 -2295) translate(397 2271.5) translate(24 24)"
      />
    </svg>
  );
};

export default Doc;
