import styled from 'styled-components';

import Media from '../../../utils/Media';

interface StyledImageWrapperProps {
  alignImageRight?: boolean;
}

export const StyledContainer = styled.div<StyledImageWrapperProps>`
  background-color: white;
  color: ${props => props.theme.imageAndText.color};
  display: flex;

  ${props =>
    Media({
      flexDirection: ['column', null, 'row'],
      margin: ['0', '0', '0', props.alignImageRight ? '0 auto 0 0' : '0 0 0 auto'],
      width: ['100%', '100%', '100%', 'calc(100% / 12 * 11)'],
    })}
`;

export const StyledContainerBrandX = styled(StyledContainer)`
  gap: 20px;
  width: 100%;

  ${Media({
    flexDirection: [null, null, 'column', 'row'],
    margin: ['20px 0', null, '36px 0', '57px 0'],
  })}
`;

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>`
  ${props =>
    Media({
      order: [null, null, 1, props.alignImageRight ? 2 : 1],
      width: ['100%', null, '50%'],
    })}

  > .gatsby-image-wrapper {
    div {
      ${Media({
        paddingBottom: [null, null, null, '0px !important'],
      })}
    }
  }
`;

export const StyledImageWrapperBrandX = styled(StyledImageWrapper)`
  ${Media({
    width: ['100%', '100%', '100%', '50%'],
  })}
`;

interface StyledTextWrapperProps {
  alignImageRight?: boolean;
}

export const StyledTextWrapper = styled.div<StyledTextWrapperProps>`
  ${props =>
    Media({
      order: [null, null, 2, props.alignImageRight ? 1 : 2],
      padding: ['8px 0px', null, null, '0px 20px'],
      width: ['100%', null, '50%'],
    })}

  *:first-child {
    margin-top: 0;
  }

  ul li:before {
    background-color: ${props => props.theme.imageAndText.color};
  }

  a,
  a:hover {
    color: ${props => props.theme.imageAndText.color};
  }

  a:hover {
    text-decoration: none;
  }
`;

export const StyledTextWrapperBrandX = styled(StyledTextWrapper)`
  padding: 0px;

  ${Media({
    width: ['100%', '100%', '100%', '50%'],
  })}
`;
