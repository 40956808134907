import styled from 'styled-components';

interface StyledWrapperProps {
  position?: string;
}

interface StyledImageProps {
  size?: string;
}
const calculateWidth = (size: string) => {
  switch (size) {
    case 'Small':
      return '50%';
    case 'Medium':
      return '75%';
    case 'Large':
      return '100%';
    default:
      return '100%';
  }
};

export const StyledImageWrapper = styled.div<StyledImageProps>`
  width: ${props => (props.size ? calculateWidth(props.size) : '100%')};

  @media (max-width: 900px) {
    width: ${props => (props.size === 'Large' ? '100%' : '75%')};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  justify-content: ${props => (props.position === 'Left' ? 'flex-start' : 'center')};
`;
