import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Linkedin as LinkedinIcon,
  Twitter as TwitterIcon,
  Youtube as YoutubeIcon,
} from '@arvesta-websites/icons';
import { ReactElement } from 'react';

import { withErrorBoundary } from '../../ErrorBoundary';

import { StyledFooterSocialList, StyledFooterSocialListItem } from './Styled';

const socialIconMap: Record<string, ReactElement> = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  twitter: <TwitterIcon />,
  youtube: <YoutubeIcon />,
};

type Link = {
  to: string;
  label: string;
};

type propsType = {
  socialLinks?: Link[];
  className?: string;
};

const SocialIcons = ({ socialLinks, className }: propsType) => {
  if (!socialLinks) {
    return null;
  }

  return (
    <StyledFooterSocialList className={className}>
      {socialLinks.map((item, idx) => (
        <StyledFooterSocialListItem key={`social-${item.to}-${idx}`}>
          <a href={item.to} rel="noopener noreferrer" target="_blank">
            {socialIconMap[item.label]}
          </a>
        </StyledFooterSocialListItem>
      ))}
    </StyledFooterSocialList>
  );
};

export default withErrorBoundary(SocialIcons, { componentName: 'SocialIcons' });
