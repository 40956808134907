import { withErrorBoundary } from '../../../components';

import { CardTitle, CardWrapper, Gradient, StyledChevron, StyledImage, TitleWrapper } from './Styled';

type FeauturesSectionLink = {
  cardLink: {
    slug: string;
  };
  title: string;
  inColumn?: boolean;
  id: string;
  image?: any;
};

const BottomCard = ({ item, inColumn }: { item: FeauturesSectionLink; inColumn: boolean }) => {
  const { title, id, image } = item;

  return (
    <CardWrapper key={id}>
      <TitleWrapper inColumn={inColumn}>
        <CardTitle>{title}</CardTitle>
        <StyledChevron />
      </TitleWrapper>
      <StyledImage
        alt={image?.altText ? image.altText : image.title}
        className="bottomCardImage"
        image={image.asset?.gatsbyImageData}
        objectFit="cover"
      />
      <Gradient className="bottomCardGradient" />
    </CardWrapper>
  );
};

export default withErrorBoundary(BottomCard, { componentName: 'ColumnComponent/BottomCard' });
