import { useConfiguration } from '@arvesta-websites/configuration';
import { Location } from '@arvesta-websites/icons';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { useCountryCode } from '../../../../utils';
import { Button, Cta, withErrorBoundary } from '../../../components';
import { Address, Dealer } from '../DealerOverviewModule';

interface ListCardProps {
  className?: string;
  active?: boolean;
  dealer: Dealer;
  onSelect: (id: string) => void;
}

const styles = tv({
  slots: {
    wrapper:
      'relative block bg-white hover:rounded hover:ring-offset-0 hover:ring-2 hover:ring-dealerOverview-card-border overflow-x-hidden',
    content: 'static p-4 pb-[70px] md:pb-[82px] flex items-start gap-4 overflow-x-hidden',
    actions: 'absolute bottom-4 right-4 flex flex-row gap-2 items-end scale-75 origin-right md:scale-100',
    locationName: 'uppercase text-sm mb-0 leading-tight',
    locationAddress: 'mb-0 text-sm leading-tight',
    button: 'max-h-[48px] dealer-overview-card-button',
    icon: 'flex-[0_0_0.7rem]',
  },
  variants: {
    active: {
      true: {
        wrapper: 'rounded ring-offset-0 ring-2 ring-dealerOverview-card-border shadow-lg',
      },
    },
  },
});

export const formatAddress = (toCountryName: (x: string) => string) => (address?: Address) => {
  if (!address) return '';
  const { street, number, cityTown, countryCode } = address;
  const streetPart = street && number ? `${street} ${number}` : street;
  return [streetPart, cityTown, toCountryName(countryCode)].filter(el => !!el).join(', ');
};

const ListCard = ({ className, dealer, onSelect, active = false }: ListCardProps) => {
  const intl = useIntl();
  const toCountryName = useCountryCode();
  const thisCard = useRef<HTMLLIElement>(null);
  const {
    button: { dealerOverview },
  } = useConfiguration();
  const { wrapper, content, actions, locationName, locationAddress, button, icon } = styles();
  const encodedLocation =
    dealer.address?.location && encodeURI(`${dealer.address?.location.lat},${dealer.address?.location.lon}`);

  useEffect(() => {
    if (!active || !thisCard?.current) return;
    thisCard.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  }, [active]);

  const addressFormatter = formatAddress(toCountryName);

  return (
    <li id={`dealer-${dealer.id}`} ref={thisCard} className={wrapper({ class: className, active })}>
      <div onClick={() => onSelect(dealer.id)} data-name="CONTENT" role="button" className={content()}>
        <Location className={icon()} />
        <div>
          <p className={locationName()}>{dealer.name}</p>
          <p className={locationAddress()}>{addressFormatter(dealer.address)}</p>
        </div>
      </div>
      <div data-name="ACTIONS" className={actions()}>
        {dealer.address?.location && (
          <Cta to={`https://www.google.com/maps/dir/?api=1&destination=${encodedLocation}`}>
            <Button className={button()} variant={dealerOverview?.navigation ?? 'secondary'}>
              {intl.formatMessage({ id: 'sections.dealerOverview.route' })}
            </Button>
          </Cta>
        )}
        {dealer.owner?.slug && (
          <Cta to={dealer.owner.slug}>
            <Button className={button()} variant={dealerOverview?.details ?? 'primary'}>
              {intl.formatMessage({ id: 'sections.dealerOverview.details' })}
            </Button>
          </Cta>
        )}
      </div>
    </li>
  );
};

export default withErrorBoundary(ListCard, { componentName: 'DealerOverviewModule/ListCard' });
