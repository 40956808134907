/* eslint-disable react/require-default-props */
import { BoxProps } from 'rebass/styled-components';

import {
  Button,
  DocumentDownload,
  type DocumentDownloadProps,
  HeadingTag,
  ProductBasicTable,
  type ProductType,
  withErrorBoundary,
} from '../../components';

import {
  StyledContainer,
  StyledContentContainer,
  StyledCta,
  StyledDocumentDownloadWrapper,
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledProductBasicTableWrapper,
  StyledSidebar,
  StyledSidebarDescription,
  StyledSidebarHeading,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

interface ProductListingTableModuleProps extends BoxProps {
  title: string;
  titleTag: HeadingTag;
  shortDescription?: string;
  tableRows: ProductType[];
  sidebarTitle: string;
  sidebarDescription: string;
  productCatalogue?: DocumentDownloadProps;
  cta: CtaProps;
}

const ProductListingTableModule = ({
  title,
  titleTag,
  shortDescription,
  tableRows,
  sidebarTitle,
  sidebarDescription,
  productCatalogue,
  cta,
  ...rest
}: ProductListingTableModuleProps) => (
  <StyledContainer {...rest}>
    <StyledHeadingAndDescriptionContainer>
      <StyledHeadingAndDescriptionWrapper>
        <StyledModuleHeading variant="h1" tag={titleTag}>
          {title}
        </StyledModuleHeading>
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledHeadingAndDescriptionWrapper>
    </StyledHeadingAndDescriptionContainer>
    <StyledContentContainer>
      <StyledProductBasicTableWrapper>
        <ProductBasicTable rows={tableRows} />
      </StyledProductBasicTableWrapper>
      <StyledSidebar>
        <StyledSidebarHeading>{sidebarTitle}</StyledSidebarHeading>
        <StyledSidebarDescription>{sidebarDescription}</StyledSidebarDescription>
        {productCatalogue && (
          <StyledDocumentDownloadWrapper>
            <DocumentDownload {...productCatalogue} />
          </StyledDocumentDownloadWrapper>
        )}
        {cta && cta.to ? (
          <StyledCta to={cta.to}>
            <Button>{cta.label}</Button>
          </StyledCta>
        ) : null}
      </StyledSidebar>
    </StyledContentContainer>
  </StyledContainer>
);

export default withErrorBoundary(ProductListingTableModule, { componentName: 'ProductListingTableModule' });
