import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="60"
      version="1.1"
      viewBox="0 -20 426.56 75.587"
      xmlSpace="preserve"
    >
      <path
        fill="#4a7628"
        fillRule="evenodd"
        d="M48.39,8.3l5.07,15.21-15.2-5.07,10.14-10.14h0Zm8.3,20.04h-14.34l7.17,14.34,7.17-14.34h0Zm-8.3,20.04l-10.14-10.14-5.07,15.2,15.21-5.07h0Zm-20.04,8.3v-14.34l-14.34,7.17,14.34,7.17h0Zm-20.04-8.3l10.14-10.14-15.21-5.07,5.07,15.21h0ZM0,28.35H14.34L7.17,14.01,0,28.35H0ZM8.3,8.3l10.14,10.14L23.51,3.23l-15.21,5.07h0Z"
      />
      <path
        fill="#8e8279"
        d="M28.35,22.59c3.18,0,5.75,2.58,5.75,5.75s-2.58,5.75-5.75,5.75-5.75-2.58-5.75-5.75,2.58-5.75,5.75-5.75h0Zm0-22.59V14.34l14.34-7.17L28.35,0h0ZM319.55,10.7c.63,0,1.25,.16,1.86,.49,.6,.33,1.07,.79,1.41,1.4,.34,.6,.5,1.24,.5,1.89s-.17,1.28-.5,1.88c-.33,.6-.8,1.07-1.4,1.4-.6,.33-1.22,.5-1.88,.5s-1.28-.17-1.88-.5c-.6-.33-1.07-.8-1.4-1.4-.33-.6-.5-1.23-.5-1.88s.17-1.29,.51-1.89c.34-.61,.81-1.07,1.41-1.4,.6-.32,1.22-.49,1.86-.49h0Zm0,.62c-.53,0-1.05,.14-1.55,.41-.5,.27-.9,.66-1.18,1.16-.28,.51-.43,1.03-.43,1.58s.14,1.06,.42,1.56,.67,.89,1.17,1.17c.5,.28,1.02,.42,1.57,.42s1.07-.14,1.57-.42c.5-.28,.89-.67,1.17-1.17,.28-.5,.42-1.02,.42-1.56s-.14-1.08-.42-1.58c-.28-.5-.67-.89-1.18-1.16-.51-.27-1.02-.41-1.55-.41h0Zm-1.66,5.24v-4.06h1.4c.48,0,.82,.04,1.04,.11,.21,.08,.38,.21,.51,.39,.12,.19,.19,.39,.19,.6,0,.3-.11,.56-.32,.78-.21,.22-.49,.34-.84,.37,.14,.06,.26,.13,.35,.22,.17,.16,.37,.43,.6,.81l.5,.79h-.8l-.36-.64c-.28-.5-.51-.82-.68-.95-.12-.09-.3-.14-.53-.14h-.38v1.73h-.66Zm.66-2.29h.79c.38,0,.64-.06,.78-.17,.14-.11,.21-.26,.21-.45,0-.12-.03-.23-.1-.32-.07-.09-.16-.17-.28-.21-.12-.05-.34-.07-.66-.07h-.74v1.23h0ZM73.46,28.49v-.1c0-10.06,7.75-17.96,18.21-17.96,7.06,0,11.59,2.96,14.65,7.2l-7.2,5.58c-1.97-2.47-4.24-4.05-7.55-4.05-4.83,0-8.24,4.09-8.24,9.13v.1c0,5.18,3.4,9.23,8.24,9.23,3.6,0,5.72-1.68,7.8-4.19l7.2,5.13c-3.26,4.49-7.65,7.79-15.29,7.79-9.87,0-17.81-7.55-17.81-17.86Zm35.03,0v-.1c0-9.92,7.99-17.96,18.65-17.96s18.55,7.94,18.55,17.86v.1c0,9.92-7.99,17.96-18.65,17.96s-18.55-7.94-18.55-17.86Zm27.43,0v-.1c0-4.98-3.6-9.32-8.88-9.32s-8.73,4.24-8.73,9.23v.1c0,4.98,3.6,9.32,8.83,9.32s8.78-4.24,8.78-9.23Z"
      />
      <path
        fill="#8e8279"
        fillRule="evenodd"
        d="M174.1,27.6c3.06-1.33,5.33-3.7,5.33-7.7v-.1c0-2.37-.84-4.19-2.22-5.58-2.02-2.02-5.08-3.11-9.42-3.11h-17.71V45.66h17.81c8.24,0,13.07-3.6,13.07-9.52v-.1c0-4.69-2.67-7.05-6.86-8.44Zm-14.7-8.78h6.22c2.86,0,4.34,1.09,4.34,2.96v.1c0,2.02-1.63,3.01-4.54,3.01h-6.02v-6.07Zm11.99,15.98c0,2.02-1.68,3.16-4.59,3.16h-7.4v-6.36h7.3c3.21,0,4.69,1.23,4.69,3.11v.1Z"
      />
      <polygon
        fill="#8e8279"
        fillRule="evenodd"
        points="185.35 11.12 213.12 11.12 213.12 19.26 194.82 19.26 194.82 24.49 211.4 24.49 211.4 32.04 194.82 32.04 194.82 37.52 213.37 37.52 213.37 45.66 185.35 45.66 185.35 11.12"
      />
      <polygon
        fill="#8e8279"
        fillRule="evenodd"
        points="218.25 11.12 227.82 11.12 227.82 37.27 244.55 37.27 244.55 45.66 218.25 45.66 218.25 11.12"
      />
      <polygon
        fill="#8e8279"
        fillRule="evenodd"
        points="289.49 11.12 299.06 11.12 299.06 37.27 315.79 37.27 315.79 45.66 289.49 45.66 289.49 11.12"
      />
      <path
        fill="#8e8279"
        fillRule="evenodd"
        d="M271.28,10.88h-9.22l-14.7,34.78h10.06l2.47-6.17h13.32l2.52,6.17h10.26l-14.7-34.78Zm-8.58,21.16l3.9-9.82,3.85,9.82h-7.75Z"
      />
    </svg>
  );
};

export default Logo;
