import { getEmbedIframe } from '../../../utils/video';
import { withErrorBoundary } from '../ErrorBoundary';

import { StyledVideoEmbedded } from './Styled';

export type VideoProps = {
  videoUrl: string;
};

const Video = ({ videoUrl }: VideoProps) => (
  <StyledVideoEmbedded>{getEmbedIframe({ url: videoUrl })}</StyledVideoEmbedded>
);

export default withErrorBoundary(Video, { componentName: 'Video' });
