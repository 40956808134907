import * as React from 'react';

const Instagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#3D3D3D"
      d="M11.696 5.628c-3.318 0-5.995 2.644-5.995 5.923 0 3.278 2.677 5.923 5.995 5.923s5.995-2.645 5.995-5.923c0-3.279-2.677-5.923-5.995-5.923zm0 9.773c-2.144 0-3.897-1.726-3.897-3.85S9.546 7.7 11.696 7.7c2.15 0 3.897 1.726 3.897 3.85s-1.753 3.85-3.897 3.85zm7.638-10.015c0 .768-.626 1.381-1.398 1.381a1.387 1.387 0 01-1.398-1.381 1.39 1.39 0 011.398-1.382 1.39 1.39 0 011.398 1.382zm3.97 1.402c-.088-1.85-.516-3.49-1.888-4.84C20.049.596 18.39.173 16.516.08 14.586-.028 8.8-.028 6.87.08c-1.868.088-3.527.51-4.9 1.861C.6 3.292.177 4.932.083 6.782c-.11 1.908-.11 7.625 0 9.532.089 1.85.517 3.49 1.889 4.84 1.372 1.35 3.026 1.774 4.899 1.866 1.93.109 7.716.109 9.647 0 1.873-.087 3.532-.51 4.899-1.866 1.367-1.35 1.795-2.99 1.889-4.84.11-1.907.11-7.619 0-9.526zM20.812 18.36a3.92 3.92 0 01-2.223 2.196c-1.539.604-5.191.464-6.892.464-1.7 0-5.358.134-6.892-.464a3.923 3.923 0 01-2.223-2.196c-.61-1.52-.47-5.129-.47-6.81 0-1.68-.135-5.293.47-6.809a3.923 3.923 0 012.223-2.196c1.539-.603 5.191-.464 6.892-.464 1.7 0 5.358-.134 6.892.464a3.923 3.923 0 012.223 2.196c.61 1.52.47 5.13.47 6.81 0 1.68.14 5.294-.47 6.81z"
    />
  </svg>
);

export default Instagram;
