import * as React from 'react';

const Xls = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33">
      <path
        fill="#3D3D3D"
        d="M17.927 0L26 8.06v20.534c0 1.816-1.425 3.304-3.212 3.401l-.186.005H5.101c-1.812 0-3.297-1.427-3.394-3.22l-.005-.186v-1.526H1.37c-.712 0-1.297-.544-1.364-1.24L0 25.696v-8.324c0-.713.543-1.3 1.238-1.366L1.37 16h.332V3.406c0-1.814 1.425-3.304 3.212-3.4L5.101 0h12.826zm-.778 2.075L5.1 2.088c-.68 0-1.242.52-1.31 1.183l-.007.135V16H20.1c.712 0 1.297.544 1.364 1.24l.006.132v8.324c0 .713-.543 1.3-1.238 1.366l-.132.006H3.784v1.526c0 .683.52 1.246 1.182 1.313l.135.007h17.501c.68 0 1.241-.52 1.308-1.185l.007-.135-.049-19.707-4.362.012c-1.248 0-2.27-.972-2.352-2.2l-.005-.161V2.075zM15.656 18.82c-1.216 0-1.96.592-1.96 1.656 0 .776.4 1.4 1.432 1.576l.592.096c.608.096.84.312.84.752 0 .536-.352.84-.992.84-.552 0-1.04-.224-1.456-.704l-.632.656c.424.544 1.104.904 2.056.904 1.352 0 2.08-.728 2.08-1.768 0-.832-.472-1.384-1.432-1.56l-.584-.104c-.616-.104-.848-.312-.848-.736 0-.496.312-.752.912-.752.56 0 .976.224 1.272.576l.616-.656c-.384-.48-1.024-.776-1.896-.776zm-10.648.096H3.816l1.536 2.704-1.64 2.88h1.104l.624-1.112.504-.976h.064l.488.976.608 1.112h1.184l-1.632-2.864 1.544-2.72H7.096l-.536.944-.496.968h-.056l-.488-.968-.512-.944zm5.24 0H9.2V24.5h3.52v-.864h-2.472v-4.72z"
        transform="translate(-421 -2395) translate(397 2371.5) translate(24 24)"
      />
    </svg>
  );
};

export default Xls;
