import { IGatsbyImageData, getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';

import { withErrorBoundary } from '../../ErrorBoundary';

import {
  StyledAdviceContent,
  StyledAdviceContentWrapper,
  StyledAdviceCta,
  StyledAdviceCtaWrapper,
  StyledAdviceDescription,
  StyledAdviceHeading,
  StyledAdviceWrapper,
  StyledImageBox,
  StyledImageBoxWrapper,
} from './Styled';

export type AdviceCardProps = {
  title: string;
  shortDescription: string;
  link: string;
  image: IGatsbyImageData;
  slug: string;
};

const AdviceCard = ({ title, shortDescription, image, link }: AdviceCardProps) => {
  const intl = useIntl();

  return (
    <StyledAdviceWrapper>
      <StyledAdviceContentWrapper>
        <StyledAdviceContent>
          <StyledAdviceHeading>{title}</StyledAdviceHeading>
          <StyledAdviceDescription>{shortDescription}</StyledAdviceDescription>
        </StyledAdviceContent>
        <Box>
          <StyledAdviceCtaWrapper>
            <StyledAdviceCta to={link}>
              <span>{intl.formatMessage({ id: 'cards.advice.link' })}</span>
            </StyledAdviceCta>
          </StyledAdviceCtaWrapper>
        </Box>
      </StyledAdviceContentWrapper>
      <StyledImageBoxWrapper>
        <StyledImageBox
          sx={{
            backgroundImage: `url(${getSrc(image)})`,
          }}
        />
      </StyledImageBoxWrapper>
    </StyledAdviceWrapper>
  );
};

export default withErrorBoundary(AdviceCard, { componentName: 'AdviceCard' });
