import { Hamburger as HamburgerIcon, SearchIcon } from '@arvesta-websites/icons';
import React, { useContext, useState } from 'react';
import { Box, type FlexProps } from 'rebass';

import type { LocaleType, NavItemType } from '../../../../types';
import { useScrollDirection } from '../../../../utils';
import { withErrorBoundary } from '../../ErrorBoundary';
import Logo from '../../Logo';
import LangSwitcher, { LangSwitcherLight } from '../LangSwitcher';
import { LocationContext } from '../Location';
import matchUrl from '../matchUrl';
import { MobileSearch } from '../MobileSearch';
import { PrimaryNavigationLight } from '../PrimaryNavigation';
import Search, { SearchLight } from '../Search';
import { SecondaryNavigationLight } from '../SecondaryNavigation';
import { SecondaryNavigationMobileLight } from '../SecondaryNavigationMobile';

import {
  StyledHamburgerMenu,
  StyledHeader,
  StyledHeaderBottom,
  StyledHeaderInnerContainer,
  StyledHeaderTop,
  StyledHeaderTopSecondaryVariant,
  StyledHeaderTopSecondaryVariantInner,
  StyledMobileIcons,
  StyledMobileNavigation,
  StyledRightSection,
  StyledSearchMenu,
  StyledSeparator,
} from './Styled';

export interface HeaderLightProps extends FlexProps {
  siteName: string;
  navigation: NavItemType[];
  ribonLinks?: NavItemType[];
  defaultLocale: LocaleType;
  activeLocales: string[];
  searchFunction: (searchTerm: string) => void;
  variant?: 'default' | 'display search & language selector in separate bar' | 'display top-level navigation';
  isHomepage: boolean;
}

export const HEADERLIGHT_VARIANTS = {
  default: 'default',
  secondary: 'display search & language selector in separate bar',
  topLevel: 'display top-level navigation',
};

const renderSecondaryNavigation = (activeMenuItem: NavItemType | undefined, activeMenuUrl: string) => {
  if (activeMenuItem?.children?.length) {
    return (
      <StyledHeaderBottom>
        <SecondaryNavigationLight activeMenu={activeMenuUrl} items={activeMenuItem.children} />
      </StyledHeaderBottom>
    );
  }

  return null;
};

// Could maybe tidy this up
const renderSecondaryMobileNavigation = (
  isVisible: boolean,
  menuItems: Array<NavItemType>,
  activeMenuUrl: string,
  closeNav: () => void,
  defaultLocale: LocaleType,
  activeLocales: Array<string>,
) => {
  if (isVisible) {
    return (
      <StyledMobileNavigation>
        <SecondaryNavigationMobileLight
          activeLocales={activeLocales}
          activeMenuUrl={activeMenuUrl}
          defaultLocale={defaultLocale}
          items={menuItems}
          onClose={() => {
            closeNav();
          }}
        />
      </StyledMobileNavigation>
    );
  }

  return null;
};

const HeaderLight = ({
  siteName,
  navigation,
  defaultLocale,
  activeLocales,
  searchFunction,
  variant,
  isHomepage,
}: HeaderLightProps) => {
  const currentLocation = useContext(LocationContext);
  const [currentPageUrl] = useState(currentLocation.pathname);
  const [visible, setVisible] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currentItem, setCurrentItem] = useState({} as NavItemType);
  const [mobileSecondaryMenuVisible, setMobileSecondaryMenuVisible] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const direction = useScrollDirection({ initialDirection: 'up', off: false, thresholdPixels: 50 });

  React.useEffect(() => {
    if (loaded) {
      setVisible(direction === 'up');
    }
    setLoaded(true);
  }, [direction]);

  const activeMenuItem = navigation.find(navItem => {
    if (!currentPageUrl) {
      return false;
    }
    return matchUrl(currentPageUrl, navItem);
  });

  const secondary = (activeMenuItem && activeMenuItem.children) || null;
  const classes = [];

  const handleNavItemClicked = (navItem: NavItemType) => {
    if (navItem.children?.length) {
      setCurrentItem(navItem);
    } else {
      setCurrentItem(null);
    }
  };

  if (secondary) {
    classes.push('has-secondary');
  }

  if (loaded) {
    if (!visible) {
      classes.push('is-hidden');
    }
  }

  return (
    <>
      <StyledHeader className={classes.join(' ')}>
        {variant === HEADERLIGHT_VARIANTS.secondary && (
          <StyledHeaderTopSecondaryVariant>
            <StyledHeaderTopSecondaryVariantInner>
              <SearchLight
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                variant={variant}
              />
              <LangSwitcherLight activeLocales={activeLocales} defaultLocale={defaultLocale.id} variant={variant} />
            </StyledHeaderTopSecondaryVariantInner>
          </StyledHeaderTopSecondaryVariant>
        )}
        <StyledHeaderInnerContainer>
          <StyledHeaderTop>
            {mobileSearchVisible ? (
              <MobileSearch
                onClose={() => setMobileSearchVisible(false)}
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
              />
            ) : (
              <>
                <Box>
                  <Logo defaultLocale={defaultLocale} siteName={siteName} variant={variant} />
                </Box>
                <PrimaryNavigationLight
                  activeMenuItem={activeMenuItem || null}
                  currentItem={currentItem}
                  handleClickMenuItem={handleNavItemClicked}
                  items={navigation}
                  variant={variant}
                />
                {variant !== HEADERLIGHT_VARIANTS.secondary && (
                  <StyledRightSection>
                    <Search
                      searchFunction={searchTerm => {
                        searchFunction(searchTerm);
                      }}
                      variant={variant}
                    />
                    <LangSwitcher activeLocales={activeLocales} defaultLocale={defaultLocale.id} variant={variant} />
                  </StyledRightSection>
                )}
                <StyledMobileIcons>
                  <StyledSearchMenu>
                    <span
                      onClick={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      onKeyUp={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <SearchIcon />
                    </span>
                  </StyledSearchMenu>
                  <StyledHamburgerMenu>
                    <span
                      onClick={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      onKeyUp={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <HamburgerIcon />
                    </span>
                  </StyledHamburgerMenu>
                </StyledMobileIcons>
              </>
            )}
          </StyledHeaderTop>
        </StyledHeaderInnerContainer>
        <StyledSeparator />
        {currentItem?.to && renderSecondaryNavigation(currentItem, currentPageUrl)}
      </StyledHeader>
      {renderSecondaryMobileNavigation(
        mobileSecondaryMenuVisible,
        navigation,
        currentPageUrl,
        setMobileSecondaryMenuVisible as () => void,
        defaultLocale,
        activeLocales,
      )}
    </>
  );
};

export default withErrorBoundary(HeaderLight, { componentName: 'HeaderLight' });
