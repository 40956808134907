import React from 'react';

const FlagPt = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 31.66">
    <g id="Vlaggen">
      <g>
        <path
          fill="#fafafa"
          d="M31.66,15.83H0c0-1.76,.29-3.44,.81-5.02,.72-2.15,1.9-4.09,3.41-5.72C7.11,1.96,11.23,0,15.83,0c6.98,0,12.91,4.53,15.01,10.81,.53,1.58,.82,3.26,.82,5.02Z"
        />
        <path
          fill="#e52528"
          d="M31.66,15.83c0,1.75-.29,3.44-.82,5.02-2.1,6.28-8.03,10.81-15.01,10.81-4.43,0-8.43-1.82-11.3-4.75-1.66-1.7-2.95-3.76-3.72-6.06-.52-1.58-.81-3.27-.81-5.02H31.66Z"
        />
      </g>
    </g>
  </svg>
);

export default FlagPt;
