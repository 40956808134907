import { Box, BoxProps } from 'rebass/styled-components';

import { Button, type CategoryCardProps as CardProps, Cta, withErrorBoundary } from '../../components';

import {
  StyledBlueBox,
  StyledCardContainer,
  StyledCategoryCard,
  StyledContainer,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

export interface AdviceCardModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  advices: CardProps[];
  cta?: CtaProps | null;
}

const AdviceCardModule = ({ title, shortDescription, advices, cta }: AdviceCardModuleProps) => (
  <StyledContainer data-appsearch-ignore="true">
    <StyledModuleHeading>{title}</StyledModuleHeading>
    {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}

    <StyledCardContainer className={`cards-${advices.length}`}>
      {advices.map((card: CardProps) => (
        <StyledCategoryCard
          className={`cards-${advices.length}`}
          id={card.id}
          image={card.image}
          key={`category-${card.id}`}
          link={card.link}
          shortDescription={card.shortDescription || null}
          title={card.title}
        />
      ))}
      <StyledBlueBox />
    </StyledCardContainer>

    {cta && (
      <Box>
        <Cta to={cta.to}>
          <Button>{cta.label}</Button>
        </Cta>
      </Box>
    )}
  </StyledContainer>
);

export default withErrorBoundary(AdviceCardModule, { componentName: 'AdviceCardModule' });
