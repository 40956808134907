import { Config, useConfiguration } from '@arvesta-websites/configuration';
import { HeroBannerCustomMaskInverted, HeroBannerMaskInverted } from '@arvesta-websites/icons';
import React from 'react';
import Slider from 'react-slick';

import { HeadingTag, withErrorBoundary } from '../../../../components';
import { StyledHeroContentWrapper, StyledSimpleHeroWrapper, StyledTitle, StyledWrapper } from '../../Styled';
import { toDataUrl } from '../../utils';
import HeroBannerWrapper from '../HeroBannerWrapper';
import SliderArrow from '../SliderArrow';

export type CarouselWrapperProps = {
  title: string;
  titleTag: HeadingTag;
  isHero: boolean;
  windowWidth: number;
  shouldBeInfinite: boolean;
  sliderRef: React.RefObject<Slider>;
  children: React.ReactNode;
  hasOverlaySVG?: string | null;
  height?: 'Regular' | 'Large';
};

type HeroBannerType = Config['heroBanner'];

const getMask = (
  heroBanner: HeroBannerType,
  device: 'Desktop' | 'Tablet' | 'Mobile',
  hasOverlaySVG?: string | null,
) => {
  if (hasOverlaySVG) {
    return undefined;
  }
  if (heroBanner.customMask) {
    return toDataUrl(HeroBannerCustomMaskInverted[device]);
  }
  return toDataUrl(HeroBannerMaskInverted[device]);
};

const HeroWithOrWithoutMask = ({
  children,
  height,
  isHero,
  hasOverlaySVG,
}: {
  children: React.ReactNode;
  height: 'Regular' | 'Large' | undefined;
  isHero: boolean;
  hasOverlaySVG?: string | null;
}) => {
  const { heroBanner } = useConfiguration();

  if (!isHero) {
    return children;
  }

  if (!(process.env.BRAND_TAG === 'brand_equilannoo' || process.env.BRAND_TAG === 'brand_aveveagrarisch2024')) {
    // Temporary solution for Proxani - TWDS-442 and TWDS-449
    return <StyledSimpleHeroWrapper height={height}>{children}</StyledSimpleHeroWrapper>;
  }

  return (
    <StyledHeroContentWrapper
      height={height}
      maskPresent={heroBanner.mask && !heroBanner.customMask}
      customMaskPresent={heroBanner.customMask}
      desktopMask={getMask(heroBanner, 'Desktop', hasOverlaySVG)}
      tabletMask={getMask(heroBanner, 'Tablet', hasOverlaySVG)}
      mobileMask={getMask(heroBanner, 'Mobile', hasOverlaySVG)}
    >
      {children}
    </StyledHeroContentWrapper>
  );
};

const CarouselWrapper = ({
  title,
  titleTag,
  isHero,
  windowWidth,
  shouldBeInfinite,
  sliderRef,
  children,
  hasOverlaySVG,
  height,
}: CarouselWrapperProps) => {
  return (
    <StyledWrapper isHero={isHero}>
      {title && !isHero && (
        <StyledTitle variant="h2" tag={titleTag}>
          {title}
        </StyledTitle>
      )}
      <HeroBannerWrapper isHero={isHero} windowWidth={windowWidth} shouldBeInfinite={shouldBeInfinite} height={height}>
        <SliderArrow
          isHero={isHero}
          isInfinite={shouldBeInfinite}
          windowWidth={windowWidth}
          side="left"
          handleClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickPrev();
            }
          }}
        />
        <HeroWithOrWithoutMask height={height} isHero={isHero} hasOverlaySVG={hasOverlaySVG}>
          {children}
        </HeroWithOrWithoutMask>
        <SliderArrow
          isHero={isHero}
          isInfinite={shouldBeInfinite}
          windowWidth={windowWidth}
          side="right"
          handleClick={() => {
            if (sliderRef.current) {
              sliderRef.current.slickNext();
            }
          }}
        />
      </HeroBannerWrapper>
    </StyledWrapper>
  );
};

export default withErrorBoundary(CarouselWrapper, { componentName: 'CarouselWrapper' });
