import React from 'react';

const Logo = () => (
  <svg
    id="AveveAgrarisch_Logo"
    data-name="AveveAgrarisch Logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 134.898 76.804"
    preserveAspectRatio="xMidYMid"
  >
    <g id="Group_1" data-name="Group 1">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M134.9,27.328C41.811-17.511,7.639-7.836,0,61.27c104.981,35.244,126.457,4.738,134.9-34.012"
        transform="translate(0 0)"
        fill="#00936f"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M200.736,145.275h-2.457a3.193,3.193,0,0,0-3.027,2.176c-.773,2.3-1.963,5.87-2.591,7.886-.146.47-.3.936-.466,1.444a.544.544,0,0,1-.209-.345c-.446-1.415-.9-2.829-1.335-4.248-.349-1.128-.9-2.966-1.343-4.456a3.415,3.415,0,0,0-3.232-2.441c-1.178-.015-2.42-.026-2.868-.019-.334,0-.421.137-.276.455.024.051.037.107.055.16,1.424,3.912,3.906,10.739,5.284,14.528a4.137,4.137,0,0,0,7.734.105c.223-.56.432-1.1.57-1.478q2.348-6.479,4.7-12.957c.3-.813.305-.811-.541-.811"
        transform="translate(-143.024 -113.62)"
        fill="#fff"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M104.019,117.85c-1.187-2.612-2.629-2.83-3.919-2.849-1.087-.017-1.825.054-2.623.042a.762.762,0,0,0-.855.593q-.823,2.182-1.663,4.356h0c-.448,1.16-.915,2.381-1.39,3.624-.226.589-.454,1.177-.679,1.766q-1.344,3.512-2.686,7.027h.017c-.755,1.977-1.436,3.749-1.972,5.1-.205.521-.2.524.348.53.484.013,1.762.091,2.991.118a2.7,2.7,0,0,0,2.6-1.693c.172-.439.661-1.776,1.288-3.492.629,0,4.2-.008,5.3-.013.458,0,.458,0,.3-.424-.09-.254-.772-2.25-1.311-3.81h-2.74c.494-1.349.99-2.7,1.438-3.9.473-1.2.911-2.313,1.251-3.282.029-.077.071-.144.144-.293.392,1.142.756,2.2,1.129,3.266.131.374.663,1.9,1.364,3.927l1.652,4.755c1.5,4.3,3.044,8.737,3.362,9.575a2.871,2.871,0,0,0,2.744,1.831c1.305-.012,2.663-.078,3.178-.086.578,0,.588,0,.377-.558-2.408-6.312-7.35-21.052-9.648-26.107"
        transform="translate(-68.925 -89.944)"
        fill="#fff"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M446.553,146.042a8.5,8.5,0,0,0-13.573,1.938,10.333,10.333,0,0,0-.491,8.585,7.991,7.991,0,0,0,4.811,4.9,10.463,10.463,0,0,0,6.011.428,8.807,8.807,0,0,0,4.449-2.332c.262-.256.3-.449.041-.741-.1-.119-.229-.261-.364-.419a3.764,3.764,0,0,0-4.2-1.043,3.782,3.782,0,0,1-.8.216,3.933,3.933,0,0,1-4.935-2.62c-.155-.486-.126-.528.4-.529q2.569,0,5.138,0c1.727,0,3.454-.006,5.18,0,.307,0,.508-.058.57-.407a8.841,8.841,0,0,0-2.238-7.975m-3.282,4.953c-.933,0-1.866,0-2.8,0s-1.838,0-2.757,0c-.185,0-.394.027-.324-.282a3.437,3.437,0,0,1,2.406-2.748c2.165-.444,3.449.873,3.847,2.685.07.318-.135.349-.373.348"
        transform="translate(-337.714 -112.211)"
        fill="#fff"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M364.345,145.275h-2.457a3.193,3.193,0,0,0-3.027,2.176c-.773,2.3-1.962,5.87-2.591,7.886-.146.47-.3.936-.466,1.444a.542.542,0,0,1-.208-.345c-.446-1.415-.9-2.829-1.336-4.248-.349-1.128-.9-2.966-1.343-4.456a3.415,3.415,0,0,0-3.232-2.441c-1.178-.015-2.42-.026-2.869-.019-.333,0-.421.137-.276.455.024.051.037.107.056.16,1.423,3.912,3.906,10.739,5.283,14.528a4.137,4.137,0,0,0,7.734.105c.223-.56.431-1.1.569-1.478q2.349-6.479,4.7-12.957c.3-.813.3-.811-.54-.811"
        transform="translate(-270.988 -113.62)"
        fill="#fff"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M275.219,143.585a7.966,7.966,0,0,0-6.189,4.394,10.337,10.337,0,0,0-.49,8.585,7.988,7.988,0,0,0,4.811,4.9,10.462,10.462,0,0,0,6.011.428,8.805,8.805,0,0,0,4.45-2.332c.262-.256.3-.449.041-.741-.1-.119-.229-.261-.364-.419a3.764,3.764,0,0,0-4.2-1.043,3.79,3.79,0,0,1-.8.216,3.932,3.932,0,0,1-4.935-2.62c-.155-.486-.126-.528.395-.529q2.568,0,5.137,0c1.727,0,3.454-.006,5.181,0,.307,0,.508-.058.57-.407a8.839,8.839,0,0,0-2.237-7.975,7.975,7.975,0,0,0-7.385-2.456m4.1,7.409c-.933,0-1.866,0-2.8,0q-1.378,0-2.757,0c-.185,0-.393.027-.324-.282a3.438,3.438,0,0,1,2.405-2.748c2.165-.444,3.448.873,3.848,2.685.07.318-.134.349-.373.348"
        transform="translate(-209.483 -112.211)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Logo;
