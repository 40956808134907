import React from 'react';
import { ButtonProps as RebassButtonProps } from 'rebass';

import { withErrorBoundary } from '../ErrorBoundary';

import StyledButton from './Styled';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'primary-with-background';
export interface ButtonProps extends RebassButtonProps {
  variant?: ButtonVariant;
  children: React.ReactNode;
}

const Button = ({ children, variant = 'primary', disabled = false, ...rest }: ButtonProps) => (
  <StyledButton disabled={disabled} variant={disabled ? 'disabled' : variant} {...(rest as RebassButtonProps)}>
    {children}
  </StyledButton>
);

export default withErrorBoundary(Button, { componentName: 'Button' });
