import React from 'react';

const Leaf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660.01 375.77" preserveAspectRatio="xMinYMax">
    <g id="Laag_2" data-name="Laag 2">
      <g id="Laag_1-2" data-name="Laag 1">
        <g id="Group_1" data-name="Group 1">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M811.3,226.82c-455.45-219.38-622.64-172-660,166.06,513.63,172.44,618.7,23.18,660-166.4"
            transform="translate(-151.29 -93.12)"
            style={{ fill: '#00936f' }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Leaf;
