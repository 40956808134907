import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Cta } from '../../components';

export const StyledContainer = styled(Box)`
  position: relative;
  max-width: ${props => props.theme.sizes.xl};
  margin: 0 auto;
  ${Media({
    marginLeft: ['1.25rem', null, null, '0px'],
    marginRight: ['1.25rem', null, null, '0px'],
  })};
`;

export const StyledLoadingContainer = styled(Box)`
  min-height: 80vh;
`;

export const StyledResultsContainer = styled(Box)`
  ${Media({
    marginLeft: ['0px', null, '1rem', `1rem`],
    marginRight: ['0px', null, '1rem', '1rem'],
  })}
`;

export const StyledResultsHeader = styled(Box)`
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  justify-content: center;
  align-items: center;
  display: flex;

  ${Media({
    marginBottom: ['2rem', null, null, '2.625rem'],
    marginLeft: ['0', null, null, `calc(340 / 1324 * 100%)`],
    maxWidth: ['100%', null, null, `calc(637 / 1324 * 100%)`],
    paddingTop: ['3.125rem', null, null, '4.5rem'],
  })}
`;

export const StyledResultsHeading = styled.h2`
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  font-weight: ${props => `${props.theme.fontWeights.heading}`};
  line-height: 1.2;
  margin: 0;
  word-break: break-word;

  ${Media({
    fontSize: ['2.5rem', null, null, '3.5rem'],
  })}
`;

export const StyledResultsList = styled(Box)`
  max-width: 1100px;
  margin: 0 auto;

  /* show the result in pairs to prevent large images*/
  ${Media({
    display: ['block', 'flex', null, 'block'],
    flexWrap: ['wrap', 'wrap', null, 'nowrap'],
    justifyContent: ['left', 'space-between', null, 'left'],
    marginBottom: ['5rem', null, null, '6rem'],
  })}

  > div {
    ${Media({
      width: ['100%', null, '48%', '100%'],
    })}

    > div:first-child {
      ${Media({
        flexGrow: [null, null, null, '0'],
      })}
    }
  }
`;

export const StyledResultRow = styled(Flex)`
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  min-height: 222px;
  margin-bottom: 1rem;

  ${Media({
    flexDirection: ['column', null, null, 'row'],
    maxHeight: ['none', null, null, '222px'],
  })}
`;

export const StylededResultContent = styled(Box)`
  flex: 1;
  ${Media({
    flex: ['1', '1', '1', '0 1 auto'],
    padding: ['1.5rem', null, null, '2rem'],
    width: ['100%', null, null, `calc(100% - 222px)`],
  })}

  h3 {
    font-weight: ${props => `${props.theme.fontWeights.headingAlt}`};
    color: ${props => `${props.theme.colors.text}`};
    margin: 0;

    ${Media({
      marginBottom: ['0.5rem', null, null, '1rem'],
    })}
  }
`;

export const StylededResultContentImage = styled(Cta)`
  flex: 1;
  ${props =>
    Media({
      borderBottom: [
        `1px solid ${props.theme.colors.lightGray}`,
        `1px solid ${props.theme.colors.lightGray}`,
        `1px solid ${props.theme.colors.lightGray}`,
        'none',
      ],
      borderRight: ['none', null, null, `1px solid ${props.theme.colors.lightGray}`],
      marginRight: ['0px', null, null, '1rem'],
      padding: ['1.5rem', null, null, '2rem'],
      width: ['100%', null, null, `calc(100% - 222px)`],
    })}
`;

export const StyledHeading = styled(Cta)`
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 1rem;
  font-weight: ${props => props.theme.fontWeights.headingAlt};
  color: ${({ theme }) => theme.text.heading.color ?? theme.colors.text};
  margin: 0;
`;

export const StyledText = styled(Text)`
  line-height: ${props => props.theme.lineHeights.paragraph};
  color: ${props => props.theme.colors.text};

  ${Media({
    display: ['block', null, null, '-webkit-box'],
    marginTop: ['0.5rem', null, null, '1rem'],
    overflow: ['visible', null, null, 'hidden'],
    paddingRight: ['0', null, null, `calc(100 / 878 * 100%)`],
    textOverflow: ['ellipsis', null, null, 'ellipsis'],
  })}

  @media all and (min-width: 920px) {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

export const StyledResultImage = styled(Box)`
  img {
    width: 100%;
  }
`;

export const StyledResultImageWrapperProductImage = styled(Box)`
  position: relative;
  margin-right: 1rem;

  ${Media({
    height: ['333px!important', null, 'auto!important', null],
    margin: ['0 auto', null, null, '0 1rem 0 0'],
    width: ['80%', null, null, '222px'],
  })}

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((1 / 1) * 100%);
  }

  > ${StyledResultImage} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const StyledResultImageWrapper = styled(Cta)`
  position: relative;
  margin-right: 1rem;
  margin: 0px;
  overflow: hidden;

  ${Media({
    height: ['333px!important', null, 'auto!important', null],
    width: ['100%', null, null, '267px'],
  })}

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((1 / 1) * 100%);
  }

  > ${StyledResultImage} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const StyledNoResultsContainer = styled(Flex)`
  max-width: ${props => props.theme.sizes.xl};
  margin: 0 auto;

  ${Media({
    flexDirection: ['column', null, null, 'row'],
    marginLeft: ['1.25rem', null, null, '56px'],
    marginRight: ['1.25rem', null, null, '56px'],
    paddingTop: ['3rem', null, null, '5.125rem'],
  })}
`;

export const StyledNoResultsMain = styled.main`
  color: ${props => `${props.theme.colors.text}`};

  ${Media({
    width: ['100%', null, null, `calc(792 / 1324 * 100%)`],
  })}

  h2 {
    font-weight: ${props => `${props.theme.fontWeights.heading}`};
    margin: 0 1rem;

    ${Media({
      fontSize: ['2.5rem', null, null, '3.5rem'],
    })}
  }

  ${StyledText} {
    p {
      font-size: 1.25rem;
    }
  }
`;

export const StyledNoResultsAside = styled.aside`
  ${Media({
    width: ['100%', null, null, `calc(532 / 1324 * 100%)`],
  })}
`;

export const StyledContactsFormModule = styled(Box)`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

  ${Media({
    padding: ['1.5rem', null, null, '2rem'],
  })}

  h3 {
    font-weight: ${props => `${props.theme.fontWeights.headingAlt}`};
    margin: 0;
  }
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledImageBoxLink = styled(Cta)``;

export const StyledHorizontalEventCardWrapperSearch = styled(Flex)`
  ${Media({
    marginBottom: ['1rem', null, null, '0'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }

  > .horizontal,
  > .horizontal div {
    max-width: none;
  }

  > .horizontal {
    ${Media({
      marginTop: [null, null, null, '1rem'],
    })}
  }
`;

export const StyledHorizontalEventsCardWrapperSearch = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledCategoriesContainer = styled.div`
  ${Media({
    marginLeft: ['-1rem', '-1rem', '-32px'],
    marginRight: ['-1rem', '-1rem', '-32px'],
  })}
`;

export const StyledModuleHeading = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: normal;
  margin-bottom: 40px;
  word-break: break-word;
  margin-top: 0px;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
    marginBottom: ['40px', null, null, '24px'],
  })}
`;

export const StyledModuleCtaContainerSearch = styled.div`
  ${Media({
    marginTop: ['7px', null, null, '32px'],
  })}
`;

export const StyledResultsOuterContainer = styled.div`
  overflow-y: hidden;
`;
