export default {
  'brandListing.all': 'Todos',
  'brandListing.byCategories': 'Por categorias',
  'brandListing.clear': 'Apagar',
  'brandListing.productsAndServices': 'Produtos e serviços',
  'brandListing.seeProductsFor': 'Ver produtos para',
  'brandListing.similarBrands': 'Marcas com produtos e serviços semelhantes',
  'brandListing.visitWebsite': 'Visite o website',
  'cards.advice.link': 'Ler artigo',
  'cards.category.link': 'Ler mais',
  'cards.event.link': 'Ver evento',
  'cards.news.link': 'Continuar a leitura',
  'cards.product.link': 'Ver mais',
  'footer.copyright': '© {year} Arvesta. Todos os direitos reservados.',
  'footer.newsletter.error': 'Ocorreu um erro com os dados que inseriu, por favor tente novamente.',
  'footer.newsletter.heading':
    'Gostaria de receber novidades e conselhos que correspondam aos seus interesses? Teremos todos o prazer em mantê-lo/a informado/a!',

  'footer.newsletter.placeholder': 'Inserir endereço de e-mails',
  'footer.newsletter.subscribe': 'Inscrever',
  'footer.newsletter.success': 'Inscrição completada, obrigado. Iremos dar-lhe notícias em breve.',
  'footer.subscribe.enteremail': 'Inserir endereço de e-mails',
  'footer.subscribe.failure': 'Ocorreu um erro com os dados que inseriu, por favor tente novamente.',
  'footer.subscribe.invalid_email': 'E-mail inválido',
  'footer.subscribe.subscribe': 'Inscrever',
  'footer.subscribe.subscribing': 'A inscrever',
  'footer.subscribe.success': 'Inscrição completada, obrigado. Iremos dar-lhe notícias em breve.',
  'forms.contact.description': 'Preencha o formulário abaixo e a nossa equipa entrará em contacto consigo',
  'forms.contact.email.label': 'Endereço de e-mail',
  'forms.contact.email.placeholder': 'Inserir endereço de e-mail',
  'forms.contact.errors.email': 'E-mail inválido',
  'forms.contact.errors.required': 'Este campo é obrigatório',
  'forms.contact.errors.telephone': `Por favor, insira apenas números, espaços e o símbolo "+". O primeiro carácter deve ser um "+" ou um número`,
  'forms.contact.failure': 'Ocorreu um erro com os dados que inseriu, por favor tente novamente. ',
  'forms.contact.fullname.label': 'Nome completo',
  'forms.contact.fullname.placeholder': 'Por favor insira o seu nome completo',
  'forms.contact.message.label': 'Mensagem',
  'forms.contact.message.placeholder': 'Insira a sua mensagem',
  'forms.contact.telephone.label': 'Número de telefone',
  'forms.contact.telephone.placeholder': 'Digite seu número de telefone',
  'forms.contact.other': 'Outro',
  'forms.contact.newsletter.title':
    'Gostaria de receber e-mails com novidades, atualizações de produtos, informações sobre eventos? Pode inscrever-se mais tarde.',
  'forms.contact.submit': 'Enviar',
  'forms.contact.success': 'Obrigado, já estamos a ver a sua mensagem',
  'forms.contact.title': 'Envie-nos uma mensagem',
  'globals.download': 'Descarregar',
  'globals.featuredProducts': 'Produtos em destaque',
  'globals.no': 'Não',
  'globals.yes': 'Sim',
  'header.search.placeholder': 'Procurar',
  'header.selectlanguage': 'Selecionar idioma',
  'legacy.cookie.description': 'Ao continuar a navegar no nosso site, estará a concordar com o nosso uso de',
  'legacy.footer.headoffice': 'Sede principal',
  'legacy.footer.headquarters': 'Escritório principal',
  'legacy.header.about': 'acerca de',
  'legacy.header.contact': 'contacto',
  'legacy.header.jobs': 'vagas de emprego',
  'legacy.header.news': 'novidades e imprensa',
  'legacy.header.products': 'produtos e serviços',
  'newsletter.email.error': 'Por favor insira um endereço de e-mail válido',
  'newsletter.email.success': 'Você se inscreveu com sucesso. Você vai ouvir de nós em breve.',
  'newsletter.input.placeholder': 'E-mailadress',
  'newsletter.terms.error': 'Por favor, concorde com as condições gerais de privacidade',
  'newsletter.terms.privacyPolicy': 'política de Privacidade',
  'newsletter.terms.sectionOne': 'eu concordo com o',
  'newsletter.terms.sectionTwo': 'de Proxani',
  'newsletter.terms.subscribe': 'Se inscrever',
  'owner.storecard.contact': 'Contacto',
  'owner.storecard.route': 'Direcções',
  'owner.storecard.openinghours': 'Horário de abertura',
  'owner.storecard.services': 'Serviços',
  'product.certifications.title': 'Certificações de produtos',
  'sections.advice.loadmore': 'Carregar mais conselhos',
  'sections.category.loadmore': 'Carregar mais categorias',
  'sections.contact.offices': 'Escritórios',
  'sections.contact.openinghours': 'Horários de abertura',
  'sections.contact.salesteam': 'Equipa',
  'sections.contact.viewonmap': 'Visualizar no mapa',
  'sections.dealer.clear': 'LIMPAR',
  'sections.dealer.invalidaddress': "Couldn't find addressNão foi possível encontrar o endereço",
  'sections.dealer.loadmore': 'Ver todos os distribuidores',
  'sections.dealer.maplink': 'Visualizar no mapa',
  'sections.dealer.placeholder': 'Introduza o seu código postal e município',
  'sections.dealer.search': 'Procurar',
  'sections.dealer.total': '{total} Resultados para "{query}"',
  'sections.dealerOverview.details': 'Pormenores',
  'sections.dealerOverview.route': 'Direcções',
  'sections.dealerOverview.no_results': 'Nenhum resultado',
  'sections.dealerOverview.show_all': 'Voltar à lista de todas as lojas',
  'sections.dealerOverview.clear_filters': 'Limpar todos os filtros',
  'sections.dealerOverview.remove_filter': 'Remover filtro',
  'sections.dealerOverview.services': 'Serviços',
  'sections.events.loadmore': 'Carregar mais eventos',
  'sections.events.loadmorepast': 'Carregar mais eventos passados',
  'sections.news.loadmore': 'Carregar mais novidades',
  'sections.product.loadmore': 'Carregar mais',
  'sections.product.noproductsfoundfor': 'Não foram encontrados produtos para',
  'sections.product.table.brochure': 'PANFLETO',
  'sections.product.table.download': 'Descarregar',
  'sections.product.table.number': 'N° de artigo',
  'sections.product.table.product': 'PRODUTO',
  'sections.product.table.weight': 'PESO',
  'sections.search.events': 'Eventos',
  'sections.search.news': 'Novidades',
  'sections.search.noncategorised': 'Não categorizado',
  'sections.search.noresults.title': 'Sorry we havent found any results for ',
  'sections.search.products': 'Produtos',
  'sections.search.results.title': '{total} Resultados para "{query}"',
  'sections.search.seeallevents': 'Ver todos os eventos',
  'sections.search.seeallnews': 'Ver todas as novidades',
  'sections.search.seeallproducts': 'Ver todos os produtos',
};
