import merge from 'lodash/merge';

import defaultConfig from './default-config';

type DeepPartial<T> = T extends Array<infer U> ? Array<DeepPartial<U>> : { [K in keyof T]?: DeepPartial<T[K]> };

const mergeConfig = (configOverride: DeepPartial<typeof defaultConfig>): typeof defaultConfig => {
  return merge({}, defaultConfig, configOverride);
};

export default mergeConfig;
