import { useConfiguration } from '@arvesta-websites/configuration';
import { GatsbyImage } from 'gatsby-plugin-image';

import { getDestination } from '../../../utils';
import { Button, Cta, withErrorBoundary } from '../../components';
import { documentToReactComponents } from '../../components/RichText';

import BottomCard from './BottomCard';
import {
  FlexItem,
  StyledCTA,
  StyledColoredBox,
  StyledCta,
  StyledDescription,
  StyledDescriptionColored,
  StyledRTE,
  StyledTitle,
  StyledTitleColored,
  StyledWrapper,
} from './Styled';
interface ColumnProps {
  __typename: string;
  content: any;
}

const COLUMN_TYPES = {
  IMAGE: 'ContentfulImage',
  IMAGE_CARD: 'ContentfulImageCardImageLink',
  INFO_BLOCK: 'ContentfulInfoBlock',
  RICH_TEXT: 'ContentfulRichTextSection',
};

const ColumnComponent = ({ content }: ColumnProps) => {
  const config = useConfiguration();
  return (
    <StyledWrapper>
      {content?.map((item: any) => {
        if (item?.type === COLUMN_TYPES.RICH_TEXT) {
          return (
            <FlexItem key={item.id} mb={'auto'} items={content.length}>
              <StyledRTE>{documentToReactComponents(item.richText.json)} </StyledRTE>
            </FlexItem>
          );
        }
        if (item?.type === COLUMN_TYPES.IMAGE) {
          return (
            <FlexItem key={item.id} items={content.length}>
              <GatsbyImage alt="" image={item?.gatsbyImageData} style={{ height: '100%' }} />
            </FlexItem>
          );
        }
        if (item?.type === COLUMN_TYPES.IMAGE_CARD) {
          return (
            <FlexItem key={item.id} items={content.length}>
              <StyledCTA to={item?.link} style={{ width: '100%' }}>
                <BottomCard
                  inColumn
                  item={{
                    ...item,
                    title: item.imageTitle,
                  }}
                />
                <StyledTitle>{item.title}</StyledTitle>
                <StyledDescription>{item.description}</StyledDescription>
              </StyledCTA>
            </FlexItem>
          );
        }
        if (item?.type === COLUMN_TYPES.INFO_BLOCK) {
          return (
            <FlexItem key={item.id} items={content.length}>
              <StyledColoredBox>
                <StyledTitleColored>{item.title}</StyledTitleColored>
                <StyledDescriptionColored>{documentToReactComponents(item.description)}</StyledDescriptionColored>
                {!!item.cta && (
                  <StyledCta>
                    <Cta to={item.cta?.externalUrl ?? getDestination(item.cta?.internalEntry)}>
                      <Button variant={config?.button?.infoBlockColumns}>{item.cta.title}</Button>
                    </Cta>
                  </StyledCta>
                )}
              </StyledColoredBox>
            </FlexItem>
          );
        }
      })}
    </StyledWrapper>
  );
};

export default withErrorBoundary(ColumnComponent, { componentName: 'ColumnComponent' });
