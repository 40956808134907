import { ChevronRightList } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

export const CardTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-weight: bold;
  filter: drop-shadow(1px 1px 0px #0000004d);

  &:hover {
    text-decoration: none;
  }

  ${Media({
    fontSize: ['21px', null, '21px', '36px'],
    lineHeight: ['27px', null, '27px', '45px'],
  })}
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  transition: all 0.3s ease-in-out;

  ${Media({
    height: ['0 !important', null, '100% !important'],
    paddingBottom: ['100%', null, '0px'],
    position: [null, null, 'absolute !important'],
    width: ['100% !important', null, '100%'],
  })}
`;

export const StyledChevron = styled(ChevronRightList)`
  align-self: flex-end;
  color: #f8faf2;
  opacity: 0.9;

  ${Media({
    height: [null, null, null, '32px'],
    width: [null, null, null, '32px'],
  })}
`;

export const TitleWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  ${Media({
    padding: ['0.75rem', null, null, '1.5rem'],
  })}
`;

export const Gradient = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  box-shadow: 3px 3px 3px #00000029;
  overflow: hidden;

  ${Media({
    minWidth: ['85%', null, '100%'],
    paddingBottom: [null, null, '100%'],
  })}

  &:hover {
    ${Gradient} {
      height: 100%;
    }

    ${StyledImage} {
      transform: scale(1.05);
    }

    ${StyledChevron} {
      color: white;
      opacity: 1;
    }

    ${TitleWrapper} {
      ${Media({
        paddingBottom: ['1.5rem', null, null, '2.5rem'],
      })}
    }
  }
`;
