import { IntlContext } from '@arvesta-websites/i18n';
import React, { ReactNode, useContext } from 'react';
import scrollToElement from 'scroll-to-element';
import { tv } from 'tailwind-variants';

import { LocaleType } from '../../../types';
import Utils from '../../../utils';
import { withErrorBoundary } from '../ErrorBoundary';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

export interface CtaProps extends React.HTMLProps<HTMLAnchorElement> {
  /** Destination route or url */
  to?: string;
  /** standard children prop: accepts any valid React Node */
  children: ReactNode;
  /** Optional click callback function */
  onClick?: (e: React.SyntheticEvent) => void;
  /** Optional boolean to force locale prefix */
  forceLocale?: boolean;
}

const styling = tv({
  slots: {
    gatsbyLink:
      'text-link hover:text-link-hover underline hover:bold hover:underline-offset-linkUnderline hover:decoration-linkThickness',
    link: 'text-success',
  },
});

const Cta = ({ to, children, onClick = () => null, forceLocale = false, ...rest }: CtaProps) => {
  const locale: string | LocaleType = useContext(IntlContext);

  const { gatsbyLink, link } = styling();

  if (!to) {
    return (
      <a href="#" className={link()} onClick={e => e.preventDefault()} {...rest}>
        {children}
      </a>
    );
  }

  const isInternal = to ? Utils.isInternalUrl(to) : false;

  if (to && to.startsWith('#') && to.length > 1) {
    return (
      <a
        href={to}
        className={link()}
        onClick={e => {
          e.preventDefault();
          scrollToElement(to, { offset: -50 });
          if (onClick) {
            onClick(e);
          }
        }}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (!isInternal) {
    return (
      <a
        href={to.startsWith('http') || to.startsWith('mailto') || to.startsWith('//') ? to : `https://${to}`}
        className={link()}
        onClick={onClick}
        rel="noreferrer nofollow"
        target="_blank"
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (rest.target && rest.target === '_blank') {
    return (
      <a href={to} className={link()} onClick={onClick} rel="noreferrer nofollow" target="_blank" {...rest}>
        {children}
      </a>
    );
  }

  let prefixedTo = to;

  if (dontPrefixPaths) {
    prefixedTo = `/${to}`.replace('//', '/');
  } else {
    // eslint-disable-next-line no-lonely-if
    if (to && forceLocale && locale && !to.startsWith(`/${(locale as LocaleType).path}`)) {
      prefixedTo = `/${(locale as LocaleType).path}/${to}`.replace('//', '/');
    } else if (to && locale && !to.startsWith(`/${(locale as LocaleType).path}`)) {
      prefixedTo = `/${(locale as LocaleType).path}/${to}`.replace('//', '/');
    }
  }

  return (
    <a className={gatsbyLink()} onClick={onClick} href={prefixedTo} {...rest}>
      {children}
    </a>
  );
};

export default withErrorBoundary(Cta, { componentName: 'CTA' });
