import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

interface IExpandedStyled {
  active?: boolean;
  nested?: boolean;
}

export const StyledArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledBottomNavigationMobile = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding: 20px;
  background-color: white;
  flex-grow: 1;
  gap: 10px;
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 42px 24px;

  ${Media({
    justifyContent: ['space-between', 'space-between', 'space-between', 'flex-start'],
  })}
`;

export const StyledFilterButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${props => props.theme.articleListing.background};
  color: ${props => props.theme.articleListing.text};
  font-size: 14px;
  font-weight: bold;
  width: 142px;
  height: 42px;

  > svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.articleListing.hover};
  }

  ${Media({
    display: ['block', 'block', 'block', 'none'],
  })}
`;

export const StyledFilterButtonMobile = styled.button`
  unset: all;
  cursor: pointer;
  border: none;
  background-color: ${props => props.theme.articleListing.background};
  color: ${props => props.theme.articleListing.text};
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 42px;
  align-self: center;
  &:hover {
    background-color: ${props => props.theme.articleListing.hover};
  }
`;

type StyledFilterItemProps = {
  lastItem?: boolean;
};

export const StyledFilterItem = styled.ul<StyledFilterItemProps>`
  text-decoration: none;
  font-size: 14px;
  gap: 10px;
  cursor: pointer;
  color: ${props => props.theme.articleListing.filter};
  margin: 0;
  padding: 0;
  padding-bottom: ${props => props?.lastItem && '10px'};
  list-style: none;
  width: 100%;
`;

export const StyledFilterTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.articleListing.title};
  margin-bottom: 2px;
`;

export const StyledFilterTitleMobile = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.articleListing.title};
`;

export const StyledFilterWrapper = styled.div`
  flex-direction: column;
  width: 300px;
  gap: 10px;

  ${Media({
    display: ['none', 'none', 'none', 'flex'],
    marginTop: ['0px', '0px', '0px', '45px'],
  })}
`;

export const StyledIconButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-top: 5px;
`;

export const StyledInnerButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 6px;

  > svg {
    width: 17px;
    height: 17px;
  }
`;

export const StyledMobileFilter = styled(Box)`
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  ${Media({
    width: ['320px', null, '70%', '320px'],
  })}
`;

export const StyledMobileFilterWrapper = styled(Box)`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
  overflow: hidden;
`;

export const StyledMobileFooter = styled(Box)`
  color: ${props => props.theme.articleListing.title};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  background-color: ${props => props.theme.articleListing.mobile.background};
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  padding: 30px;
`;

export const StyledMobileHeader = styled(Box)`
  color: ${props => props.theme.articleListing.title};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  background-color: ${props => props.theme.articleListing.mobile.background};
  padding-left: 15px;
  padding-right: 15px;
`;

export const StyledMoreButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${props => props.theme.articleListing.background};
  color: ${props => props.theme.articleListing.mobile.background};
  font-size: 14px;
  width: 142px;
  height: 42px;
  font-weight: bold;
  margin-top: 80px;
  align-self: center;

  &:hover {
    background-color: ${props => props.theme.articleListing.filter};
  }
`;

export const StyledPageExtra = styled.div`
  font-size: 14px;
  opacity: 0.5;
  margin-left: auto;
`;

export const StyledRadioWrapper = styled.div<IExpandedStyled>`
  display: flex;
  gap: 10px;
  align-items: center;
  opacity: ${props => (props.active ? '1' : '0.5')};
  padding-left: ${props => (props.nested ? '20px' : '0px')};
  padding-top: ${props => (props.nested ? '6px' : '0px')};
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTitle = styled(Heading)`
  color: ${props => props.theme.articleListing.title};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-weight: 700;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 24px;
`;
