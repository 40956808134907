import { useIntl } from 'react-intl';

import { withErrorBoundary } from '../../../components';
import {
  StyledHeading,
  StyledResultRow,
  StyledResultsContainer,
  StyledResultsHeader,
  StyledResultsHeading,
  StyledResultsList,
  StyledResultsOuterContainer,
  StyledText,
  StylededResultContent,
} from '../Styled';

type ResultType = {
  id: string;
  title: string;
  headings: Array<string>;
  description: string;
  image: string;
  page_type: string;
  text: string;
  preview_image: any;
  path: string;
  date: string;
  location: string;
};

type ResultsProps = {
  query: string;
  results: Array<ResultType>;
  productPageSlug: string;
  eventPageSlug: string;
  newsPageSlug: string;
};

const renderDescription = (result: ResultType) => {
  return <StyledText>{result.description}</StyledText>;
};

const Results = ({ query, results }: ResultsProps) => {
  const intl = useIntl();

  return (
    <StyledResultsOuterContainer>
      <StyledResultsHeader>
        <StyledResultsHeading>
          {intl.formatMessage({ id: 'sections.search.results.title' }, { query, total: results.length })}
        </StyledResultsHeading>
      </StyledResultsHeader>
      <StyledResultsContainer>
        <StyledResultsList>
          {results.map((result: ResultType) => (
            <StyledResultRow key={result.id}>
              <StylededResultContent>
                <StyledHeading to={result.path}>
                  {result.headings && result.headings.length ? result.headings[0] : result.title}
                </StyledHeading>
                {renderDescription(result)}
              </StylededResultContent>
            </StyledResultRow>
          ))}
        </StyledResultsList>
      </StyledResultsContainer>
    </StyledResultsOuterContainer>
  );
};

export default withErrorBoundary(Results, { componentName: 'SearchResultsModule/Results' });
