import * as React from 'react';

const Jpg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33">
      <path
        fill="#3D3D3D"
        d="M17.927 0L26 8.06v20.534c0 1.816-1.425 3.304-3.212 3.401l-.186.005H5.101c-1.812 0-3.297-1.427-3.394-3.22l-.005-.186v-1.526H1.37c-.712 0-1.297-.544-1.364-1.24L0 25.696v-8.324c0-.713.543-1.3 1.238-1.366L1.37 16h.332V3.406c0-1.814 1.425-3.304 3.212-3.4L5.101 0h12.826zm-.778 2.075L5.1 2.088c-.68 0-1.242.52-1.31 1.183l-.007.135V16H20.1c.712 0 1.297.544 1.364 1.24l.006.132v8.324c0 .713-.543 1.3-1.238 1.366l-.132.006H3.784v1.526c0 .683.52 1.246 1.182 1.313l.135.007h17.501c.68 0 1.241-.52 1.308-1.185l.007-.135-.049-19.707-4.362.012c-1.248 0-2.27-.972-2.352-2.2l-.005-.161V2.075zM7.616 18.916H4.672v.864H6.56v3.072c0 .6-.272.88-.744.88-.52 0-.744-.344-.84-.808l-.984.208c.168.888.768 1.464 1.824 1.464 1.088 0 1.8-.632 1.8-1.624v-4.056zm8-.096c-1.416 0-2.088 1.032-2.088 2.928 0 1.8.592 2.848 1.72 2.848.712 0 1.072-.416 1.256-.872h.064v.776h.936v-2.92h-1.952v.76h1.016v.456c0 .552-.32.952-.912.952-.76 0-1.016-.656-1.016-1.624v-.896c0-.96.272-1.552.968-1.552.592 0 .792.432.936.912l.96-.272c-.224-.856-.768-1.496-1.888-1.496zm-4.456.096H9.04V24.5h1.048v-2.128h1.072c1.08 0 1.68-.672 1.68-1.728 0-1.064-.6-1.728-1.68-1.728zm-.216.856c.56 0 .792.224.792.72v.304c0 .496-.232.72-.792.72h-.856v-1.744h.856z"
        transform="translate(-421 -2095) translate(397 2071.5) translate(24 24)"
      />
    </svg>
  );
};

export default Jpg;
