import { useConfiguration } from '@arvesta-websites/configuration';
import { ChevronRightSlider } from '@arvesta-websites/icons';

import { Button, Cta, withErrorBoundary } from '../../components';

import { CtaGroupWrapper, StyledControl, StyledCta, StyledTitle } from './Styled';

type CtaProps = {
  to: string;
  label: string;
  id: string;
};

export interface CtaGroupProps {
  items: CtaProps[];
  title: string;
}

const CtaGroupModule = ({ items, title }: CtaGroupProps) => {
  const config = useConfiguration();

  if (!items?.length) return null;

  return (
    <CtaGroupWrapper>
      <StyledTitle>{title}</StyledTitle>
      {items.map(item => (
        <StyledCta key={item.id}>
          <Cta to={item.to}>
            <Button variant={config.button?.ctaGroup ?? 'primary'}>
              {item.label}
              <StyledControl>
                <ChevronRightSlider />
              </StyledControl>
            </Button>
          </Cta>
        </StyledCta>
      ))}
    </CtaGroupWrapper>
  );
};

export default withErrorBoundary(CtaGroupModule, { componentName: 'CtaGroupModule' });
