import * as React from 'react';

const RadioChecked = () => (
  <svg height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <g id="radio-button-checked" transform="translate(0 0)">
      <path
        d="M8.25,2.25a6,6,0,1,0,6,6A6,6,0,0,0,8.25,2.25Zm0,11.143A5.143,5.143,0,1,1,13.393,8.25,5.143,5.143,0,0,1,8.25,13.393Z"
        data-name="Path 2223"
        fill="#536121"
        id="Path_2223"
        transform="translate(-2.25 -2.25)"
      />
      <path
        d="M13.821,11.25a2.571,2.571,0,1,0,2.571,2.571A2.571,2.571,0,0,0,13.821,11.25Z"
        data-name="Path 2224"
        fill="#536121"
        id="Path_2224"
        transform="translate(-7.821 -7.821)"
      />
    </g>
  </svg>
);

export default RadioChecked;
