import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import { mediumBreakpoint } from '../../../utils/breakpoints';
import { chunkArray } from '../../../utils/common';
import { useCurrentBreakpoint } from '../../../utils/hooks/breakpoints';
import { Button, Cta, ProductCard, ProductCardProps, withErrorBoundary } from '../../components';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../shared';

import {
  GreyBoxContainer,
  StyledCardContainer,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingNoDescription,
  StyledVerticalCardContainer,
  StyledVerticalProductWrapper,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

export type ProductCardModuleProps = BoxProps & {
  /** optional title */
  title?: string;
  /** optional card short description */
  shortDescription?: string;
  /** categories */
  products: ProductCardProps[];
  /** card cta */
  cta?: CtaProps;

  greyVariant?: boolean;
};

const Heading = ({ title, shortDescription }: { title?: string; shortDescription?: string }) => {
  const intl = useIntl();

  if (!title) {
    return <StyledModuleHeading>{intl.formatMessage({ id: 'globals.featuredProducts' })}</StyledModuleHeading>;
  }

  if (!shortDescription) {
    return <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>;
  }

  return (
    <>
      <StyledModuleHeading>{title}</StyledModuleHeading>
      <StyledModuleDescription>{shortDescription}</StyledModuleDescription>
    </>
  );
};

const Cards = ({ products = [], cta }: { products: ProductCardProps[]; cta?: CtaProps }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const productChunkSize = currentBreakpoint === mediumBreakpoint ? 2 : 3;
  const chunkedProducts = chunkArray(products.slice(0, 3), productChunkSize);

  return (
    <>
      <StyledCardContainer className={`cards-${products.length}`}>
        {chunkedProducts.map(({ chunk, key }) => (
          <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`}>
            {chunk.map(({ id, ...productRest }) => (
              <StyledVerticalProductWrapper key={id} className={`cards-${chunk.length}`}>
                <ProductCard id={id} {...productRest} />
              </StyledVerticalProductWrapper>
            ))}
          </StyledVerticalCardContainer>
        ))}
      </StyledCardContainer>

      {cta && cta.to ? (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      ) : null}
    </>
  );
};

const ProductCardModule = ({
  title,
  shortDescription,
  cta,
  products,
  greyVariant = false,
  ...rest
}: ProductCardModuleProps) => {
  if (greyVariant) {
    return (
      <GreyBoxContainer data-appsearch-ignore="true">
        <Heading title={title} shortDescription={shortDescription} />
        <Cards products={products} cta={cta} />
      </GreyBoxContainer>
    );
  }

  return (
    <StyledModuleContainer {...rest} data-appsearch-ignore="true">
      <Heading title={title} shortDescription={shortDescription} />
      <Cards products={products} cta={cta} />
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(ProductCardModule, { componentName: 'ProductCardModule' });
