import { useState } from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import { AdviceCard, type AdviceCardProps, Button, Cta, HeadingTag, withErrorBoundary } from '../../components';

import {
  StyledCardsWrapper,
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

export interface AdviceListingModuleProps extends BoxProps {
  title: string;
  titleTag: HeadingTag;
  shortDescription?: string | null;
  advice: AdviceCardProps[];
  initialAmount?: number;
  incrementAmount?: number;
}

const AdviceListingModule = ({
  title,
  titleTag,
  shortDescription,
  advice,
  initialAmount = 3,
  incrementAmount = 8,
}: AdviceListingModuleProps) => {
  const intl = useIntl();
  const [amountOfAdviceItemsToDisplay, setDisplayLimit] = useState(initialAmount);

  return (
    <StyledContainer data-appsearch-ignore="true">
      <StyledHeadingAndDescriptionWrapper>
        <StyledModuleHeading variant="h1" tag={titleTag}>
          {title}
        </StyledModuleHeading>
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledHeadingAndDescriptionWrapper>
      <StyledCardsWrapper>
        {advice
          .map(({ slug, ...adviceRest }: AdviceCardProps) => <AdviceCard key={slug} slug={slug} {...adviceRest} />)
          .slice(0, amountOfAdviceItemsToDisplay)}
      </StyledCardsWrapper>
      {advice.length > amountOfAdviceItemsToDisplay ? (
        <StyledLoadMoreWrapper>
          <Cta>
            <Button
              onClick={event => {
                event.preventDefault();
                setDisplayLimit(amountOfAdviceItemsToDisplay + incrementAmount);
              }}
            >
              {intl.formatMessage({ id: 'sections.advice.loadmore' })}
            </Button>
          </Cta>
        </StyledLoadMoreWrapper>
      ) : null}
    </StyledContainer>
  );
};

export default withErrorBoundary(AdviceListingModule, { componentName: 'AdviceListingModule' });
