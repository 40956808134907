import { Arrow } from '@arvesta-websites/icons';
import { IGatsbyImageData, getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { CardProps } from 'rebass';

import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';

import {
  StyledCard,
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBox,
  StyledImageBoxLink,
  StyledTitle,
} from './Styled';

export interface CategoryCardProps extends CardProps {
  /** category id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?:
    | { alt?: string; asset: { gatsbyImageData: IGatsbyImageData }; title: string }
    | IGatsbyImageData
    | string
    | undefined;

  /** card cta */
  link: string;
  /** display link text */
  showLink?: boolean;
}

const getImage = (image: { asset: { gatsbyImageData: IGatsbyImageData } } | IGatsbyImageData | string | undefined) => {
  if (!image) {
    return '';
  }

  if (typeof image === 'string') {
    return image;
  }

  if ('asset' in image) {
    return getSrc(image.asset.gatsbyImageData);
  }

  return getSrc(image);
};

const CategoryCard = ({ title, shortDescription, image, link, showLink = true, ...rest }: CategoryCardProps) => {
  const intl = useIntl();

  return (
    <StyledCard {...rest}>
      <StyledImageBoxLink to={link}>
        <StyledImageBox
          sx={{
            backgroundImage: `url(${getImage(image)})`,
          }}
        />
      </StyledImageBoxLink>

      <StyledContentBox>
        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>

        <StyledDescription>{shortDescription}</StyledDescription>

        {showLink && (
          <StyledCtaWrapper>
            <StyledCta to={link}>
              <Arrow />
              <span>{intl.formatMessage({ id: 'cards.category.link' })}</span>
            </StyledCta>
          </StyledCtaWrapper>
        )}
      </StyledContentBox>
    </StyledCard>
  );
};

export default withErrorBoundary(CategoryCard, { componentName: 'CategoryCard' });
