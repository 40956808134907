import React from 'react';

const FlagEs = () => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 31.66">
    <g id="Vlaggen">
      <g>
        <path fill="#e52528" d="M15.83,0C8.84,0,2.92,4.53,.82,10.81H30.84C28.74,4.53,22.82,0,15.83,0Z" />
        <path fill="#e52528" d="M15.83,31.66c6.99,0,12.91-4.53,15.01-10.81H.82c2.1,6.28,8.02,10.81,15.01,10.81Z" />
        <path
          fill="#feca08"
          d="M31.66,15.83c0-1.76-.29-3.44-.82-5.02H.82c-.53,1.58-.82,3.26-.82,5.02s.29,3.44,.82,5.02H30.84c.53-1.58,.82-3.26,.82-5.02Z"
        />
      </g>
    </g>
  </svg>
);

export default FlagEs;
