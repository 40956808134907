import { useConfiguration } from '@arvesta-websites/configuration';
import { BoxProps } from 'rebass/styled-components';

import { Button, Cta, HeadingTag, withErrorBoundary } from '../../components';

import {
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledHeroCtaWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

type CtaProps = {
  label: string;
  to: string;
};

interface HeroSectionModuleProps extends BoxProps {
  title?: string;
  titleTag?: HeadingTag;
  shortDescription?: string;
  cta?: CtaProps;
  wide?: boolean;
}

// FIXME wide is never passed from either Contentful or transforms
const HeroSectionModule = ({ title, titleTag, shortDescription, cta, wide }: HeroSectionModuleProps) => {
  const config = useConfiguration();

  return (
    <StyledContainer>
      <StyledHeadingAndDescriptionWrapper wide={wide}>
        {shortDescription ? (
          <>
            <StyledModuleHeading variant="h1" tag={titleTag}>
              {title}
            </StyledModuleHeading>
            <StyledModuleDescription wide={wide}>{shortDescription}</StyledModuleDescription>
          </>
        ) : (
          <StyledModuleHeading variant="h2" tag={titleTag}>
            {title}
          </StyledModuleHeading>
        )}
        {cta && (
          <StyledHeroCtaWrapper>
            <Cta to={cta.to}>
              <Button variant={config?.button?.heroSection ?? 'primary'}>{cta.label}</Button>
            </Cta>
          </StyledHeroCtaWrapper>
        )}
      </StyledHeadingAndDescriptionWrapper>
    </StyledContainer>
  );
};

export default withErrorBoundary(HeroSectionModule, { componentName: 'HeroSectionModule' });
