import React from 'react';

const FlagDe = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <filter id="c-de" width="108.9%" height="137.5%" x="-4.5%" y="-18.8%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="0.5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feColorMatrix in="shadowOffsetOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      </filter>
      <filter id="e-de" width="108.9%" height="137.5%" x="-4.5%" y="-18.8%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="0.5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feColorMatrix in="shadowOffsetOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      </filter>
      <path id="d-de" d="M0 8h33.6v8H0z" />
      <path id="f-de" d="M0 16h33.6v8H0z" />
      <rect id="a-de" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-de" fill="#fff">
        <use xlinkHref="#a-de" />
      </mask>
      <use fill="#FFF" xlinkHref="#a-de" />
      <path fill="#262626" d="M0 0h33.6v8H0z" mask="url(#b-de)" />
      <g mask="url(#b-de)">
        <use fill="#000" filter="url(#c-de)" xlinkHref="#d-de" />
        <use fill="#F01515" xlinkHref="#d-de" />
      </g>
      <g mask="url(#b-de)">
        <use fill="#000" filter="url(#e-de)" xlinkHref="#f-de" />
        <use fill="#FFD521" xlinkHref="#f-de" />
      </g>
    </g>
  </svg>
);

export default FlagDe;
