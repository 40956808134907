import React from 'react';

const LogoMobile = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.46 246.88">
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M119.76,240.4v6.26s-3.67,0-3.67,0v-15.87s9.08,0,9.08,0v3.04s-5.42,0-5.42,0v3.54s5.42,0,5.42,0v3.04s-5.42,0-5.42,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M128.93,246.66v-15.87s10.42,0,10.42,0v3.04s-6.75,0-6.75,0v3.15s6.76,0,6.76,0v3.04s-6.75,0-6.75,0v3.61s6.76,0,6.76,0v3.04s-10.43,0-10.43,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M143.45,246.65v-15.87s10.42,0,10.42,0v3.04s-6.75,0-6.75,0v3.15s6.76,0,6.76,0v3.04s-6.75,0-6.75,0v3.61s6.76,0,6.76,0v3.04s-10.43,0-10.43,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M157.95,230.77h4.76c4.13,0,8.78,2.12,8.78,7.86,0,5.87-4.58,8.01-8.97,8.01h-4.56s0-15.87,0-15.87ZM161.63,243.6h.2c3.4,0,5.76-1.3,5.76-4.76,0-3.29-1.93-5.03-5.24-5.03h-.73v9.8Z"
    />
    <path fill="#ffffff" strokeWidth="0px" d="M175.49,246.63v-15.87s3.66,0,3.66,0v15.87s-3.66,0-3.66,0Z" />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M194.14,246.62l-6.72-9.2v9.21s-3.67,0-3.67,0v-15.87s3.23,0,3.23,0l6.79,9.25v-9.25s3.67,0,3.67,0v15.87s-3.3,0-3.3,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M209.56,240.35v-3.04s7.39,0,7.39,0v6.26c-1.72,2.34-4.28,3.31-7.07,3.31-4.78,0-8.44-3.35-8.44-8.2,0-4.62,3.76-8.21,8.41-8.21,2.79,0,5.06,1.11,6.89,3.24l-2.54,2.22c-1.09-1.31-2.54-2.29-4.33-2.29-2.63,0-4.53,2.25-4.53,5.04,0,2.93,1.75,5.03,4.52,5.03,1.16,0,2.47-.5,3.42-1.41v-1.95s-3.72,0-3.72,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M231.81,246.6v-6.6s-5.02-9.27-5.02-9.27h4.51s2.48,5.35,2.48,5.35h.05l2.76-5.35h4.26s-5.37,9.41-5.37,9.41v6.46s-3.67,0-3.67,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M251.02,246.86c-4.74,0-8.37-3.67-8.37-8.2,0-4.53,3.62-8.21,8.36-8.21,4.74,0,8.37,3.67,8.37,8.2,0,4.54-3.62,8.21-8.36,8.21ZM251.01,233.62c-2.58,0-4.47,2.22-4.46,5.04,0,2.81,1.89,5.03,4.47,5.03,2.58,0,4.47-2.22,4.46-5.04,0-2.81-1.88-5.03-4.47-5.03Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M269.83,246.85c-4.51,0-6.44-2.76-6.44-7.75v-8.39s3.67,0,3.67,0v8.78c0,2.83,1.1,4.19,2.82,4.19s2.81-1.36,2.81-4.2v-8.78s3.67,0,3.67,0v8.39c0,4.99-1.92,7.76-6.52,7.76Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M280.93,230.7h5.17c2.61,0,5.94,1.33,5.94,4.82,0,2.38-1.56,3.74-3.29,4.42,1.41,1.95,3.72,5.17,4.79,6.62h-4.58s-4.2-6.19-4.2-6.19h-.16v6.19s-3.67,0-3.67,0v-15.87ZM284.61,237.34h.97c1.47,0,2.56-.52,2.56-1.75,0-1.11-.86-1.86-2.13-1.86h-1.41s0,3.61,0,3.61Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M303.89,230.68h4.76c4.13,0,8.78,2.12,8.78,7.86,0,5.87-4.58,8.01-8.97,8.01h-4.56s0-15.87,0-15.87ZM307.57,243.52h.2c3.4,0,5.76-1.3,5.76-4.77,0-3.29-1.93-5.03-5.24-5.03h-.73s0,9.8,0,9.8Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M321.42,230.67h5.17c2.61,0,5.94,1.33,5.94,4.82,0,2.38-1.56,3.74-3.29,4.42,1.41,1.95,3.72,5.17,4.79,6.62h-4.58s-4.2-6.18-4.2-6.18h-.16v6.19s-3.67,0-3.67,0v-15.87ZM325.1,237.32h.97c1.47,0,2.56-.52,2.56-1.75,0-1.11-.86-1.86-2.13-1.86h-1.41s0,3.61,0,3.61Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M337.35,246.54v-15.87s10.42,0,10.42,0v3.04s-6.75,0-6.75,0v3.15s6.76,0,6.76,0v3.04s-6.75,0-6.75,0v3.61s6.76,0,6.76,0v3.04s-10.43,0-10.43,0Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M362.07,246.52l-.93-2.79h-5.24s-1,2.79-1,2.79h-4.06s6.59-15.87,6.59-15.87h2.92s5.93,15.87,5.93,15.87h-4.22ZM356.96,240.81h3.17s-1.52-4.58-1.52-4.58h-.02l-1.63,4.58Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M380.26,246.51h-2.63s-4.34-10.97-4.34-10.97v10.97s-3.67,0-3.67,0v-15.87s5.3,0,5.3,0l4.07,10.56,4.21-10.57h5.17s0,15.87,0,15.87h-3.67s0-10.88,0-10.88l-4.44,10.89Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M.03,208.02l-.03-50.84,33.41-.02v9.73s-21.64.01-21.64.01v10.1s21.65-.01,21.65-.01v9.73s-21.64.01-21.64.01v11.55s21.65-.01,21.65-.01v9.73s-33.41.02-33.41.02Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M88.21,213.2l-7.27-7.19c-3.63,1.82-7.77,2.84-12.2,2.84-15.18,0-26.81-11.75-26.82-26.28,0-14.53,11.61-26.3,26.79-26.31s26.81,11.75,26.82,26.28c0,6.75-2.53,12.86-6.67,17.51l6.25,6.24-6.9,6.9ZM80.57,191.85c1.52-2.62,2.47-5.81,2.46-9.3,0-9.01-6.04-16.12-14.32-16.12-8.28,0-14.3,7.13-14.3,16.13,0,9.01,6.04,16.12,14.32,16.12,1.45,0,2.9-.22,4.21-.66l-5.81-5.81,6.9-6.9,6.54,6.53Z"
    />
    <path
      fill="#ffffff"
      strokeWidth="0px"
      d="M126.27,208.82c-14.45,0-20.63-8.85-20.64-24.83v-26.87s11.75,0,11.75,0l.02,28.11c0,9.08,3.49,13.43,9.01,13.43,5.52,0,9-4.36,9-13.44l-.02-28.11h11.77s.01,26.87.01,26.87c0,15.98-6.16,24.84-20.9,24.85Z"
    />
    <path fill="#ffffff" strokeWidth="0px" d="M159.17,207.93l-.03-50.84h11.77s.03,50.84.03,50.84h-11.77Z" />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M182.92,207.91l-.03-50.84h11.77s.02,41.1.02,41.1h20.34s0,9.72,0,9.72l-32.1.02Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M258.1,207.87l-2.98-8.93h-16.78s-3.19,8.95-3.19,8.95h-13s21.11-50.85,21.11-50.85h9.37s18.99,50.83,18.99,50.83h-13.51ZM241.75,189.58h10.17s-4.88-14.67-4.88-14.67h-.07l-5.22,14.67Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M312.5,207.84l-21.52-29.48.02,29.49h-11.77s-.03-50.84-.03-50.84h10.39s21.73,29.62,21.73,29.62l-.02-29.63h11.77s.03,50.84.03,50.84h-10.6Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M368.36,207.81l-21.51-29.48.02,29.49h-11.77s-.03-50.84-.03-50.84h10.39s21.73,29.62,21.73,29.62l-.02-29.63h11.77s.03,50.84.03,50.84h-10.6Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M415.86,208.65c-15.18,0-26.81-11.75-26.82-26.28,0-14.53,11.61-26.3,26.79-26.31,15.18,0,26.81,11.75,26.82,26.28,0,14.53-11.61,26.3-26.79,26.31ZM415.84,166.24c-8.28,0-14.3,7.13-14.3,16.13,0,9.01,6.04,16.12,14.32,16.12,8.28,0,14.3-7.13,14.3-16.13,0-9.01-6.04-16.12-14.32-16.12Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M477.67,208.62c-15.18,0-26.81-11.75-26.82-26.28,0-14.53,11.61-26.3,26.79-26.31,15.18,0,26.81,11.75,26.82,26.28,0,14.53-11.61,26.3-26.79,26.31ZM477.65,166.2c-8.28,0-14.3,7.13-14.3,16.13,0,9.01,6.04,16.12,14.32,16.12,8.28,0,14.3-7.13,14.3-16.13,0-9.01-6.04-16.12-14.32-16.12Z"
    />
    <path
      fill="#bd8336"
      strokeWidth="0px"
      d="M335.44,63.9c-.1,4.01-2.03,7.84-5.29,10.51l-1.56,1.26c-2.56,2.09-5.72,3.17-8.9,3.17-2.19,0-4.39-.51-6.42-1.55l-.29-.16-20.39-12.2c-4.19.51-13.92.9-21.43-4.71-2.77-2.07-4.92-4.68-6.47-7.81l8.6-5.28c.88,2.12,2.15,3.81,3.85,5.08,5.96,4.45,15.35,2.63,15.44,2.61l1.92-.4,23.45,14.02c1.42.67,3.1.47,4.32-.53l1.56-1.26c1.01-.82,1.58-1.89,1.61-3,.03-1.11-.49-2.21-1.47-3.09-5.48-4.95-10.19-10.86-14.74-16.57-3.96-4.96-7.69-9.65-11.6-13.29l-.39-.36-.88-1.27c-1.23-1.76-1.88-3.82-1.88-5.97v-10.79c-2.32,1.25-4.39,3.14-6.08,5.58-1.7,2.48-4.19,3.78-7.07,3.65-.94-.04-23.14-.83-42.24,17.32-24.85,23.59-40.03,25.77-62.75,21.63-18.53-3.38-28.52-4.7-35.22-3.55l15.75-9.66c5.85.68,12.79,1.83,21.26,3.38,17.76,3.24,30.26,3.57,54.07-19.05,19.99-18.98,42.69-20.14,48.46-20.08,4.45-6.09,10.67-9.89,17.6-10.76l6.22-.78v23.11c0,.09.03.17.08.25l.28.41c4.29,4.08,8.31,9.12,12.2,14,4.3,5.4,8.75,10.97,13.63,15.38,3.13,2.82,4.86,6.75,4.76,10.76ZM226.37,76.33h17.99v16.28h-44.53l-16.31,10h60.84v16.28h-87.38l-16.31,10h113.68v-62.55h-11.68l-16.31,10ZM347.48,118.88h-75.75v-46.46l-10-6.13v62.59h102.06l-16.31-10Z"
    />
  </svg>
);

export default LogoMobile;
