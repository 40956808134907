import styled from 'styled-components';

type StyledWrapperProps = {
  position: 'left' | 'center';
};

type StyledImageProps = {
  size: 'small' | 'medium' | 'large';
};

export const StyledImageWrapper = styled.div<StyledImageProps>`
  width: ${props => (props.size === 'small' ? '50%' : props.size === 'medium' ? '75%' : '100%')};

  @media (max-width: 900px) {
    width: ${props => (props.size === 'large' ? '100%' : '75%')};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: ${props => (props.position === 'left' ? 'flex-start' : 'center')};
`;
