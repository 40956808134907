import { useConfiguration } from '@arvesta-websites/configuration';
import { Arrow } from '@arvesta-websites/icons';
import * as geolib from 'geolib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { Heading, HeadingTag, withErrorBoundary } from '../../components';

import { useGeoCoder } from './hooks/usePlacesApi';
import InteractiveMap from './InteractiveMap/InteractiveMap';
import ListCard from './ListCard/ListCard';
import DealerSearch, { SearchValue } from './Search/Search';

export interface Address {
  street: string;
  number: string;
  cityTown: string;
  countryCode: string;
  postalCode: string;
  location: {
    lat: string | number;
    lon: string | number;
  };
}

export interface TeamMember {
  name: string;
  telephone?: string;
  id: string;
}

export interface Dealer {
  id: string;
  name: string;
  owner?: {
    slug: string;
  };
  telephone?: string[];
  email?: string[];
  openingHours?: { raw: any };
  teamMembers: TeamMember[] | null;
  address: Address;
  services: string[] | null;
}

const styles = tv({
  slots: {
    outer: 'mt-[57px]',
    wrapper:
      'flex flex-col lg:flex-row gap-3 lg:h-[677px] lg:[@media(min-height:1080px)]:h-[820px] lg:[@media(max-height:800px)]:h-[547px] -mx-5 md:mx-0',
    leftBlock: 'flex-none w-full lg:w-[497px] h-[100%] overflow-auto',
    search: 'block mb-3 top-0 bg-white z-10 max-md:px-5',
    list: 'block list-none flex flex-col gap-3 m-0 pt-10 px-5 pb-5 bg-dealerOverview-list',
    mapWrapper: 'hidden lg:block bg-tertiary flex-auto',
    showAllButton: 'inline-flex gap-2 dealer-overview-show-all w-full justify-start',
    showAllButtonArrow: 'rotate-180 scale-75',
    topText: 'text-center mb-6 lg:mb-8',
  },
  variants: {
    topText: { true: { wrapper: 'mt-6 lg:mt-20' } },
  },
});

const createActiveLocationCheck =
  (activeId: string | null) =>
  (currentId: string): boolean =>
    !!activeId && activeId === currentId;

const hasMatchingFilters = (dealerFilters: string[] | null, activeFilters: string[] | null): boolean =>
  !!dealerFilters?.length && !!activeFilters?.length && dealerFilters.some(filter => activeFilters.includes(filter));

const reduceToUniqueFilters = (dealers?: Dealer[]): string[] | undefined => {
  if (!dealers?.length) return undefined;
  const onlyServices = dealers?.filter((dealer?: Dealer) => dealer?.services).flatMap(dealer => dealer.services ?? '');
  return Array.from(new Set(onlyServices));
};

const matchAddressToString = (input: string, dealers: Dealer[]) =>
  dealers.filter(({ address }) =>
    input
      .split(' ')
      .map(part => new RegExp(part, 'gi'))
      .some(regex => regex.test(address.cityTown.toLowerCase()) || regex.test(address.postalCode.toLowerCase())),
  );

const findStringBasedMatches = (input: SearchValue, dealers: Dealer[]) => {
  const stringValue = typeof input === 'string' ? input : input.description.replace(/,/gi, '');
  return matchAddressToString(stringValue, dealers);
};

interface Coordinates {
  latitude: number;
  longitude: number;
}
const sortByDistanceInRadius = (referenceCoordinates: Coordinates, dealers: Dealer[], radius: number) => {
  const dealersForMethod = dealers.map(dealer => ({
    ...dealer,
    latitude: dealer.address?.location?.lat,
    longitude: dealer.address?.location?.lon,
  }));
  const sorted: any[] = geolib.orderByDistance(referenceCoordinates, dealersForMethod);
  const dealersInRange: Dealer[] = [];
  for (const dealer of sorted) {
    if (!geolib.isPointWithinRadius(dealer, referenceCoordinates, radius)) break;

    delete dealer.latitude;
    delete dealer.longitude;
    dealersInRange.push(dealer as Dealer);
  }
  return dealersInRange;
};

interface DealerOverviewModuleProps {
  dealers: Dealer[];
  shortDescription?: string;
  title?: string;
  titleTag?: HeadingTag;
}
const DealerOverviewModule = ({ dealers, title, titleTag, shortDescription }: DealerOverviewModuleProps) => {
  const { mapSearchRadius } = useConfiguration();
  const intl = useIntl();
  const { libraryLoaded, geocode } = useGeoCoder();
  const [activeLocation, setActiveLocation] = useState<string | null>(null);
  const [activeServices, setActiveServices] = useState<string[] | null>(null);
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [searchReset, setSearchReset] = useState<number | null>(null);
  const [dealersInRange, setDealersInRange] = useState(dealers);

  const countryCodes = useMemo(() => {
    if (!dealers?.length) return [];
    const codes = dealers.filter(dealer => dealer.address).map(({ address }) => address.countryCode);
    return Array.from(new Set(codes));
  }, [dealers]);

  const services = useMemo(
    () =>
      dealersInRange?.length < dealers.length ? reduceToUniqueFilters(dealersInRange) : reduceToUniqueFilters(dealers),
    [dealers, dealersInRange],
  );

  const sortedFilteredDealers = useMemo(() => {
    return activeServices
      ? dealersInRange.filter(dealer => hasMatchingFilters(dealer.services, activeServices))
      : dealersInRange.slice();
  }, [dealersInRange, activeServices]);

  useEffect(() => {
    if (sortedFilteredDealers.length === 1) setActiveLocation(sortedFilteredDealers[0].id);
  }, [sortedFilteredDealers]);

  const handleSearch = async (input: SearchValue) => {
    setActiveLocation(null);
    if (input === '') return setDealersInRange(dealers.slice());

    const fallbackToStringSearch = () => {
      const foundByString = findStringBasedMatches(input, dealers);
      return foundByString ? setDealersInRange(foundByString) : setDealersInRange(dealers.slice());
    };

    if (!libraryLoaded) return fallbackToStringSearch(); // no geocode loaded, cant' use it

    const { results, status } = await geocode(input, mapBounds);
    if (status !== 'OK' || !results?.length) return fallbackToStringSearch(); // geocode failed, can't use it

    const coords = { latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() };
    const distanceSortedDealers = sortByDistanceInRadius(coords, dealers, mapSearchRadius);
    setDealersInRange(distanceSortedDealers);
  };

  const isActiveLocation = createActiveLocationCheck(activeLocation);
  const showLocation = useCallback((id: string) => {
    setActiveLocation(id);
  }, []);

  const showAll = () => {
    setActiveLocation(null);
    setActiveServices(null);
    setDealersInRange(dealers.slice());
    setSearchReset(val => (val === null ? 1 : ++val));
  };

  const handleFilterChange = useCallback((filters: string[] | null) => {
    setActiveServices(filters?.length ? filters : null);
    setActiveLocation(null);
  }, []);

  const { wrapper, search, list, leftBlock, mapWrapper, showAllButton, showAllButtonArrow, topText, outer } = styles();
  return (
    <section className={outer()}>
      {title ? (
        <Heading tag={titleTag} variant="h2" className={topText()}>
          {title}
        </Heading>
      ) : null}
      {shortDescription ? <p className={topText()}>{shortDescription}</p> : null}
      <div className={wrapper({ topText: !!shortDescription || !!title })}>
        <div className={leftBlock()}>
          <DealerSearch
            className={search()}
            services={services}
            onFilterChange={handleFilterChange}
            onSearch={handleSearch}
            mapBounds={mapBounds}
            countryCodes={countryCodes}
            shouldReset={searchReset}
          />
          <ul className={list()}>
            {sortedFilteredDealers.length < dealers?.length && (
              <li>
                <button className={showAllButton()} onClick={showAll}>
                  <Arrow className={showAllButtonArrow()} />{' '}
                  {intl.formatMessage({ id: 'sections.dealerOverview.show_all' })}
                </button>
              </li>
            )}
            {sortedFilteredDealers.length ? (
              sortedFilteredDealers.map(dealer => (
                <ListCard
                  dealer={dealer}
                  key={dealer.id}
                  active={isActiveLocation(dealer.id)}
                  onSelect={showLocation}
                />
              ))
            ) : (
              <li>
                <p>{intl.formatMessage({ id: 'sections.dealerOverview.no_results' })}</p>
              </li>
            )}
          </ul>
        </div>
        <div className={mapWrapper()}>
          <InteractiveMap
            isActiveLocation={isActiveLocation}
            activeLocationId={activeLocation}
            dealers={sortedFilteredDealers}
            onMarkerClick={showLocation}
            boundsDetermined={setMapBounds}
          />
        </div>
      </div>
    </section>
  );
};

export default withErrorBoundary(DealerOverviewModule, { componentName: 'DealerOverviewModule' });
