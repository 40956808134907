import styled from 'styled-components';

import Media from '../../../../../utils/Media';
import { HEADERLIGHT_VARIANTS } from '../../HeaderLight/HeaderLight';

interface StyledProps {
  variant?: keyof typeof HEADERLIGHT_VARIANTS;
}

export const StyledClosedButton = styled.span<StyledProps>`
  position: absolute;
  right: 5px;
  top: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '3px' : '6px')};
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '18px' : '24px')};
  width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '18px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledInput = styled.input<StyledProps>`
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0 0 0 10px;
  flex: 1;
  height: 100%;
  font-size: ${props => props.theme.search.fontSize || '12px'};
  text-transform: ${props => props.theme.search.textTransform || 'uppercase'};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  outline: none;

  ${props => `
  &::-webkit-input-placeholder {
    color: ${props.theme.colors.text};
  }
  &::-moz-placeholder {
    color: ${props.theme.colors.text};
  }
  &:-ms-input-placeholder {
    color: ${props.theme.colors.text};
  }
  &:-moz-placeholder {
    color: ${props.theme.colors.text};
  }
  &::placeholder {
    color: ${props.theme.colors.text};
  }

  &:focus,
  &:hover {
    &::-webkit-input-placeholder {
      color: ${props.theme.search.activeColor};
    }
    &::-moz-placeholder {
      color: ${props.theme.search.activeColor};
    }
    &:-ms-input-placeholder {
      color: ${props.theme.search.activeColor};
    }
    &:-moz-placeholder {
      color: ${props.theme.search.activeColor};
    }
    &::placeholder {
      color: ${props.theme.search.activeColor};
    }
  }`}
`;

export const StyledSearchIconButton = styled.span<StyledProps>`
  display: 'block';
  align-items: center;
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '18px' : '24px')};
  width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '17px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledWrapper = styled.div<StyledProps>`
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '24px' : '40px')};
  max-width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '105px' : '120px')};
  position: relative;
  border-radius: ${props => props.theme.search.borderRadius};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  margin-right: 8px;
  border: ${props =>
    props.variant === HEADERLIGHT_VARIANTS.secondary ? 'none' : `1px solid ${props.theme.colors.darkGray}`};
  color: ${props => props.theme.colors.text};
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;

  &:focus,
  &:hover {
    border-color: ${props => props.theme.search.activeColor};

    svg {
      fill: ${props => `${props.theme.search.activeColor}`};

      > * {
        fill: ${props => `${props.theme.search.activeColor}`};
      }
    }
  }

  span:last-child {
    cursor: pointer;

    svg {
      transform: scale(0.85);
    }
  }

  &.focused-input {
    ${Media({
      maxWidth: ['150px', null, null, null, '206px'],
    })}

    input {
      padding-right: 30px;
      &::-webkit-input-placeholder {
        color: #999;
      }
      &::-moz-placeholder {
        color: #999;
      }
      &:-ms-input-placeholder {
        color: #999;
      }
      &:-moz-placeholder {
        color: #999;
      }
      &::placeholder {
        color: #999;
      }
    }
  }
`;
