import { Alert as AlertIcon, Check as CheckIcon } from '@arvesta-websites/icons';
import React from 'react';
import { useCookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass';

import type { EventType, FieldType, FormDataType, FormSubmitResponseType } from '../../../../types';
import { Config, EMAIL_REGEX } from '../../../../utils';
import Button from '../../Button';
import { withErrorBoundary } from '../../ErrorBoundary';
import InputField from '../InputField';
import RadioFieldGroup from '../RadioFieldGroup';

import {
  StyledContainer,
  StyledDescription,
  StyledError,
  StyledForm,
  StyledFormRow,
  StyledHeading,
  StyledSuccess,
} from './Styled';

export interface ContactsFormLightProps extends BoxProps {
  /** title */
  title: string;
  /** optional short description */
  shortDescription?: string;
  /** submit handler */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => FormSubmitResponseType;
  /** show email subscribe options */
  showSubscribeOptions?: boolean;
  basicPageTitle?: string;
  NETLIFY_FORM_NAME: string;
  ONE_TRUST_ID?: string;
}

const defaultFormDataType: FormDataType = {
  email: { error: '', name: 'email', value: '' },
  message: { error: '', name: 'message', value: '' },
  name: { error: '', name: 'name', value: '' },
  newsletter: { error: '', name: 'newsletter', value: '' },
};

const ContactsFormLight = ({
  title,
  shortDescription,
  handleFormSubmit,
  showSubscribeOptions,
  basicPageTitle,
  NETLIFY_FORM_NAME,
  ONE_TRUST_ID,
  ...rest
}: ContactsFormLightProps) => {
  const [cookies] = useCookies(['OptanonConsent', 'OptanonAlertBoxClosed']);
  const intl = useIntl();
  const recaptchaRef = React.useRef(null);
  const [formData, setFormData] = React.useState(defaultFormDataType);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitStatus, setSubmitStatus] = React.useState('');
  const [captchaSolved, setCaptchaSolved] = React.useState(false);
  const netlifyForm = `${NETLIFY_FORM_NAME}-${intl.locale}`;

  const validateField = (name: keyof FormDataType) => {
    let error = '';
    const field: FieldType = formData[name];
    const { value } = field;

    switch (name) {
      case 'name':
      case 'message':
      case 'newsletter':
        if (!value.length && showSubscribeOptions) {
          error = intl.formatMessage({ id: 'forms.contact.errors.required' });
        }
        break;
      case 'email':
        if (!value.length) {
          error = intl.formatMessage({ id: 'forms.contact.errors.required' });
        } else if (!EMAIL_REGEX.test(value)) {
          error = intl.formatMessage({ id: 'forms.contact.errors.email' });
        }
        break;
      default:
    }

    return error;
  };

  const handleInputChange = (e: EventType, field: keyof FormDataType) => {
    const target = e.target as HTMLInputElement;
    const newFormDataType: FormDataType = { ...formData };
    newFormDataType[field].value = target.value;
    newFormDataType[field].error = validateField(field);
    setFormData(newFormDataType);
  };

  const handleRadioChange = (_e: React.FormEvent<HTMLInputElement>, field: keyof FormDataType, value: string) => {
    const newFormDataType: FormDataType = { ...formData };
    newFormDataType[field].value = value;
    newFormDataType[field].error = validateField(field);
    setFormData(newFormDataType);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const newFormData: FormDataType = { ...formData };
    const values: { [key: string]: string } = {};
    let errors = false;

    Object.keys(formData).forEach((key: string) => {
      values[key] = formData[key as keyof FormDataType].value;
      newFormData[key as keyof FormDataType].error = validateField(key as keyof FormDataType);
      if (newFormData[key as keyof FormDataType].error.length) {
        errors = true;
      }
    });

    if (errors || !captchaSolved) {
      setFormData(newFormData);
    } else {
      setSubmitting(true);

      (async function performSubmission() {
        const result: any = await handleFormSubmit(netlifyForm, { ...values, pageTitle: (basicPageTitle = '') });
        if (!result.status || result.status !== 200) {
          setSubmitStatus('error');
          setSubmitting(false);
        } else {
          setSubmitStatus('success');
          const newFormDataType: any = { ...formData };
          newFormDataType.name.value = '';
          newFormDataType.email.value = '';
          newFormDataType.message.value = '';
          newFormDataType.newsletter.value = '';
          setFormData(newFormDataType);
        }
      })();
    }
  };

  const onCaptchaChange = (value: unknown) => {
    if (value) {
      setCaptchaSolved(true);
    } else {
      setCaptchaSolved(false);
    }
  };

  const hasOneTrustEnabled = ONE_TRUST_ID;
  const hasConsent = hasOneTrustEnabled && cookies.OptanonAlertBoxClosed;

  return (
    <StyledContainer {...rest}>
      <StyledHeading>{title}</StyledHeading>
      {shortDescription && <StyledDescription>{shortDescription}</StyledDescription>}

      <StyledForm
        as="form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        name={netlifyForm}
        onSubmit={handleSubmit}
      >
        <input name="form-name" type="hidden" value={netlifyForm} />

        <InputField
          field={formData.name}
          handleChange={handleInputChange}
          label={intl.formatMessage({ id: 'forms.contact.fullname.label' })}
          placeholder={intl.formatMessage({ id: 'forms.contact.fullname.placeholder' })}
        />

        <InputField
          field={formData.email}
          handleChange={handleInputChange}
          label={intl.formatMessage({ id: 'forms.contact.email.label' })}
          placeholder={intl.formatMessage({ id: 'forms.contact.email.placeholder' })}
        />

        <InputField
          field={formData.message}
          handleChange={handleInputChange}
          label={intl.formatMessage({ id: 'forms.contact.message.label' })}
          placeholder={intl.formatMessage({ id: 'forms.contact.message.placeholder' })}
          textarea
        />

        {showSubscribeOptions && (
          <RadioFieldGroup
            field={formData.newsletter}
            handleChange={handleRadioChange}
            label={intl.formatMessage({ id: 'forms.contact.newsletter.title' })}
            options={[
              { label: intl.formatMessage({ id: 'globals.yes' }), name: 'yes' },
              { label: intl.formatMessage({ id: 'globals.no' }), name: 'no' },
            ]}
          />
        )}

        {((hasOneTrustEnabled && hasConsent) || !hasOneTrustEnabled) && Config.RECAPTCHA_KEY && (
          <StyledFormRow>
            <ReCAPTCHA
              hl={intl.locale}
              onChange={onCaptchaChange}
              ref={recaptchaRef}
              sitekey={Config.RECAPTCHA_KEY}
              size="compact"
            />
          </StyledFormRow>
        )}

        <StyledFormRow>
          <Button disabled={(hasOneTrustEnabled && !hasConsent) || submitting || !captchaSolved}>
            {intl.formatMessage({ id: 'forms.contact.submit' })}
          </Button>
        </StyledFormRow>

        {submitStatus && (
          <>
            {submitStatus === 'success' && (
              <StyledSuccess>
                <CheckIcon />
                <span>{intl.formatMessage({ id: 'forms.contact.success' })}</span>
              </StyledSuccess>
            )}
            {submitStatus === 'error' && (
              <StyledError>
                <AlertIcon />
                <span>{intl.formatMessage({ id: 'forms.contact.failure' })}</span>
              </StyledError>
            )}
          </>
        )}
      </StyledForm>
    </StyledContainer>
  );
};

export default withErrorBoundary(ContactsFormLight, { componentName: 'ContactsFormLight' });
