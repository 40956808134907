import * as React from 'react';

const FooterSVGDesktop = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 1440 134.58"
    preserveAspectRatio="xMinYMax"
    {...props}
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="Laag_1-2" data-name="Laag 1">
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M1440,135.58H0V38.12C37.53,46,75,53.48,111.43,60.36c36.11,6.81,72.17,13.22,107.15,19,34.72,5.78,69.36,11.14,103,15.93,33.34,4.75,66.6,9.08,98.86,12.87,32,3.76,63.92,7.09,94.86,9.89,30.69,2.79,61.28,5.13,90.93,7,29.41,1.83,58.72,3.23,87.11,4.14,28.15.91,56.2,1.37,83.36,1.37h.22c34.65,0,69-.75,102.05-2.22,32.68-1.45,65-3.65,96.18-6.54,30.78-2.86,61.23-6.47,90.51-10.73,28.93-4.21,57.54-9.18,85-14.76,27.15-5.51,54-11.78,79.76-18.64,25.43-6.76,50.56-14.29,74.68-22.36,23.78-8,47.26-16.7,69.79-25.95,22.2-9.12,44.11-19,65.1-29.38V135.58Z"
          style={{ fill: '#00936f' }}
        />
      </g>
    </g>
  </svg>
);

const FooterSVGTablet = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 768 37.22"
    width="100%"
    height="100%"
    preserveAspectRatio="xMinYMax"
    {...props}
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="Laag_1-2" data-name="Laag 1">
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M768,38.22H0V0C40.2,5.59,80.17,10.54,118.81,14.72c38.24,4.13,76.24,7.61,112.93,10.35,36.33,2.72,72.41,4.78,107.23,6.13,34.48,1.33,68.69,2,101.7,2h.22c29.87,0,59.55-.56,88.22-1.65,28.39-1.08,56.59-2.71,83.82-4.86,27-2.13,53.73-4.82,79.58-8,25.58-3.15,51-6.87,75.49-11V38.22Z"
          style={{ fill: '#00936f' }}
        />
      </g>
    </g>
  </svg>
);

const FooterSVGMobile = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 375 14.93"
    width="100%"
    height="100%"
    preserveAspectRatio="xMinYMax"
    {...props}
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="Laag_1-2" data-name="Laag 1">
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M375,15.93H0V0C42.66,3.64,84.91,6.41,125.56,8.22c40.17,1.79,79.91,2.7,118.11,2.7h.22c22.52,0,45-.31,66.8-.93C332.34,9.37,354,8.44,375,7.21v8.71Z"
          style={{ fill: '#00936f' }}
        />
      </g>
    </g>
  </svg>
);

export default {
  Desktop: FooterSVGDesktop,
  Mobile: FooterSVGMobile,
  Tablet: FooterSVGTablet,
};
