import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.92 126.64" width="162px" height="50px">
    <path
      fill="#8abd24"
      d="M198.55,115.28h-1.69l.44-2.24h10.81c1.17,0,1.57.49,1.34,1.67L209,117h-2.37l.23-1.17c.07-.39-.1-.57-.47-.57h-5.23L200,121h6.43l-.43,2.24H199.6l-1,5.34a.42.42,0,0,0,.46.57h5.36a.66.66,0,0,0,.69-.57l.23-1.18h2.39l-.45,2.32a1.78,1.78,0,0,1-2,1.67h-8.18c-1.17,0-1.57-.5-1.34-1.67l2.81-14.48Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M213.68,124.66l-1.79-3.75c-.21-.42-.48-.5-1-.5h-.73l.42-2.16h1.62c1.22,0,1.54.23,2,1.33l1.12,2.55c.12.34.26.83.26.83h0s.33-.49.6-.83l2.09-2.55a2.61,2.61,0,0,1,2.55-1.33h1.64l-.42,2.16h-.76a1.39,1.39,0,0,0-1.21.47l-3.26,3.78,3.14,6.77h-2.84l-1.95-4.3c-.12-.31-.25-.7-.25-.7h-.06s-.28.39-.55.7l-3.57,4.3h-2.87l5.77-6.77Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M224.63,121c.08-.39-.09-.57-.46-.57H223.1l.42-2.16h2.37c1.18,0,1.52.49,1.36,1.33-.08.39-.16.7-.16.7h0a5.94,5.94,0,0,1,4.83-2.34c3.54,0,5.26,2.81,4.47,6.9s-3.9,6.9-7.34,6.9a3.59,3.59,0,0,1-3.62-2.11h0a10.91,10.91,0,0,1-.18,1.15l-1.13,5.86h-2.53l3-15.66Zm4.5,8.6c2.09,0,4.14-1.72,4.72-4.72s-.62-4.71-2.8-4.71c-2,0-4.08,1.38-4.73,4.74-.45,2.35.37,4.69,2.81,4.69Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M246.29,117.94c3.7,0,5.15,2.76,4.48,6.17-.06.34-.28,1-.28,1h-9.61c-.39,2.81,1.33,4.4,3.68,4.4a7.45,7.45,0,0,0,4.15-1.53l.74,1.79a9.36,9.36,0,0,1-5.45,1.93c-4.17,0-6.42-3-5.66-6.9a8.2,8.2,0,0,1,7.95-6.9Zm2,5.36c.35-2.19-.8-3.36-2.44-3.36a4.84,4.84,0,0,0-4.54,3.36Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M254.74,121c.07-.39-.1-.57-.47-.57h-1.06l.42-2.16H256c1.15,0,1.6.47,1.4,1.54l-.16.83c-.11.52-.23.91-.23.91h.05a5.8,5.8,0,0,1,4.83-3.44,4.27,4.27,0,0,1,.67.06l-.49,2.5a3.38,3.38,0,0,0-.74-.08,5.12,5.12,0,0,0-4.3,3.1,9.13,9.13,0,0,0-.77,2.37l-1,5.39h-2.52l2-10.45Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M265.06,120.28h-1.72l.39-2h1.77l.71-3.62h2.47l-.7,3.62h3.15l-.4,2h-3.15l-1.17,6c-.51,2.63,1.24,3,2.21,3a3.43,3.43,0,0,0,.61,0l-.44,2.24a7,7,0,0,1-.9.05c-1.66,0-4.92-.52-4.06-4.92l1.23-6.33Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M272.46,127.73a4.41,4.41,0,0,0,3.61,2,2.43,2.43,0,0,0,2.62-1.69c.46-2.37-6.78-2.11-6-6.35.5-2.53,2.94-3.7,5.62-3.7,1.59,0,4.22.54,3.83,2.55l-.25,1.25h-2.26l.13-.65c.15-.78-1-1.12-1.77-1.12-1.48,0-2.55.52-2.75,1.56-.49,2.5,6.81,2,6,6.36-.46,2.34-2.84,3.85-5.6,3.85a5.4,5.4,0,0,1-4.81-2.32l1.63-1.69Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M292.94,121c.08-.39-.1-.57-.46-.57h-1.07l.42-2.16h2.47c1.18,0,1.58.49,1.35,1.67l-1.71,8.77c-.07.39.1.58.46.58h1.07l-.42,2.16h-2.47c-1.18,0-1.58-.5-1.35-1.67l1.71-8.78Zm1.6-7.94h2.26l-.51,2.63H294l.52-2.63Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M300,121c.08-.39-.1-.57-.46-.57h-1.07l.42-2.16h2.42c1.12,0,1.57.49,1.39,1.46l-.1.49a5.83,5.83,0,0,1-.2.76h.05a6.79,6.79,0,0,1,5.54-3c3.13,0,4.2,1.69,3.55,5l-1.11,5.7c-.07.39.1.58.46.58h1.07l-.42,2.16H309c-1.17,0-1.57-.5-1.34-1.67l1.2-6.2c.36-1.85.28-3.31-1.83-3.31a5.47,5.47,0,0,0-4.8,3.23,6.09,6.09,0,0,0-.57,1.78l-1.2,6.17h-2.53L300,121Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M323.52,120.28H321.8l.39-2H324l.7-3.62h2.47l-.7,3.62h3.15l-.39,2h-3.15l-1.18,6c-.51,2.63,1.25,3,2.21,3a3.3,3.3,0,0,0,.61,0l-.44,2.24a6.76,6.76,0,0,1-.89.05c-1.67,0-4.93-.52-4.07-4.92l1.23-6.33Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M333.2,115.77c.07-.39-.1-.57-.46-.57h-1.07l.42-2.16h2.5c1.17,0,1.57.49,1.35,1.61l-1,5.14c-.12.62-.26,1.09-.26,1.09h.05a6.77,6.77,0,0,1,5.47-2.94c3.1,0,4.18,1.69,3.52,5l-1.11,5.7c-.07.39.1.58.47.58h1.06l-.42,2.16h-2.47c-1.17,0-1.57-.5-1.34-1.67l1.2-6.2c.36-1.85.25-3.31-1.86-3.31a5.56,5.56,0,0,0-4.8,3.26,6.1,6.1,0,0,0-.57,1.75l-1.2,6.17h-2.53l3.05-15.66Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M354.42,117.94c3.7,0,5.14,2.76,4.48,6.17-.07.34-.28,1-.28,1H349c-.39,2.81,1.34,4.4,3.68,4.4a7.41,7.41,0,0,0,4.15-1.53l.75,1.79a9.41,9.41,0,0,1-5.46,1.93c-4.16,0-6.42-3-5.66-6.9a8.22,8.22,0,0,1,8-6.9Zm2,5.36c.35-2.19-.81-3.36-2.45-3.36a4.82,4.82,0,0,0-4.53,3.36Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M370,120.28h-1.64l.4-2h1.64l.08-.44a5.65,5.65,0,0,1,5.93-4.9,5.08,5.08,0,0,1,.95.08l-.42,2.16a4.33,4.33,0,0,0-.69-.05,3.12,3.12,0,0,0-3.26,2.78l-.07.37h6.65c1.17,0,1.57.49,1.34,1.67l-1.71,8.77c-.07.39.1.58.46.58h1.07l-.42,2.16h-2.47c-1.2,0-1.6-.5-1.37-1.67l1.73-8.91c.07-.36-.1-.57-.46-.57h-5.21l-2.17,11.15h-2.52L370,120.28Zm9.77-7.24h2.29l-.51,2.63h-2.29l.51-2.63Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M391.16,117.94c3.7,0,5.14,2.76,4.48,6.17-.07.34-.28,1-.28,1h-9.61c-.39,2.81,1.33,4.4,3.68,4.4a7.45,7.45,0,0,0,4.15-1.53l.74,1.79a9.36,9.36,0,0,1-5.45,1.93c-4.17,0-6.42-3-5.67-6.9a8.22,8.22,0,0,1,8-6.9Zm2,5.36c.35-2.19-.8-3.36-2.44-3.36a4.84,4.84,0,0,0-4.54,3.36Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M400.57,115.77c.07-.39-.1-.57-.47-.57H399l.42-2.16H402c1.17,0,1.57.49,1.34,1.67l-2.72,14c-.07.39.1.58.46.58h1.07l-.42,2.16h-2.5c-1.17,0-1.57-.5-1.34-1.67l2.72-14Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M411.89,117.94c3.05,0,3.67,2,3.67,2h.05s0-.39.12-.89l.65-3.31c.07-.39-.1-.57-.47-.57h-1.06l.42-2.16h2.5c1.17,0,1.57.49,1.34,1.67l-2.72,14c-.08.39.1.58.46.58h1.07l-.42,2.16h-2.42c-1.15,0-1.52-.52-1.34-1.41.07-.39.15-.67.15-.67h-.05a5.91,5.91,0,0,1-4.79,2.39c-3.57,0-5.26-2.81-4.47-6.9s3.82-6.9,7.31-6.9Zm2.81,6.87c.45-2.34-.32-4.69-2.84-4.69-2.06,0-4.11,1.72-4.7,4.72s.62,4.71,2.81,4.71c2,0,4.07-1.38,4.73-4.74Z"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#4d6113"
      d="M85.29,83.21c-4.07-.77-3.74-1.61-2.94-2.31,17.07-10.54,30.88-22.37,30-36.46C112.11,39.7,109,32,110.18,33.59c45.34,62.07-15.49,51.12-24.89,49.62M308.4,65H272.64c.49,7.12,6.8,11.08,12.87,11,8.69,0,6.9-6,21.6-6a25.12,25.12,0,0,1-9,11.17A22,22,0,0,1,285.43,85,23.37,23.37,0,0,1,262,61.58c0-12.62,9.87-23.71,23.3-23.71a22.68,22.68,0,0,1,16.59,6.8c5.42,5.58,7.44,12.54,6.55,20.31m-35.5-8.43H298c-.4-4.2-4.85-10.1-12.6-10s-12.12,5.65-12.52,10m146.75-9V83.28s-10.58,1.09-10.58-6.38c-2.83,5.17-8.72,7.76-14.94,7.76-14.22,0-22.54-11.07-22.54-23.59,0-14,10.1-23.5,22.54-23.5,8.07,0,13,4.28,14.94,7.84V38.94c10.42,0,10.58,8.62,10.58,8.62M382.17,61.24c0,5.57,4,13.56,13.49,13.56a12.54,12.54,0,0,0,11.79-7.1,15.21,15.21,0,0,0,1.7-6.22,13.8,13.8,0,0,0-1.21-6.22,13,13,0,0,0-12.36-7.84c-8.56,0-13.41,6.95-13.41,13.82M196.07,76.47c0,7.63,10.58,6.81,10.58,6.81V60.8c0-7.67,3.8-12.57,11.23-12.57h.48V39c-6.7.08-10.34,2.93-12.27,6.88a10,10,0,0,0-10-6.93V76.47Zm68.38-37.53c-11.69,0-13,5.92-13,5.92L242.81,70,234.9,46.78s-2.56-7.84-13.74-7.84c4.16,11.66,13.61,36.68,13.61,36.68,3.81,9.2,13.21,7.66,13.21,7.66l16.47-44.34ZM344,50.9c-.89-10.66-9.78-13.33-15.75-13.33-8.56,0-15.91,5.41-15.91,14.13.08,6.39,4.92,9.7,10.9,11.56,8.16,2.82,11.47,3.87,11.47,7.67.08,3-2.75,5.17-6.06,5.17-7.8,0-4.44-5.74-17.12-5.74.81,10.74,10.1,14.3,17,14.3,9.29,0,17-5.82,16.8-14.94-.08-9.53-7.75-11.63-14.86-13.57-4.2-1.22-7.43-2.75-7.51-5.58,0-2.18,1.69-4.44,5.25-4.44,4.12,0,5.7,4.77,15.83,4.77m9.66,26.29c0,7,10.58,6.09,10.58,6.09V47.1h7.6V38.94h-7.6V30.73s.81-7.21-10.58-7.21V38.94h-7.35s-1.9,8.16,7.35,8.16V77.19ZM191.39,47.56V83.28s-10.58,1.09-10.58-6.38c-2.83,5.17-8.73,7.76-14.95,7.76-14.21,0-22.53-11.07-22.53-23.59,0-14,10.1-23.5,22.53-23.5,8.08,0,13,4.28,14.95,7.84V38.94c10.42,0,10.58,8.62,10.58,8.62M153.91,61.24c0,5.57,4,13.56,13.49,13.56a12.54,12.54,0,0,0,11.79-7.1,15.37,15.37,0,0,0,1.7-6.22,13.94,13.94,0,0,0-1.21-6.22,13,13,0,0,0-12.36-7.84c-8.56,0-13.41,6.95-13.41,13.82"
      transform="translate(-8.72 -10)"
    />
    <path
      fill="#8abd24"
      d="M104.17,22.21C131.72,69.1,16.68,89.76,8.72,86,29.35,74.77,55.45,21.3,79.38,11.53,88.05,8,96.43,10.19,104.17,22.21"
      transform="translate(-8.72 -10)"
    />
  </svg>
);

export default Logo;
