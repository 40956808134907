import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

const ChevronLeft = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    fill="none"
    viewBox="1 0 24 24"
    strokeWidth="2.3"
    stroke="currentColor"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
  </svg>
);

export default ChevronLeft;
