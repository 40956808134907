import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import Cta from '../../Cta/Cta';

export const StyledCard = styled(Box)`
  background-color: ${props => props.theme.colors.white};
  &.vertical {
    ${Media({
      maxWidth: ['100%', '100%', '441px'],
    })}
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &.horizontal {
    width: 100%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;

    ${Media({
      maxWidth: ['441px', '441px', '441px', '1324px'],
    })}

    > div {
      display: flex;
      width: 100%;

      ${Media({
        flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
      })}
    }
  }
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledImageBoxLink = styled(Cta)`
  text-decoration: none;
  position: relative;
  display: block;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((311 / 441) * 100%);
  }

  > ${StyledImageBox} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${StyledCard}.horizontal & {
    ${Media({
      width: ['100%', null, null, `calc(420 / 1324 * 100%)`],
    })}

    &::before {
      ${Media({
        content: ['', null, null, 'none'],
      })}
    }
  }
`;

export const StyledHorizontalImageBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledContentBox = styled(Box)`
  height: 100%;
  ${Media({
    padding: ['1.5rem', null, '2rem'],
  })}

  ${StyledCard}.horizontal & {
    ${Media({
      width: ['100%', null, null, `calc(100% - (420 / 1324 * 100%))`],
    })}

    display: flex;
    flex-direction: column;

    &.no-image {
      width: 100%;
    }
  }

  ${StyledCard}.vertical & {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledTitle = styled.h3`
  color: ${props => props.theme.text.heading.color ?? props.theme.colors.text};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 1rem 0;
  word-wrap: break-word;

  a {
    color: ${props => props.theme.text.heading.color ?? props.theme.colors.text};
    text-decoration: none;
  }
`;

export const StyledEventDetails = styled.div`
  color: ${props => props.theme.colors.text};
  margin-bottom: 1rem;

  ${StyledCard}.horizontal & {
    ${Media({
      display: ['block', 'block', 'block', 'flex'],
    })}

    > *:not(:last-child) {
      ${Media({
        marginRight: ['0', null, '2.875rem'],
      })}
    }
  }
`;

export const StyledEventInfo = styled.p`
  display: flex;
  color: ${props => props.theme.colors.text}
  font-size: 0.875rem;
  line-height: 1.7;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  > span {
    vertical-align: middle;
    width: calc(100% - 15px);
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.25rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.colors.text};

    > * {
      fill: ${props => props.theme.colors.text};
    }
  }
`;

export const StyledEventDate = styled(StyledEventInfo)``;
export const StyledEventLocation = styled(StyledEventInfo)``;

export const StyledDescription = styled.p`
  font-size: 1rem;
  line-height: 1.75;
  max-width: 534px !important;
  color: ${props => props.theme.colors.text};
  flex: 1;
  word-wrap: break-word;
  white-space: pre-wrap;

  h3 + & {
    ${Media({
      marginTop: ['0.5rem', null, '1.5625rem'],
    })}
  }
`;

export const StyledCtaWrapper = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding-bottom: 1.4375rem;
  padding-top: 1.4375rem;

  ${Media({
    marginBottom: ['-1.5rem', null, '-2rem'],
    marginLeft: ['-1.5rem', null, '-2rem'],
    marginRight: ['-1.5rem', null, '-2rem'],
    marginTop: ['1.5rem', null, '2rem'],
    paddingLeft: ['1.5rem', null, '2rem'],
    paddingRight: ['1.5rem', null, '2rem'],
  })}

  ${StyledCard}.horizontal & {
    ${Media({
      borderTop: [null, null, null, 'none'],
    })}
  }
`;

export const StyledCta = styled(Cta)`
  color: ${props => props.theme.cardCtas.color};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-transform: ${props => props.theme.cardCtas.textTransform};
  letter-spacing: ${props => props.theme.cardCtas.letterSpacing};
  font-size: ${props => props.theme.cardCtas.fontSize};
  line-height: ${props => props.theme.cardCtas.lineHeight};

  > span {
    vertical-align: middle;
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.cardCtas.arrowColor};

    > * {
      fill: ${props => props.theme.cardCtas.arrowColor};
    }
  }

  &:hover {
    color: ${props => props.theme.cardCtas.hoverColor};

    > svg {
      fill: ${props => props.theme.cardCtas.arrowHoverColor};

      > * {
        fill: ${props => props.theme.cardCtas.arrowHoverColor};
      }
    }
  }
`;

export const StyledContentWrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
`;
