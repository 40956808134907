import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import { mediumBreakpoint } from '../../../utils/breakpoints';
import { useCurrentBreakpoint } from '../../../utils/hooks/breakpoints';
import { Button, Cta, EventCard, type EventCardProps, HeadingTag, withErrorBoundary } from '../../components';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../shared';

import {
  StyledCardContainer,
  StyledEventCardWrapper,
  StyledHeadingAndDescriptionWrapper,
  StyledHorizontalEventCardWrapper,
  StyledHorizontalEventsCardWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

export interface EventListingModuleProps extends BoxProps {
  title: string;
  titleTag: HeadingTag;
  shortDescription?: string | null;
  events: EventCardProps[];
  featuredEvent?: EventCardProps;
  initialAmount?: number;
  incrementAmount?: number;
}

const EventListingModule = ({
  title,
  titleTag,
  shortDescription,
  events,
  featuredEvent,
  initialAmount = 3,
  incrementAmount = 8,
  ...rest
}: EventListingModuleProps) => {
  const intl = useIntl();
  const [amountOfEventsToDisplay, setDisplayLimit] = React.useState(initialAmount);
  const currentBreakpoint = useCurrentBreakpoint();
  const isMediumBreakpoint = currentBreakpoint === mediumBreakpoint;

  return (
    <StyledModuleContainer {...rest}>
      <StyledHeadingAndDescriptionWrapper>
        <StyledModuleHeading variant="h1" tag={titleTag}>
          {title}
        </StyledModuleHeading>
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledHeadingAndDescriptionWrapper>
      <StyledCardContainer className={`cards-${events.length}`}>
        {events
          .map(({ id, ...eventRest }: EventCardProps) => (
            <StyledEventCardWrapper key={id} className={`cards-${events.length}`}>
              <EventCard id={id} {...eventRest} />
            </StyledEventCardWrapper>
          ))
          .slice(0, 3)}
        {isMediumBreakpoint && featuredEvent && (
          <StyledEventCardWrapper className={`cards-${events.length}`}>
            <EventCard {...featuredEvent} />
          </StyledEventCardWrapper>
        )}
      </StyledCardContainer>
      <StyledHorizontalEventsCardWrapper>
        {!isMediumBreakpoint && featuredEvent && (
          <StyledHorizontalEventCardWrapper>
            <EventCard variant="horizontal" {...featuredEvent} />
          </StyledHorizontalEventCardWrapper>
        )}
        {events.length > 3
          ? events
              .map(({ id, ...eventRest }: EventCardProps) => (
                <StyledHorizontalEventCardWrapper key={id}>
                  <EventCard id={id} variant="horizontal" {...eventRest} />
                </StyledHorizontalEventCardWrapper>
              ))
              .slice(3, amountOfEventsToDisplay)
          : null}
      </StyledHorizontalEventsCardWrapper>
      <StyledModuleCtaContainer>
        {events.length > amountOfEventsToDisplay ? (
          <Cta
            onClick={() => {
              setDisplayLimit(amountOfEventsToDisplay + incrementAmount);
            }}
          >
            <Button
              onClick={() => {
                setDisplayLimit(amountOfEventsToDisplay + incrementAmount);
              }}
            >
              {intl.formatMessage({ id: 'sections.events.loadmore' })}
            </Button>
          </Cta>
        ) : null}
      </StyledModuleCtaContainer>
    </StyledModuleContainer>
  );
};

export default withErrorBoundary(EventListingModule, { componentName: 'EventListingModule' });
