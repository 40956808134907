import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';

import { withErrorBoundary } from '../../ErrorBoundary';

import { StyledDescription, StyledImageWrapper, StyledLink, StyledTitle, StyledWrapper } from './Styled';

type Image = {
  title: string;
  alt?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

export interface ArticleCardProps {
  title?: string;
  description?: string;
  image?: Image;
  ctaLabel?: string;
  link?: string;
}

const getImageData = (image?: Image): IGatsbyImageData | null => {
  if (image && image.asset) {
    return image.asset.gatsbyImageData;
  }

  console.warn('NewsCard: No image data provided', JSON.stringify(image));

  return null;
};

const ArticleCard = ({ title, description, image, ctaLabel, link, ...rest }: ArticleCardProps) => {
  const intl = useIntl();

  return (
    <StyledWrapper to={link} {...rest}>
      <StyledImageWrapper>
        {image && getImageData(image) && (
          <GatsbyImage
            alt={image.alt ? image.alt : image.title}
            image={getImageData(image) as IGatsbyImageData}
            objectFit="cover"
            style={{ height: '100%', margin: '0 auto' }}
          />
        )}
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      <StyledLink>{ctaLabel || intl.formatMessage({ id: 'newscard.readmore' })}</StyledLink>
    </StyledWrapper>
  );
};

export default withErrorBoundary(ArticleCard, { componentName: 'ArticleCard' });
