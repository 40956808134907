/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

// BASICS
export const baseColors = {
  backgroundGreen: '#99BB39',
  backgroundLight: '#a0bb4f',
  beige: '#F8FAF2',
  black: '#000',
  earthyGreen: '#536121',
  earth: '#cd5b1b',
  freshGreen: '#8abd24',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  livelyWhite: '#f8faf0',
  nature: '#146f67',
  red: '#f00101',
  sea: '#50adc8',
  sky: '#2b4390',
  sun: '#f2d807',
  white: '#fff',
};

export const colors = {
  ...baseColors,
  background: baseColors.livelyWhite,
  backgroundLight: baseColors.backgroundLight,
  buttonGreen: baseColors.backgroundGreen,
  danger: baseColors.red,
  dark: baseColors.gray[4],
  darkGray: baseColors.gray[4],
  info: baseColors.sea,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.freshGreen,
  primaryBeige: baseColors.beige,
  primaryDarkGreen: baseColors.earthyGreen,
  primaryWhite: baseColors.livelyWhite,
  secondary: baseColors.freshGreen,
  secondaryCyan: baseColors.nature,
  secondaryDarkBlue: baseColors.sky,
  secondaryLightBlue: baseColors.sea,
  secondaryOrange: baseColors.earth,
  secondaryYellow: baseColors.sun,
  success: baseColors.freshGreen,
  superLightGrey: baseColors.gray[1],
  text: baseColors.earthyGreen,
  textMuted: baseColors.gray[3],
  warning: baseColors.sun,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: 'Montserrat, sans-serif',
  heading: 'Merriweather',
  hightlight: 'Montserrat Medium Italic',
  quotes: 'Merriweather',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 700,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '0.75rem', // 12px (80%),
  1: '0.875rem',
  2: '1rem', // 16px
  3: '1.25rem',
  4: '1.5rem',
  5: '1.75rem',
  6: '2rem', // 32px
  7: '2.25rem',
  8: '2.5rem',
  9: '3.5rem', // 56px
  10: '4.5rem',
  11: '5.5rem',
  12: '6rem',
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  // container widths
  sm: '540px',
  md: '720px',
  lg: '1150px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  a: {
    color: colors.backgroundGreen,
    textDecoration: 'underline',
    ':hover': {
      fontWeight: fontWeights.bold,
      textDecoration: 'solid underline 2px',
      textUnderlineOffset: '9px',
    },
  },
  blockquote: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontStyle: 'normal',
    fontWeight: 'bold',
    textTransform: 'normal',
  },
  h1: {
    ...heading,
    fontSize: { desktop: fontSizes[8], mobile: fontSizes[5], tablet: fontSizes[6] },
    lineHeight: { desktop: 1.5, mobile: 1.5, tablet: 1.5 },
  },
  h2: {
    ...heading,
    fontSize: { desktop: fontSizes[7], mobile: fontSizes[4], tablet: fontSizes[5] },
    lineHeight: { desktop: 1.5, mobile: 1.5, tablet: 1.5 },
  },
  h3: {
    ...heading,
    fontSize: { desktop: fontSizes[6], mobile: fontSizes[3], tablet: fontSizes[4] },
    lineHeight: { desktop: 1.5, mobile: 1.5, tablet: 1.5 },
  },
  h4: {
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[2], tablet: fontSizes[2] },
    lineHeight: { desktop: 1.5, mobile: 1.5, tablet: 1.5 },
  },
  h5: {
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[2], tablet: fontSizes[2] },
    lineHeight: { desktop: 1.5, mobile: 1.5, tablet: 1.5 },
  },
  highlight: {
    backgroundColor: colors.primaryBeige,
    color: colors.text,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.primaryDarkGreen,
  },
  li: {
    bulletBorderRadius: '5px',
    bulletColor: colors.primaryDarkGreen,
  },
  p: {
    fontSize: fontSizes[2],
    lineHeight: lineHeights.paragraph,
    margin: '0',
    marginBottom: '1.7rem',
  },
  rteHighlight: {
    color: colors.primary,
    textDecoration: 'underline',
    hoverColor: colors.primary,
    isFooter: colors.white,
  },
  table: {
    // todo
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '3rem',
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '0px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: '14px',
  fontWeight: fontWeights.bold,
  height: 'auto',
  minWidth: '194px',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    backgroundColor: '#99BB39',
    borderColor: colors.primaryBeige,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
  'primary-with-background': {
    ...buttonDefaults,
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primaryDarkGreen,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.white,
    },
  },
  secondary: {
    ...buttonDefaults,
    backgroundColor: colors.primaryBeige,
    borderColor: colors.primaryBeige,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primaryDarkGreen,
    '&:hover': {
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.primaryBeige,
      color: colors.primaryBeige,
    },
  },
  tertiary: {
    ...buttonDefaults,
    backgroundColor: 'transparent',
    borderColor: colors.primaryBeige,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primaryBeige,
    '&:hover': {
      backgroundColor: colors.primaryBeige,
      color: colors.primaryDarkGreen,
    },
  },
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
};

export const articleDetail = {
  tags: {
    backgroundColor: colors.freshGreen,
    color: colors.primaryWhite,
  },
  title: colors.primaryDarkGreen,
  introduction: colors.earthyGreen,
};

export const articleListing = {
  background: colors.buttonGreen,
  filter: colors.primaryDarkGreen,
  hover: colors.primaryDarkGreen,
  text: colors.white,
  title: colors.primaryDarkGreen,

  mobile: {
    title: colors.primaryDarkGreen,
    text: colors.text,
    background: colors.primaryWhite,
  },
};

export const breadcrumb = {
  backgroundColor: colors.white,
};

export const cardCtas = {
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.primary,
  color: 'rgba(138, 188, 36, 0.8)',
  fontSize: '14px',
  hovercolor: colors.primary,
  letterSpacing: '0px',
  lineHeight: '14px',
  textTransform: 'normal',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      color: colors.primary,
      '&:hover': {
        color: colors.primaryDarkGreen,
      },
    },
    title: colors.text,
  },
  logo: {
    color: colors.primaryDarkGreen,
  },
};

export const carousel = {
  boxShadow: '3px 3px 3px #00000029',
  controls: {
    regular: {
      backgroundColor: colors.primaryDarkGreen,
      color: colors.beige,
      hover: {
        backgroundColor: colors.primaryDarkGreen,
        color: colors.beige,
      },
    },
    hero: {
      opacity: '0.8',
      color: colors.primaryDarkGreen,
      backgroundColor: colors.beige,
      stroke: colors.beige,
      hover: {
        color: colors.primaryDarkGreen,
        backgroundColor: colors.beige,
        stroke: colors.beige,
      },
    },
  },
  indicator: colors.primaryDarkGreen,
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.primaryDarkGreen,
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  text: colors.primaryDarkGreen,
};

export const categoryCards = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
};

export const categorySection = {
  background: colors.earthyGreen,
  backgroundImage: '/images/category-background.png',
  boxShadow: '3px 3px 3px #0000004d',
  color: colors.primaryWhite,
  date: colors.earthyGreen,
  dateColor: colors.earthyGreen,
  headingColor: colors.earthyGreen,

  chevron: {
    fill: colors.beige,
    stroke: colors.earthyGreen,
    opacity: 0.8,
  },
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.text,
  infoBlock: {
    background: colors.primaryWhite,
    boxShadow: '3px 3px 3px #00000029',
    title: colors.text,
    description: colors.text,
    button: {
      backgroundColor: colors.buttonGreen,
      color: colors.primaryWhite,
      '&:hover': {
        backgroundColor: colors.primaryDarkGreen,
        textDecoration: 'none',
      },
    },
  },
};

export const contactForm = {
  heading: {
    color: colors.text,
  },
};

export const contacts = {
  description: colors.earthyGreen,
  title: colors.primaryDarkGreen,
  titleFontWeight: 'bold',
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '14px',
    mapTextFontWeight: 'bold',
    mapTextLetterSpacing: '0px',
    mapTextLineHeight: '2',
    mapTextTransform: 'normal',
    openingHoursFontSize: '16px',
  },
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.primary,
    color: colors.white,
    titleFontWeight: 'bold',
  },
  focusText: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
};

export const ctaGroup = {
  title: colors.primaryDarkGreen,
};

export const customBlockCards = {
  backgroundColor: colors.primary,
  color: colors.primaryDarkGreen,
  titleFontWeight: 'bold',
};

export const dealer = {
  button: {
    backgroundColor: colors.buttonGreen,
    border: 'none',
    color: colors.white,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.primaryDarkGreen,
    },
    '&:disabled': {
      backgroundColor: 'lightgrey',
      cursor: 'not-allowed',
    },
  },
  textColor: colors.primary,
  titleFontWeight: 'bold',
};

export const documentDownload = {
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.primary,
  color: 'rgba(138, 188, 36, 0.8)',
  fontWeight: 'bold',
  hovercolor: colors.primary,
};

export const footer = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.primaryDarkGreen,
  minHeight: '160px',
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '4px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
  label: {
    fontSizes: ['12px', null, '16px'],
    lineHeights: ['21px', null, '26px'],
  },
};

export const header = {
  activeColor: colors.primary,
  backgroundColor: colors.primaryBeige,
  color: colors.primaryDarkGreen,
};

export const headerBottom = {
  activeColor: colors.primaryDarkGreen,
  activeFontWeight: '700',
  backgroundColor: colors.primaryWhite,
  color: colors.primaryDarkGreen,
  fontSize: '14px',
  fontWeight: '400',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: colors.primaryWhite,
      color: colors.primaryDarkGreen,
    },
    backgroundColor: 'transparent',
    borderColor: colors.primaryWhite,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primaryWhite,
    height: '54px',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: colors.primaryWhite,
      borderStyle: 'solid',
      borderWidth: '1px',
      color: colors.primaryWhite,
    },
    backgroundColor: colors.primaryWhite,
    color: colors.primaryDarkGreen,
    height: '54px',
    justifyContent: 'space-between',
    padding: '0px 12px',
    width: 'fit-content',
  },
  arrow: {
    display: 'block',
  },
};

export const heroSection = {
  titleFontWeight: 'bold',
};

export const imageAndText = {
  color: colors.primaryDarkGreen,
};

export const infoBlock = {
  background: colors.primaryWhite,
  boxShadow: '3px 3px 3px #00000029',
  title: colors.text,
  description: colors.text,
  button: {
    backgroundColor: colors.buttonGreen,
    color: colors.primaryWhite,
    '&:hover': {
      backgroundColor: colors.primaryDarkGreen,
      textDecoration: 'none',
    },
  },
};

export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
  title: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.primaryWhite,
};

export const media = {
  backgroundColor: 'transparent',
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.lightGray,
  chevronColor: colors.darkGray,
};

export const navItems = {
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0px',
  selectedFontWeight: 400,
  textTransform: 'normal',
};

export const newsListing = {
  titleFontWeight: 'bold',
};

export const ownerDetail = {
  tags: {
    backgroundColor: colors.freshGreen,
    color: colors.primaryWhite,
  },
  title: colors.primaryDarkGreen,
  introduction: colors.earthyGreen,
};

export const product = {
  backgroundColor: colors.primaryDarkGreen,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'bold',
};

export const salesPerson = {
  borderColor: colors.primaryBeige,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '0px',
  fontWeight: 'normal',
  textTransform: 'normal',
};

export const shortBanner = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  headerFontSize: '12px',
  headerLetterSpacing: '0px',
  scrollbarColor: colors.primaryWhite,
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleFontWeight: 'bold',
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Proxani',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
