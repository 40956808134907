import styled from 'styled-components';

interface StyledWrapperInner {
  fixFirstColumn?: boolean;
  isScrolledToLeft?: boolean;
  isScrolledToRight?: boolean;
}

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledWrapperInner = styled.div<StyledWrapperInner>`
  width: 100%;
  overflow: auto;
  padding-bottom: 32px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.muted};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${props => props.theme.table.scrollbarColor};
  }

  ::-webkit-scrollbar-corner {
    -webkit-appearance: none;
  }

  ${props =>
    !props.isScrolledToLeft &&
    !props.fixFirstColumn &&
    `
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: calc(100% - 37px);
      content: "";
      opacity: 0.15;
      background-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }
  `}

  ${props =>
    !props.isScrolledToLeft &&
    props.fixFirstColumn &&
    `
    td:first-child,
    th:first-child {
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 100%;
        content: "";
        opacity: 0.15;
        background-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      }
    }
  `}

  ${props =>
    !props.isScrolledToRight &&
    `
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: calc(100% - 37px);
      content: "";
      opacity: 0.15;
      background-image: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }
  `}
`;

interface StyledTable {
  fixFirstColumn?: boolean;
}

export const StyledTable = styled.table<StyledTable>`
  min-width: 100%;

  ${props =>
    props.fixFirstColumn &&
    `
    td:first-child,
    th:first-child {
      position: sticky;
      left: 0;
    }
  `}
`;

export const StyledRow = styled.tr`
  width: 100%;

  &:nth-child(odd) td {
    background-color: ${props => props.theme.colors.white};
  }

  &:nth-child(even) td {
    background-color: ${props => props.theme.colors.muted};
  }
`;

export const StyledCell = styled.td`
  font-size: 16px;
  color: ${props => props.theme.colors.text};
`;

export const StyledHeadingCell = styled.th`
  font-size: ${props => props.theme.table.headerFontSize || '12px'};
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.table.headerColor};
  background-color: ${props => props.theme.table.headerBackgroundColor};
  letter-spacing: ${props => props.theme.table.headerLetterSpacing || '0px'};
`;

export const StyledCellContents = styled.div`
  text-align: left;
  padding: 24px;
  line-height: 24px;
  min-width: 125px;
`;
