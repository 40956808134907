/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

export const baseColors = {
  black: '#000',
  blue: '#007dba',
  darkBlue: '#005b87',
  darkRed: '#bd2817',
  darkestRed: '#811B10',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  green: '#008411',
  red: '#f00101',
  white: '#fff',
  yellow: '#ffcc32',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  danger: baseColors.red,
  dark: baseColors.gray[4],
  darkGray: baseColors.gray[4],
  info: baseColors.blue,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.darkRed,
  secondary: baseColors.yellow,
  success: baseColors.green,
  superLightGrey: baseColors.gray[1],
  text: baseColors.gray[4],
  textMuted: baseColors.gray[3],
  warning: baseColors.yellow,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: "'Ropa Sans', Arial, sans-serif",
  heading: 'inherit',
  monospace: 'Menlo, monospace',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = [
  '0.75rem', // 12px (80%),
  '0.875rem',
  '1rem', // 16px
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem', // 32px
  '2.5rem',
  '3.5rem', // 56px
  '4.5rem',
  '5.5rem',
  '6rem',
];

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  lg: '1150px',

  md: '720px',
  // container widths
  sm: '540px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  lg: '0.3rem',
  pill: '50rem',
  sm: '0.2rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
  marginBottom: '1rem',
  marginTop: '1rem',
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  a: {
    ':hover': {
      color: colors.primary,
    },
    color: colors.darkGray,
    textDecoration: 'underline',
  },
  blockquote: {
    backgroundColor: colors.primary,
    color: colors.background,
    fontStyle: 'normal',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  h1: {
    ...heading,
    fontSize: fontSizes[8],
  },
  h2: {
    ...heading,
    fontSize: fontSizes[7],
  },
  h3: {
    ...heading,
    fontSize: fontSizes[6],
  },
  h4: {
    ...heading,
    fontSize: fontSizes[4],
  },
  h5: {
    ...heading,
    fontSize: fontSizes[3],
  },
  h6: {
    ...heading,
    fontSize: fontSizes[2],
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.darkGray,
  },
  li: {
    bulletBorderRadius: '0px',
    bulletColor: colors.primary,
  },
  p: {
    fontSize: fontSizes[2],
    lineHeight: lineHeights.paragraph,
    margin: '0',
    marginBottom: '1.7rem',
  },
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  table: {
    borderCollapse: 'collapse',

    color: colors.gray[3],

    marginBottom: '3rem',
    // todo
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '0px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: fontSizes[0],
  fontWeight: fontWeights.bold,
  height: '48px',
  minWidth: '194px',
  textTransform: 'uppercase',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
  primary: {
    ...buttonDefaults,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.darkGray,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
  },
  'primary-with-background': {
    ...buttonDefaults,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.white,
    },
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
  },
  secondary: {
    ...buttonDefaults,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.darkGray,
    },
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
  },
};

export const header = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.darkGray,
};

export const headerBottom = {
  activeColor: colors.secondary,
  activeFontWeight: '600',
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: '12px',
  fontWeight: '600',
};

export const mobileNavigation = {
  arrowColor: colors.primary,
  chevronColor: colors.primary,
};

export const footer = {
  activeColor: colors.primary,
  backgroundColor: colors.white,
  color: colors.darkGray,
  minHeight: '160px',
};

export const cardCtas = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.darkestRed,
  color: colors.primary,
  fontSize: '0.75rem',
  hoverColor: colors.darkestRed,
  letterSpacing: '2px',
  lineHeight: '13px',
  textTransform: 'uppercase',
};

export const documentDownload = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.darkestRed,
  color: colors.primary,
  hoverColor: colors.darkestRed,
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '0px',
  radioInputBorderRadius: '0px',
  radioSpanBorderRadius: '0px',
};

export const categoryCards = {
  backgroundColor: colors.primary,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '0px',
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  focusText: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
};

export const shortBanner = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'uppercase',
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.secondary,
};

export const salesPerson = {
  borderColor: colors.primary,
};

export const dealer = {
  textColor: colors.primary,
};

export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '1px',
};

export const imageAndText = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const media = {
  titleFontWeight: 'bold',
  titleTextTransform: 'uppercase',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.primary,
};

export const customBlockCards = {
  backgroundColor: colors.primary,
};

export const adviceCardModule = {
  backgroundColor: colors.primary,
};

export const contacts = {
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '0.875rem',
    mapTextFontWeight: 'normal',
    mapTextLetterSpacing: '2.33px',
    mapTextLineHeight: '1.71',
    mapTextTransform: 'uppercase',
    openingHoursFontSize: '1rem',
  },
};

export const heroSection = {
  titleFontWeight: 'normal',
};

export const newsListing = {
  titleFontWeight: 'normal',
};

export const productListing = {
  titleFontWeight: 'normal',
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export default {
  breakpoints,
  colors,
  buttons,
  cookieBanner,
  cardCtas,
  fontSizes,
  categoryCards,
  fontWeights,
  ctaBanner,
  fonts,
  documentDownload,
  footer,
  formInputs,
  header,
  dealer,
  headerBottom,
  imageAndText,
  lineHeights,
  infoSection,
  logo: Logo,
  customBlockCards,
  name: 'Arieblok',
  adviceCardModule,
  radii,
  contacts,
  text,
  heroSection,
  ingredients,
  space,
  media,
  mobileNavigation,
  shadows,
  navItems,
  sizes,
  newsListing,
  productListing,
  typeStyles,
  salesPerson,
  search,
  shortBanner,
  styles,
  table,
  upComingEvents,
};
