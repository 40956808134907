import * as React from 'react';

const HeroBannerMaskDesktop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 260.44"
    preserveAspectRatio="xMinYMax"
    height="327"
    width="100%"
    fill="#fff"
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="svg1">
        <g id="layer1">
          <path
            id="Intersection_8"
            data-name="Intersection 8"
            d="M1440,0c-150.4,159.35-378.25,260.47-736.35,260.44H1440ZM0,164.85v95.59H703.63C510,260.42,278.06,230.8,0,164.85Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

const HeroBannerMaskMobile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 425 12.39"
    height="327"
    width="100%"
    fill="#ffffff"
    preserveAspectRatio="xMinYMax"
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="svg1">
        <path
          id="Intersection_8"
          data-name="Intersection 8"
          d="M425,0C353.93,8.12,277.23,12.39,194.33,12.39H425ZM194.33,12.39h0Zm-.34,0q-91.83,0-194-7.1v7.1Z"
        />
      </g>
    </g>
  </svg>
);

const HeroBannerMaskTablet = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 768 54.69"
    height="327"
    width="100%"
    fill="#ffffff"
    preserveAspectRatio="xMinYMax"
  >
    <g id="Laag_2" data-name="Laag 2">
      <g id="svg1">
        <g id="layer1">
          <path
            id="Intersection_8"
            data-name="Intersection 8"
            d="M768,0C656.61,35.24,524.63,54.7,367.65,54.69H768ZM0,24.91V54.69H367.63C257.83,54.68,135.79,45.18,0,24.91Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default {
  Desktop: HeroBannerMaskDesktop,
  Mobile: HeroBannerMaskMobile,
  Tablet: HeroBannerMaskTablet,
};
