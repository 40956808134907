import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <defs>
      <polygon id="path-1" points="0.0004 0.0382 5.86412603 0.0382 5.86412603 10 0.0004 10" />
    </defs>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Arie-Blok" transform="translate(2.000000, 20.000000)">
        <g id="Group-3" transform="translate(0.000000, 22.513200)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-2" />
          <path
            d="M5.6044,10.0002 C5.6044,10.0002 7.6974,5.8452 0.0004,0.0382 C0.0004,0.0382 0.3554,7.5992 5.6044,10.0002"
            id="Fill-1"
            fill="#FFD028"
            mask="url(#mask-2)"
          />
        </g>
        <path
          d="M7.1464,27.2507 C7.1464,27.2507 9.3554,23.1467 1.8084,17.1227 C1.8084,17.1227 1.9564,24.7027 7.1464,27.2507"
          id="Fill-4"
          fill="#FFD028"
        />
        <path
          d="M8.6913,21.7595 C8.6913,21.7595 11.1363,17.8275 4.0243,11.3865 C4.0243,11.3865 3.7023,18.9115 8.6913,21.7595"
          id="Fill-6"
          fill="#FFD028"
        />
        <path
          d="M10.6264,16.988 C10.6264,16.988 13.3134,13.627 7.4554,6.905 C7.4554,6.905 6.3344,13.814 10.6264,16.988"
          id="Fill-8"
          fill="#FFD028"
        />
        <path
          d="M12.5244,11.9236 C12.5244,11.9236 14.8564,9.4606 10.6984,3.7926 C10.6984,3.7926 9.3614,9.1466 12.5244,11.9236"
          id="Fill-10"
          fill="#FFD028"
        />
        <path
          d="M14.7109,7.9592 C14.7109,7.9592 16.7399,5.9152 13.3229,1.0212 C13.3229,1.0212 12.0819,5.5392 14.7109,7.9592"
          id="Fill-12"
          fill="#FFD028"
        />
        <path
          d="M16.4223,6.5227 C16.4223,6.5227 19.2473,5.9677 19.1633,-0.0003 C19.1633,-0.0003 15.6033,3.0447 16.4223,6.5227"
          id="Fill-14"
          fill="#FFD028"
        />
        <path
          d="M6.6992,30.8132 C12.4382,31.8432 17.0872,25.7742 17.0872,25.7742 C7.3552,26.1592 6.6992,30.8132 6.6992,30.8132"
          id="Fill-17"
          fill="#FFE586"
        />
        <path
          d="M8.1328,25.613 C8.1328,25.613 8.7898,20.954 18.5328,20.568 C18.5328,20.568 13.8788,26.645 8.1328,25.613"
          id="Fill-19"
          fill="#FFE586"
        />
        <path
          d="M10.038,20.157 C10.038,20.157 10.696,15.493 20.448,15.107 C20.448,15.107 15.788,21.189 10.038,20.157"
          id="Fill-21"
          fill="#FFE586"
        />
        <path
          d="M12.3832,14.7703 C12.3832,14.7703 12.9272,10.9113 20.9972,10.5923 C20.9972,10.5923 17.1412,15.6243 12.3832,14.7703"
          id="Fill-23"
          fill="#FFE586"
        />
        <path
          d="M14.4667,9.9231 C14.4667,9.9231 15.1957,6.7081 22.0197,7.0031 C22.0197,7.0031 18.4187,10.9761 14.4667,9.9231"
          id="Fill-25"
          fill="#FFE586"
        />
        <path
          d="M20.8998,28.6531 L21.5278,28.6531 C22.1788,28.6531 22.6748,28.5281 23.0748,28.2211 C23.5838,27.8291 23.9848,27.0841 24.0388,26.3691 C24.1118,25.3881 23.6188,24.8461 22.6068,24.8461 L21.9858,24.8461 L20.8998,28.6531 Z M22.6438,24.0381 C24.4248,24.0381 25.2678,24.8461 25.1498,26.4091 C25.0678,27.5071 24.4758,28.4891 23.6388,28.9751 C22.9248,29.3911 22.2428,29.4851 21.4258,29.4851 L19.5498,29.4851 L21.1208,24.0381 L22.6438,24.0381 Z"
          id="Fill-27"
          fill="#FFD028"
        />
        <polygon id="Fill-29" fill="#FFD028" points="28.6645 24.0378 27.1015 29.4848 26.0265 29.4848 27.5895 24.0378" />
        <polygon
          id="Fill-31"
          fill="#FFD028"
          points="34.0419 24.0378 33.8099 24.8228 31.5179 24.8228 31.1049 26.2438 33.2719 26.2438 33.0569 27.0198 30.8819 27.0198 30.4029 28.6928 32.7969 28.6928 32.5649 29.4848 29.0959 29.4848 30.6669 24.0378"
        />
        <path
          d="M36.2128,26.3845 C36.3458,26.3925 36.4708,26.4015 36.5888,26.4015 C37.5308,26.4015 38.0418,26.0715 38.0868,25.4745 C38.1178,25.0585 37.8298,24.8155 37.2648,24.8155 L36.6528,24.8155 L36.2128,26.3845 Z M37.5508,24.0375 C38.7128,24.0375 39.2668,24.4925 39.2028,25.3485 C39.1428,26.1495 38.5618,26.6755 37.5888,26.7695 C37.9378,26.9425 38.0448,27.1935 38.1078,27.6015 L38.3818,29.4855 L37.3228,29.4855 L37.0508,27.6725 C36.9878,27.2565 36.7578,27.1775 36.1848,27.1775 L35.9888,27.1775 L35.3288,29.4855 L34.2608,29.4855 L35.8238,24.0375 L37.5508,24.0375 Z"
          id="Fill-33"
          fill="#FFD028"
        />
        <polygon
          id="Fill-35"
          fill="#FFD028"
          points="41.9081 24.0378 42.0821 28.6218 44.8511 24.0378 45.9821 24.0378 42.4801 29.4848 41.2091 29.4848 40.8401 24.0378"
        />
        <path
          d="M49.4477,24.7205 C49.0007,24.7205 48.5347,24.9565 48.1737,25.3885 C47.7597,25.8825 47.4707,26.5885 47.4167,27.3035 C47.3477,28.2215 47.7717,28.7555 48.5097,28.7555 C49.0197,28.7555 49.4467,28.5045 49.8617,27.9935 C50.2247,27.5465 50.4957,26.8645 50.5477,26.1725 C50.6167,25.2465 50.1937,24.7205 49.4477,24.7205 M51.6677,26.2045 C51.5917,27.2255 51.1697,28.1355 50.4857,28.7795 C49.9197,29.3135 49.1837,29.5955 48.3517,29.5955 C46.9857,29.5955 46.1917,28.6775 46.3007,27.2255 C46.3687,26.3145 46.7807,25.4275 47.4497,24.7765 C48.0177,24.2185 48.7547,23.9205 49.5547,23.9205 C50.9997,23.9205 51.7757,24.7685 51.6677,26.2045"
          id="Fill-37"
          fill="#FFD028"
        />
        <polygon
          id="Fill-39"
          fill="#FFD028"
          points="57.4667 24.0378 57.2357 24.8228 54.9437 24.8228 54.5297 26.2438 56.6967 26.2438 56.4817 27.0198 54.3067 27.0198 53.8287 28.6928 56.2227 28.6928 55.9897 29.4848 52.5207 29.4848 54.0917 24.0378"
        />
        <path
          d="M59.0366,28.6531 L59.6646,28.6531 C60.3156,28.6531 60.8116,28.5281 61.2116,28.2211 C61.7206,27.8291 62.1216,27.0841 62.1756,26.3691 C62.2486,25.3881 61.7556,24.8461 60.7426,24.8461 L60.1226,24.8461 L59.0366,28.6531 Z M60.7796,24.0381 C62.5616,24.0381 63.4036,24.8461 63.2866,26.4091 C63.2046,27.5071 62.6126,28.4891 61.7746,28.9751 C61.0606,29.3911 60.3786,29.4851 59.5626,29.4851 L57.6856,29.4851 L59.2576,24.0381 L60.7796,24.0381 Z"
          id="Fill-41"
          fill="#FFD028"
        />
        <polygon id="Fill-43" fill="#FFD028" points="66.8012 24.0378 65.2382 29.4848 64.1622 29.4848 65.7262 24.0378" />
        <polygon
          id="Fill-45"
          fill="#FFD028"
          points="70.1371 24.0378 71.2391 28.3008 72.4291 24.0378 73.4181 24.0378 71.8561 29.4848 70.5211 29.4848 69.4391 25.1918 68.2371 29.4848 67.2401 29.4848 68.8031 24.0378"
        />
        <path
          d="M79.3774,25.074 C78.9554,24.846 78.5154,24.744 78.0054,24.744 C76.5294,24.744 75.4994,25.702 75.3994,27.028 C75.3214,28.073 75.9144,28.756 76.8714,28.756 C77.1474,28.756 77.4074,28.724 77.6564,28.653 L78.0724,27.185 L77.0054,27.185 L77.2364,26.409 L79.3324,26.409 L78.4924,29.329 C77.9854,29.502 77.4534,29.58 76.8884,29.58 C76.1664,29.58 75.6144,29.51 75.1234,29.14 C74.5224,28.685 74.2164,27.955 74.2844,27.045 C74.3544,26.118 74.8024,25.27 75.5454,24.674 C76.1544,24.187 76.9994,23.921 78.0354,23.921 C78.6084,23.921 79.1504,24.014 79.7014,24.203 L79.3774,25.074 Z"
          id="Fill-47"
          fill="#FFD028"
        />
        <path
          d="M27.0053,13.1052 L31.2893,13.0972 L29.1693,5.0082 L27.0053,13.1052 Z M30.7603,1.9962 L35.8323,19.9872 L33.0693,19.9932 L31.9713,15.7412 L26.2963,15.7522 L25.1773,20.0082 L22.6213,20.0132 L27.6603,2.0022 L30.7603,1.9962 Z"
          id="Fill-49"
          fill="#C31200"
        />
        <path
          d="M27.0053,13.1052 L31.2893,13.0972 L29.1693,5.0082 L27.0053,13.1052 Z M30.7603,1.9962 L35.8323,19.9872 L33.0693,19.9932 L31.9713,15.7412 L26.2963,15.7522 L25.1773,20.0082 L22.6213,20.0132 L27.6603,2.0022 L30.7603,1.9962 Z"
          id="Stroke-51"
          stroke="#C31200"
          strokeWidth="0.2"
        />
        <path
          d="M39.1005,9.9172 L40.4155,9.9142 C42.1075,9.9112 43.0255,8.8972 43.0225073,7.1862 C43.0185,5.2922 42.1155,4.4902 40.2175,4.49418512 L39.0895,4.4962 L39.1005,9.9172 Z M40.8135,1.97717606 C43.9895,1.9702 45.5895,3.4982 45.5955,6.6102 C45.6005,8.8932 44.6445,10.4772 42.8795,10.9992 C43.8765,11.4382 44.1605,12.3452 44.5575,13.7192 L46.3735,19.9662 L43.6675,19.9722 L42.1905,14.5282 C41.7555,12.8952 41.1535,12.4292 39.9135,12.4312 L39.1055,12.4332 L39.1205,19.9812 L36.5835,19.9862 L36.5485,1.9852 L40.8135,1.97717606 Z"
          id="Fill-53"
          fill="#C31200"
        />
        <path
          d="M39.1005,9.9172 L40.4155,9.9142 C42.1075,9.9112 43.0255,8.8972 43.0225073,7.1862 C43.0185,5.2922 42.1155,4.4902 40.2175,4.49418512 L39.0895,4.4962 L39.1005,9.9172 Z M40.8135,1.97717606 C43.9895,1.9702 45.5895,3.4982 45.5955,6.6102 C45.6005,8.8932 44.6445,10.4772 42.8795,10.9992 C43.8765,11.4382 44.1605,12.3452 44.5575,13.7192 L46.3735,19.9662 L43.6675,19.9722 L42.1905,14.5282 C41.7555,12.8952 41.1535,12.4292 39.9135,12.4312 L39.1055,12.4332 L39.1205,19.9812 L36.5835,19.9862 L36.5485,1.9852 L40.8135,1.97717606 Z"
          id="Stroke-55"
          stroke="#C31200"
          strokeWidth="0.2"
        />
        <polygon id="Fill-57" fill="#C31200" points="50.5844 1.9578 50.6194 19.9588 48.0454 19.9638 48.0104 1.9628" />
        <polygon
          id="Stroke-59"
          stroke="#C31200"
          strokeWidth="0.2"
          points="50.5844 1.9578 50.6194 19.9588 48.0454 19.9638 48.0104 1.9628"
        />
        <polygon
          id="Fill-61"
          fill="#C31200"
          points="61.4643 1.9363 61.4693 4.5303 56.0763 4.5403 56.0863 9.3133 61.1963 9.3033 61.2023 11.9493 56.0913 11.9593 56.1013 17.2243 61.6823 17.2133 61.6873 19.9373 53.5323 19.9533 53.4973 1.9523"
        />
        <polygon
          id="Stroke-63"
          stroke="#C31200"
          strokeWidth="0.2"
          points="61.4643 1.9363 61.4693 4.5303 56.0763 4.5403 56.0863 9.3133 61.1963 9.3033 61.2023 11.9493 56.0913 11.9593 56.1013 17.2243 61.6823 17.2133 61.6873 19.9373 53.5323 19.9533 53.4973 1.9523"
        />
        <path
          d="M70.4389,17.3005 L71.8099,17.2975 C73.6139,17.2935 74.5519,16.3325 74.5479127,14.5685 C74.5449,12.8575 73.6789,11.9255 71.9879,11.9274968 L70.4279,11.9315 L70.4389,17.3005 Z M70.4229,9.4155 L71.6439,9.4125 C73.3539,9.4095 74.2169,8.5255 74.2139078,6.9435 C74.2099,5.3095 73.3819,4.4545 71.7849,4.45848603 L70.4139,4.4605 L70.4229,9.4155 Z M72.1939,1.9154835 C75.2189,1.9095 76.7819,3.5405 76.7879,6.5755 C76.7919,8.4945 75.9299,9.9745 74.3719,10.5485 C76.1759,11.0645 77.1569,12.6185 77.1609214,14.9015 C77.1679,18.1435 75.4599,19.9095 72.1349,19.9165 L67.9069,19.9245 L67.8719,1.9245 L72.1939,1.9154835 Z"
          id="Fill-65"
          fill="#C31200"
        />
        <path
          d="M70.4389,17.3005 L71.8099,17.2975 C73.6139,17.2935 74.5519,16.3325 74.5479127,14.5685 C74.5449,12.8575 73.6789,11.9255 71.9879,11.9274968 L70.4279,11.9315 L70.4389,17.3005 Z M70.4229,9.4155 L71.6439,9.4125 C73.3539,9.4095 74.2169,8.5255 74.2139078,6.9435 C74.2099,5.3095 73.3819,4.4545 71.7849,4.45848603 L70.4139,4.4605 L70.4229,9.4155 Z M72.1939,1.9154835 C75.2189,1.9095 76.7819,3.5405 76.7879,6.5755 C76.7919,8.4945 75.9299,9.9745 74.3719,10.5485 C76.1759,11.0645 77.1569,12.6185 77.1609214,14.9015 C77.1679,18.1435 75.4599,19.9095 72.1349,19.9165 L67.9069,19.9245 L67.8719,1.9245 L72.1939,1.9154835 Z"
          id="Stroke-67"
          stroke="#C31200"
          strokeWidth="0.2"
        />
        <polygon
          id="Fill-69"
          fill="#C31200"
          points="82.0024 1.8962 82.0314 17.1472 87.2554 17.1382 87.2614 19.8872 79.4634 19.9022 79.4284 1.9012"
        />
        <polygon
          id="Stroke-71"
          stroke="#C31200"
          strokeWidth="0.2"
          points="82.0024 1.8962 82.0314 17.1472 87.2554 17.1382 87.2614 19.8872 79.4634 19.9022 79.4284 1.9012"
        />
        <path
          d="M89.6244673,10.8303 C89.6335,14.9803 91.1035,17.5193 93.1885,17.5153047 C95.2745,17.5113 96.7545,14.9403 96.7465323,10.8163 C96.7385,6.6923 95.2495,4.1533 93.1635,4.15729528 C91.0775,4.1613 89.6165,6.7063 89.6244673,10.8303 M99.3585383,10.8633 C99.3695,16.7513 97.0095,20.2313 93.1945,20.2393106 C89.3805,20.2463 87.0245,16.7753 87.0134541,10.8873 C87.0015,4.9993 89.3435,1.4933 93.1575,1.48528959 C96.9725,1.4783 99.3475,5.0013 99.3585383,10.8633"
          id="Fill-73"
          fill="#C31200"
        />
        <path
          d="M89.6244673,10.8303 C89.6335,14.9803 91.1035,17.5193 93.1885,17.5153047 C95.2745,17.5113 96.7545,14.9403 96.7465323,10.8163 C96.7385,6.6923 95.2495,4.1533 93.1635,4.15729528 C91.0775,4.1613 89.6165,6.7063 89.6244673,10.8303 Z M99.3585383,10.8633 C99.3695,16.7513 97.0095,20.2313 93.1945,20.2393106 C89.3805,20.2463 87.0245,16.7753 87.0134541,10.8873 C87.0015,4.9993 89.3435,1.4933 93.1575,1.48528959 C96.9725,1.4783 99.3475,5.0013 99.3585383,10.8633 Z"
          id="Stroke-75"
          stroke="#C31200"
          strokeWidth="0.2"
        />
        <polygon
          id="Fill-77"
          fill="#C31200"
          points="103.6577 1.8542 103.6737 9.7132 108.4497 1.8452 111.3997 1.8392 106.0417 10.2022 111.9417 19.8392 108.6907 19.8452 103.6767 11.2442 103.6927 19.8552 101.1187 19.8602 101.0837 1.8592"
        />
        <polygon
          id="Stroke-79"
          stroke="#C31200"
          strokeWidth="0.2"
          points="103.6577 1.8542 103.6737 9.7132 108.4497 1.8452 111.3997 1.8392 106.0417 10.2022 111.9417 19.8392 108.6907 19.8452 103.6767 11.2442 103.6927 19.8552 101.1187 19.8602 101.0837 1.8592"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
