import { type IGatsbyImageData } from 'gatsby-plugin-image';
import { Flex } from 'rebass';

import { HeadingTag, withErrorBoundary } from '../../components';

import BottomCard from './BottomCard/BottomCard';
import { BottomCards, CardsWrapper, ContentWrapper, StyledDescription, StyledTitle, TopCards } from './Styled';
import TopCard from './TopCard/TopCard';

type Link = {
  cardLink: {
    externalUrl?: string;
    internalEntry: {
      slug: string;
      title: string;
    };
  };
  title: string;
  icon?: {
    title: string;
    file: {
      url: string;
    };
  };
  id: string;
  image: {
    altText?: string;
    title: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

interface CategorySectionModuleInterface {
  title: string;
  titleTag: HeadingTag;
  description: string;
  primaryLinks: Link[];
  secondaryLinks: Link[];
  className?: string;
}

export type CardProps = {
  item: Link;
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const CategorySectionModule = ({
  title,
  titleTag,
  primaryLinks,
  description,
  secondaryLinks,
  className,
}: CategorySectionModuleInterface) => {
  if (!primaryLinks?.length && !secondaryLinks?.length) return null;

  return (
    <ContentWrapper className={className} flexDirection="column" alignContent="center">
      <Flex flexDirection="column">
        {title && (
          <StyledTitle variant="h2" tag={titleTag}>
            {title}
          </StyledTitle>
        )}
        {description && <StyledDescription>{description}</StyledDescription>}
      </Flex>
      <CardsWrapper flexDirection="column">
        {!!primaryLinks?.length && (
          <TopCards>
            {primaryLinks.map((item, index) => (
              <TopCard item={item} key={item.id} size={index < 3 ? 'large' : 'small'} />
            ))}
          </TopCards>
        )}
        {!!secondaryLinks?.length && (
          <BottomCards>
            {secondaryLinks.map(item => (
              <BottomCard item={item} key={item.id} />
            ))}
          </BottomCards>
        )}
      </CardsWrapper>
    </ContentWrapper>
  );
};

export default withErrorBoundary(CategorySectionModule, { componentName: 'CategorySectionModule' });
