import { IGatsbyImageData, getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { CardProps as RebassCardProps } from 'rebass';

import { withErrorBoundary } from '../../ErrorBoundary';

import {
  StyledCard,
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBox,
  StyledImageBoxLink,
  StyledTitle,
} from './Styled';

export interface CustomBlockCardProps extends RebassCardProps {
  /** category id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: IGatsbyImageData;
  /** card cta */
  link?: string;
  linkLabel?: string;
  /** display link text */
  showLink?: boolean;
  displayCtaCopy?: boolean;
}

const CustomBlockCard = ({
  title,
  shortDescription,
  image,
  link,
  linkLabel,
  showLink = true,
  displayCtaCopy = false,
  ...rest
}: CustomBlockCardProps) => {
  const intl = useIntl();

  return (
    <StyledCard {...rest}>
      <StyledImageBoxLink
        onClick={e => {
          if (!link) e.preventDefault();
        }}
        to={link || '#'}
      >
        <StyledImageBox
          sx={{
            backgroundImage: `url(${getSrc(image as IGatsbyImageData)})`,
          }}
        />
      </StyledImageBoxLink>

      <StyledContentBox>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{shortDescription}</StyledDescription>

        {link && showLink && (
          <StyledCtaWrapper>
            <StyledCta to={link}>
              <span>{displayCtaCopy ? linkLabel : intl.formatMessage({ id: 'cards.category.link' })}</span>
            </StyledCta>
          </StyledCtaWrapper>
        )}
      </StyledContentBox>
    </StyledCard>
  );
};

export default withErrorBoundary(CustomBlockCard, { componentName: 'CustomBlockCard' });
