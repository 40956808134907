import { Close, SearchIcon } from '@arvesta-websites/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { FieldType } from '../../../../types';
import { useClickOutside } from '../../../../utils';
import { InputField, MultiSelectFilter, Option, withErrorBoundary } from '../../../components';
import { useGoogleAutocomplete } from '../hooks/usePlacesApi';
import { MapBoundsValue } from '../InteractiveMap/InteractiveMap';

export type SearchValue = string | google.maps.places.AutocompletePrediction;

const styles = tv({
  slots: {
    tagList: 'list-none flex gap-1 mt-3 justify-start items-start flex-wrap',
    tag: 'inline-flex justify-between items-center py-2 px-3 text-xs bg-dealerOverview-tag gap-2',
    tagButton: 'inline py-[0.5875rem] px-3 text-xs border-none bg-dealerOverview-tag',
    removeButton: 'border-none align-middle p-1 hover:font-black text-xl leading-3',
    removeButtonIcon: 'not-sr-only w-3.5 h-3.5',
    dropdown: 'mt-3',
    suggestionListWrapper: 'block relative overflow-visible',
    suggestionList: 'list-none absolute top-12 w-full bg-white shadow py-2 z-20 max-h-40 overflow-auto',
    suggestionListItem:
      'border-b border-tertiary border-solid last:border-none p-2 hover:bg-dealerOverview-suggestion focus:bg-dealerOverview-suggestion',
  },
});

type FilterList = string[] | null;
interface Props {
  services?: string[];
  className?: string;
  onFilterChange: (filters: FilterList) => void;
  onSearch: (query: SearchValue) => void;
  mapBounds: MapBoundsValue;
  countryCodes: string[];
  shouldReset: any | null;
}
const DealerSearch = ({
  services,
  onFilterChange,
  onSearch,
  className,
  mapBounds,
  countryCodes,
  shouldReset,
}: Props) => {
  const { serviceLoaded, fetchSuggestions } = useGoogleAutocomplete();
  const intl = useIntl();
  const suggestionsListRef = useRef<HTMLUListElement>(null);
  useClickOutside(suggestionsListRef, () => setSuggestionsOpen(false));
  const [searchField, setSearchField] = useState<FieldType>({ name: 'location', value: '', error: '' });
  const [activeFilters, setActiveFilters] = useState<Option[] | null>(null);
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [activeQuery, setActiveQuery] = useState<google.maps.places.AutocompletePrediction | string | undefined>(
    undefined,
  );

  const servicesForSelect = useMemo(
    () => (services ? services.map(value => ({ label: value, value })) : []),
    [services],
  );

  useEffect(() => {
    if (shouldReset === null) return;
    setActiveFilters(null);
    setSuggestionsOpen(false);
    setSearchField(field => ({ ...field, value: '', skipFetch: false }));
  }, [shouldReset]);

  useEffect(() => {
    if (searchField.value.length <= 2 || !serviceLoaded) return;
    const loadSuggestions = async () => {
      const response = await fetchSuggestions(searchField.value, mapBounds, countryCodes, intl.locale);
      if (response?.length) {
        setSuggestions(response);
        setSuggestionsOpen(true);
      } else {
        setSuggestions([]);
        setSuggestionsOpen(false);
      }
    };
    // don't fetch if the field's been set from the suggestions
    if (!(searchField as any).skipFetch) loadSuggestions();
  }, [searchField.value, serviceLoaded, mapBounds, intl.locale]);

  useEffect(() => {
    if (activeQuery !== undefined) onSearch(activeQuery);
  }, [activeQuery]);

  const handleSearchInput = useCallback((ev: any) => {
    const { value } = ev.target;
    setSearchField(field => ({ ...field, value, skipFetch: false }));
    if (value === '') {
      handleSelectSuggestion('');
      setSuggestionsOpen(false);
    }
  }, []);

  const handleSearchKeydown = (ev: React.KeyboardEvent<any>) => {
    if (ev.key !== 'Enter' && ev.key !== 'ArrowDown') return;

    if (ev.key === 'ArrowDown' && suggestionsListRef.current) {
      (suggestionsListRef.current?.firstChild as HTMLLIElement)?.focus();
      return ev.preventDefault();
    }
    if (suggestions?.length) handleSelectSuggestion(suggestions[0]);
    else {
      const { value } = ev.target as HTMLInputElement;
      handleSelectSuggestion(value);
    }
  };

  const handleListKeydown = (ev: React.KeyboardEvent<any>, suggestion: google.maps.places.AutocompletePrediction) => {
    if (ev.key === 'Enter') return handleSelectSuggestion(suggestion);
    if (ev.key === 'ArrowUp') {
      ev.preventDefault();
      const next = (ev.target as HTMLElement).previousElementSibling as HTMLElement | null;
      return next ? next.focus() : (ev.target as any)?.parentElement?.lastElementChild?.focus();
    }
    if (ev.key === 'ArrowDown') {
      ev.preventDefault();
      const next = (ev.target as HTMLElement).nextElementSibling as HTMLElement | null;
      return next ? next.focus() : (ev.target as any)?.parentElement?.firstElementChild?.focus();
    }
  };

  const handleSelectSuggestion = (suggestion: google.maps.places.AutocompletePrediction | string) => {
    if (typeof suggestion !== 'string')
      setSearchField(field => ({ ...field, value: suggestion.description, skipFetch: true }));
    setActiveQuery(suggestion);
    setSuggestionsOpen(false);
  };

  const handleDropdownChange = (options: Option[]) => {
    let values: FilterList = options.map(opt => opt.value);
    if (values.length === 1 && values[0] === '') values = null;
    setActiveFilters(options);
    onFilterChange(values);
  };

  const handleRemoveFilter = (toRemove: Option) => {
    if (!activeFilters) return;
    let update: Option[] | null = activeFilters?.filter(active => active.value !== toRemove.value);
    if (!update?.length) update = null;
    setActiveFilters(update);
    onFilterChange(update && update.map(itm => itm.value));
  };

  const handleClearFilters = () => {
    setActiveFilters(null);
    onFilterChange(null);
  };

  const {
    tagList,
    tagButton,
    tag,
    removeButton,
    dropdown,
    suggestionListWrapper,
    suggestionList,
    suggestionListItem,
    removeButtonIcon,
  } = styles();

  return (
    <div className={className}>
      <div className={suggestionListWrapper()}>
        <InputField
          label={intl.formatMessage({ id: 'sections.dealer.placeholder' })}
          handleChange={handleSearchInput}
          handleKeydown={handleSearchKeydown}
          placeholder={intl.formatMessage({ id: 'sections.dealer.placeholder' })}
          field={searchField}
          icon={<SearchIcon />}
          hideLabel
        />
        {!suggestions.length || !suggestionsOpen ? null : (
          <ul className={suggestionList()} ref={suggestionsListRef}>
            {suggestions.map(item => (
              <li
                key={item.place_id}
                role="button"
                className={suggestionListItem()}
                onClick={() => handleSelectSuggestion(item)}
                tabIndex={0}
                onKeyDown={ev => handleListKeydown(ev as any, item)}
              >
                {item.description}
              </li>
            ))}
          </ul>
        )}
      </div>
      {services?.length ? (
        <MultiSelectFilter
          fullWidth={true}
          value={activeFilters}
          options={servicesForSelect}
          onSelect={handleDropdownChange}
          label={intl.formatMessage({ id: 'sections.dealerOverview.services' })}
          className={dropdown()}
          maxSelectionSize={10}
        />
      ) : null}
      {activeFilters?.length ? (
        <ul className={tagList()}>
          <li>
            <button className={tagButton()} onClick={handleClearFilters}>
              {intl.formatMessage({ id: 'sections.dealerOverview.clear_filters' })}
            </button>
          </li>
          {activeFilters.map(filter => (
            <li className={tag()} key={filter.value}>
              <span>{filter.value}</span>{' '}
              <button className={removeButton()} onClick={() => handleRemoveFilter(filter)}>
                <Close className={removeButtonIcon()} />
                <span className="sr-only">{intl.formatMessage({ id: 'sections.dealerOverview.remove_filter' })}</span>
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default withErrorBoundary(DealerSearch, { componentName: 'DealerOverview/Search' });
