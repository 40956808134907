import { CloseCustom, Filter } from '@arvesta-websites/icons';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';

import { HeadingTag, withErrorBoundary } from '../../components';

import ArticlesList from './ArticleList';
import Filters from './Filters';
import {
  StyledArticleWrapper,
  StyledBottomNavigationMobile,
  StyledFilterButton,
  StyledFilterButtonMobile,
  StyledFilterTitleMobile,
  StyledFilterWrapper,
  StyledIconButton,
  StyledInnerButton,
  StyledMobileFilter,
  StyledMobileFilterWrapper,
  StyledMobileFooter,
  StyledMobileHeader,
  StyledTitle,
  StyledTitleWrapper,
  StyledWrapper,
} from './Styled';
type Image = {
  title: string;
  alt?: string;
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
};
export type Article = {
  link: string;
  title: string;
  description: string;
  image: Image;
  id: string;
  contentfulId: string;
};

export type Category = {
  __typename: 'Category';
  articleCount: number;
  contentfulId: string;
  id: string;
  label: string;
  slug: string;
  articles: Article[];
  children?: Category[];
};

export interface ArticleListingProps {
  title?: string | null;
  titleTag?: HeadingTag;
  articles?: Article[];
  categories: Category[];
  contentfulId: string;
  id: string;
  shortDescription?: string;
  __typename: 'ArticleListingSection';
}

const setCategoryParams = (slug: string, params: URLSearchParams) => {
  params.set('category', slug);
  const url = `${window.location.pathname}?${params.toString()}`;
  window.history.pushState({ path: url }, '', url);
};

const MAX_ITEMS = 18;
const ArticleListing = ({ categories, articles, title, titleTag }: ArticleListingProps) => {
  const [activeCategoriesId, setActiveCategoriesId] = useState<{ child: string | null; parent: string | null }>({
    child: null,
    parent: null,
  });
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const [numberOfArticlesToShow, setNumberOfArticlesToShow] = useState(MAX_ITEMS);
  const [filteredArticles, setFilteredArticles] = useState(articles || []);

  const intl = useIntl();

  const setArticles = useCallback(
    (item: Category) => {
      const params = new URLSearchParams(document.location.search);

      if ('children' in item && item.children) {
        const allChildrensArticles = item.children
          .flatMap(child => {
            return child.articles;
          })
          .filter(Boolean);

        const allArticles = [...item.articles, ...allChildrensArticles];
        const uniqueArticles = uniqWith(allArticles, isEqual);

        setFilteredArticles(uniqueArticles);
        setActiveCategoriesId({ child: null, parent: item.id });

        setCategoryParams(item.slug, params);
        return;
      }

      const parentCategory = categories.find(category => {
        if (category.children) {
          return category.children.find(child => child.id === item.id);
        }

        return false;
      });

      setFilteredArticles(item.articles);
      if (parentCategory) {
        setActiveCategoriesId({ child: item.id, parent: parentCategory.id });
      } else {
        setActiveCategoriesId(prevState => ({ ...prevState, child: item.id }));
      }

      setCategoryParams(item.slug, params);
    },
    [categories],
  );

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const category = params.get('category');

    if (category) {
      const allCategories = categories
        .map(item => {
          if (item?.children) {
            return [...item.children, item];
          }

          return item;
        })
        .flat();

      const categoryObj = allCategories.find(item => {
        if (item.slug === category) {
          return item;
        }

        return false;
      });

      if (!categoryObj) {
        return;
      }

      setArticles(categoryObj);
    }
  }, [categories, setArticles]);

  const loadMoreItems = useCallback(() => {
    setNumberOfArticlesToShow(prev => prev + MAX_ITEMS);
  }, []);

  const handleMenuClick = useCallback(
    (item: Category) => {
      setNumberOfArticlesToShow(MAX_ITEMS);
      setArticles(item);
    },
    [setArticles],
  );

  return (
    <Box paddingTop={40}>
      <StyledWrapper>
        <StyledFilterWrapper>
          <Filters categories={categories} activeCategoriesId={activeCategoriesId} handleMenuClick={handleMenuClick} />
        </StyledFilterWrapper>

        <StyledArticleWrapper>
          <StyledTitleWrapper>
            <StyledTitle variant="h2" tag={titleTag}>
              {title}
            </StyledTitle>
            <StyledFilterButton onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
              <StyledInnerButton>
                <Filter />
                {intl.formatMessage({ id: 'articleListing.filters' })}
              </StyledInnerButton>
            </StyledFilterButton>
          </StyledTitleWrapper>
          <ArticlesList
            articles={filteredArticles}
            numberOfArticlesToShow={numberOfArticlesToShow}
            handleLoadMore={loadMoreItems}
          />
        </StyledArticleWrapper>
      </StyledWrapper>
      {mobileMenuVisible && (
        <StyledMobileFilterWrapper>
          <StyledMobileFilter>
            <StyledMobileHeader>
              <StyledFilterTitleMobile>{intl.formatMessage({ id: 'articleListing.filters' })}</StyledFilterTitleMobile>
              <StyledIconButton onClick={() => setMobileMenuVisible(false)}>
                <CloseCustom />
              </StyledIconButton>
            </StyledMobileHeader>

            <StyledBottomNavigationMobile>
              <Filters
                categories={categories}
                activeCategoriesId={activeCategoriesId}
                handleMenuClick={handleMenuClick}
              />
            </StyledBottomNavigationMobile>

            <StyledMobileFooter>
              <StyledFilterButtonMobile onClick={() => setMobileMenuVisible(false)}>
                {intl.formatMessage({ id: 'articleListing.applyFilters' })}
              </StyledFilterButtonMobile>
            </StyledMobileFooter>
          </StyledMobileFilter>
        </StyledMobileFilterWrapper>
      )}
    </Box>
  );
};
export default withErrorBoundary(ArticleListing, { componentName: 'ArticleListingModule' });
