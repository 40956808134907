import styled from 'styled-components';

import Media from '../../../../utils/Media';
import SocialIcons from '../SocialIcons';

export const StyledRichTextWrapper = styled.div`
  z-index: 10;

  div {
    color: ${({ theme }) => theme.colors.primaryWhite};
  }

  > * {
    margin: 0;

    > p {
      font-size: 14px;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledSocialIcons = styled(SocialIcons)`
  margin: 0;
  gap: 40px;
  z-index: 10;

  ${Media({
    justifyContent: ['center', null, 'flex-start'],
  })}

  > li {
    margin: 0;

    a svg path {
      fill: ${({ theme }) => theme.colors.primaryWhite};
    }

    a:hover svg path {
      fill: ${({ theme }) => theme.footer.activeColor};
    }
  }
`;
