import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useCountryCode = () => {
  const { locale } = useIntl();
  const countryNamesForLocale = useMemo(() => new Intl.DisplayNames(locale, { type: 'region' }), [locale]);

  const toCountryName = useCallback(
    (countryCode: string) => {
      if (!countryNamesForLocale) return '';
      return countryNamesForLocale.of(countryCode) ?? '';
    },
    [countryNamesForLocale],
  );

  return toCountryName;
};
