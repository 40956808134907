import { getDestination } from '../../../../utils';
import { withErrorBoundary } from '../../../components';
import { type CardProps } from '../CategorySectionModule';

import {
  CardContent,
  CardIcon,
  CardTitle,
  CardWrapper,
  Gradient,
  StyledChevron,
  StyledImage,
  TitleWrapper,
} from './Styled';

const TopCard = ({ item, className, size = 'medium' }: CardProps) => {
  const { title, image, cardLink, icon } = item;

  return (
    <CardWrapper className={className} to={cardLink?.externalUrl ?? getDestination(cardLink?.internalEntry)}>
      <CardContent size={size}>
        <TitleWrapper>
          {icon && <CardIcon alt={icon?.title} src={icon?.file?.url} />}
          <CardTitle lines="3">{title}</CardTitle>
        </TitleWrapper>
        <StyledChevron />
      </CardContent>
      <StyledImage alt={image?.altText ? image.altText : title} image={image.asset.gatsbyImageData} objectFit="cover" />
      <Gradient />
    </CardWrapper>
  );
};

export default withErrorBoundary(TopCard, { componentName: 'CategorySectionModule/TopCard' });
