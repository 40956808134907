import styled from 'styled-components';

import { HEADERLIGHT_VARIANTS } from '../../HeaderLight/HeaderLight';

interface StyledProps {
  variant?: string;
  isBrandX?: boolean;
  isMobileMenu?: boolean;
}

export const StyledChevron = styled.span<StyledProps>`
  display: inline-block;
  margin-left: 11px;

  width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '20px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
    color: #3d3d3d;
  }
`;

export const StyledCountryId = styled.span<StyledProps>`
  margin-left: 5px;
  text-transform: uppercase;
  font-size: ${props => (props.isBrandX ? '14px' : '12px')};
  color: ${props => (props.isBrandX ? props.theme.colors.primaryDarkGreen : 'black')};
`;

export const StyledDropdown = styled.div<StyledProps>`
  background-color: ${props => (props.isBrandX ? 'transparent' : props.theme.colors.white)};
  border-radius: ${props => props.theme.search.borderRadius};
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  &.is-open {
    border: ${props => (!props.isBrandX ? '1px solid #e0e0e0' : 'none')};
    box-shadow: ${props => (!props.isBrandX ? '0px 0px 15px 5px rgba(209, 209, 209, 0.5)' : 'none')};
    button {
      border: none;
    }
  }
  &.is-closed {
    button {
      border: ${props =>
        props.variant === HEADERLIGHT_VARIANTS.secondary || props.isBrandX
          ? 'none'
          : `1px solid ${props.theme.colors.darkGray}`};
    }
  }

  &.upwards {
    border: none;
    box-shadow: none;
    button {
      border: none;
    }
  }
`;

export const StyledFlag = styled.span<StyledProps>`
  display: ${props => (props.isBrandX ? 'none' : 'flex')};
  width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '14px' : '24px')};
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '14px' : '24px')};
  flex: 0 0 ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '14px' : '24px')};
  svg {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

export const StyledHeader = styled.button<StyledProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.search.borderRadius};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  background-color: ${props => (props.isBrandX ? 'transparent' : 'white')};
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '24px' : '40px')};
`;

export const StyledList = styled.ul<StyledProps>`
  margin: 0px;
  padding: 2px 4px;
  margin-top: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '0' : '10px')};
  list-style: none;
  height: auto;
`;

export const StyledListItem = styled.li`
  cursor: pointer;
  margin: 4px 0px;
  padding: 8px 4px 2px 4px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
`;

export const StyledTopList = styled.ul<StyledProps>`
  margin: 0px;
  padding: 2px 4px;
  margin-top: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '0' : '10px')};
  list-style: none;
  height: auto;
  position: absolute;
  bottom: 45px;
  background-color: ${props => (props.isBrandX ? 'transparent' : 'white')};
  border-radius: 10px;
  width: 82px;
  border: 1px solid #f0f0f0;
  box-shadow: ${props => (!props.isBrandX ? '0px 0px 15px 5px rgba(209, 209, 209, 0.5)' : 'none')};
  border: 1px solid #e0e0e0;
  border-bottom: none;

  > li:first-child {
    border-top: none;
  }
`;

export const StyledWrapper = styled.div<StyledProps>`
  height: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '24px' : '40px')};
  width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '75px' : '93px')};
  position: relative;
  z-index: 1;
`;
