import { NewsLetterHeader } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  NewsletterHeader: {
    SVG: NewsLetterHeader.AveveAgrarisch,
    className: 'newsletter-svg',
  },
  breadcrumb: {
    display: true,
  },
  button: {
    ctaBanner: {
      image: 'primary-with-background',
    },
    ctaGroup: 'secondary',
    infoBlock: 'primary',
    infoBlockColumns: 'secondary',
    shortBanner: 'primary-with-background',
  },
  footer: {
    version: 'V2',
  },
  mapSearchRadius: 50000, // in meters!!
});

export default config;
