import { useConfiguration } from '@arvesta-websites/configuration';
import { ChevronLeftSlider, ChevronRightSlider } from '@arvesta-websites/icons';

import { withErrorBoundary } from '../../../../components';
import { StyledControl } from '../../Styled';

export type ArrowProps = {
  isHero: boolean;
  handleClick: () => void;
  isInfinite: boolean;
  windowWidth: number;
  side: 'right' | 'left';
};

const ArrowIcon = ({ side }: Pick<ArrowProps, 'side'>) => {
  return side === 'right' ? <ChevronRightSlider /> : <ChevronLeftSlider />;
};

const SliderArrow = ({ isHero, isInfinite, windowWidth, side, handleClick }: ArrowProps) => {
  const config = useConfiguration();
  if (!isInfinite && windowWidth > 1350) {
    return null;
  }

  const variant = isHero ? 'hero' : 'regular';
  return (
    <StyledControl
      variant={variant}
      side={side}
      maskPresent={config.heroBanner.mask}
      className={config.heroBanner?.mask ? 'shouldhavemask' : undefined}
      onClick={handleClick}
    >
      <ArrowIcon side={side} />
    </StyledControl>
  );
};

export default withErrorBoundary(SliderArrow, { componentName: 'SliderArrow' });
