import { format } from 'date-fns';
import { de, enGB as en, fr, nl } from 'date-fns/locale';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';

import { Locales } from '../../../../types';
import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';

import { NewsCardProps } from './NewsCard';
import {
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBoxLink,
  StyledNewsDate,
  StyledTitle,
} from './Styled';

const getDate = (date: string, dateLocale: Locale) => {
  try {
    return format(new Date(date), 'MMMM do, yyyy', { locale: dateLocale });
  } catch (e) {
    return date;
  }
};

const VerticalNewsCard = ({ title, shortDescription, image, link, date }: NewsCardProps) => {
  const intl = useIntl();
  const dateLocales: Locales = { de, en, fr, nl };
  const dateLocale = dateLocales[intl.locale] || dateLocales.en;

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      {image && image.asset ? (
        <StyledImageBoxLink to={link}>
          <GatsbyImage
            alt={image.alt ? image.alt : image.title}
            image={image.asset.gatsbyImageData}
            objectFit="cover"
            style={{ height: '100%', margin: '0 auto' }}
          />
        </StyledImageBoxLink>
      ) : null}

      <StyledContentBox>
        {date && <StyledNewsDate>{getDate(date, dateLocale)}</StyledNewsDate>}

        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>

        <StyledDescription>{shortDescription}</StyledDescription>

        <StyledCtaWrapper>
          <StyledCta to={link}>
            <span>{intl.formatMessage({ id: 'cards.news.link' })}</span>
          </StyledCta>
        </StyledCtaWrapper>
      </StyledContentBox>
    </Box>
  );
};

export default withErrorBoundary(VerticalNewsCard, { componentName: 'VerticalNewsCard' });
