import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledTextBoxWrapper = styled(Box)`
  position: relative;
  max-width: 1324px;
  margin: 0 auto;
`;

export const StyledTextBox = styled(Box)`
  width: 100%;
  background-color: ${props => props.theme.infoSection.backgroundColor};
  color: ${props => props.theme.infoSection.color};
  position: relative;

  ${Media({
    marginLeft: ['1.25rem', null, '214px', `calc(226 / 1324 * 100%)`],
    marginTop: ['-2rem', null, null, '-4.5rem'],
    paddingBottom: ['2.625rem', null, null, '6rem'],
    paddingLeft: ['1.5625rem', null, '3rem', '6rem'],
    paddingRight: ['1.5625rem', null, null, '11.625rem'],
    paddingTop: ['2.625rem', null, null, '6rem'],
    width: ['calc(100% - 1.25rem)', null, 'auto', `calc(985 / 1324 * 100%)`],
  })}
`;

export const StyledCategory = styled.span`
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  display: block;

  &::after {
    content: '';
    display: block;
    background-color: white;
    height: 1px;
    width: 24px;
    margin-top: 0.875rem;
  }
`;

export const StyledTextBoxTitle = styled.h3`
  color: ${props => props.theme.infoSection.title ?? props.theme.colors.text};
  font-weight: normal;
  line-height: 1.25;
  margin: 0;
  word-break: break-word;

  ${Media({
    fontSize: ['1.5rem', null, null, '2rem'],
    marginBottom: ['1rem', null, null, '1rem'],
  })}
`;

export const StyledImage = styled(Box)`
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};

  ${Media({
    height: ['375px', null, null, '458px'],
    marginLeft: ['0', null, null, '-58px'],
    marginRight: ['0', null, null, '-58px'],
    width: ['100%', null, null, `calc(100% + 58px + 58px)`],
  })}
`;

export const StyledText = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;
`;

export const StyledTextBoxCta = styled.div`
  ${Media({
    marginTop: ['2rem', null, null, '2.5rem'],
  })}
`;
