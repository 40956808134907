import { Media, withErrorBoundary } from '../../components';
import { StyledModuleContainer } from '../../shared';

export const MediaModule = ({ ...rest }) => (
  <StyledModuleContainer smallTopSpacer>
    <Media {...rest} />
  </StyledModuleContainer>
);

export default withErrorBoundary(MediaModule, { componentName: 'MediaModule' });
