import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Scoriethom" transform="translate(15.000000, 1.000000)" fill="#008BD2">
        <path
          d="M86.7188,70.6881 C85.9438,70.6921 85.1668,70.6851 84.3928,70.7061 C84.1318,70.7121 84.0728,70.6221 84.0738,70.3791 C84.0828,68.9561 84.0788,67.5321 84.0768,66.1071 C84.0758,65.9301 84.0718,65.7501 84.0388,65.5761 C83.9388,65.0421 83.5618,64.7191 83.0378,64.7041 C82.4438,64.6851 82.0548,64.9661 81.9158,65.5221 C81.8388,65.8321 81.8208,66.1481 81.8208,66.4671 C81.8228,67.7641 81.8158,69.0611 81.8268,70.3581 C81.8288,70.6181 81.7698,70.7141 81.4928,70.7061 C80.8448,70.6861 80.1958,70.6841 79.5478,70.7071 C79.2578,70.7161 79.1848,70.6281 79.1868,70.3441 C79.1938,68.8701 79.1818,67.3951 79.1698,65.9201 C79.1688,65.7691 79.1428,65.6161 79.1058,65.4691 C78.9828,64.9811 78.7368,64.7471 78.3238,64.7081 C77.7828,64.6561 77.3878,64.8461 77.1698,65.2661 C76.9398,65.7101 76.9208,66.1951 76.9188,66.6771 C76.9118,67.8981 76.9078,69.1191 76.9218,70.3391 C76.9248,70.6171 76.8638,70.7171 76.5658,70.7081 C75.9058,70.6831 75.2428,70.6911 74.5828,70.7051 C74.3468,70.7091 74.2817899,70.6331 74.2828,70.4011 C74.2898,67.9591 74.2908,65.5181 74.2817899,63.0771 C74.2808,62.8261 74.3538,62.7491 74.6048,62.7551 C75.2398,62.7711 75.8758,62.7751 76.5118,62.7541 C76.7958,62.7451 76.8648,62.8571 76.8428,63.1101 C76.8268,63.3041 76.8398,63.5011 76.8398,63.8101 C77.3848,63.0831 77.9918,62.6371 78.7918,62.5631 C79.8018,62.4681 80.7298,62.6431 81.3178,63.5951 C81.4438,63.7981 81.5018,63.7131 81.6098,63.5891 C82.4638,62.5971 83.5558,62.3591 84.7858,62.6341 C85.8498,62.8741 86.4468,63.6061 86.6378,64.8401 C86.6528,64.9341 86.6088,65.0481 86.7188,65.1161 L86.7188,70.6881 Z"
          id="Fill-1"
        />
        <path
          d="M0,67.0241 C0.852,67.0241 1.704,67.0281 2.556,67.0201 C2.747,67.0191 2.847,67.0491 2.885,67.2721 C3.034,68.1451 3.388,68.5071 4.257,68.6661 C4.825,68.7681 5.398,68.7551 5.944,68.5311 C6.333,68.3701 6.61,68.1011 6.626,67.6521 C6.641,67.2251 6.368,66.9791 6.023,66.8031 C5.407,66.4881 4.729,66.3621 4.067,66.1901 C3.292,65.9881 2.522,65.7741 1.817,65.3811 C-0.079,64.3251 -0.234,61.7741 1.52,60.4921 C3.158,59.2941 6.049,59.2391 7.728,60.3751 C8.598,60.9621 9.042,61.7941 9.137,62.8301 C9.156,63.0361 9.094,63.1031 8.895,63.1011 C8.106,63.0951 7.318,63.0971 6.529,63.1001 C6.367,63.1011 6.276,63.0661 6.262,62.8741 C6.229,62.4191 5.952,62.1311 5.547,61.9701 C4.975,61.7411 4.388,61.7001 3.798,61.9051 C3.45,62.0261 3.202,62.2481 3.179,62.6431 C3.158,63.0201 3.4,63.2211 3.7,63.3671 C4.439,63.7251 5.252,63.8351 6.035,64.0451 C6.673,64.2171 7.294,64.4271 7.891,64.7201 C10.226,65.8651 9.718,68.9691 8.248,69.9951 C7.403,70.5831 6.459,70.8161 5.461,70.9181 C5.345,70.9291 5.205,70.8531 5.115,70.9941 L4.275,70.9941 C4.217,70.8651 4.098,70.9241 4.007,70.9141 C2.85,70.7781 1.77,70.4721 0.923,69.6001 C0.388,69.0471 0.165,68.3561 0,67.6341 L0,67.0241 Z"
          id="Fill-3"
        />
        <path
          d="M43.8941,65.9235 C44.3261,65.9235 44.7581,65.9085 45.1891,65.9285 C45.4771,65.9425 45.5431,65.8415 45.4771,65.5745 C45.2871,64.8195 44.6721,64.2975 43.9611,64.3085 C43.2041,64.3185 42.5231,64.9025 42.3721,65.6575 C42.3311,65.8635 42.3941,65.9315 42.5991,65.9265 C43.0301,65.9165 43.4621,65.9235 43.8941,65.9235 L43.8941,65.9235 Z M43.6651,70.9935 C43.3951,70.8385 43.0841,70.8815 42.7961,70.8105 C40.9071,70.3485 39.7341,68.8955 39.7071,66.9695 C39.6771,64.8225 40.8461,63.1895 42.8281,62.6885 C45.1961,62.0895 47.9021,63.1415 48.1371,66.6815 C48.1851,67.3945 48.1861,67.3945 47.4921,67.3945 C45.9021,67.3945 44.3131,67.4075 42.7241,67.3845 C42.3371,67.3785 42.3001,67.5225 42.3761,67.8265 C42.5491,68.5165 42.9531,68.9865 43.6661,69.1205 C44.3601,69.2505 44.9871,69.1155 45.4271,68.5055 C45.5271,68.3655 45.6241,68.3035 45.7971,68.3065 C46.4071,68.3165 47.0191,68.3225 47.6281,68.3045 C47.9361,68.2945 48.0041,68.3675 47.8881,68.6645 C47.3861,69.9395 46.3891,70.5635 45.1101,70.8455 C44.8581,70.9015 44.5851,70.8455 44.3521,70.9935 L43.6651,70.9935 Z"
          id="Fill-6"
        />
        <path
          d="M43.2832,0.0001 C44.4762,0.4691 44.9152,1.4151 44.9792,2.6231 C45.0352,3.6821 45.0272,4.7321 44.6602,5.7421 C44.2622,6.8381 43.4292,7.4021 42.2782,7.4071193 C41.5812,7.4101 41.0952,7.0631 40.7542,6.4981 C40.1762,5.5381 40.0682,4.4671 40.1012,3.3771 C40.1122,2.9861 40.1772,2.5941 40.2542,2.2091 C40.4652,1.1581 41.0112,0.3701 42.0612,0.0001 L43.2832,0.0001 Z"
          id="Fill-9"
        />
        <path
          d="M49.1406,23.2374 C49.1216,23.5414 49.0876,23.8464 49.0896,24.1504 C49.0916,24.6384 48.8946,25.0074 48.4926,25.2724 C46.5146,26.5754 44.6666,28.0504 42.8106,29.5154 C42.4216,29.8224 42.0016,30.0884 41.5526,30.3024 C41.1976,30.4724 40.8686,30.4554 40.5576,30.1994 C39.0386,28.9464 37.4956,27.7244 35.9256,26.5364 C35.6166,26.3014 35.4716,25.9654 35.5506,25.5574 C35.9226,23.6354 36.4096,21.7434 37.0896,19.9054 C37.3306,19.2544 37.5156,19.1974 38.0896,19.6234 C38.7826,20.1384 39.4416,20.7004 40.1176,21.2384 C40.4066,21.4684 40.7086,21.6804 41.0486,21.8294 C41.4566,22.0084 41.6406,21.9554 41.8926,21.5924 C41.9726,21.4774 42.0556,21.3614 42.1106,21.2344 C43.0836,18.9974 44.2536,16.8544 45.2616,14.6344 C45.4406,14.2424 45.6106,13.8454 45.8036,13.4604 C46.0146,13.0404 46.2096,13.0334 46.4776,13.4314 C46.7496,13.8384 46.8976,14.2994 47.0076,14.7704 C47.2096,15.6354 47.3986,16.5044 47.5996,17.3694 C47.6656,17.6564 47.5756,17.8944 47.4106,18.1164 C46.5016,19.3394 45.7216,20.6584 44.6656,21.7724 C44.4936,21.9544 44.3686,22.1824 44.2336,22.3974 C44.0886,22.6294 44.0766,22.8814 44.1946,23.1264 C44.3816,23.5144 44.5586,23.9114 44.7826,24.2794 C45.1106,24.8164 45.3406,24.8634 45.8816,24.5534 C46.4246,24.2414 46.9026,23.8404 47.3676,23.4244 C47.6506,23.1714 47.9166,22.8984 48.1936,22.6354 C48.2756,22.5574 48.3576,22.4734 48.4536,22.4144 C48.8646,22.1604 49.0866,22.2694 49.1226,22.7404 C49.1346,22.9054 49.1246,23.0714 49.1246,23.2374 C49.1296,23.2374 49.1356,23.2374 49.1406,23.2374"
          id="Fill-12"
        />
        <path
          d="M37.4653,17.1705 C37.6023,15.1645 37.6623,13.2855 37.6273,11.4035 C37.6113,10.5755 37.9383,10.2775 38.7693,10.3065 C39.9893,10.3505 41.2103,10.4215 42.4293,10.3585 C43.4223,10.3085 44.4093,10.3155 45.3983,10.3795 C46.0563,10.4235 46.1673,10.5705 45.9453,11.1915 C45.7063,11.8635 45.3723,12.4925 45.0343,13.1185 C43.8023,15.4025 42.8833,17.8285 41.8383,20.1965 C41.4663,21.0405 41.3143,21.1155 40.5163,20.6495 C39.7233,20.1865 39.0073,19.6175 38.2723,19.0715 C37.5663,18.5475 37.3183,17.8845 37.4653,17.1705"
          id="Fill-14"
        />
        <path
          d="M57.9512,63.8219 C58.8792,62.5269 60.3942,62.3129 61.6272,62.7189 C62.6122,63.0439 63.0572,63.7939 63.2062,64.7679 C63.2832,65.2739 63.2722,65.7819 63.2712,66.2909 C63.2702,67.6379 63.2642,68.9859 63.2772619,70.3339 C63.2792,70.6009 63.2342,70.7189 62.9292,70.7079 C62.2942,70.6819 61.6572,70.6849 61.0222,70.7059 C60.7282,70.7169 60.6322,70.6379 60.6362,70.3309 C60.6532,69.0209 60.6432,67.7119 60.6422,66.4019 C60.6422,66.2499 60.6382,66.0969 60.6262,65.9449 C60.5692,65.1619 60.2942,64.8079 59.6802,64.7249 C58.8712,64.6169 58.3392,64.9199 58.0902,65.6359 C57.9842,65.9389 57.9512,66.2539 57.9512,66.5739 C57.9522,67.8329 57.9422,69.0919 57.9592,70.3489 C57.9622,70.6389 57.8792,70.7159 57.5972,70.7069 C56.9482,70.6849 56.3002,70.6929 55.6512,70.7039 C55.4372,70.7069 55.3382,70.6759 55.3382,70.4199 C55.3472,66.9739 55.3462,63.5279 55.3402,60.0829 C55.3392,59.8549 55.3922,59.7719 55.6352,59.7779 C56.2962,59.7949 56.9582,59.7969 57.6182,59.7769 C57.8892,59.7699 57.9602,59.8569 57.9572,60.1209 C57.9442,61.3259 57.9512,62.5309 57.9512,63.8219"
          id="Fill-16"
        />
        <path
          d="M48.1035,29.7003 C48.1195,31.4043 48.0085,33.1043 47.9475,34.8053 C47.9145,35.7193 48.0385,36.6323 47.9515,37.5483 C47.8875,38.2043 47.7735,38.3933 47.1525,38.5953 C46.6675,38.7533 46.1685,38.8303 45.6575,38.8633 C45.2455,38.8893 45.0215,38.7043 44.8635,38.3543 C44.5565,37.6753 44.4315,36.9503 44.2825,36.2293 C43.9135,34.4523 43.5675,32.6703 43.0705,30.9233 C42.8895,30.2833 43.0215,29.7513 43.5655,29.3753 C44.8415,28.4953 46.0495,27.5183 47.3735,26.7083 C47.7935,26.4503 48.0905,26.6093 48.0995,27.1063 C48.1135,27.9703 48.1035,28.8353 48.1035,29.7003"
          id="Fill-18"
        />
        <path
          d="M67.1836,66.7535 C67.2246,67.2745 67.2776,67.7785 67.5466,68.2315 C68.0526,69.0815 69.2226,69.1875 69.8426,68.4385 C70.5156,67.6235 70.5076,65.8485 69.8286,65.0345 C69.2406,64.3325 68.1496,64.3835 67.6246,65.1335 C67.2806,65.6255 67.2276,66.1925 67.1836,66.7535 M68.7206,70.929521 C66.3156,70.9215 64.5796,69.1645 64.5805996,66.7415 C64.5826,64.2995 66.3586,62.5255 68.7946,62.5314848 C71.1856,62.5375 72.9746,64.3505 72.9666267,66.7585 C72.9576,69.1895 71.1796,70.9365 68.7206,70.929521"
          id="Fill-20"
        />
        <path
          d="M21.98,66.7614 C21.977,67.2764 22.054,67.7764 22.321,68.2264 C22.897,69.1964 24.264,69.1894 24.779,68.1874 C25.267,67.2384 25.251,66.2484 24.778,65.2884 C24.524,64.7744 24.07,64.5314 23.502,64.5424 C22.948,64.5534 22.541,64.8284 22.284,65.3234 C22.05,65.7744 21.975,66.2584 21.98,66.7614 M19.3779996,66.7504 C19.377,64.2954 21.105,62.5364 23.523,62.5323895 C25.956,62.5274 27.723,64.3014 27.7220004,66.7484 C27.721,69.2074 25.981,70.9324 23.506,70.9294039 C21.087,70.9254 19.379,69.1974 19.3779996,66.7504"
          id="Fill-22"
        />
        <path
          d="M35.3428,38.985 C35.3948,38.173 35.6098,37.385 35.7218,36.582 C35.8948,35.352 35.9828,34.108 36.1958,32.884 C36.4098,31.647 36.5828,30.403 36.7938,29.166 C36.8218,29.004 36.8588,28.842 36.9098,28.686 C37.0508,28.254 37.2818,28.15 37.6038,28.456 C38.5538,29.359 39.7038,30.016 40.6188,30.962 C41.0818,31.44 41.2648,31.946 40.9868,32.58 C40.1158,34.561 39.6848,36.684 39.0058,38.727 C38.8418,39.22 38.6868,39.717 38.5498,40.217 C38.4618,40.538 38.2648,40.668 37.9588,40.603 C37.1218,40.427 36.3278,40.134 35.5898,39.697 C35.3038,39.527 35.3428,39.252 35.3428,38.985"
          id="Fill-24"
        />
        <path
          d="M17.0811,65.695 C16.8901,65.695 16.6981,65.681 16.5091,65.698 C16.1221,65.732 15.8471,65.705 15.7071,65.217 C15.5771,64.76 15.1231,64.572 14.6431,64.553 C14.0741,64.531 13.5491,64.68 13.2821,65.216 C12.7911,66.2 12.7561,67.222 13.2581,68.217 C13.5471,68.793 14.0731,68.999 14.6991,68.921 C15.3061,68.846 15.7111,68.497 15.8471,67.907 C15.9151,67.611 16.0491,67.558 16.3141,67.565 C16.9371,67.581 17.5611,67.58 18.1841,67.565 C18.4351,67.559 18.4741,67.632 18.4301,67.874 C18.1201,69.579 16.8041,70.766 15.0341,70.912 C13.9141,71.005 12.8461,70.845 11.9161,70.166 C10.6741,69.257 10.2381,67.982 10.3181,66.493 C10.4241,64.51 11.6131,63.038 13.4661,62.643 C14.6771,62.385 15.8441,62.538 16.9151,63.195 C17.7481,63.706 18.1931,64.473 18.3681,65.422 C18.4131,65.665 18.3191,65.705 18.1111,65.698 C17.7681,65.686 17.4241,65.695 17.0811,65.695"
          id="Fill-26"
        />
        <path
          d="M50.001,66.7047 C50.001,66.0677 49.991,65.4327 50.005,64.7967 C50.01,64.5587 49.956,64.4297 49.694,64.4737 C49.682,64.4757 49.668,64.4747 49.655,64.4737 C49.344,64.4427 48.929,64.6147 48.747,64.3937 C48.595,64.2077 48.694,63.8097 48.692,63.5067 C48.689,62.7607 48.691,62.7607 49.417,62.7607 C50,62.7607 50.001,62.7607 50.001,62.1837 C50.001,61.6877 50.018,61.1907 49.994,60.6957 C49.981,60.4177 50.082,60.3577 50.34,60.3647 C50.962,60.3827 51.587,60.3877 52.209,60.3637 C52.517,60.3517 52.634,60.4217 52.615,60.7537 C52.586,61.2997 52.628,61.8487 52.601,62.3937 C52.585,62.7097 52.699,62.7867 52.988,62.7667 C53.305,62.7427 53.625,62.7687 53.942,62.7577 C54.132,62.7507 54.221,62.7997 54.215,63.0097 C54.201,63.4167 54.204,63.8237 54.214,64.2307 C54.218,64.4117 54.154,64.4797 53.972,64.4757 C53.616,64.4667 53.26,64.4797 52.903,64.4707 C52.706,64.4657 52.602,64.5067 52.604,64.7397 C52.613,65.8587 52.602,66.9777 52.611,68.0987 C52.614,68.5337 52.825,68.7397 53.262,68.7897 C53.35,68.7997 53.439,68.8087 53.527,68.8087 C54.211,68.8107 54.211,68.8097 54.211,69.4827 C54.211,69.8017 54.2,70.1197 54.215,70.4367 C54.225,70.6307 54.157,70.7087 53.966,70.7247 C53.268,70.7807 52.566,70.8267 51.871,70.7377 C50.597,70.5757 50.089,70.0027 50.016,68.6497 C49.981,68.0037 50.01,67.3527 50.01,66.7047 L50.001,66.7047 Z"
          id="Fill-28"
        />
        <path
          d="M33.0943,52.5211 C32.9523,52.5051 32.8123,52.4971 32.6763,52.4711 C32.4563,52.4301 32.2513,52.3601 32.2113,52.0941 C32.1683,51.8041 32.2343,51.5481 32.5193,51.4211 C32.9593,51.2261 33.4063,51.0431 33.8573,50.8751 C34.6903,50.5661 35.5553,50.3461 36.3613,49.9621 C36.4463,49.9211 36.5893,49.9151 36.5733,49.7841 C36.5573,49.6431 36.4113,49.6981 36.3233,49.6741 C36.2003,49.6411 36.0723,49.6341 35.9483,49.6101 C35.4573,49.5111 35.2983,49.3441 35.2623,48.8481 C35.0773,46.2331 35.1033,43.6171 35.2773,41.0011 C35.3143,40.4461 35.3413,40.4391 35.9073,40.5171 C36.5293,40.6021 37.1193,40.7881 37.7003,41.0181 C38.1343,41.1891 38.3323,41.4871 38.3133,41.9561 C38.2933,42.4551 38.2153,42.9441 38.0873,43.4211 C37.8233,44.4131 37.9153,45.4441 37.7133,46.4421 C37.4993,47.4991 37.4123,48.5801 37.1013,49.6171 C37.0503,49.7861 37.0833,49.9421 37.1303,50.1011 C37.2653,50.5541 37.3513,51.0131 37.3423,51.4891 C37.3393,51.6431 37.3133,51.7791 37.1393,51.8021 C35.7813,51.9861 34.4693,52.4371 33.0943,52.5211"
          id="Fill-30"
        />
        <path
          d="M31.6914,63.9772 C32.2784,62.9702 33.1604,62.5902 34.2644,62.6072 C34.5964,62.6122 34.7574,62.7122 34.7314,63.0952 C34.6934,63.6522 34.7154,64.2122 34.7244,64.7722 C34.7284,64.9852 34.6914,65.0662 34.4464,65.0032 C34.1044,64.9172 33.7544,64.8712 33.3914,64.9312 C32.3774,65.1012 31.7764,65.7812 31.7694,66.8212 C31.7614,68.0022 31.7594,69.1852 31.7744,70.3672 C31.7774,70.6342 31.7024,70.7132 31.4344,70.7062 C30.7744,70.6872 30.1124,70.6952 29.4524,70.7032 C29.2594,70.7052 29.1544,70.6842 29.1553929,70.4422 C29.1644,67.9642 29.1624,65.4862 29.1584,63.0082 C29.1574,62.8372 29.1934,62.7542 29.3884,62.7572 C30.0744,62.7662 30.7604,62.7682 31.4464,62.7562 C31.6634,62.7532 31.6994,62.8512 31.6934,63.0332 C31.6854,63.3472 31.6914,63.6632 31.6914,63.9772"
          id="Fill-32"
        />
        <path
          d="M36.9517,11.1593 C36.7827,12.2663 36.9197,13.3983 36.8787,14.5273 C36.8637,14.9283 36.7467,15.2633 36.4887,15.5693 C35.9517,16.2043 35.3207,16.7373 34.6757,17.2513 C34.0087,17.7833 33.4257,18.4053 32.7717,18.9503 C31.8737,19.6983 31.4907,19.6323 30.8927,18.6343 C30.4377,17.8743 30.4147,17.5763 31.0237,16.9343 C32.3087,15.5773 33.5097,14.1513 34.6837,12.7003 C35.1867,12.0783 35.6757,11.4453 36.1887,10.8323 C36.3307,10.6613 36.4697,10.3603 36.7537,10.4893 C37.0097,10.6043 36.9347,10.9053 36.9517,11.1593"
          id="Fill-34"
        />
        <path
          d="M48.9053,18.1793 C48.3513,18.1953 48.1703,18.0743 48.0593,17.5243 C47.6543,15.5193 47.0863,13.5583 46.5093,11.5983 C46.2803,10.8163 46.3583,10.6913 47.1333,10.4483 C47.4853,10.3373 47.8433,10.2593 48.2083,10.2043 C48.7943,10.1163 49.0863,10.2913 49.2343,10.8693 C49.4623,11.7543 49.6783,12.6413 49.9003,13.5283 C50.1853,14.6723 50.5483,15.7973 50.7233,16.9683 C50.8043,17.5113 50.6613,17.7843 50.1353,17.9443 C49.7343,18.0663 49.3273,18.1653 48.9053,18.1793"
          id="Fill-36"
        />
        <path
          d="M38.2896,66.7575 C38.2896,67.9645 38.2846,69.1715 38.2936,70.3775 C38.2956,70.6165 38.2426,70.7135 37.9776,70.7055 C37.3056,70.6875 36.6316,70.6915 35.9586,70.7045 C35.7266,70.7085 35.6556,70.6385 35.6565896,70.4035 C35.6646,67.9515 35.6646,65.4995 35.6565896,63.0475 C35.6556,62.7995 35.7496,62.7525 35.9686,62.7565 C36.6296,62.7675 37.2906,62.7725 37.9506,62.7545 C38.2186,62.7475 38.2976,62.8325 38.2946,63.0985 C38.2836,64.3185 38.2896,65.5375 38.2896,66.7575"
          id="Fill-38"
        />
        <path
          d="M48.2012,38.7858 C48.3502,38.8088 48.6022,38.9068 48.7582,39.1058 C50.0572,40.7778 51.6992,42.1658 52.8122,43.9908 C53.2512,44.7088 52.9192,45.3968 52.0782,45.5418 C51.8032,45.5898 51.6202,45.4188 51.4332,45.2738 C50.6092,44.6358 49.8692,43.9018 49.1362,43.1638 C48.3042,42.3268 47.4672,41.4888 46.7652,40.5368 C46.0912,39.6218 46.2472,39.7668 47.0142,39.3108 C47.2852,39.1488 47.5682,39.0058 47.8422,38.8468 C47.9222,38.8008 47.9972,38.7638 48.2012,38.7858"
          id="Fill-40"
        />
        <path
          d="M26.2422,25.2033 C25.6772,25.1953 25.2092,24.4393 25.4642,23.9263 C25.6922,23.4693 26.0172,23.0753 26.3452,22.6873 C27.3302,21.5243 28.2392,20.3043 29.1282,19.0653 C29.7162,18.2463 29.9272,18.2623 30.5802,19.0533 C31.4762,20.1403 31.4452,20.4763 30.4522,21.4513 C29.3292,22.5533 28.1182,23.5663 27.0922,24.7653 C26.8752,25.0183 26.6112,25.2243 26.2422,25.2033"
          id="Fill-42"
        />
        <path
          d="M44.7246,22.9435 C44.6936,22.6875 44.7766,22.4495 44.9136,22.2295 C45.0146,22.0685 45.1146,21.9025 45.2396,21.7615 C46.0476,20.8495 46.8836,19.9615 47.6066,18.9775 C47.8076,18.7035 48.0426,18.6485 48.3686,18.7555 C48.8906,18.9245 49.4046,19.1045 49.8276,19.4685 C50.1946,19.7845 50.2526,20.1535 49.8746,20.4425 C48.5186,21.4795 47.4976,22.8835 46.0876,23.8575 C45.9846,23.9295 45.8796,24.0035 45.7686,24.0635 C45.4196,24.2505 45.1286,24.1715 44.9256,23.8305 C44.7666,23.5605 44.7046,23.2645 44.7246,22.9435"
          id="Fill-44"
        />
        <path
          d="M50.4317,49.1608 C50.1337,49.1338 49.8957,48.9508 49.7547,48.6358 C49.6267,48.3478 49.7777,48.1298 49.9767,47.9668 C51.0447,47.0878 52.0897,46.1818 53.0767,45.2118 C53.2367,45.0548 53.3957,45.0988 53.5557,45.2088 C53.8777,45.4298 54.0887,45.7438 54.2907,46.0688 C54.4067,46.2548 54.2677,46.3548 54.1707,46.4608 C53.6637,47.0158 53.0687,47.4728 52.4757,47.9298 C51.9847,48.3098 51.4837,48.6798 50.9437,48.9908 C50.8007,49.0718 50.6577,49.1578 50.4317,49.1608"
          id="Fill-46"
        />
        <path
          d="M25.4097,26.005 C25.4317,26.189 25.3597,26.373 25.1847,26.506 C24.4457,27.074 23.6477,27.529 22.7537,27.813 C22.4427,27.911 22.2607,27.802 22.1707,27.512 C22.0997,27.283 22.0437,27.048 21.9907,26.814 C21.9277,26.541 22.0087,26.324 22.2327,26.143 C22.8507,25.644 23.5517,25.286 24.2807,24.993 C24.8257,24.773 25.3727,25.27 25.4097,26.005"
          id="Fill-48"
        />
        <path
          d="M36.9829,61.8014 C36.6399,61.8014 36.2959,61.7934 35.9529,61.8044 C35.7539,61.8104 35.6489,61.7704 35.6559,61.5354 C35.6719,61.0394 35.6679,60.5434 35.6579,60.0474 C35.6539,59.8554 35.7099,59.7774 35.9159,59.7804 C36.6279,59.7894 37.3399,59.7884 38.0519,59.7804 C38.2389,59.7784 38.2949,59.8514 38.2919,60.0274 C38.2849,60.5344 38.2819,61.0434 38.2929,61.5524 C38.2969,61.7654 38.1959,61.8084 38.0129,61.8044 C37.6699,61.7954 37.3259,61.8014 36.9829,61.8014"
          id="Fill-50"
        />
        <path
          d="M42.271,9.9894 C42.197,9.9854 42.07,9.9754 41.943,9.9704 C41.613,9.9564 41.24,9.9854 41.091,9.6104 C40.904,9.1354 40.922,8.6404 41.126,8.1654 C41.184,8.0314 41.327,8.0164 41.455,8.0164 C42.115,8.0174 42.775,8.0214 43.435,8.0304 C43.635,8.0334 43.764,8.1404 43.81,8.3434 C43.893,8.7084 43.886,9.0764 43.801,9.4374 C43.737,9.7074 43.537,9.8554 43.27,9.9274 C42.958,10.0114 42.64,9.9824 42.271,9.9894"
          id="Fill-52"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
