import { Calendar, LocationPin } from '@arvesta-websites/icons';
import { useIntl } from 'react-intl';

import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';

import { EventCardProps } from './EventCard';
import {
  StyledContentBox,
  StyledContentWrapper,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledEventDate,
  StyledEventDetails,
  StyledEventLocation,
  StyledHorizontalImageBox,
  StyledImageBoxLink,
  StyledTitle,
} from './Styled';

const HorizontalEventCard = ({ title, shortDescription, image, link, displayDate, location }: EventCardProps) => {
  const intl = useIntl();

  return (
    <StyledContentWrapper>
      <StyledContentBox className={image ? '' : 'no-image'}>
        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>
        {displayDate || location ? (
          <StyledEventDetails>
            <StyledEventDate>
              {displayDate && <Calendar />}
              <span>{displayDate || null}</span>
            </StyledEventDate>
            <StyledEventLocation>
              {location && <LocationPin />}
              <span>{location || null}</span>
            </StyledEventLocation>
          </StyledEventDetails>
        ) : null}
        <StyledDescription>{shortDescription}</StyledDescription>

        <StyledCtaWrapper>
          <StyledCta to={link}>
            <span>{intl.formatMessage({ id: 'cards.event.link' })}</span>
          </StyledCta>
        </StyledCtaWrapper>
      </StyledContentBox>

      {image ? (
        <StyledImageBoxLink to={link}>
          <StyledHorizontalImageBox
            sx={{
              backgroundImage: `url(${image})`,
            }}
          />
        </StyledImageBoxLink>
      ) : null}
    </StyledContentWrapper>
  );
};

export default withErrorBoundary(HorizontalEventCard, { componentName: 'HorizontalEventCard' });
