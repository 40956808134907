import React from 'react';

const Logo = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="74.938px"
    height="71.635px"
    viewBox="0 0 74.938 71.635"
    enableBackground="new 0 0 74.938 71.635"
    xmlSpace="preserve"
  >
    <g id="solid_logo">
      <g id="pentagon">
        <path
          fill="#146A36"
          d="M19.313,0.365c-2.75,0-5.695,2.14-6.545,4.755L1.545,39.656c-0.85,2.616,0.275,6.078,2.5,7.694
			l29.379,21.345c2.225,1.616,5.865,1.616,8.09,0l29.379-21.345c2.225-1.616,3.35-5.078,2.5-7.694L62.17,5.12
			c-0.85-2.615-3.795-4.755-6.545-4.755H19.313z"
        />
      </g>
      <g id="text">
        <path
          fill="#FFFFFF"
          d="M20.114,19.009c0.42-0.08,0.882-0.143,1.388-0.188c0.505-0.045,1.007-0.067,1.507-0.067
			c0.521,0,1.043,0.042,1.567,0.127c0.525,0.085,0.998,0.255,1.418,0.51c0.42,0.255,0.763,0.61,1.027,1.065s0.397,1.047,0.397,1.777
			c0,0.66-0.115,1.217-0.345,1.672c-0.23,0.455-0.535,0.828-0.915,1.118s-0.813,0.5-1.298,0.63s-0.987,0.195-1.507,0.195
			c-0.051,0-0.131,0-0.24,0c-0.11,0-0.226-0.002-0.345-0.007c-0.12-0.005-0.238-0.012-0.353-0.022
			c-0.115-0.01-0.198-0.02-0.248-0.03v3.615h-2.055V19.009z M23.174,20.524c-0.2,0-0.39,0.007-0.569,0.022
			c-0.181,0.015-0.325,0.033-0.436,0.052v3.405c0.04,0.01,0.1,0.02,0.18,0.03c0.08,0.01,0.165,0.018,0.256,0.022
			c0.09,0.005,0.177,0.008,0.262,0.008s0.147,0,0.188,0c0.271,0,0.538-0.025,0.803-0.075c0.265-0.05,0.503-0.143,0.713-0.277
			c0.21-0.135,0.377-0.323,0.502-0.563c0.125-0.24,0.188-0.555,0.188-0.945c0-0.33-0.06-0.602-0.18-0.817
			c-0.12-0.215-0.277-0.387-0.473-0.518c-0.195-0.13-0.418-0.22-0.667-0.27C23.689,20.549,23.434,20.524,23.174,20.524z"
        />
        <path
          fill="#FFFFFF"
          d="M34.109,28.759c-0.3,0.24-0.708,0.442-1.223,0.607c-0.516,0.165-1.063,0.248-1.643,0.248
			c-1.21,0-2.095-0.353-2.655-1.058c-0.56-0.705-0.84-1.672-0.84-2.902c0-1.32,0.315-2.31,0.945-2.97
			c0.63-0.66,1.515-0.99,2.655-0.99c0.38,0,0.75,0.05,1.109,0.15c0.36,0.1,0.68,0.265,0.96,0.495s0.505,0.54,0.675,0.93
			c0.17,0.39,0.256,0.875,0.256,1.455c0,0.21-0.013,0.435-0.038,0.675c-0.024,0.24-0.063,0.49-0.112,0.75h-4.485
			c0.03,0.63,0.192,1.105,0.486,1.425c0.294,0.32,0.77,0.48,1.428,0.48c0.408,0,0.774-0.062,1.099-0.188
			c0.323-0.125,0.57-0.252,0.739-0.382L34.109,28.759z M31.326,23.254c-0.508,0-0.884,0.152-1.127,0.458
			c-0.244,0.305-0.391,0.712-0.44,1.222h2.775c0.04-0.54-0.042-0.955-0.246-1.245S31.764,23.254,31.326,23.254z"
        />
        <path
          fill="#FFFFFF"
          d="M40.318,29.404v-4.26c0-0.61-0.087-1.05-0.262-1.32c-0.176-0.27-0.473-0.405-0.893-0.405
			c-0.37,0-0.683,0.107-0.938,0.322c-0.255,0.215-0.438,0.483-0.548,0.803v4.86h-1.95v-7.5h1.545l0.226,0.99h0.06
			c0.23-0.32,0.535-0.6,0.915-0.84c0.38-0.24,0.87-0.36,1.47-0.36c0.37,0,0.7,0.05,0.99,0.15c0.29,0.1,0.535,0.265,0.735,0.495
			s0.35,0.542,0.45,0.938c0.1,0.395,0.149,0.883,0.149,1.462v4.665H40.318z"
        />
        <path
          fill="#FFFFFF"
          d="M43.124,21.904h1.035v-1.41l1.95-0.555v1.965h1.83v1.65h-1.83v2.88c0,0.521,0.052,0.893,0.157,1.118
			c0.104,0.225,0.303,0.337,0.593,0.337c0.199,0,0.372-0.02,0.518-0.06c0.145-0.04,0.307-0.1,0.487-0.18l0.345,1.5
			c-0.27,0.13-0.585,0.24-0.945,0.33c-0.359,0.09-0.725,0.135-1.095,0.135c-0.689,0-1.197-0.178-1.522-0.532
			c-0.325-0.355-0.487-0.938-0.487-1.748v-3.78h-1.035V21.904z"
        />
        <path
          fill="#FFFFFF"
          d="M49.094,22.339c0.4-0.18,0.875-0.323,1.425-0.428s1.125-0.157,1.726-0.157c0.52,0,0.955,0.063,1.305,0.188
			s0.628,0.302,0.833,0.532c0.204,0.23,0.35,0.505,0.435,0.825c0.085,0.32,0.128,0.68,0.128,1.08c0,0.44-0.016,0.882-0.045,1.328
			c-0.03,0.445-0.048,0.882-0.053,1.313c-0.006,0.43,0.007,0.847,0.037,1.252c0.03,0.405,0.105,0.787,0.226,1.147h-1.591
			l-0.314-1.035h-0.075c-0.2,0.31-0.478,0.578-0.832,0.803c-0.355,0.225-0.813,0.337-1.373,0.337c-0.35,0-0.665-0.053-0.945-0.158
			c-0.279-0.105-0.52-0.255-0.72-0.45c-0.2-0.195-0.354-0.425-0.465-0.69c-0.11-0.265-0.165-0.563-0.165-0.892
			c0-0.46,0.103-0.848,0.308-1.163s0.5-0.57,0.885-0.765s0.845-0.33,1.38-0.405c0.535-0.075,1.133-0.098,1.793-0.068
			c0.069-0.56,0.03-0.962-0.12-1.208c-0.15-0.245-0.485-0.367-1.005-0.367c-0.391,0-0.803,0.04-1.237,0.12
			c-0.436,0.08-0.793,0.185-1.073,0.315L49.094,22.339z M51.569,27.919c0.39,0,0.699-0.087,0.93-0.263
			c0.229-0.175,0.399-0.362,0.51-0.563v-0.975c-0.31-0.03-0.607-0.035-0.893-0.015s-0.537,0.065-0.757,0.135
			c-0.221,0.07-0.396,0.17-0.525,0.3c-0.13,0.13-0.195,0.295-0.195,0.495c0,0.28,0.083,0.498,0.248,0.653
			C51.052,27.841,51.278,27.919,51.569,27.919z"
        />
        <path fill="#FFFFFF" d="M21.832,36.734h-3.061v8.67h-2.055v-8.67h-3.075v-1.83h8.19V36.734z" />
        <path
          fill="#FFFFFF"
          d="M25.417,39.734c-0.311-0.11-0.591-0.165-0.841-0.165c-0.35,0-0.643,0.093-0.877,0.277
			c-0.235,0.185-0.393,0.423-0.473,0.712v4.845h-1.95v-7.5h1.516l0.225,0.99h0.06c0.17-0.37,0.4-0.655,0.69-0.855
			c0.29-0.2,0.63-0.3,1.021-0.3c0.26,0,0.555,0.055,0.885,0.165L25.417,39.734z"
        />
        <path
          fill="#FFFFFF"
          d="M26.497,38.339c0.399-0.18,0.875-0.323,1.425-0.428s1.125-0.157,1.725-0.157
			c0.521,0,0.955,0.063,1.306,0.188c0.35,0.125,0.627,0.302,0.832,0.532c0.205,0.23,0.35,0.505,0.436,0.825
			c0.084,0.32,0.127,0.68,0.127,1.08c0,0.44-0.015,0.882-0.045,1.328c-0.03,0.445-0.048,0.882-0.053,1.313
			c-0.005,0.43,0.008,0.847,0.038,1.252c0.029,0.405,0.104,0.787,0.225,1.147h-1.59l-0.315-1.035h-0.074
			c-0.2,0.31-0.479,0.578-0.833,0.803c-0.355,0.225-0.813,0.337-1.372,0.337c-0.351,0-0.666-0.053-0.945-0.158
			c-0.28-0.105-0.521-0.255-0.72-0.45c-0.2-0.195-0.355-0.425-0.466-0.69c-0.109-0.265-0.164-0.563-0.164-0.892
			c0-0.46,0.102-0.848,0.307-1.163s0.5-0.57,0.886-0.765c0.385-0.195,0.845-0.33,1.38-0.405c0.534-0.075,1.132-0.098,1.792-0.068
			c0.07-0.56,0.03-0.962-0.12-1.208c-0.149-0.245-0.484-0.367-1.005-0.367c-0.39,0-0.802,0.04-1.237,0.12
			c-0.435,0.08-0.793,0.185-1.072,0.315L26.497,38.339z M28.972,43.919c0.39,0,0.7-0.087,0.93-0.263
			c0.23-0.175,0.4-0.362,0.511-0.563v-0.975c-0.311-0.03-0.607-0.035-0.893-0.015s-0.538,0.065-0.758,0.135
			c-0.22,0.07-0.396,0.17-0.525,0.3c-0.13,0.13-0.194,0.295-0.194,0.495c0,0.28,0.082,0.498,0.247,0.653
			C28.454,43.841,28.682,43.919,28.972,43.919z"
        />
        <path
          fill="#FFFFFF"
          d="M40.356,42.764c0,0.42,0.005,0.84,0.016,1.26c0.01,0.42,0.055,0.885,0.135,1.395h-1.396l-0.27-0.975
			h-0.061c-0.199,0.35-0.487,0.632-0.862,0.848c-0.375,0.215-0.817,0.322-1.327,0.322c-0.94,0-1.67-0.31-2.19-0.93
			c-0.52-0.62-0.779-1.59-0.779-2.91c0-1.28,0.29-2.275,0.87-2.985c0.579-0.709,1.43-1.065,2.55-1.065
			c0.31,0,0.564,0.018,0.765,0.053s0.4,0.092,0.6,0.172v-3.045h1.95V42.764z M37.042,43.964c0.38,0,0.68-0.092,0.899-0.277
			s0.375-0.453,0.465-0.803v-3.18c-0.14-0.11-0.295-0.192-0.465-0.247c-0.17-0.055-0.39-0.083-0.66-0.083
			c-0.55,0-0.962,0.185-1.237,0.555c-0.274,0.37-0.412,1.005-0.412,1.905c0,0.64,0.112,1.155,0.337,1.545
			C36.194,43.769,36.552,43.964,37.042,43.964z"
        />
        <path
          fill="#FFFFFF"
          d="M41.946,35.789c0-0.29,0.107-0.537,0.322-0.742s0.513-0.308,0.893-0.308s0.688,0.103,0.923,0.308
			c0.234,0.205,0.353,0.453,0.353,0.742c0,0.291-0.118,0.533-0.353,0.728c-0.235,0.195-0.543,0.292-0.923,0.292
			s-0.678-0.097-0.893-0.292C42.054,36.321,41.946,36.079,41.946,35.789z M42.231,37.904h1.95v7.5h-1.95V37.904z"
        />
        <path
          fill="#FFFFFF"
          d="M50.691,45.404v-4.26c0-0.61-0.088-1.05-0.263-1.32s-0.473-0.405-0.893-0.405
			c-0.37,0-0.683,0.107-0.938,0.322c-0.255,0.215-0.438,0.483-0.547,0.803v4.86h-1.95v-7.5h1.545l0.225,0.99h0.061
			c0.229-0.32,0.534-0.6,0.915-0.84c0.38-0.24,0.87-0.36,1.47-0.36c0.37,0,0.7,0.05,0.99,0.15c0.29,0.1,0.534,0.265,0.734,0.495
			s0.351,0.542,0.45,0.938s0.15,0.883,0.15,1.462v4.665H50.691z"
        />
        <path
          fill="#FFFFFF"
          d="M60.621,45.404c0,1.09-0.295,1.893-0.885,2.408c-0.591,0.515-1.415,0.772-2.476,0.772
			c-0.72,0-1.287-0.05-1.702-0.15s-0.728-0.205-0.938-0.315l0.405-1.545c0.229,0.09,0.497,0.18,0.803,0.27
			c0.305,0.09,0.682,0.135,1.132,0.135c0.68,0,1.143-0.148,1.388-0.443s0.367-0.708,0.367-1.237v-0.48h-0.06
			c-0.351,0.47-0.97,0.705-1.86,0.705c-0.97,0-1.692-0.3-2.167-0.9c-0.476-0.6-0.713-1.54-0.713-2.82c0-1.34,0.32-2.355,0.96-3.045
			s1.57-1.035,2.79-1.035c0.64,0,1.213,0.045,1.718,0.135s0.917,0.195,1.237,0.315V45.404z M57.321,43.964
			c0.38,0,0.672-0.085,0.877-0.255s0.362-0.425,0.473-0.765v-3.375c-0.31-0.13-0.695-0.195-1.154-0.195
			c-0.501,0-0.891,0.188-1.171,0.563c-0.279,0.375-0.42,0.973-0.42,1.792c0,0.73,0.12,1.285,0.36,1.665
			C56.526,43.774,56.871,43.964,57.321,43.964z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
