import { SearchSmall as SearchIcon } from '@arvesta-websites/icons';
import React, { createRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../../ErrorBoundary';
import { HEADERDEFAULT_VARIANTS } from '../../HeaderDefault/HeaderDefault';

type HeaderDefaultVariants = (typeof HEADERDEFAULT_VARIANTS)[keyof typeof HEADERDEFAULT_VARIANTS];

export interface SearchDefaultProps {
  searchFunction: (searchTerm: string) => void;
  variant?: HeaderDefaultVariants;
}

const search = tv({
  slots: {
    searchIcon: 'transform scale-[0.85]',
    searchIconButton: 'flex items-center h-6 w-6 last-child:cursor-pointer',
    searchInput:
      'bg-transparent border-none w-full py-0 pr-0 pl-2.5 flex-1 h-full outline-none searchInput lg:leading-[26px]',
    wrapper:
      'h-10 max-w-[215px] min-w-24 relative text-bold mr-2 border border-solid border-header-search-border hover:border-header-search-hover text-content bg-header-search-background rounded-button flex flex-row-reverse items-center py-0 pr-0 pl-2.5 focus:border-header-primary-hover',
  },
  variants: {
    variant: {
      default: {
        searchInput: '',
      },
      'display search & language selector in separate bar': {
        searchInput: 'h-[18px] w-[17px]',
      },
      'display top-level navigation': {
        searchInput: '',
      },
    },
  },
});

const SearchDefault = ({ searchFunction, variant }: SearchDefaultProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [focused, setFocusedState] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const intl = useIntl();

  useEffect(() => {
    if (!focused) {
      setSearchTerm('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [focused, setSearchTerm, inputRef]);

  const { wrapper, searchIcon, searchIconButton, searchInput } = search({ variant });

  return (
    <div className={wrapper()}>
      <span
        onClick={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        role="button"
        tabIndex={0}
        className={searchIconButton()}
      >
        <SearchIcon className={searchIcon()} />
      </span>
      <input
        autoComplete="off"
        name="search"
        onBlur={() => setFocusedState(false)}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setSearchTerm(e.currentTarget.value);
          setFocusedState(true);
        }}
        onFocus={() => setFocusedState(true)}
        onKeyDown={e => {
          if (e.keyCode === 27) {
            setFocusedState(false);
          }
        }}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            searchFunction(searchTerm);
          }
        }}
        placeholder={intl.formatMessage({ id: 'header.search.placeholder' })}
        ref={inputRef}
        role="search"
        className={searchInput()}
      />
    </div>
  );
};

export default withErrorBoundary(SearchDefault, { componentName: 'SearchDefault' });
