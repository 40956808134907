import styled from 'styled-components';

import Media from '../../../../../utils/Media';
import { HEADERLIGHT_VARIANTS } from '../../HeaderLight/HeaderLight';

type StyledNavigationProps = {
  variant?: string;
};

type StyledNavItemProps = {
  variant?: string;
  isActive: boolean;
  isOpen: boolean;
};

export const StyledNavigation = styled.ul<StyledNavigationProps>`
  justify-content: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? 'flex-start' : 'space-around')};
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;

  @media all and (min-width: 920px) {
    width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '100%' : 'auto')};
  }

  @media all and (min-width: ${props => props.theme.sizes.lg}) {
    width: ${props => (props.variant === HEADERLIGHT_VARIANTS.secondary ? '100%' : 'auto')};
  }

  ${Media({
    display: ['none', null, null, 'flex'],
  })}
`;

export const StyledNavItem = styled.li<StyledNavItemProps>`
  list-style: none;
  display: inline-block;
  margin-right: 30px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    padding: 2rem 0px;
    text-decoration: none;
    font-size: ${props => props.theme.navItems.fontSize};
    text-transform: ${props => props.theme.navItems.textTransform};
    font-weight: ${props => {
      if (!props.isActive) {
        return props.theme.navItems.fontWeight;
      }

      return props.theme.navItems.selectedFontWeight || 'bold';
    }};
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    line-height: 2;
    color: ${props => props.theme.header.color};

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 100%;
      background: ${props => props.theme.header.activeColor};

      ${props => props.isOpen && `content: ''`}
    }
  }

  &:focus,
  &:hover {
    color: ${props => props.theme.header.activeColor};
  }
`;

export const StyledArrow = styled.span`
  justify-content: center;
  align-items: center;
  height: 24px;
  position: relative;
  ml > span {
    > svg {
      > * {
        fill: ${props => props.theme.mobileNavigation.chevronColor};
      }
    }
  }
`;
