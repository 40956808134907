import React from 'react';
import { HeadingProps as RebassHeadingProps } from 'rebass';
import { CSSObject } from 'styled-components';

import { withErrorBoundary } from '../ErrorBoundary';

import { StyledHeading } from './Styled';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div';
export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export interface HeadingProps extends RebassHeadingProps {
  /** Heading level, h1,h2,h3,h4,h5,h6 */
  variant?: HeadingVariant;
  tag?: HeadingTag;
  /** Standard children prop: accepts any valid React Node */
  children: React.ReactNode;
  /** Optional rebass sx styling object */
  sx?: CSSObject;
}

const Heading = ({ variant = 'h2', tag, children, ...rest }: HeadingProps) => {
  return (
    <StyledHeading as={tag || variant} variant={variant} {...rest}>
      {children}
    </StyledHeading>
  );
};

export default withErrorBoundary(Heading, { componentName: 'Heading' });
