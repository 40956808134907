import styled from 'styled-components';

import Cta from '../../../../components/Cta';

export const StyledDescription = styled.div`
  color: ${props => props.theme.carousel.text};
  padding-top: 9px;
  font-size: 16px;
  font-weight: medium;
  flex: 1;

  &:hover {
    text-decoration: none;
    font-weight: ${props => props.theme.carousel.fontWeight || 'normal'};
  }
`;

export const StyledImageWrapper = styled.div`
  height: 200px;
  height: 191px;
  box-shadow: ${props => props.theme.carousel.boxShadow};
  overflow: hidden;
`;

export const StyledLink = styled.div`
  ${props => props.theme.carousel.link};
  cursor: pointer;
  padding-top: 24px;
  opacity: 0.8;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTitle = styled.div`
  color: ${props => props.theme.text.heading.color ?? props.theme.colors.text};
  font-size: 21px;
  font-weight: 700;
  padding-top: 24px;
  font-family: ${props => props.theme.fonts.heading} !important;
  overflow: hidden;
  max-height: 4em;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledWrapper = styled(Cta)`
  background-color: white;
  display: flex !important;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  height: 100%;

  &:hover {
    text-decoration: none;
  }
`;
