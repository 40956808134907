/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import PageWrapper from './src/ui-lib/components/Layout/PageWrapper';

import './src/styles.css';

const theme = process.env.THEME_NAME;
const defaultLocale = process.env.DEFAULT_LOCALE;
const locales = process.env.ENABLED_LOCALES.split(',').map(locale => locale.trim());

/**
 * Implements Gatsby wrapPageElement(params).
 */
export const wrapPageElement = ({ element, props }) => {
  return (
    <PageWrapper {...props} theme={theme} defaultLocaleId={defaultLocale} enabledLocales={locales}>
      {element}
    </PageWrapper>
  );
};

/**
 * Implements Gatsbt onClientEntry().
 */
export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    // Handle initialization of 3rd parties like GTM
  }
};
