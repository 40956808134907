/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import Media from '../../../utils/Media';

type HeadingProps = {
  variant?: any;
};

export const StyledHeading = styled.h2<HeadingProps>`
  ${props =>
    props.theme.styles[props.variant]?.color
      ? props.theme.styles[props.variant]
      : {
          ...props.theme.styles[props.variant],
          color: props.theme.colors.text,
        }};
  ${props =>
    Media({
      fontSize: [
        props.theme.styles[props.variant].fontSize.mobile,
        props.theme.styles[props.variant].fontSize.tablet,
        null,
        props.theme.styles[props.variant].fontSize.desktop,
      ],
      lineHeight: [
        props.theme.styles[props.variant].lineHeight.mobile,
        props.theme.styles[props.variant].lineHeight.tablet,
        props.theme.styles[props.variant].lineHeight.desktop,
      ],
    })};
`;
