/* eslint-disable sort-keys-fix/sort-keys-fix */
import FooterLogo from './FooterLogo';
import Logo from './Logo';

// BASICS
export const baseColors = {
  accentBlue: '#34a7d4',
  accentGreen: '#16914f',
  accentTurquoise: '#009590',
  beige: '#F2F0E3',
  black: '#000',
  darkGreen: '#1A3D15',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  green: '#008411',
  lightGreen: '#7dc244',
  primaryBlue: '#0079b5',
  primaryGreen: '#a3bf3b',
  primarylightGreen: '#c3db9a',
  red: '#f00101',
  secondaryGreen: '#c2d975',
  white: '#fff',
  yellow: '#ffcc32',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  danger: baseColors.red,
  dark: baseColors.gray[4],
  darkGray: baseColors.gray[4],
  info: baseColors.primaryBlue,
  light: baseColors.gray[2],
  lightGray: baseColors.gray[2],
  muted: baseColors.gray[1],
  primary: baseColors.primaryGreen,
  primaryWhite: baseColors.white,
  secondary: baseColors.secondaryGreen,
  success: baseColors.green,
  superLightGrey: baseColors.gray[1],
  text: baseColors.gray[4],
  textMuted: baseColors.gray[3],
  warning: baseColors.yellow,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: "'Nunito Sans', sans-serif",
  heading: 'Nunito Sans, sans-serif',
  monospace: 'Menlo, monospace',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 400,
  headingAlt: 600,
  light: 300,
  normal: 400,
};

export const fontSizes = {
  0: '0.75rem', // 12
  1: '0.875rem', // 14
  2: '1rem', // 16
  3: '1.0625rem', // 17
  4: '1.125rem', // 18
  5: '1.25rem', // 20
  6: '1.375rem', // 22
  7: '1.5rem', // 24
  8: '1.6875rem', // 27
  9: '1.875rem', // 30
  10: '2rem', // 32
  11: '2.1875rem', // 35
  12: '2.5rem', // 40
  13: '2.5625rem', // 41
  14: '2.875rem', // 46
  15: '3.5rem', // 56
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  // container widths
  sm: '540px',
  md: '720px',
  lg: '1150px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  a: {
    color: colors.darkGray,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.primary,
    },
  },
  blockquote: {
    backgroundColor: colors.primaryBlue,
    color: colors.background,
    fontStyle: 'normal',
    fontWeight: 'bold',
    textTransform: 'normal',
  },
  h1: {
    ...heading,
    fontSize: { desktop: fontSizes[15], tablet: fontSizes[14], mobile: fontSizes[13] },
    lineHeight: { desktop: '4.0625rem', tablet: '3.125rem', mobile: '2.5rem' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: fontSizes[12], tablet: fontSizes[11], mobile: fontSizes[9] },
    lineHeight: { desktop: '2.6875rem', tablet: '2.375rem', mobile: '2.25rem' },
  },
  h3: {
    ...heading,
    fontSize: { desktop: fontSizes[10], tablet: fontSizes[8], mobile: fontSizes[6] },
    lineHeight: { desktop: '2.1875rem', tablet: '1.875rem', mobile: '1.625rem' },
  },
  h4: {
    ...heading,
    fontSize: { desktop: fontSizes[7], tablet: fontSizes[5], mobile: fontSizes[4] },
    lineHeight: { desktop: '1.5rem', tablet: '1.375rem', mobile: '1.375rem' },
  },
  h5: {
    ...heading,
    fontSize: { desktop: fontSizes[5], tablet: fontSizes[4], mobile: fontSizes[3] },
    lineHeight: { desktop: '1.25rem', tablet: '1.25rem', mobile: '1.25rem' },
  },
  highlight: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.darkGray,
  },
  li: {
    bulletBorderRadius: '10px',
    bulletColor: colors.secondaryGreen,
  },
  p: {
    fontSize: fontSizes[2],
    lineHeight: lineHeights.paragraph,
    margin: '0',
    marginBottom: '1.7rem',
  },
  rteHighlight: {
    hoverColor: colors.secondary,
    color: colors.primary,
    textDecoration: 'underline',
    isFooter: colors.white,
  },
  table: {
    // todo
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '3rem',
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  borderRadius: '24px',
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: fontSizes[0],
  fontWeight: fontWeights.bold,
  height: 'auto',
  minWidth: '194px',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.accentGreen,
      color: colors.white,
    },
  },
  'primary-with-background': {
    ...buttonDefaults,
    backgroundColor: colors.white,
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.primary,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: colors.white,
      borderStyle: 'solid',
      borderWidth: '1px',
      color: 'white',
    },
  },
  secondary: {
    ...buttonDefaults,
    backgroundColor: colors.accentGreen,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
  },
  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.muted,
    color: colors.textMuted,
    cursor: 'default',
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.primary,
};
export const articleDetail = {
  tags: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  title: colors.text,
  introduction: colors.text,
};
export const articleListing = {
  background: colors.primary,
  filter: colors.text,
  hover: colors.secondary,
  text: colors.white,
  title: colors.text,

  mobile: {
    title: colors.primary,
    text: colors.text,
    background: colors.primaryBlue,
  },
};
export const breadcrumb = {
  backgroundColor: colors.primarylightGreen,
};
export const cardCtas = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.darkGreen,
  color: colors.primary,
  fontSize: '0.75rem',
  hoverColor: colors.darkGreen,
  letterSpacing: '2px',
  lineHeight: '13px',
  textTransform: 'uppercase',
};
export const cards = {
  article: {
    title: colors.text,
    description: colors.text,
    link: {
      color: colors.primary,
      '&:hover': {
        color: colors.secondary,
        textDecoration: 'underline',
        opacity: 1,
      },
    },
  },
  logo: {
    color: colors.text,
  },
};
export const carousel = {
  controls: {
    regular: {
      backgroundColor: colors.primary,
      color: colors.white,
      hover: {
        backgroundColor: colors.secondary,
        color: colors.white,
      },
    },
    hero: {
      opacity: '0.8',
      backgroundColor: colors.white,
      color: colors.primary,
      hover: {
        backgroundColor: colors.white,
        color: colors.primary,
      },
    },
  },
  indicator: colors.secondary,
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.secondary,
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  text: colors.text,
};
export const categoryCards = {
  backgroundColor: colors.primary,
};
export const categorySection = {
  background: colors.primary,
  color: colors.white,
  date: colors.primary,
  dateColor: colors.primary,
  headingColor: colors.dark,
  boxShadow: null,
  chevron: {
    fill: colors.white,
    stroke: colors.primary,
  },
};
export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.text,
  infoBlock: {
    background: colors.primaryBlue,
    boxShadow: '3px 3px 3px #00000029',
    description: colors.white,
    title: colors.white,
    button: {
      backgroundColor: colors.primary,
      borderRadius: '24px',
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.secondary,
        textDecoration: 'none',
      },
    },
  },
};
export const contactForm = {
  heading: {
    color: colors.primary,
  },
};
export const contacts = {
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '0.875rem',
    mapTextFontWeight: 'normal',
    mapTextLetterSpacing: '2.33px',
    mapTextLineHeight: '1.71',
    mapTextTransform: 'uppercase',
    openingHoursFontSize: '1rem',
  },
  description: colors.text,
  title: colors.text,
};
export const cookieBanner = {
  backgroundColor: colors.primary,
};
export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  focusText: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
};
export const ctaGroup = {
  title: colors.text,
};
export const customBlockCards = {
  backgroundColor: colors.secondaryGreen,
  color: colors.primary,
};
export const dealer = {
  button: {
    backgroundColor: colors.primary,
    border: 'none',
    color: colors.white,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.darkGreen,
    },
    '&:disabled': {
      backgroundColor: 'lightgrey',
      cursor: 'not-allowed',
    },
  },
  textColor: colors.primary,
};
export const documentDownload = {
  arrowColor: colors.primary,
  arrowHoverColor: colors.darkGreen,
  color: colors.primary,
  hoverColor: colors.darkGreen,
};
export const footer = {
  activeColor: colors.secondaryGreen,
  backgroundColor: colors.white,
  color: colors.white,
  minHeight: '160px',
};
export const formInputs = {
  activeColor: colors.secondaryGreen,
  borderRadius: '24px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
  label: {
    fontSizes: ['12px'],
    lineHeights: ['21px'],
  },
  textareaPadding: '1rem 1.5rem',
  inputPadding: '0px 1.5rem',
};
export const header = {
  activeColor: colors.secondaryGreen,
  backgroundColor: colors.white,
  color: colors.darkGray,
};
export const headerBottom = {
  activeColor: colors.white,
  activeFontWeight: 'bold',
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: '12px',
  fontWeight: 'normal',
};
export const heroBannerButton = {
  'White Background': {
    '&:hover': {
      backgroundColor: colors.darkGreen,
      border: 'none',
      color: colors.white,
    },
    backgroundColor: colors.primary,
    borderRadius: '24px',
    color: colors.white,
    height: '42px',
    justifyContent: 'center',
    padding: '0px 12px',
  },
  'No Background': {
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.darkGreen,
    },
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderRadius: '24px',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
    height: '42px',
    justifyContent: 'center',
  },
  arrow: {
    display: 'none',
  },
};
export const heroSection = {
  titleFontWeight: 'normal',
};
export const imageAndText = {
  color: colors.primary,
};
export const infoBlock = {
  background: colors.beige,
  title: colors.text,
  description: colors.text,
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.secondary,
      textDecoration: 'none',
    },
  },
};
export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
  title: colors.white,
};
export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.secondary,
};
export const media = {
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};
export const mobileNavigation = {
  arrowColor: colors.secondaryGreen,
  chevronColor: colors.secondaryGreen,
};
export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '2px',
};
export const newsListing = {
  titleFontWeight: 'normal',
};
export const ownerDetail = {
  tags: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  title: colors.text,
  introduction: colors.text,
};
export const product = {
  backgroundColor: colors.primary,
  color: colors.white,
};
export const productListing = {
  titleFontWeight: 'normal',
};
export const salesPerson = {
  borderColor: colors.primary,
};
export const search = {
  activeColor: colors.secondaryGreen,
  borderRadius: '20px',
};
export const shortBanner = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleFontWeight: 'bold',
  titleTextTransform: 'none',
};
export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.secondary,
};
export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  breadcrumb,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  dealer,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  footerLogo: FooterLogo,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Hortiplan',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
