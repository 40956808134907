import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';

export const StyledSalesPersonInfoSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  ${Media({
    flexDirection: ['column', 'column', 'column'],
  })}
`;

export const StyledSalesPerson = styled.div`
  ${Media({
    flexDirection: ['row', 'row', 'column', 'column'],
    marginBottom: ['2rem', '3rem', '4rem'],
    marginRight: ['0px', '2rem', '1rem', '2rem'],
    width: ['100%', '100%', '200px', 'calc(50% - 2rem)'],
  })}
  display: flex;
  position: relative;
  align-items: flex-start;
`;

export const StyledSalesPersonImage = styled(Box)`
  border-radius: 128px;
  margin-bottom: 1.5rem;
  ${Media({
    height: ['110px', '128px'],
    marginRight: ['20px', '20px', '0px', null],
    minWidth: ['110px', 'none'],
    width: ['110px', '128px'],
  })}
`;

export const StyledSalesPersonCategory = styled(Text)`
  font-size: 14px;
  line-height: 2.14;
  padding-bottom: 2px;
  flex: 1;
`;

export const StyledBorder = styled.div`
  width: 64px;
  height: 1px;
  background-color: ${props => props.theme.salesPerson.borderColor};
  margin-bottom: 1rem;
`;

export const StyledSalesPersonName = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;
  font-weight: ${props => props.theme.fontWeights.headingAlt};
  color: ${props => props.theme.colors.text};
  margin-bottom: 6px;
`;

export const StyledSalesPersonTelephoneNumber = styled(Text)`
  font-size: 1rem;
  line-height: 1.5;
  word-break: break-all;
  color: ${props => props.theme.colors.text};
`;

export const StyledSalesPersonEmail = styled(Text)`
  font-size: 1rem;
  line-height: 1.5;
  word-break: break-all;
  color: ${props => props.theme.colors.text};
  margin-bottom: 5px;
`;
