import { TranslatedSlugContext, locales } from '@arvesta-websites/i18n';
import { ArrowDown, ArrowUp } from '@arvesta-websites/icons';
import { navigate } from 'gatsby-link';
import * as React from 'react';
import { ButtonProps } from 'rebass';

import { type LocaleType } from '../../../../../types';
import { withErrorBoundary } from '../../../ErrorBoundary';
import flags from '../../../Flags';
import { HEADERLIGHT_VARIANTS } from '../../HeaderLight/HeaderLight';

import {
  StyledChevron,
  StyledCountryId,
  StyledDropdown,
  StyledFlag,
  StyledHeader,
  StyledList,
  StyledListItem,
  StyledTopList,
  StyledWrapper,
} from './Styled';

interface SlugType {
  node_locale: string;
}

type HeaderVariant = keyof typeof HEADERLIGHT_VARIANTS;

interface LangSwitcherLightProps extends ButtonProps {
  /** Default locale */
  defaultLocale: string;
  /** List of available locales */
  activeLocales: Array<string>;
  /** Controls open behaviour */
  openUpwards?: boolean;
  variant?: HeaderVariant;
}

const renderListItem = (
  enabledLocales: Array<LocaleType>,
  handleLocaleChange: (locale: LocaleType) => void,
  variant?: HeaderVariant,
) =>
  enabledLocales.map((locale: LocaleType) => (
    <StyledListItem key={`item-${locale.id}`} onClick={() => handleLocaleChange(locale)}>
      <StyledFlag variant={variant}>{flags[locale.id] ? flags[locale.id] : null}</StyledFlag>
      <StyledCountryId>{locale.id}</StyledCountryId>
    </StyledListItem>
  ));

export const LangLightSwitcher = ({
  defaultLocale,
  activeLocales,
  openUpwards = false,
  variant,
}: LangSwitcherLightProps) => {
  const node = React.useRef();
  const enabledLocales: Array<LocaleType> = [];
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(defaultLocale);

  const translatedSlugs = React.useContext(TranslatedSlugContext);

  const handleClickOutside = (e: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (node.current?.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLocaleChange = (locale: any) => {
    setActive(locale.id);
    setOpen(false);
    if (typeof window !== 'undefined') {
      window.location.href = `/${locale.id}${locale.slug ? `/${locale.slug}` : ''}`;
    } else {
      navigate(`/${locale.id}${locale.slug ? `/${locale.slug}` : ''}`);
    }
  };

  React.useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const mergedRoutes = translatedSlugs.map((slug: SlugType) => {
    return {
      ...slug,
      ...locales[slug.node_locale],
    };
  });

  mergedRoutes.forEach(slug => {
    if (slug.node_locale !== active && activeLocales.includes(slug.node_locale)) {
      enabledLocales.push(slug);
    }
  });

  if (!activeLocales || activeLocales.length === 1) {
    return null;
  }

  return (
    <StyledWrapper variant={variant}>
      <StyledDropdown
        className={`${openUpwards ? 'upwards' : ''} ${open ? 'is-open' : 'is-closed'}`}
        ref={node}
        variant={variant}
      >
        {open && openUpwards ? (
          <StyledTopList variant={variant}>{renderListItem(enabledLocales, handleLocaleChange, variant)}</StyledTopList>
        ) : null}
        <StyledHeader onClick={() => setOpen(!open)} variant={variant}>
          <StyledFlag variant={variant}>{flags[active] ? flags[active] : null}</StyledFlag>
          <StyledCountryId>{active}</StyledCountryId>
          <StyledChevron variant={variant}>{open ? <ArrowUp /> : <ArrowDown />}</StyledChevron>
        </StyledHeader>
        {open && !openUpwards ? (
          <StyledList variant={variant}>{renderListItem(enabledLocales, handleLocaleChange, variant)}</StyledList>
        ) : null}
      </StyledDropdown>
    </StyledWrapper>
  );
};

export default withErrorBoundary(LangLightSwitcher, { componentName: 'LangLightSwitcher' });
