import { Radio, RadioChecked, RadioNested } from '@arvesta-websites/icons';
import React, { useState } from 'react';

import { withErrorBoundary } from '../../components';

import type { Category } from './ArticleListingModule';
import { StyledFilterItem, StyledPageExtra, StyledRadioWrapper } from './Styled';

type FilterIconProps = {
  activeCategoriesIdParent: string | null;
  itemId: string;
  isChildActive: boolean;
};

const FilterIcon = ({ activeCategoriesIdParent, itemId, isChildActive }: FilterIconProps) => {
  if (activeCategoriesIdParent === itemId && !isChildActive) {
    return <RadioChecked />;
  } else if (activeCategoriesIdParent === itemId && isChildActive) {
    return <RadioNested />;
  } else {
    return <Radio />;
  }
};

type SubFiltersProps = {
  category: Category;
  activeCategoriesId: { child: string | null; parent: string | null };
  handleClick: (item: Category) => void;
};

const getLastItem = (category: Category, index: number) => {
  if (!category.children) {
    return false;
  }

  return index === category.children.length - 1;
};

const SubFilters = ({ category, activeCategoriesId, handleClick }: SubFiltersProps) => {
  if (activeCategoriesId.parent !== category.id) {
    return null;
  }

  if (!category.children) {
    return null;
  }

  return category.children.map((child, index) => {
    if (child.articleCount === 0) {
      return null;
    }

    return (
      <StyledFilterItem lastItem={getLastItem(category, index)} key={child.id}>
        <li>
          <StyledRadioWrapper
            nested
            active={activeCategoriesId.child === child.id}
            onClick={e => {
              e.stopPropagation();
              handleClick(child);
            }}
          >
            {activeCategoriesId.child === child.id ? <RadioChecked /> : <Radio />}
            <span>{child.label}</span>
            <StyledPageExtra>({child.articleCount})</StyledPageExtra>
          </StyledRadioWrapper>
        </li>
      </StyledFilterItem>
    );
  });
};

type FiltersProps = {
  categories: Category[];
  activeCategoriesId: { child: string | null; parent: string | null };
  handleMenuClick: (item: Category) => void;
};

const Filters = ({ categories, activeCategoriesId, handleMenuClick }: FiltersProps) => {
  const [childActive, setChildActive] = useState(false);

  if (!categories) {
    return null;
  }

  return categories?.map(category => {
    if (category.articleCount === 0) {
      return null;
    }

    return (
      <StyledFilterItem key={category.id}>
        <li>
          <StyledRadioWrapper
            active={
              activeCategoriesId.parent === category.id || (!activeCategoriesId.child && !activeCategoriesId.parent)
            }
            onClick={() => {
              handleMenuClick(category);
              setChildActive(false);
            }}
          >
            <FilterIcon
              activeCategoriesIdParent={activeCategoriesId.parent}
              itemId={category.id}
              isChildActive={childActive}
            />
            <span>{category.label}</span>
            <StyledPageExtra>({category?.articleCount})</StyledPageExtra>
          </StyledRadioWrapper>
          <SubFilters
            category={category}
            activeCategoriesId={activeCategoriesId}
            handleClick={item => {
              handleMenuClick(item);
              setChildActive(true);
            }}
          />
        </li>
      </StyledFilterItem>
    );
  });
};

export default withErrorBoundary(Filters, { componentName: 'ArticleListingModule/Filters' });
