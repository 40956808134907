import { Cancel as CloseIcon, SearchIcon } from '@arvesta-websites/icons';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../ErrorBoundary';

export type MobileSearchProps = {
  searchFunction: (searchTerm: string) => void;
  onClose: () => void;
};

const mobileSearch = tv({
  slots: {
    closeButton: 'absolute right-2 transform scale-[0.85] top-2',
    searchButton: 'absolute left-2 top-2 last:cursor-pointer',
    searchIcon: 'absolute left-2 top-2',
    searchInput: 'bg-transparent border-none w-full h-10 py-0 pr-2.5 pl-[42px] text-[15px] text-normal outline-none',
    wrapper: 'w-full relative text-sm text-normal mr-2',
  },
  variants: {
    focused: {
      true: {
        wrapper: 'py-0 pr-7.5 pl-0',
      },
    },
  },
});

export const MobileSearch = ({ searchFunction, onClose }: MobileSearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [focused, setFocusedState] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const intl = useIntl();

  const { wrapper, searchButton, searchIcon, searchInput, closeButton } = mobileSearch({ focused });

  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  });

  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
  return (
    <div className={wrapper()}>
      <span
        onClick={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        role="button"
        tabIndex={0}
        className={searchButton()}
      >
        <SearchIcon className={searchIcon()} />
      </span>
      <input
        autoComplete="off"
        name="search"
        onBlur={() => {
          setFocusedState(false);
          onClose();
        }}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setSearchTerm(e.currentTarget.value);
        }}
        onFocus={() => setFocusedState(true)}
        onKeyDown={e => {
          if (e.keyCode === 27) {
            setSearchTerm('');
            onClose();
            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            searchFunction(searchTerm);
          }
        }}
        placeholder={intl.formatMessage({ id: 'header.search.placeholder' })}
        ref={inputRef}
        type="search"
        className={searchInput()}
      />
      <span
        onClick={() => {
          setSearchTerm('');
          if (inputRef.current) {
            inputRef.current.value = '';
          }
          onClose();
        }}
        onKeyDown={() => {
          searchFunction(searchTerm);
          onClose();
        }}
        role="button"
        tabIndex={0}
        className={closeButton()}
      >
        <CloseIcon />
      </span>
    </div>
  );
};

export default withErrorBoundary(MobileSearch, { componentName: 'MobileSearch' });
