import React from 'react';

const Rectangle = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.03 500">
    <title>rectangle</title>
    <path
      d="M783.33,150H0V645c0,2.4,0,3.78,0,5,4.84,0,14.27-.07,16.67,0H783.33l10.48,0H800V633.33c0-100.45-.17-318.74,0-478.1,0-1.39,0-3.95,0-5.23C795.46,150,785.32,150.05,783.33,150Z"
      transform="translate(0.02 -150)"
    />
    <path d="M0,633.33V650" transform="translate(0.02 -150)" />
    <path d="M807,162.24" transform="translate(0.02 -150)" />
  </svg>
);

export default Rectangle;
