import type { FormSubmitResponseType } from '../../../../types';
import { Config } from '../../../../utils';
import { ContactsFormDefault, withErrorBoundary } from '../../../components';

import {
  StyledContainer,
  StyledFormContainer,
  StyledHeaderWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

export type ContactsModuleDefaultProps = {
  contactsShortDescription: string;
  contactsTitle: string;
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => FormSubmitResponseType;
  shortDescription: string;
  showContactForm?: boolean;
  showContactsFormSubscribeOptions: boolean;
  title: string;
  basicPageTitle?: string;
};

const ContactsModuleDefault = ({
  contactsShortDescription,
  contactsTitle,
  handleFormSubmit,
  shortDescription,
  showContactForm = true,
  showContactsFormSubscribeOptions = true,
  title,
  basicPageTitle,
}: ContactsModuleDefaultProps) => (
  <StyledContainer>
    <StyledHeaderWrapper>
      <StyledModuleHeading>{title}</StyledModuleHeading>
      <StyledModuleDescription>{shortDescription}</StyledModuleDescription>
    </StyledHeaderWrapper>

    {showContactForm && (
      <StyledFormContainer>
        <ContactsFormDefault
          handleFormSubmit={handleFormSubmit}
          shortDescription={contactsShortDescription}
          showSubscribeOptions={showContactsFormSubscribeOptions}
          title={contactsTitle}
          basicPageTitle={basicPageTitle}
          NETLIFY_FORM_NAME={Config.NETLIFY_CONTACT_FORM_NAME || 'contact-storybook'}
        />
      </StyledFormContainer>
    )}
  </StyledContainer>
);

export default withErrorBoundary(ContactsModuleDefault, { componentName: 'ContactsModuleDefault' });
