import { ChevronRight } from '@arvesta-websites/icons';
import { useEffect, useRef, useState } from 'react';
import { BoxProps } from 'rebass';
import { tv } from 'tailwind-variants';

import { LocaleType, SubnavItem } from '../../../../../types';
import Utils from '../../../../../utils';
import { withErrorBoundary } from '../../../ErrorBoundary';

export interface SecondaryNavigationDefaultProps extends BoxProps {
  items?: SubnavItem[];
  locale: LocaleType;
}

const secondaryNavigation = tv({
  slots: {
    chevronRight: 'w-3 h-3 ml-5 stroke-secondaryNavigation',
    navItem:
      'list-none navItem flex align-center items-center justify-between mb-3.5 cursor-pointer w-[264px] whitespace-nowrap no-underline text-content hover:font-bold',
    navigation: 'flex list-none gap-5 ms-0 me-0 ps-10 pb-4',
    verticalDivider: 'w-px h-full bg-header-line opacity-secondary-navigation-divider-opacity',
    verticalSpace: 'h-2.5',
  },
  variants: {
    bold: {
      true: {
        navItem: 'font-bold',
      },
    },
  },
});

const SecondaryNavigationDefault = ({ items, locale: { path: locale } }: SecondaryNavigationDefaultProps) => {
  const [secondRowItems, setSecondRowItems] = useState<SubnavItem | null>(null);
  const [thirdRowItems, setThirdRowItems] = useState<SubnavItem | null>(null);

  const closeTimer = useRef<number | null>(null);

  useEffect(() => {
    if (items) {
      setThirdRowItems(null);
      setSecondRowItems(null);
    }
  }, [items]);

  const handleOnMouseLeave = () => {
    if (closeTimer.current) {
      window.clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
  };

  const handleOnMouseEnter = (callback: () => void) => {
    closeTimer.current = window.setTimeout(() => {
      callback();
    }, 300);
  };

  const { navigation, verticalSpace, navItem, chevronRight, verticalDivider } = secondaryNavigation();

  return (
    <ul className={navigation()}>
      <div>
        {items?.map((item, index) => (
          <>
            {item.isDivided && <div className={verticalSpace()}></div>}
            {!!item.label && (
              <a
                href={Utils.getIntlSlug(item.externalUrl || item.to)}
                key={`${item.externalUrl || item.to}-${index}`}
                onMouseEnter={() => {
                  handleOnMouseEnter(() => {
                    if (item?.children?.length) {
                      setSecondRowItems(item);
                      setThirdRowItems(null);
                      return;
                    }
                    setSecondRowItems(null);
                  });
                }}
                onMouseLeave={() => {
                  handleOnMouseLeave();
                }}
                className={navItem({ bold: secondRowItems?.id === item.id })}
              >
                {item.label}
                {item.children?.length ? <ChevronRight className={chevronRight()} /> : ''}
              </a>
            )}
          </>
        ))}
      </div>
      {!!secondRowItems?.children?.length && (
        <>
          <div className={verticalDivider()}></div>
          <div>
            {secondRowItems.children.map((item: SubnavItem) => (
              <>
                {item.isDivided && <div className={verticalSpace()}></div>}
                {!!item.label && (
                  <a
                    href={Utils.getIntlSlug(item.to, locale)}
                    key={item.externalUrl || item.to}
                    onMouseEnter={() => {
                      handleOnMouseEnter(() => {
                        if (item?.children?.length) {
                          setThirdRowItems(item);
                          return;
                        }
                        setThirdRowItems(null);
                      });
                    }}
                    onMouseLeave={() => {
                      handleOnMouseLeave();
                    }}
                    className={navItem({ bold: thirdRowItems?.id === item.id })}
                  >
                    {item.label}
                    {item.children?.length ? <ChevronRight className={chevronRight()} /> : ''}
                  </a>
                )}
              </>
            ))}
          </div>
        </>
      )}
      {!!thirdRowItems?.children?.length && (
        <>
          <div className={verticalDivider()}></div>
          <div>
            {thirdRowItems.children.map((item: SubnavItem) => (
              <>
                {item.isDivided && <div className={verticalSpace()}></div>}
                {!!item.label && (
                  <a
                    key={item.externalUrl || item.to}
                    href={Utils.getIntlSlug(item.externalUrl || item.to)}
                    className={navItem()}
                  >
                    {item.label}
                    {item.children?.length ? <ChevronRight className={chevronRight()} /> : ''}
                  </a>
                )}
              </>
            ))}
          </div>
        </>
      )}
    </ul>
  );
};

export default withErrorBoundary(SecondaryNavigationDefault, { componentName: 'SecondaryNavigationDefault' });
