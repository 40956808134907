import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon } from '@arvesta-websites/icons';
import { useEffect, useState } from 'react';

import type { NavItemType } from '../../../../../types';
import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';

import { StyledArrow, StyledNavItem, StyledNavigation } from './Styled';

type PrimaryNavigationLightProps = {
  items: Array<NavItemType>;
  currentItem: NavItemType | null;
  activeMenuItem: NavItemType | null;
  handleClickMenuItem: (item: NavItemType) => void;
  variant?: 'default' | 'display search & language selector in separate bar' | 'display top-level navigation';
};

const PrimaryNavigationLight = ({
  items,
  activeMenuItem,
  currentItem,
  handleClickMenuItem,
  variant,
}: PrimaryNavigationLightProps) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
  }, [currentItem]);

  return (
    <StyledNavigation variant={variant}>
      {items.map((item: NavItemType, idx: number) => {
        return (
          <StyledNavItem
            isActive={activeMenuItem?.to === item.to}
            isOpen={currentItem?.to === item.to}
            key={`primary-active-${item.to}-${idx}`}
            onFocus={() => handleClickMenuItem(item)}
            variant={variant}
          >
            <Cta
              onClick={e => {
                if (!clicked && currentItem?.to === item.to) {
                  e.preventDefault();
                  setClicked(true);
                }
                handleClickMenuItem(item);
              }}
              to={item.to}
            >
              {item.label}
            </Cta>
            {!!item.children?.length && (
              <StyledArrow>
                <span
                  onClick={e => {
                    if (!clicked && currentItem && currentItem.to === item.to) {
                      e.preventDefault();
                      setClicked(true);
                    }
                    handleClickMenuItem(item);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {clicked && currentItem && currentItem.to === item.to ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </span>
              </StyledArrow>
            )}
          </StyledNavItem>
        );
      })}
    </StyledNavigation>
  );
};

export default withErrorBoundary(PrimaryNavigationLight, { componentName: 'PrimaryNavigationLight' });
