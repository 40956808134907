import styled from 'styled-components';

export const StyledNavigation = styled.ul`
  margin: 0 auto;
  padding-left: 0;
  list-style-type: none;
  display: inline-block;
`;

export const StyledNavItem = styled.li`
  list-style: none;
  display: inline-block;
  padding: 0px 24px;

  &.active {
    a {
      color: ${props => props.theme.headerBottom.activeColor};
      font-weight: ${props => props.theme.headerBottom.activeFontWeight};
    }
  }

  a {
    display: inline-block;
    white-space: nowrap;
    padding: 32px 0px;
    text-transform: ${props => props.theme.navItems.textTransform};
    text-decoration: none;
    font-size: ${props => props.theme.headerBottom.fontSize};
    font-weight: ${props => props.theme.headerBottom.fontWeight};
    line-height: 2;
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    color: ${props => props.theme.headerBottom.color};
    &:hover {
      color: ${props => props.theme.headerBottom.activeColor};
      font-weight: ${props => props.theme.headerBottom.activeFontWeight};
    }
  }
`;
