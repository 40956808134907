import * as React from 'react';

const Twitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24">
    <path
      fill="#3D3D3D"
      d="M25.546 5.967c.018.261.018.523.018.785 0 7.98-5.872 17.175-16.603 17.175A16.09 16.09 0 010 21.217c.47.056.921.075 1.41.075 2.727 0 5.238-.953 7.244-2.58-2.566-.055-4.716-1.793-5.456-4.186.361.056.722.094 1.102.094.524 0 1.048-.075 1.535-.206-2.673-.56-4.679-2.99-4.679-5.924v-.075a5.72 5.72 0 002.638.766C2.222 8.097 1.192 6.247 1.192 4.154c0-1.122.29-2.15.795-3.047C4.86 4.77 9.177 7.163 14.02 7.424a7.054 7.054 0 01-.144-1.383c0-3.326 2.602-6.036 5.835-6.036 1.68 0 3.198.729 4.264 1.906A11.255 11.255 0 0027.679.453a5.986 5.986 0 01-2.566 3.327 11.36 11.36 0 003.36-.934 12.775 12.775 0 01-2.926 3.12z"
    />
  </svg>
);

export default Twitter;
