import { Box } from 'rebass';
import styled from 'styled-components';

import Media from '../../../utils/Media';

interface IExpandedStyled {
  active?: boolean;
  isHero?: boolean;
  isInfinite?: boolean;
  variant?: 'hero' | 'regular';
  side?: 'left' | 'right';
  maskPresent?: boolean;
}

export const CtaGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${Media({
    margin: ['2rem 0rem', null, '2rem 15%', '2rem 20%'],
  })}
`;

export const StyledCta = styled(Box)`
  > a {
    text-decoration: none;
  }

  > a > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${({ theme }) => theme.ctaGroup.borderRadius};

    ${Media({
      fontSize: ['16px', null, '21px', '21px'],
      lineHeight: ['20px', null, '27px', '34px'],
      padding: ['0.75rem 1.5rem', null, '0.75rem 3rem'],
    })}

    &:hover {
      ${Media({
        fontSize: ['17px', null, '22px', '22px'],
        paddingRight: ['1.125rem', null, '2.625rem'],
      })}
    }
  }

  > a > svg {
    flex-shrink: 0;
  }
`;

export const StyledTitle = styled.p`
  color: ${({ theme }) => theme.ctaGroup?.title ?? theme.colors.text};
  text-align: center;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: ${props => props.theme.ctaGroup.titleFontWeight || 'bold'};
  line-height: 1.2;
  ${Media({
    fontSize: ['24px', '24px', '32px', '40px'],
  })}
`;

export const StyledControl = styled.button<IExpandedStyled>`
  opacity: ${({ theme }) => theme.ctaGroup?.chevron?.opacity ?? 1};

  > svg {
    & .iconCircle {
      fill: ${({ theme }) => theme.ctaGroup?.chevron?.fill ?? theme.colors.white};
    }

    & .iconArrow {
      stroke: ${({ theme }) => theme.ctaGroup?.chevron?.stroke ?? theme.colors.primary};
    }
  }
`;
