import { IntlContext } from '@arvesta-websites/i18n';
import React, { useContext, useReducer } from 'react';

import { handleNewsletterFormSubmission } from '../../../utils';

import NewsLetter, { NewsLetterProps } from './NewsLetter';
import { createNewsletterFormSubmitHandler, newsletterInitialState, newsletterReducer } from './utils';

const NewsletterContainer = (props: NewsLetterProps): JSX.Element => {
  const locale = useContext(IntlContext);

  const { submitHandler, state, setChecked, setEmail, ...newsletter } = props;

  const [newsletterState, dispatch] = useReducer(newsletterReducer, newsletterInitialState);
  const onSubscribeFormSubmit = createNewsletterFormSubmitHandler(
    newsletterState,
    dispatch,
    handleNewsletterFormSubmission,
    locale,
    newsletter,
  );

  return (
    <NewsLetter
      {...newsletter}
      state={newsletterState}
      setChecked={(value: boolean) => dispatch({ payload: value, type: 'SET_CHECKED' })}
      setEmail={(value: string) => dispatch({ payload: value, type: 'SET_EMAIL' })}
      submitHandler={onSubscribeFormSubmit}
    />
  );
};

export default NewsletterContainer;
