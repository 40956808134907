import { CardProps } from 'rebass';

import { withErrorBoundary } from '../../ErrorBoundary';

import HorizontalEventCard from './HorizontalEventCard';
import { StyledCard } from './Styled';
import VerticalEventCard from './VerticalEventCard';

export interface EventCardProps extends CardProps {
  /** product id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: string;
  /** card cta */
  link: string;
  /** event display date */
  displayDate?: string;
  /** event location */
  location?: string;
  /** Card variant, vertical or horizontal */
  variant?: 'vertical' | 'horizontal';
}

const EventCard = ({
  id,
  title,
  shortDescription,
  image,
  link,
  displayDate,
  variant = 'vertical',
  location,
  ...rest
}: EventCardProps) => {
  const cardProps = {
    displayDate,
    id,
    image,
    link,
    location,
    shortDescription,
    title,
    variant,
  };

  return (
    <StyledCard {...rest} className={variant}>
      {variant === 'vertical' ? <VerticalEventCard {...cardProps} /> : <HorizontalEventCard {...cardProps} />}
    </StyledCard>
  );
};

export default withErrorBoundary(EventCard, { componentName: 'EventCard' });
