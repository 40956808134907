import styled, { css } from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

interface IExpandedStyled {
  active?: boolean;
  isHero?: boolean;
  isInfinite?: boolean;
  variant?: 'hero' | 'regular';
  side?: 'left' | 'right';
  maskPresent?: boolean;
  isCustomHeroOverlay?: boolean;
  controlHeight?: string;
}

const heroIndicatorStyles = css<IExpandedStyled>`
  border: 1px solid white;
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  width: 18px;
  height: 18px;
`;

export const StyledCardWrapper = styled.div<IExpandedStyled>`
  padding: 18px;
  max-width: 375px;
  width: 100%;
  height: 100%;
  display: flex !important;

  @media (max-width: 430px) {
    padding: 10px;
  }
`;

type StyledCarouselWrapperProps = {
  isHero: boolean;
  isInfinite: boolean;
  windowWidth: number;
  maskPresent?: boolean;
  desktopMask?: string;
  tabletMask?: string;
  mobileMask?: string;
  height?: string;
};

const setJustifyContent = (isInfinite: boolean, windowWidth: number) => {
  if (isInfinite) {
    return 'space-between';
  }

  if (!isInfinite && windowWidth < 1350) {
    return 'space-between';
  }

  return 'center';
};
export const StyledCarouselWrapper = styled.div<StyledCarouselWrapperProps>`
  position: relative;
  gap: ${props => (props.isHero ? '0' : '50')};
  align-items: center;
  justify-content: ${props => setJustifyContent(props.isInfinite, props.windowWidth)};
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const heroControlStyles = css`
  position: absolute;
  z-index: 100;
  background-color: transparent;
  padding: 10px;
  margin: 0;
  top: calc(50% - 32px);

  @media (max-width: 1470px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const StyledControl = styled.button<IExpandedStyled>`
  cursor: pointer;
  border-radius: 50%;
  min-width: 32px;
  min-height: 32px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  opacity: ${({ theme, variant = 'regular' }) => theme.carousel?.controls[variant]?.opacity ?? 1};
  ${({ variant }) => variant === 'hero' && heroControlStyles}

  ${({ maskPresent, variant, theme }) =>
    maskPresent &&
    variant === 'hero' &&
    Media({
      top: maskPresent && theme.carousel?.controls?.hero?.maskArrowOffsets?.top,
    })}

  ${({ variant, side, maskPresent, theme }) =>
    variant === 'hero' &&
    side === 'left' &&
    Media({
      left: (maskPresent && theme.carousel?.controls?.hero?.maskArrowOffsets?.horizontal) || ['', '0', '0', '40px'],
    })}

  ${({ variant, side, maskPresent, theme }) =>
    variant === 'hero' &&
    side === 'right' &&
    Media({
      right: (maskPresent && theme.carousel?.controls?.hero?.maskArrowOffsets?.horizontal) || ['', '0', '0', '40px'],
    })}

  > svg {
    pointer-events: none;
    width: 40px;
    stroke: ${({ theme, variant = 'regular' }) =>
      variant === 'hero' && (theme.carousel?.controls?.hero?.stroke ?? 'white')};

    & .iconCircle {
      fill: ${({ theme, variant = 'regular' }) => theme.carousel?.controls[variant]?.backgroundColor};
    }

    & .iconArrow {
      stroke: ${({ theme, variant = 'regular' }) => theme.carousel?.controls[variant]?.color};
    }
  }

  &:hover {
    opacity: 1;
    > svg {
      stroke: ${({ theme, variant = 'regular' }) =>
        variant === 'hero' && (theme.carousel?.controls?.hero?.hover?.stroke ?? 'white')};
      & .iconCircle {
        fill: ${({ theme, variant = 'regular' }) => theme.carousel?.controls[variant]?.hover?.backgroundColor};
      }

      & .iconArrow {
        stroke: ${({ theme, variant = 'regular' }) => theme.carousel?.controls[variant]?.hover?.color};
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledIndicator = styled.div<IExpandedStyled>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.carousel.indicator};
  cursor: pointer;
  text-decoration: none;
  background-color: ${props => (props.active ? props.theme.carousel.indicator : 'transparent')};
  ${({ isHero }) => isHero && heroIndicatorStyles}
`;

export const StyledIndicatorWrapper = styled.div<IExpandedStyled>`
  padding-bottom: ${props => (props.isHero ? '50px !important' : '0px')};
  ${({ isHero, maskPresent }) => isHero && maskPresent && 'z-index: 100'};

  ${props => props.controlHeight && `margin-bottom: ${props.controlHeight} !important;`}
`;

export const StyledTitle = styled(Heading)`
  font-weight: '400';
  text-align: center;
`;

type HeroContentWrapperProps = IExpandedStyled & {
  maskPresent?: boolean;
  customMaskPresent?: boolean;
  desktopMask?: string;
  tabletMask?: string;
  mobileMask?: string;
  height?: string;
};

export const StyledHeroContentWrapper = styled.div<HeroContentWrapperProps>`
  position: relative;
  overflow: hidden;

  ${({ customMaskPresent, maskPresent, desktopMask, tabletMask, mobileMask, height }) => {
    const getMaskImage = (mask: string | undefined) => (mask ? `url("${mask}")` : 'none');
    const getHeroHeight = (height: string | undefined) => (height === 'Large' ? '420px' : '327px');

    const commonMaskStyles = {
      height: getHeroHeight(height),
      maskImage: [getMaskImage(mobileMask), null, getMaskImage(tabletMask), getMaskImage(desktopMask)],
    };

    return Media({
      ...commonMaskStyles,

      maskPosition: 'bottom',
      maskRepeat: 'no-repeat',
      maskSize: 'cover',
    });
  }}
`;

//Temporary solution for Proxani - TWDS-442
export const StyledSimpleHeroWrapper = styled.div<{ height?: string }>`
  position: relative;
  overflow: hidden;

  ${({ height }) =>
    Media({
      height: height === 'Large' ? '420px' : '327px',
    })}
`;

export const StyledWrapper = styled.div<IExpandedStyled>`
  width: 100%;

  @media (min-width: 420px) {
    padding: ${props => (!props.isHero ? '16px 0' : '0')};
  }
  @media (min-width: 600px) {
    padding: ${props => (!props.isHero ? '16px 0' : '0')};
  }
  @media (min-width: 1025px) {
    padding: ${props => (!props.isHero ? '16px 0' : '0')};
  }
  @media (min-width: 1440px) {
    padding: ${props => (!props.isHero ? '39px 0' : '0')};
  }
`;
