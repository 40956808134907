import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Heading } from '../../components';

export const InfoBlockWrapper = styled(Flex)`
  flex: 1 0 0%;
  margin-bottom: 20px;

  ${props =>
    Media({
      flex: [null, null, '1 0 33.33%', '1 0 0%'],
      flexBasis: ['100%', null, null, null],
    })}
`;

export const StyledCta = styled(Box)`
  > a > button {
    ${Media({
      marginTop: ['15px', null, '30px', '40px'],
      width: ['100%', null, 'initial'],
    })}
  }
`;

export const StyledColoredBox = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.columns?.infoBlock.background};
  padding: 36px 30px;
  flex-basis: 100%;
  box-shadow: ${props => props.theme.columns?.infoBlock.boxShadow};
`;

export const StyledDescription = styled(Box)`
  color: ${({ theme }) => theme.columns?.infoBlock?.description};
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledTitleColored = styled(Heading)`
  && {
    color: ${({ theme }) => theme.columns?.infoBlock.title};
    font-weight: ${({ theme }) => theme.infoBlock.titleFontWeight || 'bold'};
    margin-bottom: 24px !important;
    line-height: 1.2;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;

  ${Media({
    margin: ['20px 0', null, '36px 0', '57px 0'],
  })}
`;
