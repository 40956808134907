import styled from 'styled-components';

import Media from '../../utils/Media';

export const StyledModuleContainer = styled.div<StyledModuleContainer>`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginTop: ['56px', null, null, '81px'],
  })}

  ${props =>
    props.extraSmallTopSpacer &&
    `
    margin-top: 1.7rem !important;
  `}

  ${props =>
    props.smallTopSpacer &&
    `
    margin-top: 56px !important;
  `}
`;

interface StyledModuleContainer {
  smallTopSpacer?: boolean;
  extraSmallTopSpacer?: boolean;
}

export const StyledModuleCtaContainer = styled.div`
  ${Media({
    marginTop: ['23px', null, null, '39px'],
  })}
`;

export const StyledModuleContainerInfoVariant = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginTop: ['56px', null, null, '81px'],
  })}
`;
