import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { EventType, FieldType, FormDataTypeOptions } from '../../../../types';
import { withErrorBoundary } from '../../ErrorBoundary';
import InputField from '../InputField';
import type { Option } from '../MultiSelect';
import SelectField from '../SelectField';

export interface OptionalDropdownProps {
  contentfulFieldData: any;
  handleDropdownChange: (value: string) => void;
  handleOtherChange: (e: EventType, name: FormDataTypeOptions) => void;
  selectedOption: string;
  dropdownField: FieldType;
  otherField: FieldType;
}
const OptionalDropdown = ({
  contentfulFieldData,
  handleDropdownChange,
  handleOtherChange,
  selectedOption,
  dropdownField,
  otherField,
}: OptionalDropdownProps) => {
  const intl = useIntl();
  const options: Option[] = useMemo(() => {
    const { dropdownOptions, addOtherOptionToDrowdown } = contentfulFieldData;
    if (!dropdownOptions?.length) return [];
    const options = dropdownOptions.map((option: string) => ({ label: option, value: option }));
    if (addOtherOptionToDrowdown)
      options.push({
        label: intl.formatMessage({ id: 'forms.contact.other' }),
        value: intl.formatMessage({ id: 'forms.contact.other' }),
      });
    return options;
  }, [contentfulFieldData, intl]);

  return (
    <>
      <SelectField
        options={options}
        label={contentfulFieldData.fieldLabel}
        onSelect={(option: { label: string; value: string }) => {
          handleDropdownChange(option.value);
        }}
        contactForm
        field={dropdownField}
      />
      {selectedOption === `${intl.formatMessage({ id: 'forms.contact.other' })}` && (
        <InputField
          field={otherField}
          handleChange={handleOtherChange}
          label={intl.formatMessage({ id: 'forms.contact.other' })}
        />
      )}
    </>
  );
};

export default withErrorBoundary(OptionalDropdown, { componentName: 'OptionalDropdown' });
