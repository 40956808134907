import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import { Cta } from '../../components';
import { StyledText } from '../../components/RichText/Styled';

interface FlexProps {
  items?: number;
  children?: React.ReactNode;
}

export const FlexItem: React.FC<FlexProps> = styled(Flex)<FlexProps>`
  flex: ${props => (props.items && props.items > 3 ? '1' : '1 0 0%')};
  margin-bottom: 20px;

  ${props =>
    Media({
      flex: [
        null,
        null,
        props.items && props.items > 3 ? '1' : '1 0 33.33%',
        props.items && props.items > 3 ? '1' : '1 0 0%',
      ],
      flexBasis: ['100%', null, null, null],
    })}
`;

export const StyledCta = styled(Box)`
  > a > button {
    ${Media({
      marginTop: ['15px', null, '30px', '40px'],
      width: ['100%', null, 'initial'],
    })}
  }
`;

export const StyledColoredBox = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.columns?.infoBlock.background};
  padding: 36px 30px;
  width: 100%;
  box-shadow: ${props => props.theme.columns?.infoBlock.boxShadow};
`;

export const StyledCTA = styled(Cta)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
    font-weight: normal;

    path {
      fill: white;
      opacity: 1;
    }

    .bottomCardImage {
      transform: scale(1.05);
    }
    .bottomCardGradient {
      height: 80%;
    }
  }
`;

export const StyledDescription = styled(Box)`
  color: ${({ theme }) => theme.columns?.imageCard};
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
`;
export const StyledDescriptionColored = styled(Box)`
  color: ${({ theme }) => theme.columns?.infoBlock.description};
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledRTE = styled(StyledText)`
  margin: 0;
`;

export const StyledTitle = styled(Box)`
  color: ${({ theme }) => theme.columns?.imageCardTitle};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 4px;
  padding-top: 20px;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledTitleColored = styled(Box)`
  color: ${({ theme }) => theme.columns?.infoBlock.title};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 24px;
  font-weight: ${({ theme }) => theme.columns.infoBlock.titleFontWeight || 'bold'};
  margin-bottom: 24px !important;
  line-height: 1.2;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;

  ${Media({
    margin: ['20px 0', null, '36px 0', '57px 0'],
  })}
`;
