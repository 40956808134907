import { useIntl } from 'react-intl';

import type { FormSubmitResponseType } from '../../../../types';
import { Config } from '../../../../utils';
import { ContactsFormLight, RichText, withErrorBoundary } from '../../../components';
import Office from '../Office';
import SalesPerson, { type SalesPersonType } from '../SalesPerson';

import {
  StyledContactsInforamtionSection,
  StyledContainer,
  StyledForm,
  StyledFormContainer,
  StyledInfoContainer,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledOfficeContainer,
  StyledRectangle,
  StyledRectangleTablet,
  StyledSalesTeamList,
  StyledSalesTeamTitle,
} from './Styled';

type OfficeType = {
  id: string;
  title: string;
  address: string;
  mapUrl: string;
  telephone?: string;
  openingHours?: string;
};

export type ContactsModuleLightProps = {
  title: string;
  shortDescription: string;
  offices: Array<OfficeType>;
  contactsSection: any;
  salesTeam: Array<SalesPersonType>;
  contactsTitle: string;
  showContactForm?: boolean;
  contactsShortDescription: string;
  showContactsFormSubscribeOptions: boolean;
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => FormSubmitResponseType;
};

const ContactsModuleLight = ({
  title,
  shortDescription,
  offices,
  contactsSection,
  salesTeam,
  contactsTitle,
  showContactForm = true,
  contactsShortDescription,
  showContactsFormSubscribeOptions = true,
  handleFormSubmit,
}: ContactsModuleLightProps) => {
  const intl = useIntl();

  return (
    <StyledContainer>
      {showContactForm && <StyledRectangle />}
      <StyledInfoContainer>
        <StyledModuleHeading>{title}</StyledModuleHeading>
        <StyledModuleDescription>{shortDescription}</StyledModuleDescription>
        <StyledOfficeContainer>
          {offices.map(office => (
            <Office key={office.id} {...office} />
          ))}
        </StyledOfficeContainer>
        <StyledContactsInforamtionSection>
          <RichText data={contactsSection.json} />
        </StyledContactsInforamtionSection>
        {salesTeam && !!salesTeam.length && (
          <>
            <StyledSalesTeamTitle>{intl.formatMessage({ id: 'sections.contact.salesteam' })}</StyledSalesTeamTitle>
            <StyledSalesTeamList>
              {salesTeam.map(person => (
                <SalesPerson key={person.id} {...person} />
              ))}
            </StyledSalesTeamList>
          </>
        )}
      </StyledInfoContainer>
      {showContactForm && (
        <StyledFormContainer>
          <StyledRectangleTablet />
          <StyledForm>
            <ContactsFormLight
              handleFormSubmit={handleFormSubmit}
              shortDescription={contactsShortDescription}
              showSubscribeOptions={showContactsFormSubscribeOptions}
              title={contactsTitle}
              NETLIFY_FORM_NAME={Config.NETLIFY_CONTACT_FORM_NAME || 'contact-storybook'}
            />
          </StyledForm>
        </StyledFormContainer>
      )}
    </StyledContainer>
  );
};

export default withErrorBoundary(ContactsModuleLight, { componentName: 'ContactsModuleLight' });
