import { type IGatsbyImageData } from 'gatsby-plugin-image';

import { withErrorBoundary } from '../../components';

import BottomCard from './BottomCard/BottomCard';
import { BottomCards, ContentWrapper, StyledTitle, TopCards } from './Styled';
import TopCard from './TopCard/TopCard';

export type FeaturesSectionLink = {
  cardLink: {
    externalUrl?: string;
    internalEntry: {
      slug: string;
      title: string;
    };
  };
  title: string;
  inColumn?: boolean;
  id: string;
  image: {
    altText?: string;
    title: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

type FeaturesSectionModuleInterface = {
  title: string;
  primaryLinks: FeaturesSectionLink[];
  secondaryLinks: FeaturesSectionLink[];
};

const FeaturesSectionModule = ({ title, primaryLinks, secondaryLinks }: FeaturesSectionModuleInterface) => {
  const topItems = primaryLinks?.slice(0, 3);
  const bottomItems = secondaryLinks?.slice(0, 2);

  if (!topItems?.length && !bottomItems?.length) return null;

  return (
    <ContentWrapper flexDirection="column" alignContent="center">
      {title && <StyledTitle>{title}</StyledTitle>}
      {!!topItems?.length && (
        <TopCards>
          {topItems.map(item => (
            <TopCard item={item} key={item.id}></TopCard>
          ))}
        </TopCards>
      )}
      {!!bottomItems?.length && (
        <BottomCards>
          {bottomItems.map(item => (
            <BottomCard item={item} key={item.id} />
          ))}
        </BottomCards>
      )}
    </ContentWrapper>
  );
};

export default withErrorBoundary(FeaturesSectionModule, { componentName: 'FeaturesSectionModule' });
