import React from 'react';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="116"
    height="72"
    viewBox="0 0 116 72"
  >
    <defs>
      <path id="a" d="M0 .1h115.515v42.918H0z" />
      <path id="c" d="M0 .1h115.515v42.918H0z" />
      <path id="e" d="M0 71.287h115.515V.178H0z" />
      <path id="g" d="M0 .178h115.515v23.897H0z" />
      <path id="i" d="M0 .51h115.515v23.897H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 5.526)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FEFEFE"
          d="M0 24.035C0 34.52 25.857 43.018 57.758 43.018c31.9 0 57.757-8.497 57.757-18.983v-4.95C115.515 8.6 89.658.1 57.758.1 25.857.1 0 8.599 0 19.085v4.95z"
          mask="url(#b)"
        />
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M0 24.035C0 34.52 25.857 43.018 57.758 43.018c31.9 0 57.757-8.497 57.757-18.983v-4.95C115.515 8.6 89.658.1 57.758.1 25.857.1 0 8.599 0 19.085v4.95z"
          mask="url(#d)"
        />
      </g>
      <g transform="translate(0 .536)">
        <path
          fill="#0094D9"
          d="M35.298 70.235h1.204c1.012 0 1.883-.552 1.883-1.63 0-1.257-1.076-1.706-2.088-1.706h-1v3.336zm0-4.235h.614c1.076 0 2.012-.744 2.012-1.706 0-.796-.551-1.245-1.576-1.245h-1.05V66zm-1.243 5.133v-8.982h2.78c1.332 0 2.331.667 2.331 1.835 0 1.463-1.14 2.156-1.832 2.284v.026c1.179.038 2.383.77 2.383 2.232 0 1.502-1.281 2.605-3.138 2.605h-2.524z"
        />
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          fill="#0094D9"
          d="M41.28 71.133h1.153v-6.416H41.28v6.416zm-.154-8.533c0-.32.269-.68.73-.68.461 0 .73.36.73.68 0 .347-.166.783-.73.783s-.73-.436-.73-.783zm4.009 5.248c0 1.207.41 2.618 1.909 2.618 1.512 0 1.921-1.411 1.921-2.618 0-1.154-.474-2.463-1.921-2.463-1.435 0-1.909 1.309-1.909 2.463m-1.242 0c0-2.078 1.537-3.284 3.151-3.284 1.614 0 3.164 1.206 3.164 3.284 0 1.99-1.268 3.44-3.164 3.44-1.883 0-3.151-1.45-3.151-3.44m12.695 2.772c-.628.449-1.384.667-1.935.667-3.036 0-3.42-2.49-3.42-3.375 0-1.604 1.063-3.349 3.471-3.349.654 0 1.243.167 1.717.526l-.384.655c-.372-.18-.846-.334-1.32-.334-1.05 0-2.241.693-2.241 2.438 0 1.335.678 2.592 2.228 2.592.551 0 1.064-.14 1.563-.462l.32.642zm2.382-5.107c.602-.642 1.524-.95 2.19-.95 1.474 0 2.268.821 2.268 2.438v4.132h-1.153v-3.888c0-1.206-.513-1.835-1.55-1.835-.91 0-1.742.629-1.742 1.86v3.863H57.83v-9.431h1.14v3.81zm10.376 1.719c0-1-.423-1.847-1.435-1.847-1.23 0-1.717 1.026-1.755 1.847h3.19zm-3.267.77c-.013 1.283.897 2.464 2.165 2.464.59 0 1.306-.218 1.755-.552l.333.629c-.666.475-1.46.744-2.127.744-1.998 0-3.317-1.232-3.317-3.49 0-2.22 1.588-3.234 3.036-3.234 1.947 0 2.6 1.758 2.472 3.44h-4.317zm6.879-2.489c.525-.616 1.294-.95 1.998-.95 1.012 0 1.64.424 2.037.95.615-.565 1.486-.95 2.203-.95 1.422 0 2.14.924 2.178 2.284v4.286h-1.153V67.31c0-.924-.23-1.848-1.409-1.848-.973 0-1.64.514-1.64 1.81v3.862H76.02V67.31c0-1.206-.513-1.848-1.448-1.848-.973 0-1.601.63-1.601 1.861v3.811h-1.153v-6.416h1.14v.796z"
          mask="url(#f)"
        />
      </g>
      <g transform="translate(0 .536)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path
          fill="#0094D9"
          d="M115.515 24.075c0-10.486-25.857-18.985-57.757-18.985C25.857 5.09 0 13.59 0 24.075 0 10.875 25.86.178 57.758.178c31.897 0 57.757 10.697 57.757 23.897"
          mask="url(#h)"
        />
        <g transform="translate(0 28.515)">
          <mask id="j" fill="#fff">
            <use xlinkHref="#i" />
          </mask>
          <path
            fill="#0094D9"
            d="M115.515.51c0 10.486-25.857 18.984-57.757 18.984C25.857 19.494 0 10.996 0 .51c0 13.199 25.86 23.897 57.758 23.897 31.897 0 57.757-10.698 57.757-23.897"
            mask="url(#j)"
          />
        </g>
        <path
          fill="#0094D9"
          d="M68.535 14.396l4.299 11.83.049-.002 4.296-11.828h3.802l-6.498 17.888-3.248.002-6.501-17.89zm-29.298-.002l4.297 11.832.051-.002 4.299-11.828 3.8-.002-6.5 17.889h-3.247l-6.496-17.889zm-7.085 14.314l-1.299-3.576-1.438.002 1.746-4.788h.048l6.801 18.677h3.797l-8.959-24.627h-3.354l-6.5 17.89 3.83.003 1.296-3.572zM63.976 18.07v-3.676H52.385v17.907h11.591v-3.677h-7.688V25.04h7.688v-3.676h-7.688V18.07zm29.259 0v-3.676l-11.634-.005v17.848h.043v.049h11.591v-3.675h-7.731V25.03h7.731v-3.679h-7.731V18.07z"
        />
        <path
          fill="#FFCB31"
          d="M93.665 39.023l-.005-4.34-51.189-.001 1.882 4.341zm-71.182 0l.006-4.338 11.376-.002 1.992 4.34z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
