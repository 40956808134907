import { BoxProps } from 'rebass/styled-components';

import { mediumBreakpoint, smallBreakpoint } from '../../../../utils/breakpoints';
import { chunkArray } from '../../../../utils/common';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import { ProductCard, type ProductCardProps, withErrorBoundary } from '../../../components';

import { StyledVerticalCardContainer, StyledVerticalProductWrapper, StyledWrapper } from './Styled';

interface VerticalProps extends BoxProps {
  products: ProductCardProps[];
}

const Vertical = ({ products }: VerticalProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const productChunkSize = currentBreakpoint === mediumBreakpoint ? 2 : 3;
  const chunkedProducts = chunkArray(products, productChunkSize);

  return (
    <StyledWrapper>
      {chunkedProducts.map(({ chunk, key }) => {
        const chunkHasPriceOrEnquiry: boolean = chunk.reduce((acc, { price, enquiryCta }) => {
          if (acc === true) {
            return true;
          }
          if (price || enquiryCta) {
            return true;
          }

          return false;
        }, false);

        return (
          <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`}>
            {chunk.map(({ id, ...productRest }) => (
              <StyledVerticalProductWrapper key={id} className={`cards-${chunk.length}`}>
                <ProductCard
                  showCommerceSection={currentBreakpoint === smallBreakpoint ? false : chunkHasPriceOrEnquiry}
                  id={id}
                  {...productRest}
                />
              </StyledVerticalProductWrapper>
            ))}
          </StyledVerticalCardContainer>
        );
      })}
    </StyledWrapper>
  );
};

export default withErrorBoundary(Vertical, { componentName: 'ProductListingModule/Vertical' });
