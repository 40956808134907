import { ChevronRight } from '@arvesta-websites/icons';
import { BoxProps } from 'rebass';
import { tv } from 'tailwind-variants';

import type { LocaleType, NavItemType, SubnavItem } from '../../../../../types';
import Utils, { createLink } from '../../../../../utils';
import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';

export interface SecondaryNavigationMobileItemProps extends BoxProps {
  item: SubnavItem;
  open?: boolean;
  activeLevel?: number;
  openItems?: Array<SubnavItem>;
  onClose: () => void;
  onClickItem: (clickedItem: NavItemType) => void;
  locale?: LocaleType;
}

const secondaryNavigationMobileItem = tv({
  slots: {
    chevronRight: 'w-3 h-3 ml-5',
    chevronRightSubMenu: 'w-3 h-3 ml-5 transform rotate-90',
    navItem: 'flex items-center justify-between cursor-pointer no-underline list-none navItem w-full text-content',
    navItemUnderline:
      'flex items-center justify-between list-none underline underline-offset-[5px] navItem cursor-pointer w-full text-header-primary',
    navItemWrapper: 'flex flex-col w-full',
    subMenu: 'flex m-0 list-none w-full py-2.5 px-0',
    subMenuItem: 'flex items-center justify-between list-none no-underline navItem cursor-pointer w-full text-content',
    subMenuItemsWrapper: 'flex flex-col w-full gap-2.5 py-1.5',
    subMenuLine: 'w-0.5 mr-2 bg-primary opacity-10',
  },
  variants: {
    isMenuOpen: {
      true: {
        chevronRightSubMenu: '-rotate-90',
      },
    },
  },
});

const renderSubMenu = (currentOpenItem: SubnavItem | undefined, locale: string) => {
  const { subMenu, subMenuLine, subMenuItemsWrapper, subMenuItem, navItemUnderline } = secondaryNavigationMobileItem();
  if (currentOpenItem && currentOpenItem.children?.length) {
    return (
      <>
        <ul className={subMenu()}>
          <div className={subMenuLine()}></div>
          <div className={subMenuItemsWrapper()}>
            {currentOpenItem.children.map((item: SubnavItem) => (
              <a href={createLink(item.to, locale)} key={item.id} className={subMenuItem()}>
                {item.label}
              </a>
            ))}
          </div>
        </ul>
        <Cta to={createLink(currentOpenItem?.to, locale)} className={navItemUnderline()}>
          {currentOpenItem?.label}
        </Cta>
      </>
    );
  }

  return null;
};

export const SecondaryNavigationMobileItem = ({
  item,
  open,
  onClickItem,
  onClose,
  activeLevel,
  openItems,
  locale: { path: locale },
}: SecondaryNavigationMobileItemProps) => {
  const currentOpenItem = openItems?.find((openItem: NavItemType) => openItem.to === item.to);

  const clickAction = () => {
    if (open) {
      onClose();
    } else {
      onClickItem(item);
    }
  };

  const { navItemWrapper, navItem, chevronRightSubMenu, chevronRight } = secondaryNavigationMobileItem({
    isMenuOpen: currentOpenItem ? true : false,
  });

  return (
    <div className={navItemWrapper()}>
      {activeLevel >= 3 && item.children?.length ? (
        <button key={item.id} onClick={clickAction} className={navItem()}>
          {item.label}
          {!!item.children?.length && <ChevronRight className={chevronRightSubMenu()} />}
        </button>
      ) : item.children?.length ? (
        <button key={item.id} onClick={clickAction} className={navItem()}>
          {item.label}
          {!!item.children?.length && <ChevronRight className={chevronRight()} />}
        </button>
      ) : (
        <a href={Utils.getIntlSlug(item.to)} key={item.id} onClick={clickAction} className={navItem()}>
          {item.label}
          {!!item.children?.length && <ChevronRight className={chevronRight()} />}
        </a>
      )}
      {renderSubMenu(currentOpenItem, locale)}
    </div>
  );
};

export default withErrorBoundary(SecondaryNavigationMobileItem, {
  componentName: 'SecondaryNavigationMobileItemDefault',
});
