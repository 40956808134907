import React from 'react';

// Dummy implementation of LocationContext
export const LocationContext = React.createContext({
  hash: '',
  host: 'localhost:8000',
  hostname: 'localhost',
  href: 'http://localhost:8000/nl/',
  key: '1699347233693',
  origin: 'http://localhost:8000',
  pathname: '/nl/',
  port: '8000',
  protocol: 'http:',
  search: '',
  state: {
    key: '1699347233693',
  },
});
export const LocationContextProvider = LocationContext.Provider;
export const LocationContextConsumer = LocationContext.Consumer;
