import styled from 'styled-components';

import Media from '../../../../utils/Media';

interface IExpandedStyled {
  expanded?: boolean;
  checked?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 18px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<IExpandedStyled>`
  display: flex;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked ? props.theme.dealer.textColor : props.disabled ? props.theme.colors.muted : '#fff'};
  border: 1px solid white;
  transition: all 150ms;
`;

export const StyledCheckboxContainer = styled.div<IExpandedStyled>`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: ${({ disabled, theme }) =>
    disabled ? `1px solid ${theme.colors.textMuted}` : `1px solid ${theme.colors.black}`};
  transition: all 150ms;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.formInputs?.inputPadding ?? '8px'};
  border-radius: ${props => props.theme.formInputs.borderRadius};
  height: 48px;
  align-items: center;

  > span {
    align-self: center;
  }
`;

export const StyledDropDown = styled.div<IExpandedStyled>`
  position: absolute;
  top: 48px;
  z-index: 1;
  padding: 8px;
  border-radius: ${props => props.theme.formInputs.borderRadius};
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  width: 100%;

  @media (max-width: 1084px) {
    min-width: 300px;
  }

  @media (min-width: 1024px) {
    min-width: 300px;
  }

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }
  @media (max-width: 601px) {
    min-width: 100%;
  }
`;

export const StyledDropDownContainer = styled.div<IExpandedStyled>`
  position: relative;
  color: ${props => props.theme.colors.text};
  min-width: 300px;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  height: 48px;
  border: ${({ theme }) => `1px solid ${theme.colors.black}`};
  border-radius: ${props => !props.expanded && props.theme.formInputs.borderRadius};

  ${Media({
    marginBottom: ['8px', null, null, '0px'],
    marginRight: ['0px', null, '8px', '0px'],
  })}

  @media (min-width: 1024px) {
    margin-right: 16px;
  }

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }
`;

interface StyledDropDownSeparatorProps {
  disabled?: boolean;
}
export const StyledDropDownItemSeparator = styled.div<StyledDropDownSeparatorProps>`
  padding: 8px 0px 4px 4px;
  &:hover,
  &:focus-within {
    background: ${props => (!props.disabled ? props.theme.colors.muted : 'transparent')};
  }

  > label {
    display: flex;
    height: 26px;
    align-items: center;
    cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.textMuted : theme.colors.text)};

    &:focus {
      background: ${props => (!props.disabled ? props.theme.colors.muted : 'transparent')};
    }

    > span {
      height: 18px;
      line-height: 20px;
      display: inline-block;
    }
  }
`;

export const StyledDropDownClear = styled.div`
  color: ${props => props.theme.dealer.textColor};
  padding: 12px 8px 8px 8px;
  cursor: pointer;
`;

export const StyledDropDownSeparator = styled.div<IExpandedStyled>`
  border-bottom: ${props => (props.expanded ? '1px' : '0px')} solid lightgray;
  height: 16%;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -8px;
`;
