import { DocumentDownload, withErrorBoundary } from '../../components';

import { DocumentDownloadContainer, StyledWrapper } from './Styled';

export type DocumentDownloadModuleProps = {
  id?: string;
  documentTitle: string;
  documentType: string | null;
  fileSize?: string | null;
  downloadLink: string;
  renderMobileVariant?: boolean;
  showDocumentInfo?: boolean;
};

const DocumentDownloadModule = ({
  documentTitle,
  documentType,
  fileSize,
  downloadLink,
}: DocumentDownloadModuleProps) => (
  <StyledWrapper>
    <DocumentDownloadContainer>
      <DocumentDownload
        documentTitle={documentTitle}
        documentType={documentType}
        fileSize={fileSize}
        downloadLink={downloadLink}
        showDocumentInfo
      />
    </DocumentDownloadContainer>
  </StyledWrapper>
);

export default withErrorBoundary(DocumentDownloadModule, { componentName: 'DocumentDownloadModule' });
