import * as React from 'react';

const HeroBannerCustomMaskDesktop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 1440 47.83"
    preserveAspectRatio="none"
    style={{ bottom: 0, left: 0 }}
    {...props}
  >
    <path
      id="Subtraction_4"
      data-name="Subtraction 4"
      d="M17940,211.694H16500v-47.83h589.221a142.24,142.24,0,0,1,21.277,1.622,132.421,132.421,0,0,1,50.646,18.654l29.676,18.469a55.193,55.193,0,0,0,57.492,0l29.682-18.469a132.415,132.415,0,0,1,50.635-18.654,142.123,142.123,0,0,1,21.271-1.622H17940v47.828h0v0Z"
      transform="translate(-16500 -163.40)"
      fill="#ffffff"
    />
  </svg>
);

const HeroBannerCustomMaskMobile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 385 47.83"
    preserveAspectRatio="none"
    style={{ bottom: 0, left: 0 }}
    {...props}
  >
    <path
      id="Subtraction_5"
      data-name="Subtraction 5"
      d="M17412,211.694h-385v-47.83h62.219a142.234,142.234,0,0,1,21.277,1.622,132.425,132.425,0,0,1,50.646,18.654l29.676,18.469a55.193,55.193,0,0,0,57.492,0l29.682-18.469a132.419,132.419,0,0,1,50.635-18.654,142.117,142.117,0,0,1,21.271-1.622h62.1v47.828h0v0Z"
      transform="translate(-17027.002 -163.40)"
      fill="#ffffff"
    />
  </svg>
);

const HeroBannerCustomMaskTablet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 768 47.83"
    width="100%"
    preserveAspectRatio="none"
    style={{ bottom: 0, left: 0 }}
    {...props}
  >
    <path
      id="Subtraction_5"
      data-name="Subtraction 5"
      d="M17603,211.694h-768l0-47.83h254.217a142.214,142.214,0,0,1,21.279,1.622,132.437,132.437,0,0,1,50.645,18.654l29.676,18.469a55.2,55.2,0,0,0,57.5,0l29.676-18.469a132.471,132.471,0,0,1,50.637-18.654,142.1,142.1,0,0,1,21.27-1.622H17603v47.828h0v0Z"
      transform="translate(-16835.002 -163.40)"
      fill="#ffffff"
    />
  </svg>
);

export default {
  Desktop: HeroBannerCustomMaskDesktop,
  Mobile: HeroBannerCustomMaskMobile,
  Tablet: HeroBannerCustomMaskTablet,
};
