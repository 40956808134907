import type { NavItemType } from '../../../types';

const matchUrl = (currentPageUrl: string, navItem: NavItemType): any => {
  if (!navItem.to || navItem.to.startsWith('http')) {
    return false;
  }

  return (
    currentPageUrl.toLowerCase().match(new RegExp(`/(.*)/${navItem.to.toLowerCase()}`, 'i')) ||
    currentPageUrl.toLowerCase().match(new RegExp(`/(.*)/${navItem.to.toLowerCase()}/`, 'i')) ||
    currentPageUrl.toLowerCase().match(new RegExp(`/(.*)/${navItem.to.toLowerCase()}/(.*)`, 'i'))
  );
};

export default matchUrl;
