import React from 'react';

import De from './De';
import Es from './Es';
import Fr from './Fr';
import Nl from './Nl';
import Pt from './Pl';
import Pl from './Pt';
import Uk from './Uk';

const flags: Record<string, React.ReactElement> = {
  de: <De />,
  en: <Uk />,
  es: <Es />,
  fr: <Fr />,
  nl: <Nl />,
  pl: <Pl />,
  pt: <Pt />,
};

export * from './De';
export * from './Es';
export * from './Fr';
export * from './Nl';
export * from './Pl';
export * from './Pt';
export * from './Uk';

export default flags;
export { De, Es, flags, Fr, Nl, Pl, Pt, Uk };
